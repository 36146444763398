import React from 'react';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import api from '../../../service/api';
import SupplierProfile from '../../../component/supplier/SupplierProfile';
import CompanyProfileHeader from '../../../component/company/CompanyProfileHeader';

export class SupplierProfileContainer extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      supplier: {},
      cancelSource: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    try {
      const supplierId = this.props.match.params.supplierId;
      const res = await api.supplierGet(
        this.props.companyId,
        supplierId,
        this.state.cancelSource.token
      );

      this.setState({ supplier: res.data, isLoading: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    this.state.cancelSource.cancel();
  }

  render() {
    const { supplier } = this.state;
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div>
        <CompanyProfileHeader company={supplier} />
        <SupplierProfile supplier={supplier} />
      </div>
    );
  }
}

export default withRouter(SupplierProfileContainer);
