export const DASHBOARD_GET_COMPANIES_SUCCESS = 'importist/dashboard/GET_COMPANIES_SUCCESS';
export const DASHBOARD_GET_PROJECTS_SUCCESS = 'importist/dashboard/GET_PROJECTS_SUCCESS';
export const DASHBOARD_ADD_PROJECT_SUCCESS = 'importist/dashboard/ADD_PROJECT_SUCCESS';
export const DASHBOARD_EDIT_PROJECT_SUCCESS = 'importist/dashboard/EDIT_PROJECT_SUCCESS';
export const DASHBOARD_CHANGE_ACTIVE_PROJECT_ID_SUCCESS =
  'importist/dashboard/CHANGE_ACTIVE_PROJECT_ID_SUCCESS';
export const DASHBOARD_ACTIVE_COMPANY_SET = 'importist/dashboard/ACTIVE_COMPANY_SET';
export const DASHBOARD_CHANGE_ACTIVE_COMPANY_ID_SUCCESS =
  'importist/dashboard/CHANGE_ACTIVE_COMPANY_ID_SUCCESS';
export const DASHBOARD_SET_COMPANY_NEW_MESSAGES_COUNT_SUCCESS =
  'importist/dashboard/SET_COMPANY_NEW_MESSAGES_COUNT_SUCCESS';
export const DASHBOARD_INC_COMPANY_NEW_MESSAGES_COUNT_SUCCESS =
  'importist/dashboard/INC_COMPANY_NEW_MESSAGES_COUNT_SUCCESS';
export const DASHBOARD_GET_PRODUCTS_SUCCESS = 'importist/dashboard/GET_PRODUCTS_SUCCESS';
export const DASHBOARD_LEFT_MENU_SET_ACTIVE_ITEM_SUCCESS =
  'importist/dashboard/LEFT_MENU_SET_ACTIVE_ITEM_SUCCESS';
export const DASHBOARD_SET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS =
  'importist/dashboard/SET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS';
export const DASHBOARD_INC_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS =
  'importist/dashboard/INC_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS';
export const DASHBOARD_REPLACE_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS =
  'importist/dashboard/REPLACE_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS';
export const DASHBOARD_RESET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS =
  'importist/dashboard/RESET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS';
export const DASHBOARD_WS_CHANNEL_COMPANY_SET = 'importist/dashboard/CHANNEL_COMPANY_SET';
