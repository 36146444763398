import { createSelector } from 'reselect';

const projectsSelector = (state) => state.dashboard.projects;
const activeProjectIdSelector = (state) => state.dashboard.activeProjectId;

export const projectSelector = (projects, projectId) => {
  let res = null;
  if (Array.isArray(projects)) {
    const filtered = projects.filter((project) => project._id === projectId);
    if (filtered.length > 0) {
      res = filtered[0];
    }
  }

  return res;
};

export const activeProjectSelector = createSelector(
  projectsSelector,
  activeProjectIdSelector,
  projectSelector
);
