import React from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import SpinnerLoading from '../../common/SpinnerLoading';
import TableUsers from '../TableUsers';
import { areArraysOfStringsNotOrderedDiff } from '../../../util/array';
import { usersSortActiveOnTop } from '../../../util/user';

export const Form = ({
  project,
  usersError,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isValid,
  isSubmitting,
}) => {
  const areUsersChanged = areArraysOfStringsNotOrderedDiff(project.users, values.users);

  return (
    <div>
      <label className="section-header">Team Members</label>
      <label className="section-header-description">
        These members have access to this project. Add or remove others here.
      </label>
      <form onSubmit={handleSubmit}>
        <TableUsers
          users={values.sortedUsers}
          initialUserIds={project.users}
          values={values}
          setFieldValue={setFieldValue}
        />
        {usersError && <label className="error common">{usersError}</label>}
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className={'actions-bottom'}>
          {areUsersChanged && (
            <button
              onClick={() => {
                setFieldValue('users', project.users);
              }}
              className="btn white-button"
              disabled={isSubmitting}
              type="button"
            >
              Cancel
            </button>
          )}
          <button
            className="btn blue-button"
            disabled={isSubmitting || !areUsersChanged}
            type="submit"
          >
            Save
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    </div>
  );
};

const EditProjectUsersForm = withFormik({
  mapPropsToValues: (props) => ({
    _id: props.project._id,
    users: props.project.users,
    sortedUsers: usersSortActiveOnTop(props.users, props.project.users),
  }),
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(null, values, setSubmitting, setErrors);
  },
})(Form);

EditProjectUsersForm.propTypes = {
  project: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProjectUsersForm;
