import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import { getShortDatetimeDistance } from '../../../util/date';

const CompanyPhotoStyled = styled.div`
  width: 46px;
  height: 46px;
  background: center/contain no-repeat;
  margin-right: 20px;
`;

const CompanyPhotoStyledPlaceholder = styled.img`
  width: 46px;
  height: 46px;
  margin-right: 20px;
`;

class ChatListItem extends React.Component {
  static propTypes = {
    activeCompanyId: PropTypes.string,
    chatActiveId: PropTypes.string,
    chat: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      clientCompany: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      supplierCompany: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      lastMessageUserFullName: PropTypes.string,
    }),
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { chat } = this.props;
    const companionCompany =
      chat.clientCompanyId !== this.props.activeCompanyId
        ? chat.clientCompany
        : chat.supplierCompany;

    const countUnread = chat.totalMessages - chat.chatUser.readCount;

    return (
      <div
        className={classNames('chat-preview', {
          active: this.props.chatActiveId === chat._id,
        })}
        onClick={() => this.props.onClick(chat._id)}
      >
        <div className="chat-heading">
          {companionCompany.logoUrl ? (
            <CompanyPhotoStyled style={{ backgroundImage: `url(${companionCompany.logoUrl})` }} />
          ) : (
            <CompanyPhotoStyledPlaceholder src={imgPlaceholder} alt={''} />
          )}
          <div className="heading-with-names">
            <div className="company-name">{companionCompany.name}</div>
            <div className="contact-name">{chat.lastMessageUserFullName}</div>
          </div>
          <div className="time-stamp">{getShortDatetimeDistance(chat.lastMessageAt)}</div>
        </div>
        <span className="btn white-button-small product-name">{chat.product.name}</span>
        {countUnread > 0 && <div className="counter-new-messages">{countUnread}</div>}
      </div>
    );
  }
}

export default ChatListItem;
