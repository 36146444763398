import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
import _startCase from 'lodash.startcase';

import * as navigation from '../QuoteForm/navMenu';
import QuoteFormSectionHeader, { HeaderValidationScheme } from '../QuoteFormSectionHeader';
import StickyMenu from '../../common/StickyMenu';
import QuoteFormSectionSignup from '../QuoteFormSectionSignup';
import QuoteFormSectionSpecifications from '../QuoteFormSectionSpecifications';
import QuoteFormSectionQuantities from '../QuoteFormSectionQuantities';
import QuoteFormSectionSamples, { SamplesValidationScheme } from '../QuoteFormSectionSamples';
import QuoteFormSectionQuestions, { QuestionsValidationScheme } from '../QuoteFormSectionQuestions';
import ErrorTop from '../../form/ErrorTop';
import {
  prepareQuoteValues,
  prepareAccountValues,
  validateRequiredQuantity,
  SignupValidationScheme,
  QuantitiesValidationScheme,
  ShippingValidationScheme,
} from '../QuoteForm/helper';

export class Form extends Component {
  static defaultProps = {
    navigation: navigation,
    readOnly: false,
  };

  static propTypes = {
    product: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.string,
    userCompanyId: PropTypes.string,
    onSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    goToClientProfile: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      values,
      errors,
      setFieldValue,
      setErrors,
      setSubmitting,
      isValid,
      isSubmitting,
      handleSubmit,
      t,
    } = this.props;

    const sectionHeaderRefs = [
      navigation.sectionAccount,
      navigation.sectionSpecifications,
      navigation.sectionQuantities,
    ];
    if (values.samplesNumber > 0) {
      sectionHeaderRefs.push(navigation.sectionSamples);
    }
    if (values.questions.length > 0) {
      sectionHeaderRefs.push(navigation.sectionExtraQuestions);
    }

    const sectionHeaderRefsTranslated = sectionHeaderRefs.map((item) => {
      const newItem = { ...item };
      newItem.label = _startCase(t(`common.${newItem.name}`));
      return newItem;
    });

    const clientName = this.props.company.name;
    const productName = this.props.product.name;

    return (
      <form
        className={'padding-bottom-180 quote-request-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div>
          <Trans i18nKey="quote.p1.headerText">
            You have been invited by <strong>{{ clientName }}</strong> to quote for{' '}
            <strong>{{ productName }}</strong> on the Importist Platform. Importist is a free tool
            to help buyers and sellers manage their quotes and contacts more efficiently.
          </Trans>
        </div>
        <p>
          {t('quote.p1.Already a member?')} &nbsp;
          <a href="/signin">{t('quote.p1.Click here to login')}</a>
        </p>
        <QuoteFormSectionHeader {...this.props} />
        <ErrorTop
          isSubmitting={isSubmitting}
          isValid={isValid}
          errors={errors}
          commonErrorText={t('Please, fix errors below')}
        />
        <StickyMenu items={sectionHeaderRefsTranslated} />

        <label className="section-header" ref={this.props.navigation.sectionAccount.ref}>
          {_startCase(t(`common.${this.props.navigation.sectionAccount.name}`))}
        </label>
        <QuoteFormSectionSignup {...this.props} />

        <label className="section-header" ref={this.props.navigation.sectionSpecifications.ref}>
          {_startCase(t(`common.${this.props.navigation.sectionSpecifications.name}`))}
        </label>
        <QuoteFormSectionSpecifications product={this.props.product} t={this.props.t} />
        <label className="section-header" ref={this.props.navigation.sectionQuantities.ref}>
          {_startCase(t(`common.${this.props.navigation.sectionQuantities.name}`))}
        </label>
        <QuoteFormSectionQuantities {...this.props} />
        {values.samplesNumber > 0 && (
          <label className="section-header" ref={this.props.navigation.sectionSamples.ref}>
            {_startCase(t(`common.${this.props.navigation.sectionSamples.name}`))}
          </label>
        )}
        {values.samplesNumber > 0 && <QuoteFormSectionSamples {...this.props} />}

        {values.questions.length > 0 && (
          <label className="section-header" ref={this.props.navigation.sectionExtraQuestions.ref}>
            {_startCase(t(`common.${this.props.navigation.sectionExtraQuestions.name}`))}
          </label>
        )}
        {values.questions.length > 0 && <QuoteFormSectionQuestions {...this.props} />}

        {errors.common && <label className="error common">{t(errors.common, errors.common)}</label>}
        {!this.props.readOnly && (
          <div className={'actions-bottom'}>
            <button
              className="btn blue-button wide-button"
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                setFieldValue('status', 'complete');
                handleSubmit(values, { props: this.props, setSubmitting, setErrors });
              }}
            >
              {t('quote.p1.Send Quote and Create Account')}
            </button>
          </div>
        )}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  ...HeaderValidationScheme,
  ...SignupValidationScheme,
  ...QuantitiesValidationScheme,
  ...ShippingValidationScheme,
  ...SamplesValidationScheme,
  ...QuestionsValidationScheme,
});

const QuoteFormSignup = withFormik({
  mapPropsToValues: (props) => {
    const quoteValues = prepareQuoteValues(props);
    const accountValues = prepareAccountValues(props);

    return {
      ...quoteValues,
      ...accountValues,
    };
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantity(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }

    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

QuoteFormSignup.defaultProps = {
  quote: {},
};

export default QuoteFormSignup;
