import {
  SIGNUP_SUBMITTING,
  SIGNUP_EMAIL_SUCCESS,
  SIGNUP_PASSWORD_SUCCESS,
  SIGNUP_COMPANY_SUCCESS,
  SIGN_IN_COMPLETE_USER_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_AND_SEND_QUOTE_SUCCESS,
  SIGNUP_SET_INVITED_TOKEN_SUCCESS,
  SIGNUP_SET_INVITED_COMPANY_SUCCESS,
  SIGNUP_EMAIL_RESTORE,
} from './constants';

export function signupCompleteSuccess(state = false, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function signupAndSendQuoteSuccess(state = false, action) {
  switch (action.type) {
    case SIGNUP_AND_SEND_QUOTE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function signupSetInvitedToken(state = null, action) {
  switch (action.type) {
    case SIGNUP_SET_INVITED_TOKEN_SUCCESS:
      return action.token;
    default:
      return state;
  }
}

export function signupSetInvitedCompany(state = null, action) {
  switch (action.type) {
    case SIGNUP_SET_INVITED_COMPANY_SUCCESS:
      return action.company;
    default:
      return state;
  }
}

export function signupSubmitting(state = false, action) {
  switch (action.type) {
    case SIGNUP_SUBMITTING:
      return action.isSubmitting;
    default:
      return state;
  }
}

export function signupSuccess(state, action) {
  if (typeof state === 'undefined') {
    state = {
      _id: null,
      signupType: '',
      fullName: '',
      email: '',
      createdAt: '',
      company: {
        _id: null,
      },
    };
  }
  switch (action.type) {
    case SIGNUP_EMAIL_SUCCESS:
      return {
        ...state,
        ...action.user,
      };
    case SIGNUP_EMAIL_RESTORE:
      return {
        ...state,
        ...action.user,
      };
    case SIGNUP_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case SIGNUP_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
      };
    default:
      return state;
  }
}

export function signInCompleteUserSuccess(state = {}, action) {
  switch (action.type) {
    case SIGN_IN_COMPLETE_USER_SUCCESS:
      return action.user;
    default:
      return state;
  }
}
