import React from 'react';
import PropTypes from 'prop-types';

const IconNounGroup = (props) => {
  const viewBox = `0 0 ${props.width} ${props.height}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g fill="#0095FF" fillRule="nonzero">
        <path d="M16.353 21.11c-.224-.305-.433-.56-.617-.841-.328-.489-.608-1.001-.616-1.618 0-.112-.105-.232-.185-.328-.809-.945-1.345-2.01-1.49-3.26-.063-.544 0-1.065.473-1.449.08-.064.096-.24.104-.36.008-.785.04-1.578 0-2.363-.104-1.786.953-3.844 3.468-4.484a8.44 8.44 0 0 1 3.924-.08c2.026.44 3.3 1.801 3.556 3.867.12.961.072 1.938.088 2.907 0 .217.032.345.224.521.224.208.376.585.4.905.088 1-.288 1.898-.84 2.699-.473.696-.881 1.393-1.122 2.21-.168.569-.576 1.065-.913 1.642.297.16.65.384 1.017.552 1.386.617 2.78 1.217 4.173 1.826 3.123 1.37 4.484 3.844 4.524 7.167.008.585-.232 1.017-.768 1.178-.961.296-1.938.616-2.931.76-4.765.681-9.57.81-14.375.561-2.194-.112-4.389-.328-6.503-1.01-.072-.023-.152-.047-.224-.08-1.121-.424-1.345-.736-1.217-1.913.2-1.754.808-3.356 1.89-4.773.536-.697 1.217-1.201 2.002-1.562 1.513-.688 3.05-1.337 4.572-2.01.465-.2.905-.432 1.386-.664z" />
        <path d="M29.27 14.991c.336.169.729.393 1.137.577 1.426.633 2.86 1.241 4.277 1.89 2.698 1.25 3.972 3.467 4.236 6.35.016.16.024.32.04.48.064.906-.216 1.314-1.121 1.49-.85.169-1.706.273-2.57.393-.65.088-1.298.144-1.947.232-.272.04-.448.008-.552-.288-.537-1.554-1.65-2.547-3.108-3.204a328.423 328.423 0 0 1-3.811-1.761c-.345-.16-.673-.369-1.074-.593.52-.929.977-1.842 1.522-2.707.809-1.297 1.105-2.739.673-4.14-.377-1.241-.417-2.499-.593-3.748-.392-2.779-2.33-4.693-5.133-4.997-.168-.016-.345-.048-.529-.08 0-.384-.064-.777.008-1.145.417-1.986 1.962-3.372 3.972-3.636a9.2 9.2 0 0 1 3.108.104c2.122.44 3.443 1.93 3.643 4.156.08.897.032 1.81.024 2.715 0 .184.008.305.2.417.36.208.409.608.425.985.04 1.057-.352 1.994-.945 2.835a6.625 6.625 0 0 0-.945 1.906c-.184.584-.593 1.12-.937 1.77zM9.866 14.927c-.777-.768-1.137-1.657-1.33-2.626-.031-.153-.175-.297-.28-.433-.656-.84-1.177-1.754-1.313-2.827-.072-.6-.136-1.225.505-1.65.096-.063.08-.336.064-.512-.096-1.041-.08-2.082.136-3.107C8.12 1.61 9.562.32 11.772.088c1.321-.136 2.635-.144 3.892.392 1.69.73 2.81 2.587 2.603 4.357-.128.024-.265.056-.4.088-.617.16-1.25.28-1.843.505-1.93.744-3.05 2.17-3.371 4.212-.088.576-.216 1.17-.184 1.746.048.953-.473 1.761-.569 2.666-.144 1.314.032 2.571.745 3.724.545.881 1.017 1.802 1.554 2.755-.449.24-.825.473-1.218.657-1.169.552-2.346 1.089-3.523 1.617-1.522.681-2.707 1.69-3.276 3.316-.08.224-.2.304-.44.272-1.426-.192-2.859-.368-4.285-.552-.208-.025-.416-.097-.616-.153-.48-.136-.76-.464-.769-.96-.04-2.956.937-5.43 3.612-6.928 1.481-.825 3.099-1.417 4.653-2.122.488-.216.977-.472 1.53-.753z" />
      </g>
    </svg>
  );
};

IconNounGroup.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconNounGroup;
