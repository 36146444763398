import React from 'react';
import axios, { CancelToken } from 'axios';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import QuoteForm from '../../../component/quote/QuoteForm';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import {
  addCommonFormikErrorsIfEmpty,
  errorsTransformAxiosToFormik,
  SOMETHING_WENT_WRONG_ERROR_TEXT,
} from '../../../util/error';
import api from '../../../service/api';
import {
  prepareSaveQuantities,
  prepareSaveSamples,
} from '../../../component/quote/QuoteForm/helper';

const ContainerStyled = styled.div`
  margin-top: 64px;
  width: 740px;
  margin-left: auto;
  margin-right: auto;
`;

export class QuoteEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      cancelSourceGetProduct: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    try {
      const quoteId = this.props.match.params.quoteId;
      const res = await api.quoteSupplierGet(
        this.props.activeCompanyId,
        quoteId,
        this.state.cancelSourceGetProduct.token
      );
      this.setState({ quote: res.data, isLoading: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    this.state.cancelSourceGetProduct.cancel();
  }

  onSaveQuote = async (values, setSubmitting, setErrors) => {
    try {
      let payload = prepareSaveQuantities(values);
      payload = prepareSaveSamples(payload);

      await api.quoteSupplierPut(this.props.activeCompanyId, payload);
      setSubmitting(false);
      this.props.history.push(`/dashboard/company/${this.props.activeCompanyId}/quotes`);
    } catch (err) {
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };

  render() {
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <ContainerStyled>
        {this.state.error ? (
          <div>{this.state.error}</div>
        ) : (
          <QuoteForm
            quote={this.state.quote}
            product={this.state.quote.product}
            company={this.state.quote.clientCompany}
            userId={this.props.user._id}
            userCompanyId={this.props.activeCompanyId}
            onSubmit={this.onSaveQuote}
            t={this.props.t}
          />
        )}
      </ContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  company: state.dashboard.company,
  activeCompanyId: state.dashboard.activeCompanyId,
});

export default withRouter(connect(mapStateToProps)(withNamespaces()(QuoteEditFormContainer)));
