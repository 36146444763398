import React from 'react';
import axios, { CancelToken } from 'axios';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import QuoteForm from '../../../component/quote/QuoteForm';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import {
  addCommonFormikErrorsIfEmpty,
  errorsTransformAxiosToFormik,
  SOMETHING_WENT_WRONG_ERROR_TEXT,
} from '../../../util/error';
import api from '../../../service/api';
import {
  prepareSaveQuantities,
  prepareSaveSamples,
} from '../../../component/quote/QuoteForm/helper';

const ContainerStyled = styled.div`
  margin-top: 64px;
  width: 740px;
  margin-left: auto;
  margin-right: auto;
`;

export class QuoteRequestFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      product: {},
      cancelSourceFetchData: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    try {
      const productId = this.props.match.params.productId;
      const [res, resQuoteAlreadyCreated] = await Promise.all([
        api.quoteRequestProductGet(productId, this.state.cancelSourceFetchData.token),
        api.supplierProductQuoteGet(this.props.activeCompanyId, productId).catch((err) => {
          if (err.response.status !== 404) {
            throw err;
          }
        }),
      ]);

      if (resQuoteAlreadyCreated) {
        const path =
          resQuoteAlreadyCreated.data.status === 'complete'
            ? `/dashboard/company/${this.props.activeCompanyId}/quotes/${
                resQuoteAlreadyCreated.data._id
              }`
            : `/dashboard/company/${this.props.activeCompanyId}/quote/${
                resQuoteAlreadyCreated.data._id
              }`;

        this.props.history.push(path);
      } else {
        this.setState({
          product: res.data,
          isLoading: false,
        });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    this.state.cancelSourceFetchData.cancel();
  }

  onCreateQuoteProduct = async (values, setSubmitting, setErrors) => {
    try {
      let payload = prepareSaveQuantities(values);
      payload = prepareSaveSamples(payload);

      await api.quoteSupplierPost(this.props.activeCompanyId, payload);
      setSubmitting(false);
      this.props.history.push(`/dashboard/company/${this.props.activeCompanyId}/quotes`);
    } catch (err) {
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };

  render() {
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <ContainerStyled>
        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : (
          <QuoteForm
            product={this.state.product}
            company={this.state.product.clientCompany}
            userId={this.props.user._id}
            userCompanyId={this.props.activeCompanyId}
            onSubmit={this.onCreateQuoteProduct}
            t={this.props.t}
          />
        )}
      </ContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  activeCompanyId: state.dashboard.activeCompanyId,
});

export default withRouter(connect(mapStateToProps)(withNamespaces()(QuoteRequestFormContainer)));
