import { PRODUCT_CREATE_NEW_SUCCESS } from './constants';

export function productCreateNew(state = null, action) {
  switch (action.type) {
    case PRODUCT_CREATE_NEW_SUCCESS:
      return action.product;
    default:
      return state;
  }
}
