import produce from 'immer';
import {
  CHAT_COMPANY_SET_LOADING,
  CHAT_COMPANY_ADD_CHATS_SUCCESS,
  CHAT_COMPANY_FETCH_CHATS_SUCCESS,
  CHAT_CURRENT_MESSAGES_SET_LOADING,
  CHAT_CURRENT_MESSAGES_ADD_SUCCESS,
  CHAT_CURRENT_MESSAGES_FETCH_SUCCESS,
  CHAT_COMPANY_REPLACE_OR_ADD_CHAT_SUCCESS,
  CHAT_CURRENT_MESSAGES_NEW_SUCCESS,
  CHAT_ACTIVE_ID_SET,
  CHAT_COMPANY_UPDATE_USER_READ_COUNT_SUCCESS,
  CHAT_COMPANY_REVERT_USER_READ_COUNT,
} from './constants';
import { chatIdInArray, chatInArray } from '../../util/chat';

export function chatCompanyChats(state = { items: [], totalCount: 0 }, action) {
  switch (action.type) {
    case CHAT_COMPANY_FETCH_CHATS_SUCCESS:
      return {
        items: action.data.items,
        totalCount: action.data.totalCount,
      };
    case CHAT_COMPANY_ADD_CHATS_SUCCESS:
      const newItems = [...state.items];
      action.data.items.forEach((loadedItem) => {
        if (!chatInArray(loadedItem, state.items)) {
          newItems.push(loadedItem);
        }
      });
      return {
        items: newItems,
        totalCount: action.data.totalCount,
      };
    case CHAT_COMPANY_REPLACE_OR_ADD_CHAT_SUCCESS:
      let totalCount = state.totalCount;
      // Check if chat is in the list
      if (!chatInArray(action.chat, state.items)) {
        totalCount++;
      }

      return {
        items: [action.chat, ...state.items.filter((ch) => ch._id !== action.chat._id)],
        totalCount: totalCount,
      };
    case CHAT_COMPANY_UPDATE_USER_READ_COUNT_SUCCESS:
      if (!chatIdInArray(action.chatUser.chatId, state.items)) {
        return state;
      }

      return produce(state, (draftState) => {
        for (let [index, chat] of draftState.items.entries()) {
          // Update only if new data
          if (
            chat._id === action.chatUser.chatId &&
            new Date(chat.chatUser.updatedAt) <= new Date(action.chatUser.updatedAt)
          ) {
            draftState.items[index].chatUser = { ...action.chatUser };
          }
        }
      });
    case CHAT_COMPANY_REVERT_USER_READ_COUNT:
      if (!chatIdInArray(action.chatId, state.items)) {
        return state;
      }

      return produce(state, (draftState) => {
        for (let [index, chat] of draftState.items.entries()) {
          if (chat._id === action.chatId && typeof chat.chatUser.realReadCount !== 'undefined') {
            draftState.items[index].chatUser.readCount = chat.chatUser.realReadCount;
            delete draftState.items[index].chatUser.realReadCount;
          }
        }
      });
    default:
      return state;
  }
}

export function chatCompanySetLoading(state = false, action) {
  switch (action.type) {
    case CHAT_COMPANY_SET_LOADING:
      return action.loading;
    default:
      return state;
  }
}

export function chatCurrentMessagesSetLoading(state = false, action) {
  switch (action.type) {
    case CHAT_CURRENT_MESSAGES_SET_LOADING:
      return action.loading;
    default:
      return state;
  }
}

export function chatCurrentMessagesChange(state = [], action) {
  switch (action.type) {
    case CHAT_CURRENT_MESSAGES_ADD_SUCCESS:
      return action.data;
    case CHAT_CURRENT_MESSAGES_FETCH_SUCCESS:
      return [...action.data, ...state];
    case CHAT_CURRENT_MESSAGES_NEW_SUCCESS:
      return [...state, ...action.data];
    default:
      return state;
  }
}

export function chatIdActive(state = null, action) {
  switch (action.type) {
    case CHAT_ACTIVE_ID_SET:
      return action.chatId;
    default:
      return state;
  }
}
