import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import defaultEn from '../locales/en/translation.json';
import defaultZh from '../locales/zh/translation.json';

i18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // special options for react-i18next
  // learn more: https://react.i18next.com/components/i18next-instance
  react: {
    wait: true,
  },
  resources: {
    en: {
      translation: defaultEn,
    },
    zh: {
      translation: defaultZh,
    },
  },
  detection: {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'i18nextLng',
  },
});

export default i18n;
