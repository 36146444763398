import React from 'react';
import PropTypes from 'prop-types';

const IconCopy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M12 6V3a1 1 0 0 0-1-1H7.5a1.5 1.5 0 0 0-3 0H1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h3v3h12V6h-4zm-8 8H1V3h2v1h6V3h2v3H4v8zm11 3H5V7h10v10zm-2-7H7V9h6v1zm0 2H7v-1h6v1zm0 2H7v-1h6v1z"
      />
    </svg>
  );
};

IconCopy.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconCopy;
