import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import TopMenu from '../../../component/dashboard/TopMenu/index';
import ViewProjectInfo from '../../../component/project/ViewProjectInfo';
import EditProjectInfoForm from '../../../component/project/EditProjectInfoForm';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../../util/error';
import api from '../../../service/api';
import { dashboardEditProjectSuccess } from '../../dashboard/actions';
import ProjectSettingsUsersContainer from '../ProjectSettingsUsersContainer';

export class ProjectSettingsContainer extends React.Component {
  static propTypes = {
    basePath: PropTypes.string.isRequired,
  };

  cancelSourceProjectPut = CancelToken.source();

  getBasePath = () => {
    return `${this.props.basePath}${this.props.activeProjectId}/settings/`;
  };

  onProjectInfoEditClick = () => {
    this.props.history.push(`${this.getBasePath()}info/edit`);
  };

  onProjectFormSubmit = async (redirectSuffix, values, setSubmitting, setErrors) => {
    try {
      const res = await api.projectPut(
        this.props.activeCompanyId,
        values,
        this.cancelSourceProjectPut.token
      );
      this.props.onProjectUpdated(res.data);
      if (redirectSuffix !== null) {
        this.props.history.push(`${this.getBasePath()}${redirectSuffix}`);
      } else {
        setSubmitting(false);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        const errors = errorsTransformAxiosToFormik(err);
        setErrors(addCommonFormikErrorsIfEmpty(errors));
        setSubmitting(false);
      }
    }
  };

  onProjectInfoFormCancel = () => {
    this.props.history.push(`${this.getBasePath()}info`);
  };

  componentWillUnmount() {
    this.cancelSourceProjectPut.cancel();
  }

  render() {
    const { project, activeCompanyId } = this.props;
    const basePath = this.getBasePath();
    let topMenuItems = [
      {
        name: 'info',
        label: 'Project Information',
        nameAlias: 'info/edit',
      },
      {
        name: 'users',
        label: 'Team Members',
      },
    ];

    return !project ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className="project-settings-container">
        <label className="page-header">Project Settings</label>
        <TopMenu items={topMenuItems} basePath={basePath} />
        <Switch>
          <Route exact path={basePath} render={() => <Redirect to={`${basePath}info`} />} />
          <Route
            exact
            path={`${basePath}info`}
            render={() => (
              <ViewProjectInfo
                name={project.name}
                description={project.description}
                onEditClick={this.onProjectInfoEditClick}
              />
            )}
          />
          <Route
            exact
            path={`${basePath}info/edit`}
            render={() => (
              <EditProjectInfoForm
                project={project}
                onSubmit={this.onProjectFormSubmit}
                onCancel={this.onProjectInfoFormCancel}
              />
            )}
          />
          <Route
            exact
            path={`${basePath}users`}
            render={() => (
              <ProjectSettingsUsersContainer
                companyId={activeCompanyId}
                project={project}
                onProjectFormSubmit={this.onProjectFormSubmit}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeCompanyId: state.dashboard.activeCompanyId,
  activeProjectId: state.dashboard.activeProjectId,
  project: state.dashboard.projects.filter((p) => p._id === state.dashboard.activeProjectId)[0],
});

const mapDispatchToProps = (dispatch) => ({
  onProjectUpdated: (project) => dispatch(dashboardEditProjectSuccess(project)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProjectSettingsContainer)
);
