import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorTop extends React.Component {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    errors: PropTypes.object,
    commonErrorText: PropTypes.string,
  };

  static defaultProps = {
    commonErrorText: 'Please, fix errors below',
  };

  state = {
    show: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.isSubmitting &&
      !this.props.isSubmitting &&
      !this.props.isValid &&
      (Object.keys(this.props.errors).length > 1 || !this.props.errors.common)
    ) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
      this.setState({ show: true });
    } else if (prevState.show === true) {
      this.setState({ show: false });
    }
  };

  render() {
    return this.state.show ? <div className="error-top">{this.props.commonErrorText}</div> : null;
  }
}
