import React from 'react';

export const sectionAccount = {
  name: 'account',
  label: 'Account',
  ref: React.createRef(),
};

export const sectionSpecifications = {
  name: 'specifications',
  label: 'Specifications',
  ref: React.createRef(),
};

export const sectionQuantities = {
  name: 'quantities',
  label: 'Quantities',
  ref: React.createRef(),
};

export const sectionSamples = {
  name: 'samples',
  label: 'Samples',
  ref: React.createRef(),
};

export const sectionExtraQuestions = {
  name: 'extraQuestions',
  label: 'Extra Questions',
  ref: React.createRef(),
};
