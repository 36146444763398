import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import classNames from 'classnames';
import './style.css';

class SpinnerLoading extends React.Component {
  static propTypes = {
    centered: PropTypes.bool,
    embed: PropTypes.bool,
    containerStyle: PropTypes.object,
  };

  static defaultProps = {
    containerStyle: {},
  };

  render() {
    return (
      <div
        className={classNames({
          'spinner-loading-container': !this.props.embed,
          'spinner-loading-container-embed': this.props.embed,
          'centered-loading-spinner': this.props.centered,
        })}
        style={this.props.containerStyle}
      >
        <Spinner name="line-spin-fade-loader" fadeIn="half" color="#0095ff" />
      </div>
    );
  }
}

export default SpinnerLoading;
