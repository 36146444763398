import * as Yup from 'yup';

const isAbsent = (value) => value == null || value === '';

const quantityBaseRule = Yup.mixed()
  .test('maximum-number', 'Maximum of 1,000,000,000 exceeded.', (number) => {
    if (isAbsent(number)) {
      return true;
    }
    if (`${number}`.length > 10) {
      return false;
    }
    const float = parseFloat(number);
    return float <= 1000000000;
  })
  .test('only-integer', 'Only whole number allowed', (number) => {
    if (isAbsent(number)) {
      return true;
    }
    const str = `${number}`;
    return str.match(/^[-\d]+$/);
  });

export const quantityZeroAllowedValidationRule = quantityBaseRule.test(
  'zero-or-positive',
  'Only positive value allowed',
  (number) => {
    if (isAbsent(number)) {
      return true;
    }
    const float = parseFloat(number);
    return float >= 0;
  }
);

export const quantityValidationRule = quantityBaseRule.test(
  'only-positive',
  'Only positive value allowed',
  (number) => {
    if (isAbsent(number)) {
      return true;
    }
    const float = parseFloat(number);
    return float > 0;
  }
);
