import React from 'react';
import PropTypes from 'prop-types';

const IconCalendar = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <g>
        <path
          stroke={props.color}
          d="M653,199.4h9.5V140h-10.9h-32.3H375.1H347h-15.2v59.4H347H653z M741.5,19.8h-60.4V275h60.4V19.8z M314.7,19.8h-60.4V275h60.4V19.8z M928.2,141.4H803.3h-28.1H760v59.4h15.2h151.6v120.2H71.8V200.8h153h12.3v-59.4h-12.3h-32.3H71.8H10v59.4v120.2v59.4v540.3v59.4h61.8h856.5H990v-59.4V380.5v-59.4V200.8v-59.4H928.2z M926.8,920.8H71.8V380.5h855.1V920.8z M289.2,446.6h-94.3v92.8h94.3L289.2,446.6L289.2,446.6z M547.1,446.6h-94.3v92.8h94.3V446.6z M805.1,446.6h-94.3v92.8h94.3V446.6z M289.2,603.5h-94.3v92.8h94.3L289.2,603.5L289.2,603.5z M547.1,603.5h-94.3v92.8h94.3V603.5z M805.1,603.5h-94.3v92.8h94.3V603.5z M289.2,760.3h-94.3v92.8h94.3L289.2,760.3L289.2,760.3z M547.1,760.3h-94.3v92.8h94.3V760.3z"
        />
      </g>
    </svg>
  );
};

IconCalendar.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconCalendar;
