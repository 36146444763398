import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';

import { isAuthorized } from '../../../util/session';

class PrivateRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props;

    return (
      <Route
        {...props}
        render={(props) => (isAuthorized() ? <Component {...props} /> : <Redirect to="/signin" />)}
      />
    );
  }
}

export default PrivateRoute;
