import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import _startCase from 'lodash.startcase';

import QuoteFormSignupAStep1 from '../QuoteFormSignupAStep1';
import QuoteFormSignupAStep2, { Step2ValidationScheme } from '../QuoteFormSignupAStep2';

import {
  prepareQuoteValues,
  prepareAccountValues,
  validateRequiredQuantity,
  SignupValidationScheme,
} from '../QuoteForm/helper';
import FormStepsIndicator from '../../../component/form/FormStepsIndicator';
import './style.css';

export class Form extends Component {
  static defaultProps = {
    readOnly: false,
    signupAndSendQuoteDone: false,
  };

  static propTypes = {
    product: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.string,
    userCompanyId: PropTypes.string,
    onSubmit: PropTypes.func,
    signupAndSendQuoteDone: PropTypes.bool,
    signUpAndSendQuoteOnFinished: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    goToClientProfile: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentStep: 1,
  };

  onChangeFormStep = (stepNumber) => {
    this.setState({ currentStep: stepNumber });
  };

  render() {
    const { errors, handleSubmit, t } = this.props;

    if (this.props.signupAndSendQuoteDone && this.state.currentStep !== 3) {
      this.setState({ currentStep: 3 });
    }

    if (errors.email === 'Email address is already registered') {
      if (this.state.currentStep !== 1) {
        this.setState({ currentStep: 1 });
      }
    }

    return (
      <form
        className={'padding-bottom-180 quote-request-form landing-quote-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {this.state.currentStep === 1 && (
          <QuoteFormSignupAStep1 {...this.props} onChangeFormStep={this.onChangeFormStep} />
        )}

        <FormStepsIndicator
          stepsNames={[
            _startCase(t('common.register')),
            _startCase(t('common.quote_verb')),
            _startCase(t('common.send')),
          ]}
          currentStep={this.state.currentStep}
        />

        {this.state.currentStep === 2 && (
          <QuoteFormSignupAStep2 {...this.props} onChangeFormStep={this.onChangeFormStep} />
        )}

        {this.state.currentStep === 3 && (
          <div className="form-step-content form-step-3-content">
            <h2>{t('quote.common.Your quote has been sent!')}</h2>
            <p>{t('quote.common.lastStepImportistNote')}</p>
            <button
              className="btn blue-button big-button wide-button"
              type="button"
              onClick={() => {
                this.props.signUpAndSendQuoteOnFinished();
              }}
            >
              {t('quote.common.Complete Your Supplier Account')}
            </button>
          </div>
        )}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  ...SignupValidationScheme,
  ...Step2ValidationScheme,
});

const QuoteFormSignup = withFormik({
  mapPropsToValues: (props) => {
    const quoteValues = prepareQuoteValues(props);
    const accountValues = prepareAccountValues(props);

    return {
      ...quoteValues,
      ...accountValues,
    };
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantity(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }

    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

QuoteFormSignup.defaultProps = {
  quote: {},
};

export default QuoteFormSignup;
