import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from '../../../util/moment';
import { Field } from 'formik';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';
import { incotermsList } from '../../../util/incoterms';
import IconCalendar from '../../icon/IconCalendar';

const ContainerInputCalendarStyled = styled.div`
  position: relative;
`;

const IconCalendarContainerStyled = styled.div`
  position: absolute;
  z-index: 2500;
  top: 11px;
  left: 13px;
`;

class QuoteFormSignupDStep2 extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onChangeFormStep: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      values,
      errors,
      touched,
      setFieldTouched,
      setFieldValue,
      isSubmitting,
      readOnly,
      t,
    } = this.props;

    function isFieldValid(field) {
      return (!!values[field] || touched[field]) && !errors[field];
    }

    return (
      <fieldset className="form-step-content mt-0">
        <div className="form-group">
          <div>
            <label>{_startCase(t('common.incoterms'))}</label>
            <div className="form-value">
              <Field
                className="form-control big-input"
                component="select"
                name="termsPreference"
                disabled={readOnly}
              >
                {Object.values(incotermsList)
                  .filter((incoterm) => incoterm.code !== 'standard')
                  .map((incoterm, index) => {
                    return (
                      <option value={incoterm.code} key={index}>
                        {incoterm.label}
                      </option>
                    );
                  })}
              </Field>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div
            className={classNames('with-datepicker', {
              'with-error': touched.validUntil && errors.validUntil,
            })}
          >
            <label>
              {_startCase(
                t('common.quote', 'Quote') +
                  ' ' +
                  t('common.valid', 'Valid') +
                  ' ' +
                  t('common.until', 'Until')
              )}
            </label>
            <ContainerInputCalendarStyled>
              <IconCalendarContainerStyled>
                <IconCalendar width={18} height={18} color="#4d4d4d" />
              </IconCalendarContainerStyled>
              <DatePicker
                name="validUntil"
                className={'form-control big-input'}
                onChange={(date) => {
                  setFieldValue('validUntil', date.toISOString());
                }}
                onBlur={() => {
                  setFieldTouched('validUntil', true);
                }}
                selected={values.validUntil.length ? moment(values.validUntil) : null}
                dateFormat="MM/DD/YYYY"
                placeholderText="mm/dd/yyyy"
                disabled={readOnly}
                minDate={moment()}
              />
            </ContainerInputCalendarStyled>
            {touched.validUntil && errors.validUntil && (
              <label className="error">{t(`quote.validation.${errors.validUntil}`)}</label>
            )}
          </div>
        </div>

        {errors.common && <label className="error common">{t(errors.common, errors.common)}</label>}
        {!this.props.readOnly && !this.props.withoutSubmitButton && (
          <button
            className="btn blue-button wide-button big-button fullwidth-button"
            style={{ marginTop: '20px' }}
            type="button"
            disabled={
              isSubmitting || !isFieldValid('validUntil') || !isFieldValid('termsPreference')
            }
            onClick={() => {
              this.props.onChangeFormStep(3);
            }}
          >
            {t('quote.common.Next')}
          </button>
        )}
      </fieldset>
    );
  }
}

export default QuoteFormSignupDStep2;
