import {
  CHAT_COMPANY_FETCH_CHATS_SUCCESS,
  CHAT_COMPANY_ADD_CHATS_SUCCESS,
  CHAT_COMPANY_REPLACE_OR_ADD_CHAT_SUCCESS,
  CHAT_COMPANY_SET_LOADING,
  CHAT_CURRENT_MESSAGES_SET_LOADING,
  CHAT_CURRENT_MESSAGES_ADD_SUCCESS,
  CHAT_CURRENT_MESSAGES_FETCH_SUCCESS,
  CHAT_CURRENT_MESSAGES_SEND_SUCCESS,
  CHAT_CURRENT_MESSAGES_NEW_SUCCESS,
  CHAT_ACTIVE_ID_SET,
  CHAT_COMPANY_UPDATE_USER_READ_COUNT_SUCCESS,
  CHAT_COMPANY_REVERT_USER_READ_COUNT,
} from './constants';
import { EventTypes } from 'redux-segment';
import _cloneDeep from 'lodash.clonedeep';
import {
  dashboardIncCompanyNewMessagesCount,
  dashboardIncProjectsNewMessagesCountSuccess,
} from '../dashboard/actions';

export function chatCompanyLoadChatsSuccess(data, isFirstPage) {
  return {
    type: isFirstPage ? CHAT_COMPANY_FETCH_CHATS_SUCCESS : CHAT_COMPANY_ADD_CHATS_SUCCESS,
    data,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatCompanyAddOrReplaceChatSuccess(chat) {
  return {
    type: CHAT_COMPANY_REPLACE_OR_ADD_CHAT_SUCCESS,
    chat,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatCompanyUpdateUserReadCount(chatUser) {
  return {
    type: CHAT_COMPANY_UPDATE_USER_READ_COUNT_SUCCESS,
    chatUser,
  };
}

// chatUser.realReadCount should be set to revert readCount
export function chatCompanyRevertUserReadCount(chatId) {
  return {
    type: CHAT_COMPANY_REVERT_USER_READ_COUNT,
    chatId,
  };
}

export function chatCurrentMessagesAddSuccess(data, isFirstPage) {
  return {
    type: isFirstPage ? CHAT_CURRENT_MESSAGES_ADD_SUCCESS : CHAT_CURRENT_MESSAGES_FETCH_SUCCESS,
    data,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatCurrentMessagesNewSuccess(data) {
  return {
    type: CHAT_CURRENT_MESSAGES_NEW_SUCCESS,
    data,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatCurrentMessagesSetLoading(is) {
  return {
    type: CHAT_CURRENT_MESSAGES_SET_LOADING,
    loading: is,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatCurrentMessagesSendSuccess(is) {
  return {
    type: CHAT_CURRENT_MESSAGES_SEND_SUCCESS,
    loading: is,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatCompanySetLoading(is) {
  return {
    type: CHAT_COMPANY_SET_LOADING,
    loading: is,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function chatIdActiveSet(chatId) {
  return {
    type: CHAT_ACTIVE_ID_SET,
    chatId,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function fetchReplaceOrAddCompanyChat(companyId, chatId) {
  return async (dispatch, getStore, services) => {
    try {
      let res;
      if (getStore().dashboard.company.type === 'client') {
        res = await services.api.clientChatGet(companyId, chatId);
      } else {
        res = await services.api.supplierChatGet(companyId, chatId);
      }
      const isTabActive = getStore().app.isTabActive;
      const activeChatId = getStore().dashboard.chats.activeChatId;
      const fetchedChat = _cloneDeep(res.data);
      if (activeChatId === chatId) {
        if (isTabActive) {
          // Mitigate race condition around fetching chat data and updating read count when user sees active chat
          fetchedChat.chatUser.readCount = fetchedChat.totalMessages;
        }
      }
      dispatch(chatCompanyAddOrReplaceChatSuccess(fetchedChat));
    } catch (err) {}
  };
}

export function fetchCompanyChats(companyId, companyType, params, isFirstPage) {
  return async (dispatch, getStore, services) => {
    dispatch(chatCompanySetLoading(true));
    try {
      const res =
        companyType === 'client'
          ? await services.api.clientAllChatsGet(companyId, params)
          : await services.api.supplierAllChatsGet(companyId, params);
      dispatch(chatCompanyLoadChatsSuccess(res.data, isFirstPage));
      dispatch(chatCompanySetLoading(false));
    } catch (err) {
      dispatch(chatCompanySetLoading(false));
    }
  };
}

export function fetchChatMessages(companyId, chatId, params) {
  return async (dispatch, getStore, services) => {
    dispatch(chatCurrentMessagesSetLoading(true));
    try {
      const res = await services.api.messagesGet(companyId, chatId, params);
      const activeChatId = getStore().dashboard.chats.activeChatId;
      if (chatId === activeChatId) {
        dispatch(chatCurrentMessagesAddSuccess(res.data, params.isFirstPage));
      }
      dispatch(chatCurrentMessagesSetLoading(false));
    } catch (err) {
      dispatch(chatCurrentMessagesSetLoading(false));
    }
  };
}

export function sendChatMessage(chatId, data) {
  return async (dispatch, getStore, services) => {
    try {
      const res = await services.api.messagePost(chatId, data);
      dispatch(chatCurrentMessagesSendSuccess(res.data));
    } catch (err) {}
  };
}

/**
 * Update read count of messages if not all messages red
 *
 * @param chat
 * @returns {Function}
 */
export function chatUpdateReadCount(chat) {
  return async (dispatch, getStore, services) => {
    try {
      const diff = chat.chatUser.readCount - chat.totalMessages;
      const res = await services.api.chatUserReadPut(chat._id);
      if (diff < 0) {
        dispatch(dashboardIncProjectsNewMessagesCountSuccess(chat.projectId, diff));
        dispatch(dashboardIncCompanyNewMessagesCount(diff));
      }
      dispatch(chatCompanyUpdateUserReadCount(res.data));
    } catch (err) {
      // chatUser.realReadCount should be set to revert readCount
      dispatch(chatCompanyRevertUserReadCount(chat._id));
    }
  };
}
