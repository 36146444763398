import React from 'react';
import { withRouter } from 'react-router-dom';
import Spinner from 'react-spinkit';
import styled from 'styled-components';
import axios, { CancelToken } from 'axios';

import api from '../../../service/api';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';

const SpinnerContainerStyled = styled.div`
  transform: scale(0.7);
  padding-top: 50px;
  width: 300px;
  height: 80px;
  display: flex;
  justify-content: center;
`;

const MessageContainerStyled = styled.div`
  text-align: center;
`;

export class SignConfirmEmailContainer extends React.PureComponent {
  state = {
    isConfirmed: false,
    cancelSource: CancelToken.source(),
    error: null,
  };

  componentDidMount = async () => {
    if (this.props.location.search.indexOf('?token=') !== -1) {
      const confirmToken = this.props.location.search.replace('?token=', '');
      try {
        await api.userEmailConfirm(confirmToken);
        this.setState({ isConfirmed: true });
      } catch (err) {
        if (!axios.isCancel(err)) {
          this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
        }
      }
    }
  };

  componentWillUnmount() {
    this.state.cancelSource.cancel();
  }

  render() {
    return !this.state.isConfirmed && !this.state.error ? (
      <SpinnerContainerStyled>
        <Spinner name="line-spin-fade-loader" fadeIn="half" color="#0095ff" />
      </SpinnerContainerStyled>
    ) : (
      <MessageContainerStyled className="form-black-container">
        {this.state.error ? (
          <label className="error">{this.state.error}</label>
        ) : (
          'Thank you for confirming your email'
        )}
      </MessageContainerStyled>
    );
  }
}

export default withRouter(SignConfirmEmailContainer);
