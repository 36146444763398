import { sessionLogout, setJwtToken } from '../util/session';
import pusher from '../service/pusher';

export const EMAIL_BUSY_ERROR_TEXT = 'Email address is already registered';

export function userAfterSignIn(token) {
  setJwtToken(token);
  pusher.connect(token);
}

export function userAfterLogout() {
  pusher.disconnect();
  sessionLogout();
}
export const rolePriority = ['owner', 'admin', 'project-manager', 'team-member'];

function userGetActivePriority(user, activeUserIds) {
  let priority = 0;
  switch (user.role) {
    case 'owner':
      break;
    case 'admin':
      priority += 100;
      break;
    default:
      priority += 1000;
      break;
  }

  if (user.role !== 'owner' && user.role !== 'admin') {
    priority += activeUserIds.indexOf(user._id) >= 0 ? 0 : 10000;
  }

  return priority;
}

export function usersSortActiveOnTop(users, activeUserIds) {
  return users.sort((u1, u2) =>
    userGetActivePriority(u1, activeUserIds) > userGetActivePriority(u2, activeUserIds) ? 1 : -1
  );
}

export function userGetCompanyRole(userCompanies, companyId) {
  let role = 'quest';
  userCompanies.forEach((userCompany) => {
    if (userCompany.companyId === companyId) {
      // eslint-disable-next-line
      role = userCompany.role;
    }
  });

  return role;
}
