import {
  SIGNUP_SUBMITTING,
  SIGNUP_EMAIL_SUCCESS,
  SIGNUP_PASSWORD_SUCCESS,
  SIGNUP_COMPANY_SUCCESS,
  SIGN_IN_COMPLETE_USER_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_AND_SEND_QUOTE_SUCCESS,
  SIGNUP_SET_INVITED_TOKEN_SUCCESS,
  SIGNUP_SET_INVITED_COMPANY_SUCCESS,
  LOGOUT_SUCCESS,
  SIGNUP_EMAIL_RESTORE,
} from './constants';
import { push } from 'connected-react-router';
import _cloneDeep from 'lodash.clonedeep';
import { EventTypes } from 'redux-segment';
import {
  errorsTransformAxiosToFormik,
  addCommonFormikErrorsIfEmpty,
  prepareFormikError,
} from '../../util/error';
import { appLoading, modalOpen } from '../../actions';
import {
  applyCompanyAndProject,
  dashboardChangeActiveCompanyAllActions,
  dashboardGetUserCompaniesSuccess,
} from '../dashboard/actions';
import { EMAIL_BUSY_ERROR_TEXT, userAfterSignIn } from '../../util/user';
import { prepareSaveQuantities, prepareSaveSamples } from '../../component/quote/QuoteForm/helper';
import analytics from '../../service/analytics';
import { prepareCompanyDataAndUploadFiles } from '../../util/company';

export function signUpCompleteSuccess() {
  return {
    type: SIGNUP_SUCCESS,
    data: true,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signUpAndSendQuoteSuccess() {
  return {
    type: SIGNUP_AND_SEND_QUOTE_SUCCESS,
    data: true,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signUpSubmitting(isSubmitting) {
  return {
    type: SIGNUP_SUBMITTING,
    isSubmitting,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signUpEmailSuccess(user) {
  return {
    type: SIGNUP_EMAIL_SUCCESS,
    user,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          traits: {
            email: user.email,
            name: user.fullName,
          },
        },
      },
    },
  };
}

export function signUpEmailRestore(user) {
  return {
    type: SIGNUP_EMAIL_RESTORE,
    user,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signUpPasswordSuccess(data) {
  return {
    type: SIGNUP_PASSWORD_SUCCESS,
    data,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: data._id,
          traits: {
            email: data.email,
            type: data.type,
          },
        },
      },
    },
  };
}

export function signUpCompanySuccess(company) {
  return {
    type: SIGNUP_COMPANY_SUCCESS,
    company,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signInCompleteUserSuccess(user) {
  return {
    type: SIGN_IN_COMPLETE_USER_SUCCESS,
    user: user,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signupSetInvitedTokenSuccess(token) {
  return {
    type: SIGNUP_SET_INVITED_TOKEN_SUCCESS,
    token,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signupSetInvitedCompanySuccess(company) {
  return {
    type: SIGNUP_SET_INVITED_COMPANY_SUCCESS,
    company,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function signupFetchInvitedCompany(token) {
  return async (dispatch, getStore, services) => {
    try {
      const res = await services.api.userGetCompanyByInvitedToken(token);
      dispatch(signupSetInvitedCompanySuccess(res.data));
    } catch (err) {
      throw err;
    }
  };
}

export function signUpEmail(user, setSubmitting, setErrors) {
  return async (dispatch, getStore, services) => {
    dispatch(signUpSubmitting(true));
    try {
      const checkRes = await services.api.userCheckByEmail(user.email);
      dispatch(signUpSubmitting(false));
      if (checkRes.data.count > 0) {
        setSubmitting(false);
        setErrors(prepareFormikError('email', EMAIL_BUSY_ERROR_TEXT));
      } else {
        dispatch(signUpEmailSuccess(user));
        dispatch(push('/signup/password'));
      }
    } catch (err) {
      dispatch(signUpSubmitting(false));
      setSubmitting(false);
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
    }
  };
}

export function signUpPassword(data, setSubmitting, setErrors) {
  return (dispatch, getStore, services) => {
    dispatch(signUpSubmitting(true));
    services.api
      .userSignupEmail(data)
      .then((res) => {
        dispatch(signUpSubmitting(false));
        userAfterSignIn(res.data.token);
        dispatch(signUpPasswordSuccess(res.data.user));
        dispatch(push('/signup-complete'));
      })
      .catch((rej) => {
        dispatch(signUpSubmitting(false));
        setSubmitting(false);
        const errors = errorsTransformAxiosToFormik(rej);
        setErrors(addCommonFormikErrorsIfEmpty(errors));
      });
  };
}

export function signUpComplete(data, setSubmitting, setErrors) {
  return async (dispatch, getState, services) => {
    dispatch(signUpSubmitting(true));
    try {
      const company = await prepareCompanyDataAndUploadFiles(data.company, data.supplier);

      const { sign } = getState();
      let companyRes;
      if (typeof sign.user.company._id === 'string') {
        companyRes = await services.api.companyPut({ ...company, _id: sign.user.company._id });
      } else {
        companyRes = await services.api.companyPost(company);
      }
      dispatch(signUpCompanySuccess(companyRes.data));
      dispatch(dashboardGetUserCompaniesSuccess([companyRes.data]));
      dispatch(dashboardChangeActiveCompanyAllActions(companyRes.data._id));

      const resUser = await services.api.userPut(data);
      dispatch(signUpSubmitting(false));
      dispatch(signInCompleteUserSuccess(resUser.data));

      dispatch(appLoading(false));
      dispatch(signUpCompleteSuccess());
      dispatch(modalOpen());
    } catch (rej) {
      dispatch(signUpSubmitting(false));
      const errors = errorsTransformAxiosToFormik(rej);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };
}

export function signUpAndSendQuote(data, setSubmitting, setErrors) {
  return async (dispatch, getState, services) => {
    try {
      let payload = _cloneDeep(data);
      payload.company = {
        name: payload.companyName,
        type: 'supplier',
      };

      const user = await services.api.userSignupEmail({
        fullName: payload.fullName,
        email: payload.email,
        password: payload.password,
      });
      payload._id = user.data.user._id;
      //tell segment about the new user
      analytics.identify(payload._id, payload.email, payload.fullName);
      userAfterSignIn(user.data.token);

      let companyRes;
      const { sign } = getState();
      if (typeof sign.user.company._id === 'string') {
        payload.company._id = sign.user.company._id;
        companyRes = await services.api.companyPut(payload.company);
      } else {
        companyRes = await services.api.companyPost(payload.company);
      }

      dispatch(signUpCompanySuccess(companyRes.data));
      dispatch(dashboardGetUserCompaniesSuccess([companyRes.data]));
      dispatch(dashboardChangeActiveCompanyAllActions(companyRes.data._id));
      dispatch(signUpSubmitting(false));
      dispatch(signInCompleteUserSuccess(user.data.user));

      payload.createdBy = payload._id;
      payload = prepareSaveQuantities(payload);
      payload = prepareSaveSamples(payload);
      await services.api.quoteSupplierPost(companyRes.data._id, payload);

      dispatch(appLoading(false));
      dispatch(signUpAndSendQuoteSuccess());
      dispatch(modalOpen());
    } catch (rej) {
      dispatch(signUpSubmitting(false));
      const errors = errorsTransformAxiosToFormik(rej);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };
}

export function signUpAndSendQuoteA(data, setSubmitting, setErrors) {
  return async (dispatch, getState, services) => {
    try {
      let payload = _cloneDeep(data);
      payload.company = {
        name: payload.companyName,
        type: 'supplier',
      };

      const user = await services.api.userSignupEmail({
        fullName: payload.fullName,
        email: payload.email,
        password: payload.password,
      });
      payload._id = user.data.user._id;
      user.data.user.signupType = 'supplier';
      //tell segment about the new user
      analytics.identify(payload._id, payload.email, payload.fullName);
      dispatch(signUpEmailSuccess(user.data.user));
      dispatch(signUpPasswordSuccess(user.data.user));
      userAfterSignIn(user.data.token);

      let companyRes;
      const { sign } = getState();
      if (typeof sign.user.company._id === 'string') {
        payload.company._id = sign.user.company._id;
        companyRes = await services.api.companyPut(payload.company);
      } else {
        companyRes = await services.api.companyPost(payload.company);
      }

      dispatch(signUpCompanySuccess(companyRes.data));
      dispatch(dashboardGetUserCompaniesSuccess([companyRes.data]));
      dispatch(dashboardChangeActiveCompanyAllActions(companyRes.data._id));
      dispatch(signUpSubmitting(false));
      dispatch(signInCompleteUserSuccess(user.data.user));

      payload.createdBy = payload._id;
      payload = prepareSaveQuantities(payload);
      payload = prepareSaveSamples(payload);
      await services.api.quoteSupplierPost(companyRes.data._id, payload);

      dispatch(appLoading(false));
      dispatch(signUpAndSendQuoteSuccess());
    } catch (rej) {
      dispatch(signUpSubmitting(false));
      const errors = errorsTransformAxiosToFormik(rej);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };
}

export function signUpCompleteInvited(data, setSubmitting, setErrors) {
  return async (dispatch, getStore, services) => {
    dispatch(signUpSubmitting(true));
    try {
      const res = await services.api.userSignupEmail(data);
      userAfterSignIn(res.data.token);
      dispatch(signInCompleteUserSuccess(res.data.user));

      const companiesRes = await services.api.userGetCompanies();
      dispatch(dashboardGetUserCompaniesSuccess(companiesRes.data));
      dispatch(dashboardChangeActiveCompanyAllActions(companiesRes.data[0]._id));

      dispatch(signUpSubmitting(false));
      dispatch(appLoading(false));

      dispatch(signUpCompleteSuccess());
    } catch (rej) {
      dispatch(signUpSubmitting(false));
      const errors = errorsTransformAxiosToFormik(rej);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };
}

export function signIn(data, setSubmitting, setErrors) {
  return async (dispatch, getStore, services) => {
    try {
      const res = await services.api.userSignIn(data);
      userAfterSignIn(res.data.token);
      const companiesRes = await services.api.userGetCompanies();

      const shouldContinue = await applyCompanyAndProject(
        dispatch,
        services.api,
        res.data.user,
        companiesRes.data,
        getStore
      );

      if (!shouldContinue) {
        return;
      }

      dispatch(signInCompleteUserSuccess(res.data.user));

      dispatch(appLoading(false));

      const appRedirectProductId = getStore().app.redirectProductId;
      if (appRedirectProductId !== null) {
        dispatch(push('/dashboard/choose-company'));
      } else {
        dispatch(push('/dashboard'));
      }
    } catch (err) {
      setSubmitting(false);
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
    }
  };
}
