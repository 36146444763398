import React from 'react';
import PropTypes from 'prop-types';

const IconRemove = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <path
        fill="#111"
        fillRule="nonzero"
        d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm3.917 12.75a.806.806 0 0 1 0 1.167.757.757 0 0 1-.584.25.757.757 0 0 1-.583-.25L10 11.167l-2.75 2.75a.757.757 0 0 1-.583.25.757.757 0 0 1-.584-.25.806.806 0 0 1 0-1.167L8.833 10l-2.75-2.75a.806.806 0 0 1 0-1.167.806.806 0 0 1 1.167 0L10 8.833l2.75-2.75a.806.806 0 0 1 1.167 0 .806.806 0 0 1 0 1.167L11.167 10l2.75 2.75z"
        opacity=".2"
      />
    </svg>
  );
};

IconRemove.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconRemove;
