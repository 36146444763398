/* https://medium.com/@benhoffman/how-to-install-segment-io-
   javascript-into-a-react-redux-web-app-649b26ea0e65 */

// meta: {
//   analytics: {
//     eventType: EventTypes.identify,
//     eventPayload: {
//       traits: {
//         email: user.email,
//         name: user.fullName,
//       },
//     },
//   },
// },

// {
//   "type": "identify",
//   "traits": {
//     "name": "Peter Gibbons",
//     "email": "peter@initech.com",
//     "plan": "premium",
//     "logins": 5
//   },
//   "userId": "97980cfea0067"
// }

// bacon

export default {
  identify(id, email, fullName) {
    window.analytics.identify(id, {
      name: fullName,
      email,
    });
  },
};
