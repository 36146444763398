import React from 'react';
import PropTypes from 'prop-types';

const IconBox = (props) => {
  const viewBox = `0 0 36 36`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g fill={props.color} fillRule="nonzero">
        <path d="M8.004 4.85L.657 8.467l18.504 9.18 6.994-3.46zM27.433 13.553l7.94-3.929L17.098.631a.589.589 0 0 0-.52 0L9.287 4.22l18.147 9.333zM27.82 14.641a.582.582 0 0 1-.243.12l-7.835 3.877v17.144l15.937-7.842a.571.571 0 0 0 .321-.511V10.593l-8.18 4.048zM18.58 18.637L0 9.42v16.866c0 .216.124.414.321.511l18.26 8.985V18.637zm-4.904 7.839l-8.13-4a.57.57 0 0 1-.32-.51v-3.36a.58.58 0 0 1 .84-.51l8.129 4a.569.569 0 0 1 .321.51v3.359a.58.58 0 0 1-.84.511z" />
      </g>
    </svg>
  );
};

IconBox.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconBox;
