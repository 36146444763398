import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import IconDotsHorizontal from '../../icon/IconDotsHorizontal';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import moment from '../../../util/moment';
import IconShare from '../../icon/IconShare';
import QuoteRange from '../../../component/quote/QuoteRange';
import IconDuplicate from '../../icon/IconDuplicate';
import IconView from '../../icon/IconView';

export default class Products extends React.Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    modalGetQuoteLinkOpen: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
    onProductEditClick: PropTypes.func.isRequired,
    onProductViewClick: PropTypes.func.isRequired,
    onProductDuplicateClick: PropTypes.func.isRequired,
    activeCompanyId: PropTypes.string.isRequired,
    activeProjectId: PropTypes.string.isRequired,
  };

  state = {
    actionsPopupOpenProduct: null,
  };

  onProductRowClick = (product) => {
    if (product.status === 'draft') {
      this.props.onProductEditClick(product._id);
    } else if (product.quotesCount > 0) {
      this.props.history.push(
        `/dashboard/company/${this.props.activeCompanyId}/project/${
          this.props.activeProjectId
        }/product/${product._id}/quotes`
      );
    } else {
      this.props.modalGetQuoteLinkOpen(product._id);
    }
  };

  render() {
    const productsList = this.props.products.map((product) => {
      let quotesCountLabel = 'No Quotes';
      if (product.quotesCount === 1) {
        quotesCountLabel = '1 Quote';
      } else if (product.quotesCount > 1) {
        quotesCountLabel = `${product.quotesCount} Quotes`;
      }
      return (
        <tr
          key={product._id}
          onClick={() => {
            this.onProductRowClick(product);
          }}
        >
          <td>
            <img width={100} height={100} src={product.photosUrl[0] || imgPlaceholder} alt={''} />
          </td>
          <td className={'top-baseline'}>
            <div>{product.name}</div>
            <div className={'muted-text description-text'}>
              {moment(product.createdAt).format()}
            </div>
          </td>
          <td className={'top-baseline'}>{moment(product.updatedAt).format()}</td>
          <td className={'top-baseline'}>
            {product.quotesCount > 0 && (
              <QuoteRange
                quoteRange={product.quotesRange}
                currency={product.currency}
                minProductQuantity={Math.min(...product.requiredQuantity)}
              />
            )}
          </td>
          <td className={'top-baseline'}>
            <div className={'important-text'}>
              {product.status === 'draft' ? 'Draft' : quotesCountLabel}
            </div>
            <a
              onClick={(e) => {
                e.stopPropagation();
                this.onProductRowClick(product);
              }}
            >
              {product.status === 'draft' && <span>Edit Product</span>}
              {product.status === 'complete' && (
                <span>{product.quotesCount > 0 ? 'View Quotes' : 'Get Quotes'}</span>
              )}
              &raquo;
            </a>
          </td>
          <td
            className={'top-baseline'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={'popup-container'}>
              <Popup
                onClick={(e) => {
                  this.onOpenPopup(e);
                }}
                open={
                  this.state.actionsPopupOpenProduct &&
                  this.state.actionsPopupOpenProduct._id === product._id
                }
                onOpen={() => this.setState({ actionsPopupOpenProduct: product })}
                onClose={() => this.setState({ actionsPopupOpenProduct: null })}
                trigger={
                  <div
                    className={'btn action'}
                    onClick={(e) => {
                      this.onOpenPopup(e);
                    }}
                  >
                    <IconDotsHorizontal
                      width={24}
                      height={6}
                      color={
                        this.state.actionsPopupOpenProduct !== null &&
                        this.state.actionsPopupOpenProduct._id === product._id
                          ? 'var(--active-blue-color)'
                          : '#e9e9e9'
                      }
                    />
                  </div>
                }
                arrow={false}
                position="bottom right"
                closeOnDocumentClick
                contentStyle={{ width: '209px', padding: '17px 12px' }}
              >
                <div>
                  <div
                    className={'btn'}
                    onClick={() => {
                      this.setState({ actionsPopupOpenProduct: null });
                      if (product.status === 'complete') {
                        this.props.modalGetQuoteLinkOpen(product._id);
                      } else {
                        this.props.onProductEditClick(product._id);
                      }
                    }}
                  >
                    <div className={'icon-container'}>
                      <IconShare width={16} height={16} />
                    </div>
                    {product.status === 'draft' ? 'Edit Product' : 'Get Quote Link'}
                  </div>
                  <div
                    className={'btn'}
                    onClick={() => {
                      this.props.onProductViewClick(product._id);
                    }}
                  >
                    <div className={'icon-container'}>
                      <IconView width={16} height={16} />
                    </div>
                    View Product
                  </div>
                  <div
                    className={'btn'}
                    onClick={() => {
                      this.setState({ actionsPopupOpenProduct: null });
                      this.props.onProductDuplicateClick(product._id);
                    }}
                  >
                    <div className={'icon-container'}>
                      <IconDuplicate width={16} height={16} />
                    </div>
                    Duplicate Product
                  </div>
                </div>
              </Popup>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Updated</th>
            <th scope="col">Quantity and Price</th>
            <th scope="col">Status</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>{productsList}</tbody>
      </table>
    );
  }
}
