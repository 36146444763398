import React from 'react';
import PropTypes from 'prop-types';

const IconLandingLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 160 23"
    >
      <defs>
        <linearGradient id="a" x1="100%" x2="0%" y1="42.45%" y2="92.156%">
          <stop offset="0%" stopColor="#00C7FF" />
          <stop offset="100%" stopColor="#0095FF" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <text
          fill="#080513"
          fontFamily="Roboto-Medium, Roboto"
          fontSize="19.769"
          fontWeight="400"
          letterSpacing="3.295"
          transform="translate(-1)"
        >
          <tspan x="28.192" y="18">
            IMPORTIST
          </tspan>
        </text>
        <g fill="#00C7FF" fillRule="nonzero" transform="matrix(-1 0 0 1 20.593 .412)">
          <path d="M1.872.936A.936.936 0 1 0 0 .936v20.593a.936.936 0 1 0 1.872 0V.936zM17.785 11.232l2.808-6.552H14.04V1.872a.936.936 0 0 0-.936-.936H4.68a.936.936 0 0 0-.936.936v11.232c0 .517.42.936.936.936h6.552l-4.625 3.084c.12.38.462.66.881.66h13.105l-2.808-6.552z" />
        </g>
      </g>
    </svg>
  );
};

IconLandingLogo.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconLandingLogo;
