import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import styled from 'styled-components';

import StickyMenu from '../../common/StickyMenu';
import ImageGroupUpload from '../../common/ImageGroupUpload';
import { currenciesList } from '../../../util/currency';
import IconCirclePlus from '../../icon/IconCirclePlus';
import IconMuted from '../../icon/IconMuted';
import { incotermsList } from '../../../util/incoterms';
import { countriesList } from '../../../util/country';
import ErrorTop from '../../form/ErrorTop';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { quantityValidationRule, quantityZeroAllowedValidationRule } from '../../../util/yup';
import { validateRequiredQuantityAddingProduct } from './helper';

const StyledExampleTip = styled.span`
  margin-left: 28px;
`;

const StyledExampleLabel = styled.label`
  font-size: 18px;
  color: #000000;
`;

const StyledExampleText = styled.span`
  font-style: italic;
  line-height: 1;
`;

const StyledSpecRemoveIconContainer = styled.div`
  display: inline-block;
  margin-left: -15px;
  height: 38px;
  padding-top: 4px;
  cursor: pointer;
`;

const StyledCheckboxSpan = styled.span`
  margin-left: 25px;
  color: black;
`;

export class Form extends React.Component {
  sectionSpecifications = {
    name: 'specs',
    label: 'Specifications',
    ref: React.createRef(),
  };

  sectionQuantities = {
    name: 'quantities',
    label: 'Quantities',
    ref: React.createRef(),
  };

  sectionSamples = {
    name: 'samples',
    label: 'Samples',
    ref: React.createRef(),
  };

  sectionExtraQuestions = {
    name: 'questions',
    label: 'Extra Questions',
    ref: React.createRef(),
  };

  sectionHeaderRefs = [
    this.sectionSpecifications,
    this.sectionQuantities,
    this.sectionSamples,
    this.sectionExtraQuestions,
  ];

  constructor(props) {
    super(props);

    this.state = {
      deliveryAddressInFile: !props.product._id || props.isDuplication,
      photosUploadMessages: [],
    };
  }

  onChangeDeliveryAddressInFile = () => {
    if (!this.state.deliveryAddressInFile) {
      this.props.setFieldValue('deliveryAddress.company', this.props.company.name);
      this.props.setFieldValue('deliveryAddress.name', this.props.user.fullName);
      this.props.setFieldValue('deliveryAddress.position', this.props.user.position || '');
      this.props.setFieldValue('deliveryAddress.address', this.props.company.address || '');
      this.props.setFieldValue('deliveryAddress.address2', this.props.company.address2 || '');
      this.props.setFieldValue('deliveryAddress.telephone', this.props.company.telephone || '');
      this.props.setFieldValue('deliveryAddress.country', this.props.company.country);
      this.props.setFieldValue('deliveryAddress.website', this.props.company.website || '');
    }

    this.setState({ deliveryAddressInFile: !this.state.deliveryAddressInFile });
  };

  onPhotosChange = (files) => {
    this.props.setFieldValue('photoFiles', files);
    this.props.setFieldTouched('photoFiles', true);
  };

  render() {
    const {
      errors,
      values,
      touched,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      isValid,
      setSubmitting,
      setErrors,
    } = this.props;

    const nameGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.name && errors.name,
    });

    const currencyGroupClass = classNames('form-group col-sm-3', {
      'with-error': touched.currency && errors.currency,
    });

    const targetPriceGroupClass = classNames('form-group col-sm-3', {
      'with-error': touched.targetPrice && errors.targetPrice,
    });

    const requiredQuantityGroupClass = classNames('form-group col-sm-3', {
      'with-error': touched.requiredQuantity && errors.requiredQuantity,
    });

    return (
      <form className={'padding-bottom-180'}>
        <ErrorTop isSubmitting={isSubmitting} isValid={isValid} errors={errors} />
        <StickyMenu items={this.sectionHeaderRefs} />
        <label className="section-header" ref={this.sectionSpecifications.ref}>
          {this.sectionSpecifications.label}
        </label>
        <fieldset>
          <div className="row">
            <div className={nameGroupClass}>
              <label>
                Product Name<sup> *</sup>
              </label>
              <Field className="form-control" name="name" />
              {touched.name && errors.name && <label className="error">{errors.name}</label>}
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-sm-12'}>
              <label>Photo Upload</label>
              <div className={'form-group'}>
                <ImageGroupUpload
                  id={'product-photos'}
                  onChange={this.onPhotosChange}
                  onInnerEvents={(events) => {
                    if (this.state.photosUploadMessages.length > 0 || events.length > 0) {
                      this.setState({ photosUploadMessages: events });
                    }
                  }}
                  files={values.photoFiles}
                  previews={values.photoPreviews}
                />
              </div>
              {this.state.photosUploadMessages.map((item, index) => (
                <label key={index} className="error custom">
                  {item.defaultMessage}
                </label>
              ))}
            </div>
          </div>
          <div className="row">
            <div className={currencyGroupClass}>
              <label>
                Currency<sup> *</sup>
              </label>
              <select
                className="form-control"
                name="currency"
                onChange={(event) => {
                  setFieldValue('currency', event.target.value);
                }}
                onBlur={() => {
                  setFieldTouched('currency', true);
                }}
                value={values.currency}
              >
                <option value="" />
                {Object.values(currenciesList).map((currency) => {
                  return (
                    <option value={currency.code} key={currency.code}>
                      {currency.code} {currency.name_plural}
                    </option>
                  );
                })}
              </select>
              {touched.currency && errors.currency && (
                <label className="error">{errors.currency}</label>
              )}
            </div>
          </div>
        </fieldset>
        <label className="section-header">Custom Specifications</label>
        <label className="section-header-description">
          Every product is different, so add some product specific specs by adding custom fields.{' '}
          <br /> We've added a few to help get you started.
        </label>
        <fieldset>
          <div className={'row'}>
            <div className={'form-group col-sm-2'}>
              <div>
                <StyledExampleLabel>Specification</StyledExampleLabel>
              </div>
              <StyledExampleText>Size</StyledExampleText>
            </div>
            <div className={'form-group col-sm-10'}>
              <div>
                <StyledExampleLabel>Description</StyledExampleLabel>
              </div>
              <StyledExampleText>
                10 x 20 x 3 cm <StyledExampleTip>(example)</StyledExampleTip>
              </StyledExampleText>
            </div>
          </div>
          <FieldArray
            name="specs"
            render={(arrayHelpers) => (
              <div>
                {values.specs.map((spec, index) => {
                  return spec.default ? (
                    <div className={'row'} key={index}>
                      <div className={'col-sm-12'}>
                        <label>{spec.name}</label>
                      </div>
                      <div className={'form-group col-sm-11'}>
                        <Field className={'form-control'} name={`specs.${index}.value`} />
                      </div>
                      <div className={'form-group col-sm-1'}>
                        <StyledSpecRemoveIconContainer
                          id={`remove-spec-${index}`}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <IconMuted width={20} height={20} color="#111111" opacity=".138" />
                        </StyledSpecRemoveIconContainer>
                      </div>
                    </div>
                  ) : (
                    <div className={'row'} key={index}>
                      <div className={'form-group col-sm-4'}>
                        <Field
                          placeholder="Specification name"
                          className={'form-control'}
                          name={`specs.${index}.name`}
                        />
                      </div>
                      <div className={'form-group col-sm-7'}>
                        <Field
                          placeholder="Description"
                          className={'form-control'}
                          name={`specs.${index}.value`}
                        />
                      </div>
                      <div className={'form-group col-sm-1'}>
                        <StyledSpecRemoveIconContainer onClick={() => arrayHelpers.remove(index)}>
                          <IconMuted width={20} height={20} color="#111111" opacity=".138" />
                        </StyledSpecRemoveIconContainer>
                      </div>
                    </div>
                  );
                })}
                <button
                  id="add-specification"
                  className={'btn white-button'}
                  type="button"
                  onClick={() => arrayHelpers.push({ name: '', value: '' })}
                >
                  <span className={'icon'}>
                    <IconCirclePlus width={15} height={15} color={'#111111'} />
                  </span>
                  Add Specification
                </button>
                <div>
                  {touched.specs && errors.specs && <label className="error">{errors.specs}</label>}
                </div>
              </div>
            )}
          />
        </fieldset>
        <label className="section-header" ref={this.sectionQuantities.ref}>
          {this.sectionQuantities.label}
        </label>
        <label className="section-header-description">
          Do you want to give the supplier a target price for this product?
          <br />
          The supplier will estimate the order quantity needed to achieve this.
        </label>
        <fieldset className={'quantities-section'}>
          <div className="row">
            <div className={targetPriceGroupClass}>
              <label>Target Price</label>
              <Field type="number" className="form-control" name="targetPrice" />
              {touched.targetPrice && errors.targetPrice && (
                <label className="error">{errors.targetPrice}</label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <label className="section-header-2">Minimum Order Quantity</label>
              <label className="section-header-description">
                We will request the Minimum Order Quantity (MOQ) from the supplier by default.
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <label className="section-header-2">Volume Quotes</label>
              <label className="section-header-description">
                Add custom quantity amounts to see how the price changes at different quantities.
              </label>
            </div>
          </div>
          <div className="row">
            <div className={requiredQuantityGroupClass}>
              <label>Required Quantity</label>
              <FieldArray
                name="requiredQuantity"
                render={(arrayHelpers) => (
                  <div>
                    {values.requiredQuantity.map((quantity, index) => (
                      <div className="form-group" key={index}>
                        <Field
                          type="number"
                          placeholder="ex. 10,000"
                          className="form-control"
                          name={`requiredQuantity.${index}`}
                        />
                        {touched.requiredQuantity &&
                          touched.requiredQuantity[index] &&
                          errors.requiredQuantity &&
                          errors.requiredQuantity[index] && (
                            <label data-id={`requiredQuantity${index}`} className="error">
                              {errors.requiredQuantity[index]}
                            </label>
                          )}
                      </div>
                    ))}
                    <button
                      id="product-form-button-add-required-quantity"
                      className={'btn white-button'}
                      type="button"
                      onClick={() => arrayHelpers.push('')}
                    >
                      <span className={'icon'}>
                        <IconCirclePlus width={15} height={15} color={'#111111'} />
                      </span>
                      Add Quantity
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <label className="section-header-2">Incoterms</label>
              <label className="section-header-description">
                Please specify what Incoterms you wish the supplier to work under.
              </label>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-4">
              <select
                className="form-control"
                name="incoterms"
                onChange={(event) => {
                  setFieldValue('incoterms', event.target.value);
                }}
                onBlur={() => {
                  setFieldTouched('incoterms', true);
                }}
                value={values.incoterms}
              >
                {Object.values(incotermsList).map((incoterm) => {
                  return (
                    <option value={incoterm.code} key={incoterm.code}>
                      {incoterm.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </fieldset>
        <label className="section-header" ref={this.sectionSamples.ref}>
          {this.sectionSamples.label}
        </label>
        <label className="section-header-description">
          The supplier will be asked the following questions with this quote request:
          <br />
          What is the cost and lead time for samples?
        </label>
        <fieldset>
          <div className="row">
            <div
              className={classNames('form-group col-sm-3', {
                'with-error': touched.numberSamples && errors.numberSamples,
              })}
            >
              <label>Number of Samples</label>
              <Field type="number" className="form-control" name="numberSamples" />
              {touched.numberSamples && errors.numberSamples && (
                <label className="error">{errors.numberSamples}</label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <label className="section-header-2">Delivery Address for Samples</label>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <input
                type="checkbox"
                data-id="product-form-use-delivery-address-in-file"
                checked={this.state.deliveryAddressInFile}
                onChange={this.onChangeDeliveryAddressInFile}
              />
              <StyledCheckboxSpan>Use the address on file for this profile.</StyledCheckboxSpan>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-6">
              <label>Company</label>
              <Field
                className="form-control"
                name="deliveryAddress.company"
                disabled={this.state.deliveryAddressInFile}
              />
            </div>
            <div className="form-group col-sm-6">
              <label>Name</label>
              <Field
                className="form-control"
                name="deliveryAddress.name"
                disabled={this.state.deliveryAddressInFile}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-6">
              <label>Address</label>
              <Field
                className="form-control"
                name="deliveryAddress.address"
                disabled={this.state.deliveryAddressInFile}
              />
            </div>
            <div className="form-group col-sm-6">
              <label>Position</label>
              <Field
                className="form-control"
                name="deliveryAddress.position"
                disabled={this.state.deliveryAddressInFile}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-6">
              <label>Address 2</label>
              <Field
                className="form-control"
                name="deliveryAddress.address2"
                disabled={this.state.deliveryAddressInFile}
              />
            </div>
            <div className="form-group col-sm-6">
              <label>Telephone</label>
              <Field
                className="form-control"
                name="deliveryAddress.telephone"
                disabled={this.state.deliveryAddressInFile}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-6">
              <label>Country</label>
              <select
                className="form-control"
                name="deliveryAddress.country"
                disabled={this.state.deliveryAddressInFile}
                onChange={(event) => {
                  this.props.setFieldValue('deliveryAddress.country', event.target.value);
                }}
                onBlur={() => {
                  this.props.setFieldTouched('deliveryAddress.country', true);
                }}
                value={values.deliveryAddress.country}
              >
                <option value="" />
                {countriesList.map((country) => {
                  return (
                    <option value={country.name} key={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-6">
              <label>Website</label>
              <Field
                className="form-control"
                name="deliveryAddress.website"
                disabled={this.state.deliveryAddressInFile}
                placeholder={'https://www.example.com'}
              />
            </div>
          </div>
        </fieldset>
        <label className="section-header" ref={this.sectionExtraQuestions.ref}>
          {this.sectionExtraQuestions.label}
        </label>
        <label className="section-header-description">
          You can ask the supplier any other questions you have here <br />
          <br />
          <span className={'example-description'}>
            Example - What are your standard payment terms?
          </span>
          <StyledExampleText />
        </label>
        <div className="row">
          <div className="form-group col-sm-12">
            <label>Question</label>
            <FieldArray
              name="questions"
              render={(arrayHelpers) => (
                <div>
                  {values.questions.map((question, index) => (
                    <div className="form-group" key={index}>
                      <Field className="form-control" name={`questions.${index}`} />
                    </div>
                  ))}
                  <button
                    className={'btn white-button'}
                    id="add-question"
                    type="button"
                    onClick={() => arrayHelpers.push('')}
                  >
                    <span className={'icon'}>
                      <IconCirclePlus width={15} height={15} color={'#111111'} />
                    </span>
                    Add Question
                  </button>
                </div>
              )}
            />
          </div>
        </div>
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className={'actions-bottom'}>
          <button
            onClick={this.props.onCancel}
            className="btn white-button"
            disabled={isSubmitting}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn white-button"
            disabled={isSubmitting}
            type="button"
            onClick={() => {
              setFieldValue('status', 'draft');
              handleSubmit(values, { props: this.props, setSubmitting, setErrors });
            }}
          >
            Save Draft
          </button>
          <button
            className="btn blue-button"
            disabled={isSubmitting}
            type="button"
            onClick={() => {
              setFieldValue('status', 'complete');
              handleSubmit(values, { props: this.props, setSubmitting, setErrors });
            }}
          >
            Save & Get Quotes
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Product Name is required'),
  currency: Yup.string().required('Currency is required'),
  specs: Yup.array()
    .compact(function(spec) {
      return spec.name.trim().length === 0 || spec.value.trim().length === 0;
    })
    .min(1, 'At least one Specification is required'),
  targetPrice: Yup.number()
    .positive('Only positive value allowed')
    .max(9999999, 'Maximum exceeded'),
  requiredQuantity: Yup.array().of(quantityValidationRule),
  numberSamples: quantityZeroAllowedValidationRule,
});

const AddProductForm = withFormik({
  mapPropsToValues: (props) => {
    const { product } = props;
    const specs = product.specs
      ? product.specs
      : [
          { name: 'References', value: '', default: true },
          { name: 'Material', value: '', default: true },
          { name: 'Size', value: '', default: true },
          { name: 'Packaging', value: '', default: true },
          { name: '', value: '' },
        ];

    let deliveryAddress = {
      company: props.company.name,
      name: props.user.fullName,
      position: props.user.position || '',
      address: props.company.address || '',
      address2: props.company.address2 || '',
      telephone: props.company.telephone || '',
      country: props.company.country,
      website: props.company.website || '',
    };
    if (!props.isDuplication && product.deliveryAddress) {
      deliveryAddress = product.deliveryAddress;
    }

    let photoFiles = [];
    let photoPreviews = [];
    if (product.photos) {
      photoFiles = product.photos.map((name) => ({ name, uploaded: true }));
      photoPreviews = product.photosUrl;
    }

    return {
      _id: product._id || null,
      name: product.name || '',
      status: product.status || 'draft',
      photoFiles,
      photoPreviews,
      currency: product.currency || '',
      specs,
      targetPrice: product.targetPrice || '',
      requiredQuantity: product.requiredQuantity || [''],
      incoterms: product.incoterms || 'standard',
      numberSamples: product.numberSamples || 0,
      deliveryAddress,
      questions: product.questions || [''],
    };
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantityAddingProduct(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }
    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

AddProductForm.propTypes = {
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  isDuplication: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddProductForm.defaultProps = {
  product: {},
  isDuplication: false,
};

export default AddProductForm;
