import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';

import { appFetchingData } from '../../../actions';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import api from '../../../service/api';
import Quotes from '../../../component/quote/Quotes';
import SupplierQuoteContainer from '../SupplierQuoteContainer';
import QuoteFullFormContainer from '../QuoteFullFormContainer';
import Search from '../../../component/common/Search';

class QuotesContainer extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      getQuotesCancelSource: CancelToken.source(),
      error: null,
      search: '',
    };
  }

  fetchQuotes = async () => {
    this.props.appFetchingData(true);
    try {
      const res = await api.quotesSupplierGet(
        this.props.companyId,
        this.state.getQuotesCancelSource.token,
        { search: this.state.search }
      );
      this.props.appFetchingData(false);
      this.setState({ quotes: res.data });
    } catch (err) {
      this.props.appFetchingData(false);
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  componentDidMount = () => {
    this.fetchQuotes();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search) {
      this.fetchQuotes();
    }
  }

  componentWillUnmount() {
    this.state.getQuotesCancelSource.cancel();
  }

  /**
   *
   * @param quoteId
   * @param page 'view' or 'edit'
   */
  onQuoteClick = (quoteId, page) => {
    if (page === 'edit') {
      this.props.history.push(`/dashboard/company/${this.props.companyId}/quote/${quoteId}`);
    }
    if (page === 'view') {
      this.props.history.push(`/dashboard/company/${this.props.companyId}/quotes/${quoteId}`);
    }
  };

  setSearch = (search) => {
    this.setState({ search });
  };

  render() {
    const basePath = `/dashboard/company/${this.props.companyId}/quotes`;

    return (
      <Switch>
        <Route
          exact
          path={`${basePath}`}
          render={() => (
            <div className="quotes-container table-container">
              <label className="page-header">Quotes</label>
              <Search search={this.state.search} setSearch={this.setSearch} />
              <Quotes
                quotes={this.state.quotes}
                error={this.state.error}
                onQuoteClick={this.onQuoteClick}
                history={this.props.history}
              />
            </div>
          )}
        />
        <Route exact path={`${basePath}/:quoteId/full`} render={() => <QuoteFullFormContainer />} />
        <Route
          path={`${basePath}/:quoteId`}
          history={this.props.history}
          render={() => <SupplierQuoteContainer />}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.dashboard.activeCompanyId,
});

const mapDispatchToProps = (dispatch) => ({
  appFetchingData: (is) => dispatch(appFetchingData(is)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuotesContainer)
);
