import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconPrevButton from '../../icon/IconPrevButton';
import IconNextButton from '../../icon/IconNextButton';
import './style.css';

export default class Pagination extends Component {
  static propTypes = {
    pages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onSwitchPage: PropTypes.func.isRequired,
    maxControls: PropTypes.number,
    paginationSpaceString: PropTypes.string,
  };

  static defaultProps = {
    maxControls: 999,
    spaceString: '..',
  };

  state = {
    pagesList: [],
  };

  setPagesList = (pagesCount) => {
    let pagesList = [];

    const { page, spaceString, maxControls } = this.props;
    const maxPageCountPlusNavSpace =
      page === 1 || page === pagesCount ? maxControls + 1 : maxControls;

    if (pagesCount > maxPageCountPlusNavSpace) {
      const pagesListLeft = [];
      const pagesListRight = [];
      let shownPagesCount = 3; // 2 navigation spaces + 0 index

      // Push page numbers until limit reached
      for (let i = 0; i < pagesCount; i++) {
        if (page - i > 0) {
          pagesListLeft.unshift(page - i);
          shownPagesCount++;
        }

        if (shownPagesCount === maxPageCountPlusNavSpace) {
          break;
        }

        if (page + i <= pagesCount) {
          pagesListRight.push(page + i);
          shownPagesCount++;
        }

        if (shownPagesCount === maxPageCountPlusNavSpace) {
          break;
        }
      }

      // Free space at the Left part, if the Right part fully filled
      if (
        pagesListRight.length > 0 &&
        pagesListRight[pagesListRight.length - 1] === pagesCount &&
        (pagesListRight.length === 0 || pagesListLeft[0] !== 2)
      ) {
        pagesListLeft.unshift(pagesListLeft - 1);
      }

      if (pagesListLeft.length > 0 && pagesListLeft[0] !== 1) {
        if (pagesListLeft[0] !== 2) {
          pagesListLeft.splice(0, 1, spaceString);
        }

        pagesListLeft.unshift(1);
      } else if (pagesListRight[pagesListRight.length - 1] !== pagesCount - 1) {
        // Free space at the Right part, if the Left part fully filled
        pagesListRight.push(pagesListRight[pagesListRight.length - 1] + 1);
      }

      pagesListRight.splice(0, 1); // Remove duplication
      if (pagesListRight.length > 0 && pagesListRight[pagesListRight.length - 1] !== pagesCount) {
        if (pagesListRight[pagesListRight.length - 1] !== pagesCount - 1) {
          pagesListRight.splice(pagesListRight.length - 1, 1, spaceString);
        }

        pagesListRight.push(pagesCount);
      }

      pagesList = pagesListLeft.concat(pagesListRight);
    } else {
      for (let i = 1; i <= pagesCount; i++) {
        pagesList.push(i);
      }
    }

    this.setState({ pagesList: pagesList });
  };

  onSwitchPageBack = () => {
    let nextValue = this.props.page - 1;
    this.props.onSwitchPage(nextValue);
  };

  onSwitchPageForward = () => {
    let nextValue = this.props.page + 1;
    this.props.onSwitchPage(nextValue);
  };

  componentDidMount() {
    this.setPagesList(this.props.pages);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pages !== prevProps.pages || this.props.page !== prevProps.page) {
      this.setPagesList(this.props.pages);
    }
  }

  render() {
    const { page, spaceString } = this.props;
    const pagesList = this.state.pagesList.map((pageNumber, index) => {
      return pageNumber === spaceString ? (
        <div key={index} className={'pagination-btn-number pagination-space-string'}>
          {pageNumber}
        </div>
      ) : (
        <div
          key={index}
          onClick={() => {
            this.props.onSwitchPage(pageNumber);
          }}
          className={classNames('pagination-btn-number', {
            active: pageNumber === page,
          })}
        >
          {pageNumber}
        </div>
      );
    });

    return (
      <div className="pagination">
        {this.props.page > 1 && (
          <span onClick={this.onSwitchPageBack} className="pagination-btn prev">
            <IconPrevButton width={20} height={20} />
          </span>
        )}
        {pagesList}
        {this.props.page < this.props.pages && (
          <span onClick={this.onSwitchPageForward} className="pagination-btn next">
            <IconNextButton width={20} height={20} />
          </span>
        )}
      </div>
    );
  }
}
