import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';

import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import moment from '../../../util/moment';
import IconLocation from '../../icon/IconLocation';
import IconCalendar from '../../icon/IconCalendar';
import './style.css';

const ProductImageStyled = styled.div`
  width: 200px;
  height: 200px;
  background: center/contain no-repeat;
`;

const ProductImageStyledPlaceholder = styled.img`
  width: 200px;
  height: 200px;
`;

const ProductNameStyled = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #0d0d0d;
`;

const ProductFormGroupLabelStyled = styled.div`
  font-weight: 500;
  color: var(--common-text-color);
`;

const IconLocationContainerStyled = styled.div`
  margin-right: 5px;
  display: inline;
`;

const IconCalendarContainerStyled = styled.div`
  position: absolute;
  z-index: 2500;
  top: 6px;
  left: 10px;
`;

const ContainerInputCalendarStyled = styled.div`
  position: relative;
`;

class QuoteFormSectionHeader extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    goToClientProfile: PropTypes.func,
  };

  render() {
    const {
      product,
      company,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      readOnly,
      t,
    } = this.props;

    return (
      <div className="quote-form-section-header-container">
        <div className="row">
          <div className="col-sm-4 col-xs-12">
            {product.photosUrl[0] ? (
              <ProductImageStyled style={{ backgroundImage: `url(${product.photosUrl[0]})` }} />
            ) : (
              <ProductImageStyledPlaceholder src={imgPlaceholder} alt={''} />
            )}
          </div>
          <div className="col-sm-8">
            <div className={'row'}>
              <div className="col-sm-6">
                <div className="form-group">
                  <ProductNameStyled id="product-name">{product.name}</ProductNameStyled>
                  <div className="form-group">
                    <div className="description">
                      {_startCase(t('common.requested', 'Requested'))}{' '}
                      {moment(product.createdAt).format()}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <ProductFormGroupLabelStyled data-id={'companion-company-name'} id="company-name">
                    {company.name}
                  </ProductFormGroupLabelStyled>
                  <div id="company-country">
                    <IconLocationContainerStyled>
                      <IconLocation width={11} height={15} color={'#111111'} />
                    </IconLocationContainerStyled>
                    {company.country}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  className={classNames('form-group with-datepicker', {
                    'with-error': touched.validUntil && errors.validUntil,
                  })}
                >
                  <label>
                    {_startCase(
                      t('common.quote', 'Quote') +
                        ' ' +
                        t('common.valid', 'Valid') +
                        ' ' +
                        t('common.until', 'Until')
                    )}
                  </label>
                  <ContainerInputCalendarStyled>
                    <IconCalendarContainerStyled>
                      <IconCalendar width={18} height={18} color="#4d4d4d" />
                    </IconCalendarContainerStyled>
                    <DatePicker
                      name="validUntil"
                      className={'form-control'}
                      onChange={(date) => {
                        setFieldValue('validUntil', date.toISOString());
                      }}
                      onBlur={() => {
                        setFieldTouched('validUntil', true);
                      }}
                      selected={values.validUntil.length ? moment(values.validUntil) : null}
                      dateFormat="MM/DD/YYYY"
                      placeholderText="mm/dd/yyyy"
                      disabled={readOnly}
                      minDate={moment()}
                    />
                  </ContainerInputCalendarStyled>
                  {touched.validUntil && errors.validUntil && (
                    <label className="error">{t(errors.validUntil)}</label>
                  )}
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    {_startCase(
                      t('common.currency', 'Currency') + ' ' + t('common.requested', 'Requested')
                    )}
                  </label>
                  <div id="product-currency">{product.currency}</div>
                </div>
              </div>
              {/*<div className="col-sm-6">*/}
              {/*<div className="form-group">*/}
              {/*<label>Currency Quoted</label>*/}
              {/*<select*/}
              {/*className="form-control"*/}
              {/*name="currency"*/}
              {/*onChange={(event) => {*/}
              {/*setFieldValue('currency', event.target.value);*/}
              {/*}}*/}
              {/*onBlur={() => {*/}
              {/*setFieldTouched('currency', true);*/}
              {/*}}*/}
              {/*value={values.currency}*/}
              {/*disabled={readOnly}*/}
              {/*>*/}
              {/*{Object.values(currenciesList).map((currency) => {*/}
              {/*return (*/}
              {/*<option value={currency.code} key={currency.code}>*/}
              {/*{currency.code} {currency.name_plural}*/}
              {/*</option>*/}
              {/*);*/}
              {/*})}*/}
              {/*</select>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const HeaderValidationScheme = {
  validUntil: Yup.string().required('quote.validation.Quote Valid Until is required'),
};

export default QuoteFormSectionHeader;
