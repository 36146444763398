import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { withNamespaces } from 'react-i18next';

import { isAuthorized } from '../../../util/session';
import QuoteRequestSignUpFormContainer from '../../quote/QuoteRequestSignUpFormContainer';
import QuoteRequestSignUpFormAContainer from '../../quote/QuoteRequestSignUpFormAContainer';
import QuoteRequestSignUpFormBContainer from '../../quote/QuoteRequestSignUpFormBContainer';
import QuoteRequestSignUpFormCContainer from '../../quote/QuoteRequestSignUpFormCContainer';
import QuoteRequestSignUpFormDContainer from '../../quote/QuoteRequestSignUpFormDContainer';
import LanguageSelector from '../../../component/common/LanguageSelector';

class SignQuoteContainer extends Component {
  onLanguageChange = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  render() {
    return (
      <div className="dashboard-container">
        <div className="language-selector-container-top-right">
          <LanguageSelector lng={this.props.i18n.language} onLngChange={this.onLanguageChange} />
        </div>
        <Switch>
          <Route
            exact
            path="/p1/:productId"
            render={() => {
              return isAuthorized() ? (
                <Redirect to={'/p/' + this.props.match.params.productId} />
              ) : (
                <QuoteRequestSignUpFormContainer />
              );
            }}
          />
          <Route
            exact
            path="/p2/:productId"
            render={() => {
              return isAuthorized() ? (
                <Redirect to={'/p/' + this.props.match.params.productId} />
              ) : (
                <QuoteRequestSignUpFormAContainer />
              );
            }}
          />
          <Route
            exact
            path="/p3/:productId"
            render={() => {
              return isAuthorized() ? (
                <Redirect to={'/p/' + this.props.match.params.productId} />
              ) : (
                <QuoteRequestSignUpFormBContainer />
              );
            }}
          />
          <Route
            exact
            path="/p4/:productId"
            render={() => {
              return isAuthorized() ? (
                <Redirect to={'/p/' + this.props.match.params.productId} />
              ) : (
                <QuoteRequestSignUpFormCContainer />
              );
            }}
          />
          <Route
            exact
            path="/p5/:productId"
            render={() => {
              return isAuthorized() ? (
                <Redirect to={'/p/' + this.props.match.params.productId} />
              ) : (
                <QuoteRequestSignUpFormDContainer />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default withNamespaces()(SignQuoteContainer);
