import React from 'react';
import { connect } from 'react-redux';
import axios, { CancelToken } from 'axios';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AddProjectForm from '../../../component/project/AddProjectForm';
import IconBox from '../../../component/icon/IconBox';
import { appFetchingData } from '../../../actions';
import api from '../../../service/api';
import {
  addCommonFormikErrorsIfEmpty,
  errorsTransformAxiosToFormik,
  SOMETHING_WENT_WRONG_ERROR_TEXT,
} from '../../../util/error';
import IconClose from '../../../component/icon/IconClose';
import { rolePriority } from '../../../util/user';
import { projectCreatedSuccess } from '../actions';

export class AddProjectFormContainer extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);

    this.state = { users: [], getUsersCancelSource: CancelToken.source(), error: null };
  }

  onAddProject = async (values, setSubmitting, setErrors) => {
    try {
      const res = await api.projectPost(this.props.companyId, values);
      this.props.onProjectCreated(this.props.companyId, res.data);
    } catch (err) {
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };

  componentDidMount = async () => {
    this.props.appFetchingData(true);
    try {
      const res = await api.companyUsersGet(
        this.props.companyId,
        this.state.getUsersCancelSource.token
      );
      this.props.appFetchingData(false);
      const users = res.data.sort((u1, u2) => {
        if (u1.role === u2.role) {
          return 0;
        }

        return rolePriority.indexOf(u1.role) > rolePriority.indexOf(u2.role) ? 1 : -1;
      });
      this.setState({ users });
    } catch (err) {
      this.props.appFetchingData(false);
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  componentWillUnmount() {
    this.state.getUsersCancelSource.cancel();
  }

  render() {
    return (
      <div className={'add-project-from-container'}>
        <div className={'icon-close-container'} onClick={this.props.history.goBack}>
          <IconClose width={18} height={18} color={'black'} />
        </div>
        <div className="form-header">
          <span className="form-header-icon">
            <IconBox width={36} height={36} color={'#0095FF'} />
          </span>
          Add Project
        </div>
        <div className="form-header-description">
          This is where you can create a project for your team to keep a group of products
          organized.
        </div>
        {this.state.users.length > 0 && (
          <AddProjectForm
            usersError={this.state.error}
            users={this.state.users}
            onCancel={this.props.history.goBack}
            onSubmit={this.onAddProject}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.dashboard.activeCompanyId,
});

const mapDispatchToProps = (dispatch) => ({
  appFetchingData: (is) => dispatch(appFetchingData(is)),
  onProjectCreated: (companyId, project) => dispatch(projectCreatedSuccess(companyId, project)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddProjectFormContainer)
);
