import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import FormSectionCompany, {
  FormSectionCompanyValidationScheme,
} from '../../form/FormSectionCompany';
import FormSectionCompanyDetails, {
  FormSectionCompanyDetailsValidationScheme,
} from '../../form/FormSectionCompanyDetails';
import FormSectionSupplierProfile, {
  FormSectionSupplierProfileValidationScheme,
} from '../../form/FormSectionSupplierProfile';
import FormSectionUser, { FormSectionUserValidationScheme } from '../../form/FormSectionUser';
import StickyMenu from '../../common/StickyMenu';
import ErrorTop from '../../form/ErrorTop';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import './style.css';

export class Form extends React.Component {
  sectionCompany = {
    name: 'company',
    label: 'Company',
    ref: React.createRef(),
  };

  sectionCompanyDetails = {
    name: 'companyDetails',
    label: 'Company Details',
    ref: React.createRef(),
  };

  sectionSupplierProfile = {
    name: 'supplierProfile',
    label: 'Supplier Profile',
    ref: React.createRef(),
  };

  sectionUserInformation = {
    name: 'userInformation',
    label: 'User Information',
    ref: React.createRef(),
  };

  sectionHeaderRefs = [
    this.sectionCompany,
    this.sectionCompanyDetails,
    this.sectionSupplierProfile,
    this.sectionUserInformation,
  ];

  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      isValid,
      setFieldValue,
      setFieldTouched,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <ErrorTop isSubmitting={isSubmitting} isValid={isValid} errors={errors} />
        <StickyMenu items={this.sectionHeaderRefs} />
        <label className="section-header" ref={this.sectionCompany.ref}>
          {this.sectionCompany.label}
        </label>
        <FormSectionCompany
          values={values.company}
          fieldsNamePrefix="company."
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors.company}
          touched={touched.company}
        />
        <label className="section-header" ref={this.sectionCompanyDetails.ref}>
          {this.sectionCompanyDetails.label}
        </label>
        <FormSectionCompanyDetails
          values={values.company}
          fieldsNamePrefix="company."
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors.company}
          touched={touched.company}
        />
        <label className="section-header" ref={this.sectionSupplierProfile.ref}>
          {this.sectionSupplierProfile.label}
        </label>
        <FormSectionSupplierProfile
          values={values.supplier}
          fieldsNamePrefix="supplier."
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors.supplier}
          touched={touched.supplier}
        />
        <label className="section-header" ref={this.sectionUserInformation.ref}>
          {this.sectionUserInformation.label}
        </label>
        <FormSectionUser
          values={values}
          fieldsNamePrefix=""
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
        />
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className="col-sm-12 buttons-bottom">
          <button className="btn blue-button" disabled={isSubmitting} type="submit">
            Save & Continue
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    ...FormSectionCompanyValidationScheme,
    ...FormSectionCompanyDetailsValidationScheme,
  }),
  supplier: Yup.object().shape({
    ...FormSectionSupplierProfileValidationScheme,
  }),
  ...FormSectionUserValidationScheme,
});

const SignUpCompleteFormSupplier = withFormik({
  mapPropsToValues: (props) => ({
    _id: props.user._id,
    fullName: props.user.fullName,
    position: '',
    email: props.user.email,
    telephone: '',
    company: {
      _id: props.user.company._id,
      name: props.user.company.name || '',
      type: 'supplier',
      logoFile: null,
      address: '',
      address2: '',
      zipCode: '',
      country: '',
      telephone: '',
      website: '',
      about: '',
      staff: '',
      revenue: '',
      established: '',
    },
    supplier: {
      products: '',
      markets: [],
      photoFiles: [],
      customers: '',
      machineList: '',
      businessTerms: '',
      rdStaff: '',
      qcStaff: '',
      salesStaff: '',
      operationsStaff: '',
      otherStaff: '',
    },
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

SignUpCompleteFormSupplier.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    company: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }),
};

export default SignUpCompleteFormSupplier;
