import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SignResetPasswordForm from '../../../component/sign/SignResetPasswordForm';
import api from '../../../service/api';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../../util/error';
import PropTypes from 'prop-types';

class SignResetPasswordContainer extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    token: null,
    wasPasswordReset: false,
  };

  onSubmit = async (password, setSubmitting, setErrors) => {
    try {
      await api.userResetPasswordByToken(password, this.state.token);
      setSubmitting(false);
      this.setState({ wasPasswordReset: true });
    } catch (err) {
      setSubmitting(false);
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
    }
  };

  componentDidMount = () => {
    if (this.props.location.search.indexOf('?token=') === -1) {
      this.props.history.push('/sign/forgot-password');
    } else {
      const token = this.props.location.search.replace('?token=', '');
      this.setState({ token });
    }
  };

  render() {
    return (
      <div className="sign-reset-password-form-container form-black-container">
        <div className="form-header">
          {this.state.wasPasswordReset ? 'Password successfully reset!' : 'Reset Password'}
        </div>
        {!this.state.wasPasswordReset && <SignResetPasswordForm onSubmit={this.onSubmit} />}
        {this.state.wasPasswordReset && (
          <button className="btn" type="submit" onClick={() => this.props.history.push('/signin')}>
            Return to Login
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(SignResetPasswordContainer);
