import React from 'react';
import PropTypes from 'prop-types';

const IconLngZh = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 14 10"
    >
      <defs>
        <rect id="a" width={props.width} height={props.height} rx="2" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#FFF" xlinkHref="#a" />
        <path fill="#F1361D" d="M0 0h14v10H0z" mask="url(#b)" />
        <path
          fill="#FFDC42"
          d="M6.753 2.989l-.42.255.012-.491-.256-.42.491.012.42-.256-.011.491.255.42-.491-.011zM5.52 1.633l-.485-.083.331-.363.083-.484.363.33.484.083-.33.363-.083.485-.363-.331zm.243 4.352l-.41.27-.006-.49-.27-.411.491-.006.41-.27.006.491.27.41-.49.006zm.845-1.323l-.488.054.217-.44-.054-.489.44.218.489-.054-.218.44.054.488-.44-.217zM3.333 4.113l-1.175.838.434-1.377-1.16-.859 1.443-.013.458-1.369.459 1.37 1.443.012-1.16.86.434 1.376z"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
};

IconLngZh.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconLngZh;
