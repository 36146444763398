import React from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
}) => {
  const emailGroupClass = classNames('form-group', {
    'with-error': touched.email && errors.email,
  });
  const passwordGroupClass = classNames('form-group', {
    'with-error': touched.password && errors.password,
  });

  return (
    <form onSubmit={handleSubmit}>
      {errors.common && <label className="error common">{errors.common}</label>}
      <div className={emailGroupClass}>
        <label>Email Address</label>
        <Field className="form-control" type="email" name="email" id="username" />
        {touched.email && errors.email && <label className="error">{errors.email}</label>}
      </div>
      <div className={passwordGroupClass}>
        <label>Password</label>
        <Field
          className="form-control"
          id="password"
          type="password"
          name="password"
          autoComplete="current-password"
        />
        {touched.password && errors.password && <label className="error">{errors.password}</label>}
      </div>
      <button className="btn" disabled={isSubmitting} type="submit">
        Sign In
      </button>
    </form>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email Address is not valid')
    .required('Email Address is required'),
  password: Yup.string().required('Password is required'),
});

const SignUpFormEmail = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    // Errors set by setErrors from action if action rejected
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

SignUpFormEmail.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpFormEmail;
