import React from 'react';
import PropTypes from 'prop-types';
import IconPhoto from '../../icon/IconPhoto';
import './style.css';

class UploadButton extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputRef: PropTypes.any.isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    buttonLabel: PropTypes.string,
  };

  static defaultProps = {
    multiple: false,
    buttonLabel: 'Upload photos',
  };

  onFileChange = (e) => {
    e.preventDefault();

    this.props.onChange(this.props.multiple ? e.target.files : e.target.files[0]);
  };

  render() {
    return (
      <div className="upload-button-container">
        <button className="btn white-button" type="button">
          <span className="btn-icon">
            <IconPhoto width={16} height={16} color="#111" />
          </span>
          {this.props.buttonLabel}
        </button>
        <input
          className="hidden-upload-button-file"
          id={this.props.id}
          ref={this.props.inputRef}
          onChange={this.onFileChange}
          type="file"
          accept={this.props.accept}
          multiple={this.props.multiple}
        />
      </div>
    );
  }
}

export default UploadButton;
