import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';

import { displayMoney } from '../../../util/currency';
import { incotermsList } from '../../../util/incoterms';
import { debouncedAfterChangeTargetRequiredQuantity } from '../QuoteForm/helper';

const SectionDescriptionStyled = styled.div`
  max-width: 570px;
  margin-bottom: 40px;
`;

const CheckboxStyled = styled.input`
  display: inline-flex;
`;

const CheckboxLabelStyled = styled.label`
  margin-left: 7px;
`;

class QuoteFormSectionQuantities extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    debouncedAfterChangeTargetRequiredQuantity.cancel();
  }

  render() {
    const {
      readOnly,
      product,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      setValues,
      t,
    } = this.props;
    return (
      <fieldset>
        {product.targetPrice && (
          <div data-id="quote-form-target-required-quantity">
            <SectionDescriptionStyled>{t('quote.common.targetPriceText')}</SectionDescriptionStyled>
            <div className={'row'}>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>{_startCase(t('common.target price'))}</label>
                  <div id="targetPrice">{displayMoney(product.targetPrice, product.currency)}</div>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className="col-sm-5">
                <div
                  className={classNames('form-group', {
                    'with-error': touched.targetRequiredQuantity && errors.targetRequiredQuantity,
                  })}
                >
                  <label>{t('quote.common.Required Quantity to hit this price?')}</label>
                  <Field
                    name="targetRequiredQuantity"
                    className="form-control"
                    type="number"
                    disabled={
                      values.targetPriceCanNotReach ||
                      (values.minimumQuantity === values.targetRequiredQuantity &&
                        values.pricePerUnit === product.targetPrice) ||
                      readOnly
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      setFieldValue('targetRequiredQuantity', value);
                      debouncedAfterChangeTargetRequiredQuantity(this.props, value);
                    }}
                    onBlur={() => {
                      setFieldTouched('targetRequiredQuantity', true);
                    }}
                  />
                  {touched.targetRequiredQuantity && errors.targetRequiredQuantity && (
                    <label id="targetRequiredQuantity-error" className="error">
                      {t(`quote.validation.${errors.targetRequiredQuantity}`)}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-sm-5">
                <div
                  className={classNames('form-group', {
                    'with-error': touched.targetLeadTime && errors.targetLeadTime,
                  })}
                >
                  <label>{_startCase(t('quote.common.leadTimeLabel'))}</label>
                  <Field
                    className="form-control narrow"
                    name={`targetLeadTime`}
                    type="number"
                    placeholder="ex. 30"
                    disabled={
                      values.targetPriceCanNotReach ||
                      (values.minimumQuantity === values.targetRequiredQuantity &&
                        values.pricePerUnit === product.targetPrice) ||
                      readOnly
                    }
                    onChange={(event) => {
                      const v = event.target.value;
                      const data = { ...values, targetLeadTime: v };
                      Object.keys(data.requiredQuantity).forEach((i) => {
                        if (data.requiredQuantity[i].q === parseInt(v, 10)) {
                          data.requiredQuantity[i].leadTime = v;
                        }
                      });
                      setValues(data);
                    }}
                    onBlur={() => {
                      setFieldTouched('targetLeadTime', true);
                    }}
                  />
                  {touched.targetLeadTime && errors.targetLeadTime && (
                    <label className="error">
                      {t(`quote.validation.${errors.targetLeadTime}`)}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className="col-sm-6">
                <div className="form-group">
                  <CheckboxStyled
                    id="targetPriceCanNotReach"
                    name="targetPriceCanNotReach"
                    type="checkbox"
                    onChange={() => {
                      setFieldValue('targetPriceCanNotReach', !values.targetPriceCanNotReach);
                    }}
                    onBlur={() => {
                      setFieldTouched('targetPriceCanNotReach', true);
                    }}
                    checked={values.targetPriceCanNotReach}
                    disabled={readOnly}
                  />
                  <CheckboxLabelStyled htmlFor="targetPriceCanNotReach">
                    {t('quote.common.Select here if you cannot reach this price')}
                  </CheckboxLabelStyled>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="section-header">{_startCase(t('common.volume quotes'))}</div>
        <div className="section-header-description">
          <em>{t('quote.common.moqText')}</em>
        </div>
        <div className="row">
          <div
            className={classNames('form-group col-sm-4', {
              'with-error': touched.minimumQuantity && errors.minimumQuantity,
            })}
          >
            <label>{_startCase(t('common.minimum quantity'))}</label>
            <div className="form-value">
              <input
                className="form-control narrow"
                type="number"
                maxLength="15"
                name="minimumQuantity"
                placeholder="ex. 1,000"
                onChange={(event) => {
                  const v = event.target.value;
                  const data = { ...values, minimumQuantity: v };
                  Object.keys(data.requiredQuantity).forEach((i) => {
                    if (data.requiredQuantity[i].q === parseInt(v, 10)) {
                      data.requiredQuantity[i].pricePerUnit = data.pricePerUnit;
                      data.requiredQuantity[i].leadTime = data.leadTime;
                    }
                    if (data.requiredQuantity[i].q < parseInt(v, 10)) {
                      data.requiredQuantity[i].pricePerUnit = '';
                      data.requiredQuantity[i].leadTime = '';
                    }
                  });
                  setValues(data);
                }}
                onBlur={() => {
                  setFieldTouched('minimumQuantity', true);
                }}
                value={values.minimumQuantity}
                disabled={readOnly}
              />
              {touched.minimumQuantity && errors.minimumQuantity && (
                <label className="error">{t(`quote.validation.${errors.minimumQuantity}`)}</label>
              )}
            </div>
          </div>
          <div
            className={classNames('form-group col-sm-4', {
              'with-error': touched.pricePerUnit && errors.pricePerUnit,
            })}
          >
            <label>{_startCase(t('common.price per unit'))}</label>
            <div className="form-value">
              <input
                className="form-control narrow"
                type="number"
                name="pricePerUnit"
                placeholder="ex. $4.56"
                onChange={(event) => {
                  const v = event.target.value;
                  const data = { ...values, pricePerUnit: v };
                  Object.keys(data.requiredQuantity).forEach((i) => {
                    if (data.requiredQuantity[i].q === parseInt(data.minimumQuantity, 10)) {
                      data.requiredQuantity[i].pricePerUnit = v;
                    }
                  });
                  setValues(data);
                }}
                onBlur={() => {
                  setFieldTouched('pricePerUnit', true);
                }}
                value={values.pricePerUnit}
                disabled={readOnly}
              />
              {touched.pricePerUnit && errors.pricePerUnit && (
                <label className="error">{t(`quote.validation.${errors.pricePerUnit}`)}</label>
              )}
            </div>
          </div>
          <div
            className={classNames('form-group col-sm-4', {
              'with-error': touched.leadTime && errors.leadTime,
            })}
          >
            <label>{t('quote.common.leadTimeLabel')}</label>
            <div className="form-value">
              <input
                className="form-control narrow"
                name="leadTime"
                type="number"
                placeholder="ex. 30"
                onChange={(event) => {
                  const v = event.target.value;
                  const data = { ...values, leadTime: v };
                  Object.keys(data.requiredQuantity).forEach((i) => {
                    if (data.requiredQuantity[i].q === parseInt(data.minimumQuantity, 10)) {
                      data.requiredQuantity[i].leadTime = v;
                    }
                  });
                  setValues(data);
                }}
                onBlur={() => {
                  setFieldTouched('leadTime', true);
                }}
                value={values.leadTime}
                disabled={readOnly}
              />
              {touched.leadTime && errors.leadTime && (
                <label className="error">{t(`quote.validation.${errors.leadTime}`)}</label>
              )}
            </div>
          </div>
        </div>

        <div className="section-header" />
        <div className="section-header-description">
          <em>{t('quote.common.requiredQuantityText')}</em>
        </div>
        <div className="row d-none d-sm-block">
          <div className="form-group col-4 col-sm-4">
            <label>{_startCase(t('common.required quantity'))}</label>
          </div>
          <div className="form-group col-4 col-sm-4">
            <label>{_startCase(t('common.price per unit'))}</label>
          </div>
          <div className="form-group col-4 col-sm-4">
            <label>{_startCase(t('quote.common.leadTimeLabel'))}</label>
          </div>
        </div>
        {values.requiredQuantity.map((item, index) => {
          const quantity = item.q;
          const fieldTouched = (touched.requiredQuantity && touched.requiredQuantity[index]) || {};
          const fieldError = (errors.requiredQuantity && errors.requiredQuantity[index]) || {};
          const fieldDisabled =
            (values.minimumQuantity && values.minimumQuantity >= quantity) || readOnly;

          return (
            <div className="row" key={index}>
              <div className="form-group col-sm-4">
                <label className="d-block d-sm-none mt-4">
                  {_startCase(t('common.required quantity'))}
                </label>
                <div className="form-value vertically-centered required-quantity">{quantity}</div>
              </div>
              <div
                className={classNames('form-group col-sm-4', {
                  'with-error': fieldTouched.pricePerUnit && fieldError.pricePerUnit,
                })}
              >
                <label className="d-block d-sm-none">
                  {_startCase(t('common.price per unit'))}
                </label>
                <div className="form-value">
                  <Field
                    className="form-control narrow"
                    type="number"
                    name={`requiredQuantity.${index}.pricePerUnit`}
                    placeholder="ex. $4.56"
                    disabled={fieldDisabled}
                  />
                  {fieldTouched.pricePerUnit && fieldError.pricePerUnit && (
                    <label className="error">
                      {t(`quote.validation.${fieldError.pricePerUnit}`)}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={classNames('form-group col-sm-4', {
                  'with-error': fieldTouched.leadTime && fieldError.leadTime,
                })}
              >
                <label className="d-block d-sm-none">
                  {_startCase(t('quote.common.leadTimeLabel'))}
                </label>
                <div className="form-value">
                  <Field
                    className="form-control narrow"
                    name={`requiredQuantity.${index}.leadTime`}
                    type="number"
                    placeholder="ex. 30"
                    disabled={fieldDisabled}
                  />
                  {fieldTouched.leadTime && fieldError.leadTime && (
                    <label className="error">{t(`quote.validation.${fieldError.leadTime}`)}</label>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        <div className="section-header">{_startCase(t('common.incoterms'))}</div>
        <div className="section-header-description">
          <em>{t('quote.common.incotermsText')}</em>
        </div>
        <div className="row d-none d-md-block">
          <div className="form-group col-sm-4">
            <label>{_startCase(t('common.requested terms'))}</label>
          </div>
          <div className="form-group col-sm-4">
            <label>{_startCase(t('common.terms preference'))}</label>
          </div>
        </div>
        <div className="row form-group">
          <div className="form-group col-sm-4">
            <div className="form-value vertically-centered">
              {incotermsList[product.incoterms].label}
            </div>
          </div>
          <div className="form-group col-sm-4">
            <div className="form-value">
              <Field
                className="form-control narrow"
                component="select"
                name="termsPreference"
                disabled={readOnly}
              >
                {Object.values(incotermsList)
                  .filter((incoterm) => incoterm.code !== 'standard')
                  .map((incoterm) => {
                    return (
                      <option value={incoterm.code} key={incoterm.code}>
                        {incoterm.label}
                      </option>
                    );
                  })}
              </Field>
            </div>
          </div>
        </div>

        <div className="section-header">{_startCase(t('common.shipping information'))}</div>
        <div
          className={classNames('form-group', {
            'with-error': touched.cartonSize && errors.cartonSize,
          })}
        >
          <label>{_startCase(t('common.carton size'))}</label>
          <Field
            className="form-control"
            name="cartonSize"
            placeholder={t('quote.common.cartonSizePlaceholder')}
            disabled={readOnly}
          />
          {touched.cartonSize && errors.cartonSize && (
            <label className="error">{t(`quote.validation.${errors.cartonSize}`)}</label>
          )}
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.quantityPerCarton && errors.quantityPerCarton,
          })}
        >
          <label>{_startCase(t('common.quantity per carton'))}</label>
          <Field
            className="form-control"
            type="number"
            name="quantityPerCarton"
            placeholder={t('quote.common.cartonQuantityPlaceholder')}
            disabled={readOnly}
          />
          {touched.quantityPerCarton && errors.quantityPerCarton && (
            <label className="error">{t(`quote.validation.${errors.quantityPerCarton}`)}</label>
          )}
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.cartonGrossWeight && errors.cartonGrossWeight,
          })}
        >
          <label>{t('quote.common.cartonGrossWeightLabel')}</label>
          <Field
            className="form-control"
            type="number"
            name="cartonGrossWeight"
            placeholder={t('quote.common.cartonGrossWeightPlaceholder')}
            disabled={readOnly}
          />
          {touched.cartonGrossWeight && errors.cartonGrossWeight && (
            <label className="error">{t(`quote.validation.${errors.cartonGrossWeight}`)}</label>
          )}
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.cartonNetWeight && errors.cartonNetWeight,
          })}
        >
          <label>{t('quote.common.cartonNetWeightLabel')}</label>
          <Field
            className="form-control"
            type="number"
            name="cartonNetWeight"
            placeholder={t('quote.common.cartonNetWeightPlaceholder')}
            disabled={readOnly}
          />
          {touched.cartonNetWeight && errors.cartonNetWeight && (
            <label className="error">{t(`quote.validation.${errors.cartonNetWeight}`)}</label>
          )}
        </div>
      </fieldset>
    );
  }
}

export default QuoteFormSectionQuantities;
