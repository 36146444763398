import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import IconDotsHorizontal from '../../icon/IconDotsHorizontal';
import IconView from '../../icon/IconView';
import IconEmail from '../../icon/IconEmail';
import { getLabelFromOptions, revenueOptions } from '../../../util/selectOptions';
import ExternalLink from '../../common/ExternalLink';

const ClientPhotoStyled = styled.div`
  width: 100px;
  height: 100px;
  background: center/contain no-repeat;
`;

const ClientPhotoStyledPlaceholder = styled.img`
  width: 100px;
  height: 100px;
`;

export class ClientsTableRow extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    companyId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    actionsPopupOpenClient: null,
  };

  goToClientProfile = (clientId) => {
    this.props.history.push(`/dashboard/company/${this.props.companyId}/clients/${clientId}`);
  };

  render() {
    const isChatOn = process.env.REACT_APP_CHAT_DISPLAY;
    const client = this.props.client;

    return (
      <tr
        key={client._id}
        onClick={() => {
          this.goToClientProfile(client._id);
        }}
      >
        <td>
          {client.logoUrl ? (
            <ClientPhotoStyled style={{ backgroundImage: `url(${client.logoUrl})` }} />
          ) : (
            <ClientPhotoStyledPlaceholder src={imgPlaceholder} alt={''} />
          )}
        </td>
        <td>{client.name}</td>
        <td>{client.country}</td>
        <td>{client.quotesCount + ' Quotes Sent'}</td>
        <td>{getLabelFromOptions(revenueOptions, client.revenue)}</td>
        <td>{client.website && <ExternalLink link={client.website} />}</td>
        <td
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Popup
            onClick={(e) => {
              this.onOpenPopup(e);
            }}
            open={
              this.state.actionsPopupOpenClient &&
              this.state.actionsPopupOpenClient._id === client._id
            }
            onOpen={() => this.setState({ actionsPopupOpenClient: client })}
            onClose={() => this.setState({ actionsPopupOpenClient: null })}
            arrow={false}
            trigger={
              <div
                className={'btn action'}
                onClick={(e) => {
                  this.onOpenPopup(e);
                }}
              >
                <IconDotsHorizontal
                  width={24}
                  height={6}
                  color={
                    this.state.actionsPopupOpenClient !== null &&
                    this.state.actionsPopupOpenClient._id === client._id
                      ? 'var(--active-blue-color)'
                      : '#e9e9e9'
                  }
                />
              </div>
            }
            position="bottom right"
            contentStyle={{
              textAlign: 'left',
              width: '209px',
              padding: '17px 10px',
              border: 'none',
            }}
          >
            <div>
              <div
                className={'btn'}
                onClick={() => {
                  this.goToClientProfile(client._id);
                  this.setState({ actionsPopupOpenClient: null });
                }}
              >
                <div className={'icon-container'}>
                  <IconView width={14} height={16} />
                </div>
                View Client
              </div>
              {isChatOn && (
                <div
                  className={'btn'}
                  onClick={() => {
                    this.goToClientProfile(client._id);
                    this.setState({ actionsPopupOpenClient: null });
                  }}
                >
                  <div className={'icon-container'}>
                    <IconEmail width={20} height={16} />
                  </div>
                  Message Client
                </div>
              )}
            </div>
          </Popup>
        </td>
      </tr>
    );
  }
}

export default class ClientsTable extends Component {
  static propTypes = {
    clients: PropTypes.array.isRequired,
    companyId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  render() {
    const clients =
      this.props.clients &&
      this.props.clients.map((client) => {
        return (
          <ClientsTableRow
            key={client._id}
            client={client}
            companyId={this.props.companyId}
            history={this.props.history}
          />
        );
      });

    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Logo</th>
              <th scope="col">Client</th>
              <th scope="col">Location</th>
              <th scope="col">Quotes Sent</th>
              <th scope="col">Revenue</th>
              <th scope="col">Website</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>{clients}</tbody>
        </table>
      </div>
    );
  }
}
