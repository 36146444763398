import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import SignInForm from '../../../component/sign/SignInForm';
import {
  signIn,
  signupFetchInvitedCompany,
  signupSetInvitedCompanySuccess,
  signupSetInvitedTokenSuccess,
} from '../actions';
import './style.css';
import { getJwtToken, isAuthorized, isTokenActive } from '../../../util/session';
import { appFetchingData } from '../../../actions';
import SpinnerLoading from '../../../component/common/SpinnerLoading';

const FormDescription = styled.div`
  opacity: 0.69;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  margin-bottom: 30px;
`;

class SignInFormContainer extends Component {
  state = {
    authToken: getJwtToken(),
  };

  static propTypes = {
    signInOnSubmit: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  onInvitedToken = () => {
    const invitedToken = this.props.location.search.replace('?token=', '');
    this.props.setInvitedToken(invitedToken);
    this.props.fetchInvitedCompany(invitedToken);
    if (isAuthorized()) {
      this.props.isAppFetchingData(true);

      isTokenActive()
        .then(() => {
          this.props.isAppFetchingData(false);
          this.props.history.push('/dashboard');
        })
        .catch(() => {
          this.props.isAppFetchingData(false);
        });
    }
  };

  checkRedirectProductId = () => {
    if (this.props.appRedirectProductId !== null && isAuthorized()) {
      this.props.isAppFetchingData(true);

      isTokenActive()
        .then(() => {
          this.props.isAppFetchingData(false);
          this.props.history.push('/dashboard/choose-company');
        })
        .catch(() => {
          this.props.isAppFetchingData(false);
        });
    }
  };

  componentDidMount = () => {
    this.checkRedirectProductId();
    if (this.props.location.search.indexOf('?token=') !== -1) {
      this.onInvitedToken();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.appRedirectProductId !== null &&
      prevProps.appRedirectProductId !== this.props.appRedirectProductId
    ) {
      this.checkRedirectProductId();
    }
  };

  render() {
    const formHeader =
      this.props.invitedToken || this.props.appRedirectProductId
        ? 'Welcome to Importist'
        : 'Sign In';

    return (
      <div>
        {this.props.appFetchingData ? (
          <SpinnerLoading centered={true} />
        ) : (
          <div className="sign-in-form-container form-black-container">
            <div className="form-header">{formHeader}</div>
            {this.props.invitedToken && (
              <FormDescription>
                You’ve been invited to join a company at Importist. Login to connect your current
                account or sign up below.
              </FormDescription>
            )}
            {this.props.appRedirectProductId && (
              <FormDescription>
                To quote for this product you will need an Importist account. Please Log In or Sign
                Up below.
              </FormDescription>
            )}
            <SignInForm onSubmit={this.props.signInOnSubmit} />
            <div className="divider" />
            <button
              className="btn gray-button"
              onClick={() => {
                if (this.props.invitedToken) {
                  const token = this.props.invitedToken;
                  this.props.setInvitedToken(null);
                  this.props.resetInvitedCompany();
                  this.props.history.push(`/signup-complete?token=${token}`);
                } else {
                  this.props.history.push('/signup');
                }
              }}
            >
              Create Account
            </button>
            <div
              className="link-forgot-password"
              onClick={() => this.props.history.push('/sign/forgot-password')}
            >
              Forgot your password?
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invitedToken: state.sign.invited.token,
  appFetchingData: state.app.fetchingData,
  appRedirectProductId: state.app.redirectProductId,
});

const mapDispatchToProps = (dispatch) => ({
  signInOnSubmit: (user, setSubmitting, setErrors) =>
    dispatch(signIn(user, setSubmitting, setErrors)),
  setInvitedToken: (token) => dispatch(signupSetInvitedTokenSuccess(token)),
  resetInvitedCompany: () => dispatch(signupSetInvitedCompanySuccess(null)),
  fetchInvitedCompany: (token) => dispatch(signupFetchInvitedCompany(token)),
  isAppFetchingData: (is) => dispatch(appFetchingData(is)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignInFormContainer)
);
