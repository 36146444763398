import React from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
}) => {
  const passwordGroupClass = classNames('form-group', {
    'with-error': touched.password && errors.password,
  });
  const confirmPasswordGroupClass = classNames('form-group', {
    'with-error': touched.confirmPassword && errors.confirmPassword,
  });

  return (
    <form onSubmit={handleSubmit}>
      {errors.common && <label className="error common">{errors.common}</label>}
      <div className={passwordGroupClass}>
        <label>Password</label>
        <Field className="form-control" type="password" name="password" />
        {touched.password && errors.password && <label className="error">{errors.password}</label>}
      </div>
      <div className={confirmPasswordGroupClass}>
        <label>Confirm Password</label>
        <Field className="form-control" type="password" name="confirmPassword" />
        {touched.confirmPassword && errors.confirmPassword && (
          <label className="error">{errors.confirmPassword}</label>
        )}
      </div>
      <button className="btn" disabled={isSubmitting} type="submit">
        Reset Password
      </button>
    </form>
  );
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .test('confirm-password-test', 'Passwords do not match', function(value) {
      const { password } = this.parent;
      return value === password;
    }),
});

const SignResetPasswordForm = withFormik({
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    // Errors set by setErrors from action if action rejected
    props.onSubmit(values.password, setSubmitting, setErrors);
  },
})(Form);

SignResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignResetPasswordForm;
