export const incotermsList = {
  standard: { code: 'standard', label: 'Supplier Standard Terms' },
  EXW: { code: 'EXW', label: 'EXW – Ex Works' },
  FCA: { code: 'FCA', label: 'FCA – Free Carrier' },
  CPT: { code: 'CPT', label: 'CPT – Carriage Paid To' },
  CIP: { code: 'CIP', label: 'CIP – Carriage and Insurance Paid to' },
  DAT: { code: 'DAT', label: 'DAT – Delivered At Terminal' },
  DAP: { code: 'DAP', label: 'DAP – Delivered At Place' },
  DDP: { code: 'DDP', label: 'DDP – Delivered Duty Paid' },
  FAS: { code: 'FAS', label: 'FAS – Free Alongside Ship' },
  FOB: { code: 'FOB', label: 'FOB – Free on Board' },
  CFR: { code: 'CFR', label: 'CFR – Cost and Freight' },
  CIF: { code: 'CIF', label: 'CIF – Cost, Insurance & Freight' },
};
