import axios from 'axios';

export default {
  presignedPut(url, file, headers) {
    const options = {
      headers,
    };

    return axios.put(url, file, options);
  },
};
