import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';

const ChatsLoadingSpinnerContainerStyled = styled.div`
  align-items: center;
  height: 66px;
  display: flex;
  justify-content: center;
  transform: scale(0.5);
`;

export default () => (
  <ChatsLoadingSpinnerContainerStyled>
    <Spinner name="line-spin-fade-loader" fadeIn="half" color="#0095ff" />
  </ChatsLoadingSpinnerContainerStyled>
);
