import React from 'react';
import PropTypes from 'prop-types';

const IconQuote = (props) => {
  const viewBox = `0 0 14 17`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <path
        fill={props.color}
        opacity={props.opacity}
        fillRule="nonzero"
        d="M1.892 0C.844 0 0 .903 0 2.024V17h9.46V2.024c0-.79.31-1.508.804-2.024H1.892zm10.216 0c-1.048 0-1.892.903-1.892 2.024v6.88H14v-6.88C14 .903 13.156 0 12.108 0zM1.454 5.06H7.94a.374.374 0 0 1 .332.2.43.43 0 0 1 0 .408.374.374 0 0 1-.332.201H1.508c-.21.016-.39-.153-.405-.376-.015-.224.142-.418.351-.433zm0 2.833H7.94a.374.374 0 0 1 .332.2.43.43 0 0 1 0 .409.374.374 0 0 1-.332.2H1.508c-.21.016-.39-.152-.405-.376-.015-.223.142-.417.351-.433zm0 2.833H7.94a.374.374 0 0 1 .332.2.43.43 0 0 1 0 .409.374.374 0 0 1-.332.2H1.508c-.21.016-.39-.152-.405-.376-.015-.223.142-.417.351-.433zm0 2.834h2.702a.374.374 0 0 1 .332.2.43.43 0 0 1 0 .408.374.374 0 0 1-.332.201H1.508c-.21.016-.39-.153-.405-.376-.015-.224.142-.418.351-.433z"
      />
    </svg>
  );
};

IconQuote.propsDefault = {
  opacity: 1,
};

IconQuote.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.any,
};

export default IconQuote;
