import React from 'react';
import PropTypes from 'prop-types';

const IconNextButton = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <g fill="none" fillRule="evenodd" opacity=".6" transform="matrix(-1 0 0 1 20 0)">
        <rect width="19" height="19" x=".5" y=".5" fill="#FBFBFB" stroke="#4D4D4D" rx="4" />
        <path
          fill="#4D4D4D"
          fillRule="nonzero"
          d="M6.765 10.75a.604.604 0 0 1-.202-.442c0-.099.025-.295.202-.443l5.61-5.015c.253-.22.658-.22.885.025.228.246.228.64-.025.86l-5.106 4.573 5.106 4.572a.583.583 0 0 1 .025.86.623.623 0 0 1-.884.025L6.765 10.75z"
        />
      </g>
    </svg>
  );
};

IconNextButton.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconNextButton;
