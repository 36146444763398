import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SignCompleteFormContainer from '../SignUpCompleteFormContainer';
import IconCompany from '../../../component/icon/IconCompany';
import './style.css';
import { connect } from 'react-redux';
import IconFactory from '../../../component/icon/IconFactory';
import IconNounGroup from '../../../component/icon/IconNounGroups';

class SignUpCompleteContainer extends Component {
  render() {
    let headerIcon;
    switch (this.props.user.signupType) {
      case 'client':
        headerIcon = <IconCompany width={36} height={39} color={'#0095ff'} />;
        break;
      case 'supplier':
        headerIcon = <IconFactory width={36} height={39} color={'#0095ff'} />;
        break;
      default:
        headerIcon = <IconNounGroup width={39} height={34} color={'#0095ff'} />;
        break;
    }
    const containerClass = classNames('sign-up-complete-container', {
      'content-blur': this.props.modalOpened,
    });

    return (
      <div className={containerClass}>
        <div className="form-header">
          <span className="form-header-icon">{headerIcon}</span>Complete Registration
        </div>
        <div className="form-header-description">
          Please complete the full registration to begin using Importist.
        </div>
        <SignCompleteFormContainer user={this.props.user} />
      </div>
    );
  }
}

SignUpCompleteContainer.propTypes = {
  user: PropTypes.shape({
    signupType: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  user: state.sign.user,
  modalOpened: state.app.modalOpened,
});

export default connect(mapStateToProps)(SignUpCompleteContainer);
