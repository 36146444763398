import React from 'react';
import styled from 'styled-components';
import IconEmail from '../../icon/IconEmail';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';

const SupplierNameStyled = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
`;

export const HeaderContainerStyled = styled.div`
  padding-bottom: 16px;
  border-bottom: solid 1px #d9d9d9;
  margin-bottom: 35px;
`;

const HeaderRightContainerStyled = styled.div`
  display: inline-block;
  vertical-align: middle;
  display: flex;
  float: right;
  align-items: center;
  height: 100px;
`;

const ButtonMessageStyled = styled.button`
  width: 139px;
  height: 36px;
  margin-left: 36px;
  display: flex;
  justify-content: center;
`;

const EmailIconContainerStyled = styled.div`
  margin-right: 10px;
`;

const EmailIconContentStyled = styled.div`
  margin-top: -1px;
`;

const CompanyProfileHeader = (props) => {
  const isChatOn = process.env.REACT_APP_CHAT_DISPLAY;
  const { company } = props;
  const logoUrl = typeof company.logoUrl !== 'undefined' ? company.logoUrl : imgPlaceholder;

  return (
    <HeaderContainerStyled>
      <img src={logoUrl} width={100} height={100} alt={''} />
      <SupplierNameStyled>
        <div className="heading-label">{company.type}</div>
        <div className="form-header">{company.name}</div>
      </SupplierNameStyled>
      <HeaderRightContainerStyled>
        <div>
          {company.quotesCount} Product{company.quotesCount > 1 ? 's' : ''} Quoted
        </div>
        {isChatOn && (
          <ButtonMessageStyled className="btn white-button">
            <EmailIconContainerStyled>
              <EmailIconContentStyled>
                <IconEmail width={15} height={12} />
              </EmailIconContentStyled>
            </EmailIconContainerStyled>
            <div>Message</div>
          </ButtonMessageStyled>
        )}
      </HeaderRightContainerStyled>
    </HeaderContainerStyled>
  );
};

export default CompanyProfileHeader;
