import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import api from '../../../service/api';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import ProductQuote from '../../../component/product/ProductQuote';

export class ClientQuoteContainer extends React.Component {
  static propTypes = {
    productId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      quote: {},
      companyName: '',
      companyCountry: '',
      getCancelToken: CancelToken.source(),
    };
  }

  componentDidMount = async () => {
    const productId = this.props.productId;
    const quoteId = this.props.match.params.quoteId;
    try {
      const quoteRes = await api.quoteGet(productId, quoteId, this.state.getCancelToken.token);
      this.setState({
        quote: quoteRes.data,
        supplierName: quoteRes.data.createdByCompany.name,
        companyCountry: quoteRes.data.createdByCompany.country,
        companyName: quoteRes.data.createdByCompany.name,
        isLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  onClickBack = () => {
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.state.getCancelToken.cancel();
  }

  render() {
    const basePath = `/dashboard/company/${this.props.companyId}/project/${
      this.props.projectId
    }/product/${this.props.match.params.productId}`;

    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <ProductQuote
        history={this.props.history}
        basePath={basePath}
        quote={this.state.quote}
        companyName={this.state.companyName}
        companyCountry={this.state.companyCountry}
        companyType={this.props.companyType}
        onClickBack={this.onClickBack}
        goToMessages={() => {
          this.props.history.push(
            `/dashboard/company/${this.props.companyId}/messages/project/${
              this.props.activeProjectId
            }?quoteId=${this.state.quote._id}`
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.dashboard.activeCompanyId,
  companyType: state.dashboard.company.type,
  activeProjectId: state.dashboard.activeProjectId,
});

export default withRouter(connect(mapStateToProps)(ClientQuoteContainer));
