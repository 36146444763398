import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CompanyCreateNew from '../../../component/company/CompanyCreateNew';
import IconFactory from '../../../component/icon/IconFactory';
import styled from 'styled-components';
import IconCompany from '../../../component/icon/IconCompany';
import { companyCreateNew } from '../actions';

const ContainerStyled = styled.div`
  margin-top: 100px;
  width: 720px;
  margin-left: auto;
  margin-right: auto;
`;

export class CreateNewCompanyContainer extends Component {
  render() {
    let headerIcon;
    switch (this.props.type) {
      case 'client':
        headerIcon = <IconCompany width={36} height={39} color={'#0095ff'} />;
        break;
      case 'supplier':
        headerIcon = <IconFactory width={36} height={39} color={'#0095ff'} />;
        break;
      default:
    }

    return (
      <ContainerStyled>
        <div className="form-header">
          <span className="form-header-icon">{headerIcon}</span>Create New Company
        </div>
        {this.props.type !== null && (
          <CompanyCreateNew type={this.props.type} onSubmit={this.props.onSubmit} />
        )}
      </ContainerStyled>
    );
  }
}

CreateNewCompanyContainer.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  type: state.newCompany.type,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data, setSubmitting, setErrors) =>
    dispatch(companyCreateNew(data, setSubmitting, setErrors)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewCompanyContainer);
