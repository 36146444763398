import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import StickyMenu from '../../common/StickyMenu';
import classNames from 'classnames';
import TableUsers from '../TableUsers';

class Form extends React.Component {
  sectionProjectInfo = {
    name: 'projectInfo',
    label: 'Project Information',
    ref: React.createRef(),
  };

  sectionAddTeamMembers = {
    name: 'addTeamMembers',
    label: 'Add Team Members',
    ref: React.createRef(),
  };

  sectionHeaderRefs = [this.sectionProjectInfo, this.sectionAddTeamMembers];

  render() {
    const {
      values,
      usersError,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      setFieldValue,
    } = this.props;

    const nameGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.name && errors.name,
    });

    return (
      <form onSubmit={handleSubmit} className={'padding-bottom-180'}>
        <StickyMenu items={this.sectionHeaderRefs} />
        <label className="section-header" ref={this.sectionProjectInfo.ref}>
          {this.sectionProjectInfo.label}
        </label>
        <label className="section-header-description">
          Create a name for your project and add a description for the rest of the team and other
          users to see.
        </label>
        <fieldset>
          <div className="row">
            <div className={nameGroupClass}>
              <label>
                Project Name<sup> *</sup>
              </label>
              <Field className="form-control" name="name" />
              {touched.name && errors.name && <label className="error">{errors.name}</label>}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <label>Project Description</label>
              <textarea
                className="form-control"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.description && errors.description && (
                <label className="error">{errors.description}</label>
              )}
            </div>
          </div>
        </fieldset>
        <label className="section-header" ref={this.sectionAddTeamMembers.ref}>
          {this.sectionAddTeamMembers.label}
        </label>
        <label className="section-header-description">
          Add the members of your organization you want to have access to the project.
        </label>
        <fieldset>
          <TableUsers users={this.props.users} values={values} setFieldValue={setFieldValue} />
        </fieldset>
        {usersError && <label className="error common">{usersError}</label>}
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className={'actions-bottom'}>
          <button
            onClick={this.props.onCancel}
            className="btn white-button"
            disabled={isSubmitting}
            type="button"
          >
            Cancel
          </button>
          <button className="btn blue-button" disabled={isSubmitting} type="submit">
            Create Project
          </button>
        </div>
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Project Name is required'),
});

const AddProjectForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
    users: [],
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

AddProjectForm.propTypes = {
  users: PropTypes.array.isRequired,
  usersError: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddProjectForm;
