import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { yearsRange } from '../../../util/date';
import { staffOptions, revenueOptions } from '../../../util/selectOptions';

class FormSectionCompanyDetails extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    fieldsNamePrefix: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  render() {
    const touched = this.props.touched || {};
    const errors = this.props.errors || {};

    const fieldNameAbout = `${this.props.fieldsNamePrefix}about`;

    const staffGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.staff && errors.staff,
    });
    const fieldNameStaff = `${this.props.fieldsNamePrefix}staff`;

    const revenueGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.revenue && errors.revenue,
    });
    const fieldNameRevenue = `${this.props.fieldsNamePrefix}revenue`;

    const establishedGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.established && errors.established,
    });
    const fieldNameEstablished = `${this.props.fieldsNamePrefix}established`;

    const yearsList = yearsRange();
    yearsList.reverse();

    return (
      <fieldset>
        <div className="row">
          <div className="form-group col-sm-12">
            <label>About</label>
            <textarea
              className="form-control"
              rows="4"
              name={fieldNameAbout}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameAbout, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameAbout, true);
              }}
              value={this.props.values.about}
            />
            {touched.about && errors.about && <label className="error">{errors.about}</label>}
          </div>
        </div>
        <div className="row">
          <div className={staffGroupClass}>
            <label>
              Staff<sup> *</sup>
            </label>
            <select
              className="form-control"
              name={fieldNameStaff}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameStaff, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameStaff, true);
              }}
              value={this.props.values.staff}
            >
              <option value="" />
              {staffOptions.map((item) => {
                return (
                  <option value={item.value} key={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {touched.staff && errors.staff && <label className="error">{errors.staff}</label>}
          </div>
          <div className={revenueGroupClass}>
            <label>
              Revenue<sup> *</sup>
            </label>
            <select
              className="form-control"
              name={fieldNameRevenue}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameRevenue, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameRevenue, true);
              }}
              value={this.props.values.revenue}
            >
              <option value="" />
              {revenueOptions.map((item) => {
                return (
                  <option value={item.value} key={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {touched.revenue && errors.revenue && <label className="error">{errors.revenue}</label>}
          </div>
        </div>
        <div className="row">
          <div className={establishedGroupClass}>
            <label>
              Established<sup> *</sup>
            </label>
            <select
              className="form-control"
              name={fieldNameEstablished}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameEstablished, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameEstablished, true);
              }}
              value={this.props.values.established}
            >
              <option value="" />
              {yearsList.map((year) => {
                return (
                  <option value={year} key={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            {touched.established && errors.established && (
              <label className="error">{errors.established}</label>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
}

export const FormSectionCompanyDetailsValidationScheme = {
  staff: Yup.string().required('Staff is required'),
  revenue: Yup.string().required('Revenue is required'),
  established: Yup.string().required('Established is required'),
};

export default FormSectionCompanyDetails;
