import React from 'react';
import PropTypes from 'prop-types';

const IconClients = (props) => {
  const viewBox = `0 0 16 17`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g fill={props.color} opacity={props.opacity} fillRule="nonzero">
        <path d="M1.45.199v14.28h2.925v-3.73c0-.221.18-.4.4-.4H11.231c.221 0 .4.179.4.4v3.73h2.92V.2H1.45zM3.3 1.586a.4.4 0 0 1 .038 0H4.6c.221 0 .4.18.4.4V3.58a.4.4 0 0 1-.4.4H3.338a.4.4 0 0 1-.4-.4V1.986a.4.4 0 0 1 .362-.4zm4.031 0a.4.4 0 0 1 .038 0H8.63c.221 0 .4.18.4.4V3.58a.4.4 0 0 1-.4.4H7.37a.4.4 0 0 1-.4-.4V1.986a.4.4 0 0 1 .362-.4zm4.032 0a.4.4 0 0 1 .037 0h1.263c.22 0 .4.18.4.4V3.58a.4.4 0 0 1-.4.4H11.4a.4.4 0 0 1-.4-.4V1.986a.4.4 0 0 1 .363-.4zM3.3 6.674a.4.4 0 0 1 .038 0H4.6c.221 0 .4.179.4.4v1.593a.4.4 0 0 1-.4.4H3.338a.4.4 0 0 1-.4-.4V7.074a.4.4 0 0 1 .362-.4zm4.031 0a.4.4 0 0 1 .038 0H8.63c.221 0 .4.179.4.4v1.593a.4.4 0 0 1-.4.4H7.37a.4.4 0 0 1-.4-.4V7.074a.4.4 0 0 1 .362-.4zm4.032 0a.4.4 0 0 1 .037 0h1.263c.22 0 .4.179.4.4v1.593a.4.4 0 0 1-.4.4H11.4a.4.4 0 0 1-.4-.4V7.074a.4.4 0 0 1 .363-.4z" />
        <path d="M5.173 11.147H7.6v3.332H5.173v-3.332zM8.4 11.147h2.43v3.332H8.4v-3.332zM.132 15.279h15.736v.922H.132v-.922z" />
      </g>
    </svg>
  );
};

IconClients.propsDefault = {
  opacity: 1,
};

IconClients.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.any,
};

export default IconClients;
