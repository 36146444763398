import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import _startCase from 'lodash.startcase';

export class QuoteFormSectionSpecifications extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    photoIndex: 0,
    isLightboxOpen: false,
  };

  render() {
    const { product, t } = this.props;
    const { photoIndex } = this.state;
    const photosLength = product.photosUrl.length;

    return (
      <fieldset>
        {photosLength > 0 && (
          <div className="row photos-row">
            <div className="col-sm-12 form-group">
              <label>{_startCase(t('common.photo examples'))}</label>
            </div>
            {product.photosUrl.map((photoUrl, index) => {
              return (
                <div
                  className="product-photos-row-photo-container"
                  onClick={() => {
                    this.setState({ isLightboxOpen: true, photoIndex: index });
                  }}
                  key={index}
                >
                  <img alt="" src={photoUrl} style={{ width: '100%', maxHeight: '125px' }} />
                </div>
              );
            })}
            {this.state.isLightboxOpen && (
              <Lightbox
                mainSrc={product.photosUrl[photoIndex]}
                nextSrc={product.photosUrl[(photoIndex + 1) % photosLength]}
                prevSrc={product.photosUrl[(photoIndex + photosLength - 1) % photosLength]}
                onCloseRequest={() => this.setState({ isLightboxOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + photosLength - 1) % photosLength,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % photosLength,
                  })
                }
              />
            )}
          </div>
        )}
        {product.specs.map((spec, index) => (
          <div key={index} className="row">
            <div className="col-sm-12 form-group">
              <label>
                {_startCase(t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name)))}
              </label>
              <div>{spec.value}</div>
            </div>
          </div>
        ))}
      </fieldset>
    );
  }
}

export default QuoteFormSectionSpecifications;
