import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Search from '../../../component/common/Search';
import { withRouter } from 'react-router-dom';
import ChatMessagesContainer from '../ChatMessagesContainer';
import { connect } from 'react-redux';
import {
  chatCompanyLoadChatsSuccess,
  chatIdActiveSet,
  fetchCompanyChats,
  fetchReplaceOrAddCompanyChat,
} from '../actions';
import ChatListItem from '../../../component/chat/ChatListItem';
import ChatLoadingSpinner from '../../../component/chat/ChatLoadingSpinner';
import InfiniteScroll from '../../../component/common/InfiniteScroll';
import { projectSelector } from '../../dashboard/selectors';
import { activeChatSelector } from '../ChatMessagesContainer/selectors';

export class ChatCompanyContainer extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    chatActive: null,
    limit: 20,
    search: '',
  };

  chatListContainerRef = React.createRef();
  chatListRef = React.createRef();

  wsCompanyChannelOnNewChatMessage = (res) => {
    this.props.fetchReplaceOrAddChat(this.props.activeCompanyId, res.data.chatId);
  };

  onFetchAllChats = (page) => {
    const filters = this.props.history.location.search
      ? queryString.parse(this.props.history.location.search)
      : {};
    if (this.props.match.params.projectId) {
      filters.projectId = this.props.match.params.projectId;
    }
    this.props.fetchAllChats(
      this.props.activeCompanyId,
      this.props.activeCompany.type,
      {
        page,
        limit: this.state.limit,
        search: this.state.search,
        ...filters,
      },
      page === 1
    );
  };

  onSearchChange = (search) => {
    this.setState({ search });
  };

  componentDidMount() {
    if (this.props.wsCompanyChannel) {
      this.props.wsCompanyChannel.bind('newChatMessage', this.wsCompanyChannelOnNewChatMessage);
    }
    if (this.props.chats.length > 0) {
      this.props.selectChatId(this.props.chats[0]._id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.wsCompanyChannel && this.props.wsCompanyChannel) ||
      (prevProps.wsCompanyChannel &&
        this.props.wsCompanyChannel &&
        this.props.wsCompanyChannel.name !== prevProps.wsCompanyChannel.name)
    ) {
      this.props.wsCompanyChannel.bind('newChatMessage', this.wsCompanyChannelOnNewChatMessage);
    }

    if (
      (prevProps.chats.length === 0 && this.props.chats.length > 0) ||
      // When search/filter totalChats is changed before loading flag is set to false
      (prevProps.totalChats !== this.props.totalChats && this.props.chatsLoading)
    ) {
      this.props.selectChatId(this.props.chats[0]._id);
    }
  }

  componentWillUnmount() {
    if (this.props.wsCompanyChannel) {
      this.props.wsCompanyChannel.unbind('newChatMessage', this.wsCompanyChannelOnNewChatMessage);
    }
    this.props.selectChatId(null);
    this.props.cleanCompanyChats();
  }

  render() {
    const { activeChatId, activeChat, projects, match, chats } = this.props;
    const chatList = chats.map((chat) => (
      <ChatListItem
        key={chat._id}
        activeCompanyId={this.props.activeCompanyId}
        chatActiveId={activeChatId}
        chat={chat}
        onClick={() => {
          if (chat._id !== activeChatId) {
            this.props.selectChatId(chat._id);
          }
        }}
      />
    ));

    if (this.props.chatsLoading) {
      chatList.push(<ChatLoadingSpinner key={'loading-spinner'} />);
    }

    const filteredByProject = projectSelector(projects, match.params.projectId);
    const isFilteredByQuoteId = this.props.history.location.search.indexOf('quoteId') >= 0;

    return (
      <div className="messages-container">
        <InfiniteScroll
          containerRef={this.chatListContainerRef}
          contentRef={this.chatListRef}
          load={this.onFetchAllChats}
          loading={this.props.chatsLoading}
          itemsCount={chats.length}
          totalCount={this.props.totalChats}
          search={`${this.props.match.params.projectId}${this.state.search}${
            this.props.history.location.search
          }`}
        >
          <div ref={this.chatListContainerRef} className="messages-chat-rooms">
            <div className="messages-heading">
              <h3>{filteredByProject || isFilteredByQuoteId ? 'Messages' : 'All messages'}</h3>
              <div className={'messages-filters-search-container'}>
                {isFilteredByQuoteId && activeChat && (
                  <div className={'filter-label'}>
                    {this.props.activeCompanyId === activeChat.clientCompanyId
                      ? activeChat.supplierCompanyName
                      : activeChat.clientCompanyName}
                    {' - '}
                    {activeChat.product.name}
                  </div>
                )}
                {filteredByProject && !isFilteredByQuoteId && (
                  <div className={'filter-label'}>{filteredByProject.name}</div>
                )}
                {(filteredByProject || isFilteredByQuoteId) && (
                  <div className={'messages-filters-search-separator-container'}>
                    <div className={'messages-filters-search-separator'} />
                  </div>
                )}
                <Search search={this.state.search} setSearch={this.onSearchChange} />
              </div>
            </div>
            <div ref={this.chatListRef} className="chat-previews">
              {chatList}
            </div>
          </div>
        </InfiniteScroll>
        {this.props.activeChat && (
          <ChatMessagesContainer history={this.props.history} chat={this.props.activeChat} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeCompanyId: state.dashboard.activeCompanyId,
  activeCompany: state.dashboard.company,
  projects: state.dashboard.projects,
  wsCompanyChannel: state.dashboard.ws.companyChannel,
  chats: state.dashboard.chats.data.items,
  totalChats: state.dashboard.chats.data.totalCount,
  chatsLoading: state.dashboard.chats.loading,
  activeChatId: state.dashboard.chats.activeChatId,
  activeChat: activeChatSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllChats: (companyId, companyType, params, isFirstPage) =>
    dispatch(fetchCompanyChats(companyId, companyType, params, isFirstPage)),
  fetchReplaceOrAddChat: (companyId, chatId) => {
    dispatch(fetchReplaceOrAddCompanyChat(companyId, chatId));
  },
  selectChatId: (chatId) => {
    dispatch(chatIdActiveSet(chatId));
  },
  cleanCompanyChats: () => {
    dispatch(chatCompanyLoadChatsSuccess({ items: [], totalCount: 0 }, true));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChatCompanyContainer)
);
