import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import _startCase from 'lodash.startcase';

import classNames from 'classnames';
import * as Yup from 'yup';
import styled from 'styled-components';

const SectionDescriptionStyled = styled.div`
  max-width: 570px;
  margin-bottom: 40px;
`;

const TotalStyled = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.35;
  color: #0095ff;
`;

class QuoteFormSectionSamples extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    product: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { product, values, errors, touched, readOnly, t } = this.props;
    let total =
      (parseFloat(values.samplesTotalPrice) || 0) + (parseFloat(values.samplesShippingCost) || 0);

    return (
      <fieldset>
        <SectionDescriptionStyled>{t('quote.common.samplesText')}</SectionDescriptionStyled>

        <div className="row form-group">
          <div className="form-group col-sm-4">
            <label>{_startCase(t('common.required quantity'))}</label>
            <div className="form-value vertically-centered">{product.numberSamples}</div>
          </div>
          <div
            className={classNames('form-group col-sm-4', {
              'with-error': touched.samplesTotalPrice && errors.samplesTotalPrice,
            })}
          >
            <label>{_startCase(t('common.total price'))}</label>
            <Field
              className="form-control"
              type="number"
              name="samplesTotalPrice"
              placeholder="ex. $4.56"
              disabled={readOnly}
            />
            {touched.samplesTotalPrice && errors.samplesTotalPrice && (
              <label className="error">{t(`quote.validation.${errors.samplesTotalPrice}`)}</label>
            )}
          </div>
          <div
            className={classNames('form-group col-sm-4', {
              'with-error': touched.samplesLeadTime && errors.samplesLeadTime,
            })}
          >
            <label>{t('quote.common.leadTimeLabel')}</label>
            <Field
              className="form-control"
              name="samplesLeadTime"
              type="number"
              placeholder="ex. 30"
              disabled={readOnly}
            />
            {touched.samplesLeadTime && errors.samplesLeadTime && (
              <label className="error">{t(`quote.validation.${errors.samplesLeadTime}`)}</label>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-4">
            <label>{t('quote.common.Delivery Address for Samples')}</label>
            <div>{product.deliveryAddress.company}</div>
            <div>{product.deliveryAddress.address}</div>
            <div>{product.deliveryAddress.address2}</div>
            <div>{product.deliveryAddress.country}</div>
          </div>
          <div className="form-group col-sm-4" />
          <div
            className={classNames('form-group col-sm-4', {
              'with-error': touched.samplesShippingCost && errors.samplesShippingCost,
            })}
          >
            <label>{_startCase(t('common.shipping cost'))}</label>
            <Field
              className="form-control"
              type="number"
              name="samplesShippingCost"
              placeholder="ex. $4.56"
              disabled={readOnly}
            />
            {touched.samplesShippingCost && errors.samplesShippingCost && (
              <label className="error">{t(`quote.validation.${errors.samplesShippingCost}`)}</label>
            )}
          </div>
        </div>
        <div className={'section-separator'} />
        <div className="row">
          <div className="form-group col-sm-4">
            <label>{t('quote.common.Samples and Shipping Total')}</label>
            <TotalStyled>{total.toFixed(2)}</TotalStyled>
          </div>
        </div>
      </fieldset>
    );
  }
}

export const SamplesValidationScheme = {
  samplesNumber: Yup.number().notRequired(),
  samplesTotalPrice: Yup.mixed().when('samplesNumber', {
    is: (samplesNumber) => samplesNumber > 0,
    then: Yup.number().required('Total Price is required'),
    otherwise: Yup.mixed().notRequired(),
  }),
  samplesLeadTime: Yup.number()
    .integer()
    .positive('Only positive numbers allowed')
    .when('samplesNumber', {
      is: (samplesNumber) => samplesNumber > 0,
      then: Yup.number().required('Lead Time is required'),
      otherwise: Yup.number().notRequired(),
    }),
  samplesShippingCost: Yup.mixed().when('samplesNumber', {
    is: (samplesNumber) => samplesNumber > 0,
    then: Yup.number().required('Shipping Cost is required'),
    otherwise: Yup.mixed().notRequired(),
  }),
};

export default QuoteFormSectionSamples;
