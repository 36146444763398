import React from 'react';
import PropTypes from 'prop-types';

class IconView extends React.Component {
  static defaultProps = {
    strokeColor: '#111',
  };

  static propTypes = {
    width: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
    strokeColor: PropTypes.string,
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 14 17"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={this.props.strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 1v5h5" />
          <path d="M9 1H1v15h13V6z" />
        </g>
      </svg>
    );
  }
}

export default IconView;
