import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LeftMenu from '../../../component/dashboard/LeftMenu';
import IconFlagComplex from '../../../component/icon/IconFlagComplex';
import {
  changeUserActiveProjectIdAndRedirectDefault,
  dashboardLeftMenuSetActiveItemSuccess,
} from '../actions';
import { logoutSuccess } from '../../sign/actions';
import { AbilityContext } from '../../../util/session';

export class LeftMenuContainer extends React.Component {
  static propTypes = {
    onOpenCompanySwitcher: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
  };

  onActiveProjectChange = (projectId) => {
    this.props.changeUserActiveProjectId(this.props.activeCompanyId, projectId);
  };

  render() {
    const {
      user,
      company,
      projects,
      activeItem,
      activeProjectId,
      projectsNewMessagesCount,
      onOpenCompanySwitcher,
      basePath,
    } = this.props;
    return (
      <div className="dashboard-left-menu">
        <div className={'menu-header'}>
          <IconFlagComplex width={21} height={23} />
          <label>IMPORTIST</label>
        </div>
        <LeftMenu
          user={user}
          company={company}
          companyNewMessagesCount={this.props.companyNewMessagesCount}
          projects={projects}
          activeItem={activeItem}
          activeProjectId={activeProjectId}
          projectsNewMessagesCount={projectsNewMessagesCount}
          onActiveItemChange={this.props.onActiveItemChange}
          onActiveProjectChange={this.onActiveProjectChange}
          onOpenCompanySwitcher={onOpenCompanySwitcher}
          onLogout={this.props.logout}
          basePath={basePath}
          history={this.props.history}
          ability={this.props.ability}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  company: state.dashboard.company,
  companyNewMessagesCount: state.dashboard.companyNewMessagesCount,
  projects: state.dashboard.projects,
  activeItem: state.dashboard.leftMenu.activeItem,
  activeCompanyId: state.dashboard.activeCompanyId,
  activeProjectId: state.dashboard.activeProjectId,
  projectsNewMessagesCount: state.dashboard.projectsNewMessagesCount,
});

const mapDispatchToProps = (dispatch) => ({
  onActiveItemChange: (name) => {
    dispatch(dashboardLeftMenuSetActiveItemSuccess(name));
  },
  changeUserActiveProjectId: (companyId, projectId) =>
    dispatch(changeUserActiveProjectIdAndRedirectDefault(companyId, projectId)),
  logout: () => dispatch(logoutSuccess()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )((props) => (
    <AbilityContext.Consumer>
      {(ability) => <LeftMenuContainer {...props} ability={ability} />}
    </AbilityContext.Consumer>
  ))
);
