import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import api from '../../../service/api';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import ProductQuoteHeader from '../../../component/product/ProductQuoteHeader';
import ProductDetails from '../../../component/product/ProductDetails';
import ProductQuote from '../../../component/product/ProductQuote';

export class SupplierQuoteContainer extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      product: null,
      quote: {},
      clientCompany: {},
      getCancelToken: CancelToken.source(),
    };
  }

  componentDidMount = async () => {
    const quoteId = this.props.match.params.quoteId;
    try {
      const quoteRes = await api.quoteSupplierGet(
        this.props.activeCompanyId,
        quoteId,
        this.state.getCancelToken.token
      );

      this.setState({
        isLoading: false,
        quote: quoteRes.data,
        product: quoteRes.data.product,
        clientCompany: quoteRes.data.clientCompany,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  onClickBack = () => {
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.state.getCancelToken.cancel();
  }

  render() {
    const companyType = 'supplier';
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className="product-quote-container">
        <ProductQuoteHeader
          companyType={companyType}
          heading="Quote"
          productName={this.state.product.name}
          companyName={this.state.clientCompany.name}
          companionCompanyId={this.state.clientCompany._id}
          activeCompanyId={this.props.activeCompanyId}
          history={this.props.history}
        />
        <ProductDetails
          product={this.state.product}
          quote={this.state.quote}
          onViewFullSpec={() => {
            this.props.history.push(`${this.state.quote._id}/full`);
          }}
        />
        <ProductQuote
          history={this.props.history}
          quote={this.state.quote}
          companyName={this.state.clientCompany.name}
          companyCountry={this.state.clientCompany.country}
          companyType={companyType}
          deliveryAddress={this.state.product.deliveryAddress}
          onClickBack={this.onClickBack}
          goToMessages={() => {
            this.props.history.push(
              `/dashboard/company/${this.props.activeCompanyId}/messages?quoteId=${
                this.state.quote._id
              }`
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeCompanyId: state.dashboard.activeCompanyId,
});

export default withRouter(connect(mapStateToProps)(SupplierQuoteContainer));
