import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import SignUpFormContainer from '../SignUpFormContainer';
import SignInFormContainer from '../SignInFormContainer';
import SignForgotPasswordContainer from '../SignForgotPasswordContainer';
import SignResetPasswordContainer from '../SignResetPasswordContainer';
import SignConfirmEmailContainer from '../SignConfirmEmailContainer';
import './style.css';

class SignContainer extends Component {
  render() {
    return (
      <div className="sign-container">
        <div className="content">
          <div className="logo" />
          <Switch>
            <Route path="/signup" render={() => <SignUpFormContainer />} />
            <Route path="/signin" render={() => <SignInFormContainer />} />
            <Route path="/sign/forgot-password" render={() => <SignForgotPasswordContainer />} />
            <Route path="/sign/reset-password" render={() => <SignResetPasswordContainer />} />
            <Route path="/sign/confirm-email" render={() => <SignConfirmEmailContainer />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default SignContainer;
