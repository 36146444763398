export function validateRequiredQuantityAddingProduct(values) {
  let errors = [];

  if (!(values.targetPrice > 0)) {
    let isValid = false;
    values.requiredQuantity.forEach((quantity) => {
      if (quantity > 0) isValid = true;
    });
    if (!isValid) {
      errors[0] = 'At least one Quantity is required';
    }
  }

  return !errors.length ? null : errors;
}
