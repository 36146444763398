import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

import { marketsList } from '../../../util/country';
import ImageGroupUpload from '../../common/ImageGroupUpload/index';

class FormSectionSupplierProfile extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    fieldsNamePrefix: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  state = {
    supplierPhotosPreviewUrl: [],
    supplierPhotosUploadMessages: [],
  };

  onSupplierPhotosChange = (files) => {
    this.props.setFieldValue(`${this.props.fieldsNamePrefix}photoFiles`, files);
    this.props.setFieldTouched(`${this.props.fieldsNamePrefix}photoFiles`, true);
  };

  render() {
    const touched = this.props.touched || {};
    const errors = this.props.errors || {};

    const productsGroupClass = classNames('form-group col-sm-12', {
      'with-error': touched.products && errors.products,
    });
    const fieldNameProducts = `${this.props.fieldsNamePrefix}products`;

    const marketsGroupClass = classNames('form-group col-sm-12 market-buttons', {
      'with-error': touched.markets && errors.markets,
    });
    const fieldNameMarkets = `${this.props.fieldsNamePrefix}markets`;

    const photosGroupClass = classNames('form-group col-sm-12', {
      'with-error': touched.photoFiles && errors.photoFiles,
    });

    const customersGroupClass = classNames('form-group col-sm-12', {
      'with-error': touched.customers && errors.customers,
    });
    const fieldNameCustomers = `${this.props.fieldsNamePrefix}customers`;

    const fieldNameMachineList = `${this.props.fieldsNamePrefix}machineList`;

    const fieldNameBusinessTerms = `${this.props.fieldsNamePrefix}businessTerms`;
    const fieldNameRdStaff = `${this.props.fieldsNamePrefix}rdStaff`;
    const fieldNameQcStaff = `${this.props.fieldsNamePrefix}qcStaff`;
    const fieldNameSalesStaff = `${this.props.fieldsNamePrefix}salesStaff`;
    const fieldNameOperationsStaff = `${this.props.fieldsNamePrefix}operationsStaff`;
    const fieldNameOtherStaff = `${this.props.fieldsNamePrefix}otherStaff`;

    return (
      <fieldset>
        <div className="row">
          <div className={productsGroupClass}>
            <label>
              Products<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameProducts}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameProducts, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameProducts, true);
              }}
              value={this.props.values.products}
            />
            {touched.products && errors.products && (
              <label className="error">{errors.products}</label>
            )}
          </div>
        </div>
        <div className="row">
          <div className={marketsGroupClass}>
            <label>
              Markets<sup> *</sup>
            </label>
            <FieldArray
              name={fieldNameMarkets}
              render={() => (
                <div data-id="signup-supplier-market-buttons">
                  {marketsList.map((market, listIndex) => {
                    const index = this.props.values.markets.indexOf(market);
                    const isActive = index > -1;
                    const className = classNames('btn', {
                      'muted-button': !isActive,
                      'blue-button': isActive,
                    });
                    return (
                      <button
                        key={market}
                        data-id={`signup-supplier-market-button-${listIndex}`}
                        className={className}
                        type="button"
                        onClick={() => {
                          let markets;
                          if (isActive) {
                            markets = this.props.values.markets.filter((m) => m !== market);
                          } else {
                            markets = [...this.props.values.markets, market];
                          }
                          this.props.setFieldValue(fieldNameMarkets, markets);
                        }}
                      >
                        {market}
                      </button>
                    );
                  })}
                </div>
              )}
            />
            {touched.markets && errors.markets && <label className="error">{errors.markets}</label>}
          </div>
        </div>
        <div className="row">
          <div className={customersGroupClass}>
            <label>
              Customers<sup> *</sup>
            </label>
            <div className="description">Please give some examples of companies you work with.</div>
            <input
              className="form-control"
              type="text"
              name={fieldNameCustomers}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameCustomers, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameCustomers, true);
              }}
              value={this.props.values.customers}
            />
            {touched.customers && errors.customers && (
              <label className="error">{errors.customers}</label>
            )}
          </div>
        </div>
        <div className="row">
          <div className={photosGroupClass}>
            <label>
              Photos<sup> *</sup>
            </label>
            <div className={'form-group'}>
              <ImageGroupUpload
                id={'signup-complete-supplier-photos'}
                onChange={this.onSupplierPhotosChange}
                onInnerEvents={(events) => {
                  if (this.state.supplierPhotosUploadMessages.length > 0 || events.length > 0) {
                    this.setState({ supplierPhotosUploadMessages: events });
                  }
                }}
                files={this.props.values.photoFiles || []}
                previews={this.props.values.photoPreviews || []}
              />
            </div>
            {touched.photoFiles && errors.photoFiles && (
              <label className="error">{errors.photoFiles}</label>
            )}
            {this.state.supplierPhotosUploadMessages.map((item, index) => (
              <label key={index} className="error custom">
                {item.defaultMessage}
              </label>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-12">
            <label>Machine List</label>
            <textarea
              className="form-control"
              rows="4"
              name={fieldNameMachineList}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameMachineList, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameMachineList, true);
              }}
              value={this.props.values.machineList}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-12">
            <label>Business Terms</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameBusinessTerms}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameBusinessTerms, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameBusinessTerms, true);
              }}
              value={this.props.values.businessTerms}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <label>R&D Staff</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameRdStaff}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameRdStaff, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameRdStaff, true);
              }}
              value={this.props.values.rdStaff}
            />
          </div>
          <div className="form-group col-sm-6">
            <label>QC Staff</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameQcStaff}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameQcStaff, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameQcStaff, true);
              }}
              value={this.props.values.qcStaff}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <label>Sales Staff</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameSalesStaff}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameSalesStaff, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameSalesStaff, true);
              }}
              value={this.props.values.salesStaff}
            />
          </div>
          <div className="form-group col-sm-6">
            <label>Operations Staff</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameOperationsStaff}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameOperationsStaff, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameOperationsStaff, true);
              }}
              value={this.props.values.operationsStaff}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <label>Other Staff</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameOtherStaff}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameOtherStaff, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameOtherStaff, true);
              }}
              value={this.props.values.otherStaff}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}

export const FormSectionSupplierProfileValidationScheme = {
  products: Yup.string().required('Products are required'),
  markets: Yup.array().min(1, 'Select at least one market'),
  customers: Yup.string().required('Customers are required'),
  photoFiles: Yup.array().min(1, 'Upload at least one photo'),
};

export default FormSectionSupplierProfile;
