import React from 'react';
import Raven from 'raven-js';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    Raven.captureException(error, { extra: info });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
