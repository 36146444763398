import React from 'react';
import PropTypes from 'prop-types';

const IconStar = (props) => {
  const viewBox = `0 0 17 16`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g fill={props.color} fillRule="nonzero">
        <path
          id="star"
          fillRule="evenodd"
          stroke="#0095FF"
          d="M8.587 12.618l-4.944 2.599.944-5.506-4-3.899 5.528-.803L8.587 0l2.472 5.01 5.528.802-4 3.9.945 5.505z"
        />
      </g>
    </svg>
  );
};

IconStar.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconStar;
