import React from 'react';
import PropTypes from 'prop-types';

const IconSettings = (props) => {
  const viewBox = `0 0 ${props.width} ${props.height}`;
  return (
    <svg
      style={{ overflow: 'visible' }}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <path
        fill={props.fill}
        fillRule="nonzero"
        stroke={props.color}
        d="M16 9.455v-2.91h-2.372a5.795 5.795 0 0 0-.62-1.496l1.677-1.677-2.057-2.057-1.677 1.677a5.795 5.795 0 0 0-1.496-.62V0h-2.91v2.372a5.795 5.795 0 0 0-1.496.62L3.372 1.315 1.315 3.372l1.677 1.677a5.795 5.795 0 0 0-.62 1.496H0v2.91h2.372c.138.532.347 1.034.62 1.496l-1.677 1.677 2.057 2.057 1.677-1.677c.462.273.964.482 1.496.62V16h2.91v-2.372a5.795 5.795 0 0 0 1.496-.62l1.677 1.677 2.057-2.057-1.677-1.677c.273-.462.482-.964.62-1.496H16zm-8 .727a2.182 2.182 0 1 1 0-4.364 2.182 2.182 0 0 1 0 4.364z"
      />
    </svg>
  );
};

IconSettings.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  fill: PropTypes.string,
};

IconSettings.defaultProps = {
  fill: 'none',
};

export default IconSettings;
