import React from 'react';
import PropTypes from 'prop-types';

const AccountMyProfile = (props) => {
  return (
    <div>
      <label className="section-header">User Information</label>
      <span className={'action-link-text'} onClick={props.onEditClick}>
        Edit
      </span>
      <div className="row">
        <div className="form-group col-sm-3">
          <label>Full Name</label>
          <div>{props.fullName}</div>
        </div>
        <div className="form-group col-sm-3">
          <label>Position</label>
          <div>{props.position}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-3">
          <label>Email</label>
          <div>{props.email}</div>
        </div>
        <div className="form-group col-sm-3">
          <label>Telephone</label>
          <div>{props.telephone}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-3">
          <button onClick={props.onClickChangePassword} className="btn white-button" type="button">
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
};

AccountMyProfile.propTypes = {
  fullName: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  onClickChangePassword: PropTypes.func.isRequired,
};

export default AccountMyProfile;
