import { createSelector } from 'reselect';

const chatsSelector = (state) => state.dashboard.chats.data.items;
const activeChatIdSelector = (state) => state.dashboard.chats.activeChatId;

export const chatSelector = (chats, chatId) => {
  let res = null;
  if (Array.isArray(chats)) {
    const filtered = chats.filter((chat) => chat._id === chatId);
    if (filtered.length > 0) {
      res = filtered[0];
    }
  }

  return res;
};

export const activeChatSelector = createSelector(
  chatsSelector,
  activeChatIdSelector,
  chatSelector
);
