import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';

import { signUpEmail, signUpPassword } from '../actions';
import SignUpFormEmail from '../../../component/sign/SignUpFormEmail';
import SignUpFormPassword from '../../../component/sign/SignUpFormPassword';
import { isAuthorized } from '../../../util/session';

class SignUpFormContainer extends Component {
  render() {
    return (
      <div className="sign-up-form-container form-black-container">
        <div className="form-header">Create Account</div>
        <Switch>
          {!isAuthorized() && this.props.pathname !== '/signup' && !this.props.user.email && (
            <Redirect to="/signup" />
          )}
          {isAuthorized() &&
            this.props.pathname === '/signup/password' &&
            !this.props.user.email && <Redirect to="/dashboard" />}
          <Route
            exact
            path="/signup"
            render={() => (
              <SignUpFormEmail user={this.props.user} onSubmit={this.props.signUpEmailOnSubmit} />
            )}
          />
          <Route
            path="/signup/password"
            render={() => (
              <SignUpFormPassword
                user={this.props.user}
                onSubmit={this.props.signUpPasswordOnSubmit}
              />
            )}
          />
        </Switch>
        <div className="link-sign-in" onClick={() => this.props.history.push('/signin')}>
          Already have an account?
          <br />
          Log in here.
        </div>
      </div>
    );
  }
}

SignUpFormContainer.propTypes = {
  signUpEmailOnSubmit: PropTypes.func.isRequired,
  signUpPasswordOnSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  user: state.sign.user,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  signUpEmailOnSubmit: (user, setSubmitting, setErrors) =>
    dispatch(signUpEmail(user, setSubmitting, setErrors)),
  signUpPasswordOnSubmit: (data, setSubmitting, setErrors) =>
    dispatch(signUpPassword(data, setSubmitting, setErrors)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUpFormContainer)
);
