import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from '../../common/Modal';
import IconClose from '../../icon/IconClose';
import IconCompany from '../../icon/IconCompany';
import IconFactory from '../../icon/IconFactory';
import { companyCreateSelectTypeSuccess } from '../../../container/company/actions';

const ContainerStyled = styled.div`
  text-align: center;
`;

const CompanyTypeStyled = styled.div`
  font-size: 14px;
  margin-bottom: 50px;
`;

const SubmitButtonStyled = styled.button`
  width: 100%;
`;

const CompanyTypeButtonStyled = styled.button`
  width: 100%;
`;

class ModalNewCompanyType extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    type: null,
  };

  render() {
    const { type } = this.state;

    const userTypeColumnClientClass = classNames('user-type-column', {
      selected: type === 'client',
    });
    const userTypeColumnSupplierClass = classNames('user-type-column', {
      selected: type === 'supplier',
    });

    return (
      <Modal>
        <Popup
          className="modal-new-company-type"
          open={true}
          onClose={this.props.onClose}
          modal
          contentStyle={{ width: '386px', padding: '50px' }}
        >
          <ContainerStyled>
            <div className={'icon-close-container'} onClick={this.props.onClose}>
              <IconClose width={18} height={18} />
            </div>
            <label className="form-header">Create New Company</label>
            <CompanyTypeStyled className="user-type">
              <div
                className={userTypeColumnClientClass}
                onClick={() => this.setState({ type: 'client' })}
              >
                <div className="user-type-column-icon">
                  <IconCompany
                    width={33}
                    height={35}
                    color={type === 'client' ? '#0095ff' : '#4d4d4d'}
                  />
                </div>
                <label>I’m looking for suppliers</label>
                <CompanyTypeButtonStyled
                  value="client"
                  className="btn black-white-button"
                  type="button"
                >
                  Client
                </CompanyTypeButtonStyled>
              </div>
              <div
                className={userTypeColumnSupplierClass}
                onClick={() => this.setState({ type: 'supplier' })}
              >
                <div className="user-type-column-icon">
                  <IconFactory
                    width={37}
                    height={36}
                    color={type === 'supplier' ? '#0095ff' : '#4d4d4d'}
                  />
                </div>
                <label>I’m a supplier looking for clients</label>
                <CompanyTypeButtonStyled
                  name="type"
                  value="supplier"
                  className="btn black-white-button"
                  type="button"
                >
                  Supplier
                </CompanyTypeButtonStyled>
              </div>
            </CompanyTypeStyled>
            <SubmitButtonStyled
              onClick={() => {
                this.props.companyNewSelectType(this.state.type);
                this.props.onClose();
                this.props.history.push(`${this.props.basePath}company/new`);
              }}
              className="btn blue-button"
              disabled={type === null}
              type="submit"
            >
              Create Company
            </SubmitButtonStyled>
          </ContainerStyled>
        </Popup>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  companyNewSelectType: (type) => dispatch(companyCreateSelectTypeSuccess(type)),
});

export default withRouter(
  connect(
    () => ({}),
    mapDispatchToProps
  )(ModalNewCompanyType)
);
