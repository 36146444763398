import { push } from 'connected-react-router';
import { EventTypes } from 'redux-segment';
import { CREATE_COMPANY_SELECT_TYPE } from './constants';
import {
  applyCompanyAndProject,
  dashboardActiveCompanySet,
  dashboardGetProjectsSuccess,
  dashboardGetUserCompaniesSuccess,
  dashboardResetProjectsNewMessagesCountSuccess,
} from '../dashboard/actions';
import { appFetchingData } from '../../actions';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../util/error';
import { signInCompleteUserSuccess } from '../sign/actions';
import { sessionSetLastCompanyId } from '../../util/session';
import { prepareCompanyDataAndUploadFiles } from '../../util/company';

export function companyCreateSelectTypeSuccess(companyType) {
  return {
    type: CREATE_COMPANY_SELECT_TYPE,
    companyType,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function companyCreateNew(data, setSubmitting, setErrors) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      const company = await prepareCompanyDataAndUploadFiles(data, data.supplier);

      const res = await services.api.companyPost(company);
      const companies = getStore().account.companies;
      const companyId = res.data._id;

      const userRes = await services.api.userGet();
      sessionSetLastCompanyId(companyId);
      await applyCompanyAndProject(
        dispatch,
        services.api,
        userRes.data,
        [...companies, res.data],
        getStore
      );

      dispatch(dashboardGetProjectsSuccess([]));
      dispatch(dashboardResetProjectsNewMessagesCountSuccess());
      dispatch(signInCompleteUserSuccess(userRes.data));

      dispatch(appFetchingData(false));
      dispatch(push('/dashboard'));
    } catch (rej) {
      const errors = errorsTransformAxiosToFormik(rej);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
      dispatch(appFetchingData(false));
    }
  };
}

export function companyUpdate(data, setSubmitting, setErrors, successRedirectPath) {
  return async (dispatch, getStore, services) => {
    try {
      const company = await prepareCompanyDataAndUploadFiles(data.company, data.supplier);

      const companyPutRes = await services.api.companyPut(company);
      const updatedCompanies = getStore().account.companies.map((company) => {
        if (company._id === companyPutRes.data._id) {
          return companyPutRes.data;
        }

        return company;
      });
      dispatch(dashboardGetUserCompaniesSuccess(updatedCompanies));
      dispatch(dashboardActiveCompanySet(companyPutRes.data));
      dispatch(push(successRedirectPath));
    } catch (rej) {
      const errors = errorsTransformAxiosToFormik(rej);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };
}
