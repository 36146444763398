import React from 'react';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import Modal from '../../common/Modal';
import IconClose from '../../icon/IconClose';
import IconCopy from '../../icon/IconCopy';
import './style.css';

const StyledLink = styled.div`
  display: inline;
`;

const StyledTooltip = styled.span`
  color: #0095ff;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
`;

const StyledTextareaLong = styled.textarea`
  width: 100%;
  height: 158px;
`;

class ModalGetQuoteLink extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    productId: PropTypes.string,
    emailBody: PropTypes.string,
  };

  state = {
    urlCopied: false,
  };

  render() {
    const url = `${window.location.origin}/p1/${this.props.productId}`;
    const emailBody = this.props.emailBody.replace('{url}', url);

    return (
      <Modal>
        <Popup
          className="modal-product-get-quote-link"
          open={true}
          onClose={this.props.onClose}
          modal
          contentStyle={{ width: '797px', padding: '50px' }}
        >
          <div>
            <div className={'icon-close-container'} onClick={this.props.onClose}>
              <IconClose width={18} height={18} />
            </div>
            <label className="form-header">Send your product link to your suppliers.</label>
            <div className="row">
              <div className="form-group group-header col-sm-12">
                <CopyToClipboard text={url} onCopy={() => this.setState({ urlCopied: true })}>
                  <div>
                    <Popup
                      className={'im-tooltip'}
                      trigger={
                        <StyledLink className={'link'}>
                          {url} &nbsp;
                          <IconCopy width={16} height={18} color="#4d4d4d" />
                        </StyledLink>
                      }
                      position="right center"
                      offsetX={7}
                      closeOnDocumentClick
                      contentStyle={{ width: '126px' }}
                      arrowStyle={{ backgroundColor: '#f4f4f4' }}
                    >
                      <StyledTooltip>Link Copied!</StyledTooltip>
                    </Popup>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className={'section-separator'} />
            <div className="row">
              <div className="form-group group-header col-sm-12">
                Or let Importist email your suppliers by adding their emails and a message below.
              </div>
            </div>
            <Formik
              initialValues={{
                emails: [],
                body: emailBody,
              }}
              validationSchema={Yup.object().shape({
                emails: Yup.array()
                  .required('Type at least one email address')
                  .of(Yup.string().email('One or more email address is not valid')),
                body: Yup.string().required('Message is required'),
              })}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                this.props.onSubmit(this.props.productId, values, setSubmitting, setErrors);
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group no-margin-bottom col-sm-6">
                      <label>Choose your recipients</label>
                      <div className="description">
                        You can add multiple email addresses. Please separate email addresses with a
                        comma (,).
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <StyledTextarea
                        name="emails"
                        value={values.emails}
                        onChange={(event) => {
                          setFieldValue('emails', event.target.value.split(/[\s,]+/));
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.emails && errors.emails && (
                        <label className="error">{errors.emails}</label>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group no-margin-bottom col-sm-6">
                      <label>Send a message with the link.</label>
                      <div className="description">
                        This email will be sent to your suppliers. We have provided a short
                        optimized template to get you started and get you the most quotes.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <StyledTextareaLong
                        name="body"
                        value={values.body}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.body && errors.body && (
                        <label className="error">{errors.body}</label>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group no-margin-bottom col-sm-12">
                      <button
                        className="btn float-right blue-button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Get Quotes
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalGetQuoteLink;
