// Object key may be related to active left menu item
// Company ID should be group 2 in regexp
export const pathRegExpsDependOnActiveCompany = {
  company: RegExp('^(.+/company/)([^/]+)/(.+)$'),
  quotes: RegExp('^(.+/company/)([^/]+)(/quotes)$'),
  quoteEdit: RegExp('^(.+/company/)([^/]+)(/quote)/([^/]+)$'),
  clients: RegExp('^(.+/company/)([^/]+)/clients'),
  messages: RegExp('^(.+/company/)([^/]+)/messages$'),
  'project-messages': RegExp('^(.+/company/)([^/]+)/messages/project/([^/]+)$'),
};

// Object key is related to active left menu item
// Project ID should be group 2 in regexp
export const pathRegExpsDependOnActiveProject = {
  products: RegExp('^(.+/project/)([^/]+)(/products)$'),
  'project-settings': RegExp('^(.+/project/)([^/]+)(/settings/).+$'),
  suppliers: RegExp('^(.+/project/)([^/]+)(/suppliers)'),
  messages: RegExp('^(.+/project/)([^/]+)(/messages)'),
  'project-messages': RegExp('^(.+/messages/project/)([^/]+)$'),
};

export const pathLeftMenuHiddenRegExps = {
  'company/new': RegExp('^.+/company/new$'),
  'choose-company': RegExp('^.+/choose-company$'),
  'project/add': RegExp('^.+/project/add$'),
  'products/add': RegExp('^(.+/project/)([^/]+)(/products/add)$'),
  'product/edit': RegExp('^(.+/project/)([^/]+)/product/([^/]+)$'),
  'product/full': RegExp('^(.+/project/)([^/]+)/product/([^/]+)/full$'),
  'product/duplicate': RegExp('^(.+/project/)([^/]+)/product/([^/]+)/duplicate$'),
  'quote/request': RegExp('^.+/quote/request/([^/]+)$'),
  'quote/edit': RegExp('^.+/quote/([^/]+)$'),
  'product-quotes': RegExp('^.(.+/project/)([^/]+)(/product/)([^/]+)(/quotes)'),
  'supplier-quote': RegExp('^.(.+/company/)([^/]+)(/quotes/)([^/]+)'),
};

export function getProjectPath(companyId, projectId) {
  return `/company/${companyId}/project/${projectId}`;
}
