import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Pagination from '../../common/Pagination';
import IconSearch from '../../icon/IconSearch';
import IconClose from '../../icon/IconClose';

export default class LeftMenuProjects extends React.Component {
  constructor(props) {
    super(props);

    const projectsPerPage = 10;

    this.state = {
      search: '',
      currentPage: 1,
      projectsPerPage,
      filteredProjects: props.projects,
      totalPages: Math.ceil(props.projects.length / projectsPerPage),
    };
  }
  static propTypes = {
    projects: PropTypes.array.isRequired,
    projectsNewMessagesCount: PropTypes.object,
    onActiveProjectChange: PropTypes.func.isRequired,
  };

  onProjectsPageChange = (page) => {
    this.setState({ currentPage: page });
  };

  filterProjects(search, projects) {
    return projects.filter(
      (pObj) => search.length === 0 || pObj.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
    );
  }

  onSearch = (e) => {
    const search = e.target.value;
    const filteredProjects = this.filterProjects(search, this.props.projects);
    const totalPages = Math.ceil(filteredProjects.length / this.state.projectsPerPage);
    const changedState = { filteredProjects, totalPages, search };
    if (totalPages === 0) {
      changedState.currentPage = 1;
    } else if (totalPages < this.state.currentPage) {
      changedState.currentPage = totalPages;
    }
    this.setState(changedState);
  };

  onSearchClear = () => {
    const totalPages = Math.ceil(this.props.projects.length / this.state.projectsPerPage);
    this.setState({ filteredProjects: this.props.projects, totalPages, search: '' });
  };

  render() {
    const { filteredProjects, currentPage, projectsPerPage } = this.state;

    const projectItems = filteredProjects
      .slice((currentPage - 1) * projectsPerPage, currentPage * projectsPerPage)
      .map((project) => {
        return (
          <div
            key={project._id}
            className={'menu-item'}
            onClick={() => {
              if (project._id !== this.props.activeProjectId) {
                this.props.onActiveProjectChange(project._id);
              }
            }}
          >
            {project.name}
            {this.props.projectsNewMessagesCount[project._id] > 0 && (
              <span className="counter-new-messages">
                {this.props.projectsNewMessagesCount[project._id] > 99
                  ? '99+'
                  : this.props.projectsNewMessagesCount[project._id]}
              </span>
            )}
          </div>
        );
      });

    // Add projects pagination
    if (filteredProjects.length > projectsPerPage) {
      projectItems.push(
        <Pagination
          key="pagination"
          pages={this.state.totalPages}
          page={currentPage}
          onSwitchPage={this.onProjectsPageChange}
          maxControls={9}
        />
      );
    }

    return (
      <div>
        <div className="menu-projects-search-container">
          {this.state.search.length > 0 ? (
            <div className="search-clear-icon-container" onClick={this.onSearchClear}>
              <IconClose width={13} height={13} color={'#fff'} />
            </div>
          ) : (
            <div className="search-icon-container">
              <IconSearch width={20} height={20} fill={'#fff'} />
            </div>
          )}
          <input
            value={this.state.search}
            onChange={this.onSearch}
            placeholder="Search Projects…"
          />
        </div>
        <div
          className={classNames('sub-menu-items-container projects', {
            fixed: filteredProjects.length > projectsPerPage,
          })}
        >
          {projectItems}
        </div>
      </div>
    );
  }
}
