import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';

import ErrorTop from '../../form/ErrorTop';

class QuoteFormSignupBSignup extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    withoutHeading: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { errors, touched, isValid, isSubmitting, readOnly, t } = this.props;

    const clientName = this.props.company.name;
    const productName = this.props.product.name;

    return (
      <div className="form-step-content form-step-1-content">
        {!this.props.withoutHeading ? (
          <div style={{ textAlign: 'center' }}>
            <div className="landing-quote-big-heading">
              <Trans i18nKey="quote.common.headerText">
                <strong>{{ clientName }}</strong> has invited you to quote for&nbsp;
                <strong className="highlighted">{{ productName }}</strong> on the Importist
                Platform.
              </Trans>
            </div>
            <p style={{ margin: '0 0 5px' }}>{t('quote.common.importistShortDescription')}</p>
            <p>
              {t('quote.common.Already have an account?')} &nbsp;
              <a href="/signin">{_capitalize(t('common.sign in'))}</a>
            </p>
            <div className="landing-quote-big-heading highlighted">
              {t('quote.common.formShortDescription')}
            </div>
          </div>
        ) : (
          ''
        )}

        <ErrorTop
          isSubmitting={isSubmitting}
          isValid={isValid}
          errors={errors}
          commonErrorText={t('Please, fix errors below')}
        />

        <fieldset>
          <div className="row">
            <div className="col-sm-6">
              <div
                className={classNames('form-group', {
                  'with-error': touched.fullName && errors.fullName,
                })}
              >
                <Field
                  name="fullName"
                  className="form-control big-input"
                  type="text"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.full name'))}
                />
                {touched.fullName && errors.fullName && (
                  <label className="error">{t(`account.validation.${errors.fullName}`)}</label>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className={classNames('form-group', {
                  'with-error': touched.companyName && errors.companyName,
                })}
              >
                <Field
                  name="companyName"
                  className="form-control big-input"
                  type="text"
                  disabled={readOnly}
                  placeholder="Company Name"
                />
                {touched.companyName && errors.companyName && (
                  <label className="error">{t(`account.validation.${errors.companyName}`)}</label>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div
                className={classNames('form-group', {
                  'with-error': touched.email && errors.email,
                })}
              >
                <Field
                  name="email"
                  className="form-control big-input"
                  type="text"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.email'))}
                />
                {touched.email && errors.email && (
                  <label className="error">{t(`account.validation.${errors.email}`)}</label>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className={classNames('form-group', {
                  'with-error': touched.password && errors.password,
                })}
              >
                <Field
                  name="password"
                  className="form-control big-input"
                  type="password"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.password'))}
                />
                {touched.password && errors.password && (
                  <label className="error">{t(`account.validation.${errors.password}`)}</label>
                )}
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default QuoteFormSignupBSignup;
