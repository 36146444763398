import React from 'react';
import PropTypes from 'prop-types';

const IconLngEn = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 15 10"
    >
      <defs>
        <rect id="a" width={props.width} height={props.height} rx="2" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(.077)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#FFF" xlinkHref="#a" />
        <path fill="#0A17A7" d="M0 0h14.179v10H0z" mask="url(#b)" />
        <path
          fill="#FFF"
          d="M3.427 6.667H0V3.333h3.427L-1.4.078l.746-1.106 6.056 4.085V-.667h3.376v3.724l6.056-4.085.745 1.106-4.826 3.255h3.426v3.334h-3.426l4.826 3.255-.745 1.106-6.056-4.085v3.724H5.402V6.943l-6.056 4.085-.746-1.106 4.827-3.255z"
          mask="url(#b)"
        />
        <path
          stroke="#DB1F35"
          strokeLinecap="round"
          strokeWidth=".667"
          d="M9.454 3.166L15.868-1"
          mask="url(#b)"
        />
        <path
          stroke="#DB1F35"
          strokeLinecap="round"
          strokeWidth=".667"
          d="M10.135 10.675l5.75-3.826"
          mask="url(#b)"
          transform="matrix(1 0 0 -1 0 17.524)"
        />
        <path
          stroke="#DB1F35"
          strokeLinecap="round"
          strokeWidth=".667"
          d="M4.054 3.155l-5.997-3.99"
          mask="url(#b)"
        />
        <path
          stroke="#DB1F35"
          strokeLinecap="round"
          strokeWidth=".667"
          d="M4.705 11.155l-6.648-4.353"
          mask="url(#b)"
          transform="matrix(1 0 0 -1 0 17.958)"
        />
        <path fill="#E6273E" d="M0 6h6.077v4h2.026V6h6.076V4H8.103V0H6.077v4H0z" mask="url(#b)" />
      </g>
    </svg>
  );
};

IconLngEn.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconLngEn;
