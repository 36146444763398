import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { countriesList } from '../../../util/country';
import UploadButton from '../../common/UploadButton/index';
import IconMuted from '../../icon/IconMuted';
import ImageFixedSizeContain from '../../common/ImageFixedSizeContain';

class FormSectionCompany extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    fieldsNamePrefix: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  companyLogoInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      companyLogoPreviewUrl: props.values.logoUrl || null,
    };
  }

  onCompanyLogoChange = (file) => {
    if (file) {
      this.props.setFieldValue(`${this.props.fieldsNamePrefix}logoFile`, file);
      this.props.setFieldValue(`${this.props.fieldsNamePrefix}logoSize`, file.size);
      this.setState({
        companyLogoPreviewUrl: URL.createObjectURL(file),
      });
    }
  };

  onCompanyLogoRemove = () => {
    this.companyLogoInputRef.current.value = null;
    this.props.setFieldValue(`${this.props.fieldsNamePrefix}logoFile`, null);
    this.props.setFieldValue(`${this.props.fieldsNamePrefix}logoSize`, 0);
    this.props.setFieldValue(`${this.props.fieldsNamePrefix}logo`, null);
    this.setState({
      companyLogoPreviewUrl: null,
    });
  };

  render() {
    const touched = this.props.touched || {};
    const errors = this.props.errors || {};

    const nameGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.name && errors.name,
    });
    const fieldNameName = `${this.props.fieldsNamePrefix}name`;

    const addressGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.address && errors.address,
    });
    const fieldNameAddress = `${this.props.fieldsNamePrefix}address`;
    const fieldNameAddress2 = `${this.props.fieldsNamePrefix}address2`;

    const zipCodeGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.zipCode && errors.zipCode,
    });
    const fieldNameZipCode = `${this.props.fieldsNamePrefix}zipCode`;

    const countryGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.country && errors.country,
    });
    const fieldNameCountry = `${this.props.fieldsNamePrefix}country`;

    const fieldNameTelephone = `${this.props.fieldsNamePrefix}telephone`;
    const websiteGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.website && errors.website,
    });
    const fieldNameWebsite = `${this.props.fieldsNamePrefix}website`;

    return (
      <fieldset>
        <div className="row">
          <div className={nameGroupClass}>
            <label>
              Company Name<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameName}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameName, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameName, true);
              }}
              value={this.props.values.name}
            />
            {touched.name && errors.name && <label className="error">{errors.name}</label>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <label>Logo</label>
            <div className="row">
              <div className="col-sm-6">
                <UploadButton
                  id={'signup-complete-file-company-logo'}
                  onChange={this.onCompanyLogoChange}
                  inputRef={this.companyLogoInputRef}
                  accept="image/*"
                  buttonLabel={'Upload Photo'}
                />
                {this.state.companyLogoPreviewUrl && (
                  <div className="image-preview-container">
                    <ImageFixedSizeContain
                      className="img-thumbnail"
                      width="125px"
                      height="125px"
                      url={this.state.companyLogoPreviewUrl}
                    />
                    <div className="image-preview-remove" onClick={this.onCompanyLogoRemove}>
                      <IconMuted width={12} height={12} color="#959595" />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group col-sm-6">
                <sup>Max file size: 512Kb</sup>
                {errors.logoSize && errors.logoSize && (
                  <label className="error">{errors.logoSize}</label>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={addressGroupClass}>
            <label>
              Address Line 1<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameAddress}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameAddress, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameAddress, true);
              }}
              value={this.props.values.address}
            />
            {touched.address && errors.address && <label className="error">{errors.address}</label>}
          </div>
          <div className="form-group col-sm-6">
            <label>Address Line 2</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameAddress2}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameAddress2, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameAddress2, true);
              }}
              value={this.props.values.address2}
            />
            {touched.address2 && errors.address2 && (
              <label className="error">{errors.address2}</label>
            )}
          </div>
        </div>
        <div className="row">
          <div className={zipCodeGroupClass}>
            <label>
              Zip / Postal Code<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameZipCode}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameZipCode, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameZipCode, true);
              }}
              value={this.props.values.zipCode}
            />
            {touched.zipCode && errors.zipCode && <label className="error">{errors.zipCode}</label>}
          </div>
          <div className={countryGroupClass}>
            <label>
              Country<sup> *</sup>
            </label>
            <select
              className="form-control"
              name={fieldNameCountry}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameCountry, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameCountry, true);
              }}
              value={this.props.values.country}
            >
              <option value="" />
              {countriesList.map((country) => {
                return (
                  <option value={country.name} key={country.name}>
                    {country.name}
                  </option>
                );
              })}
            </select>
            {touched.country && errors.country && <label className="error">{errors.country}</label>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <label>Telephone</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameTelephone}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameTelephone, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameTelephone, true);
              }}
              value={this.props.values.telephone}
            />
            {touched.telephone && errors.telephone && (
              <label className="error">{errors.telephone}</label>
            )}
          </div>
          <div className={websiteGroupClass}>
            <label>
              Website<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameWebsite}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameWebsite, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameWebsite, true);
              }}
              value={this.props.values.website}
              placeholder={'www.website.com'}
            />
            {touched.website && errors.website && <label className="error">{errors.website}</label>}
          </div>
        </div>
      </fieldset>
    );
  }
}

export const FormSectionCompanyValidationScheme = {
  name: Yup.string().required('Company Name is required'),
  address: Yup.string().required('Address Line 1 is required'),
  zipCode: Yup.string().required('Zip / Postal Code is required'),
  country: Yup.string().required('Country is required'),
  website: Yup.string()
    .required('Website is required')
    .test('url-string', 'Please use www.website.com format', (str) => {
      return str && str.indexOf('.') > 0;
    }),
  logoSize: Yup.number()
    .notRequired()
    .max(524288, 'Logo max file size is 512Kb'),
};

export default FormSectionCompany;
