import React from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SpinnerLoading from '../../common/SpinnerLoading';

export const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
  onCancel,
}) => {
  const nameGroupClass = classNames('form-group col-sm-3', {
    'with-error': touched.name && errors.name,
  });

  return (
    <div>
      <label className="section-header">Project Information</label>
      <form style={{ minHeight: '300px' }} onSubmit={handleSubmit}>
        <div className="row">
          <div className={nameGroupClass}>
            <label>Project Name</label>
            <Field className="form-control" name="name" />
            {touched.name && errors.name && <label className="error">{errors.name}</label>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-12">
            <label>Project Description</label>
            <textarea
              className="form-control"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className={'actions-bottom'}>
          <button
            onClick={onCancel}
            className="btn white-button"
            disabled={isSubmitting}
            type="button"
          >
            Cancel
          </button>
          <button className="btn blue-button" disabled={isSubmitting} type="submit">
            Save
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Project Name is required'),
});

const EditProjectInfoForm = withFormik({
  mapPropsToValues: (props) => ({
    _id: props.project._id,
    name: props.project.name,
    description: props.project.description,
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit('info', values, setSubmitting, setErrors);
  },
})(Form);

EditProjectInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProjectInfoForm;
