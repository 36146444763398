import s3 from '../service/s3';
import api from '../service/api';

export async function bulkFilesS3SignedPut(objectType, files) {
  const params = [];
  for (let i = 0; i < files.length; i += 1) {
    params.push({
      objectType,
      contentType: files[i].type,
      name: files[i].name,
    });
  }

  const signPutPromises = params.map((payloadSign) => api.signS3Put(payloadSign));

  const fileKeys = [];
  const signedResults = await Promise.all(signPutPromises);
  const uploadPromises = signedResults.map((signedRes, index) => {
    fileKeys.push(signedRes.data.key);

    const { headers } = signedRes.data;
    return s3.presignedPut(signedRes.data.url, files[index], headers);
  });
  await Promise.all(uploadPromises);

  return fileKeys;
}

export async function attachmentS3SignedPut(file) {
  const params = {
    objectType: 'attachment',
    contentType: file.type,
    name: file.name,
  };
  const signedRes = await api.signS3Put(params);
  await s3.presignedPut(signedRes.data.url, file, signedRes.data.headers);

  return {
    key: signedRes.data.key,
    origName: file.name,
    contentType: file.type,
    size: file.size,
  };
}
