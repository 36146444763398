import Pusher from 'pusher-js';
import Raven from 'raven-js';

import config from '../util/config';

let pusher;

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  Pusher.log = (msg) => {
    console.log(msg);

    Raven.captureBreadcrumb({
      category: 'pusher',
      level: 'debug',
      message: msg,
    });
  };
}

const connect = (jwtToken) => {
  pusher = new Pusher(config.PUSHER_APP_KEY, {
    cluster: config.PUSHER_APP_CLUSTER,
    forceTLS: true,
    authEndpoint: config.WS_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    },
    activityTimeout: 20000,
    pongTimeout: 10000,
  });

  pusher.connection.bind('error', (err) => {
    Raven.captureMessage('Pusher error', err);
  });

  if (process.env.NODE_ENV !== 'production') {
    pusher.connection.bind('state_change', (states) => {
      Raven.captureBreadcrumb({
        category: 'pusher',
        level: 'debug',
        data: states,
      });
    });
  }
};

const disconnect = () => {
  if (pusher) {
    pusher.disconnect();
  }
};

const subscribe = (channel) => {
  if (!pusher) {
    Raven.captureException(new Error('Pusher is not created'));
    return null;
  }
  return pusher.subscribe(channel);
};

const unsubscribe = (channel) => {
  if (!pusher) {
    Raven.captureException(new Error('Pusher is not created'));
    return null;
  }
  return pusher.unsubscribe(channel);
};

export default {
  connect,
  disconnect,
  subscribe,
  unsubscribe,
};
