import React from 'react';
import PropTypes from 'prop-types';

const IconPhoto = (props) => {
  const viewBox = `0 0 ${props.width} ${props.height}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.5 11.5l3-3 3 3 5-5"
        />
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.5 15.5h-13a1 1 0 0 1-1-1v-13a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1z"
        />
        <circle cx="5.5" cy="4.5" r="1.5" fill={props.color} fillRule="nonzero" />
      </g>
    </svg>
  );
};

IconPhoto.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconPhoto;
