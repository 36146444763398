import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import { Can } from '../../util/session';
import CompanySettingsContainer from '../company/CompanySettingsContainer/index';
import ChatCompanyContainer from '../chat/ChatCompanyContainer';
import ClientsContainer from '../client';

class CompanyContainer extends Component {
  render() {
    const basePath = `/dashboard/company/`;

    return (
      <div className="company-container">
        <Switch>
          <Route
            path={`${basePath}:companyId/settings`}
            render={() => (
              <Can I="manage" a="Company">
                <CompanySettingsContainer />
              </Can>
            )}
          />
          <Route
            path={`${basePath}:companyId/clients`}
            render={() => <ClientsContainer basePath={`${basePath}:companyId/clients`} />}
          />
          <Route
            path={`${basePath}:companyId/messages/:filter(project)?/:projectId?`}
            render={() => <ChatCompanyContainer />}
          />
        </Switch>
      </div>
    );
  }
}

export default CompanyContainer;
