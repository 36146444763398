import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import FormSectionCompany, {
  FormSectionCompanyValidationScheme,
} from '../../form/FormSectionCompany';
import FormSectionCompanyDetails, {
  FormSectionCompanyDetailsValidationScheme,
} from '../../form/FormSectionCompanyDetails';
import FormSectionSupplierProfile, {
  FormSectionSupplierProfileValidationScheme,
} from '../../form/FormSectionSupplierProfile';
import StickyMenu from '../../common/StickyMenu';
import ErrorTop from '../../form/ErrorTop';

const SubmitButtonStyled = styled.button`
  width: 174px;
  margin: 14px 0;
  float: right;
`;

class Form extends React.Component {
  sectionCompany = {
    name: 'company',
    label: 'Company',
    ref: React.createRef(),
  };

  sectionCompanyDetails = {
    name: 'companyDetails',
    label: 'Company Details',
    ref: React.createRef(),
  };

  sectionSupplierProfile = {
    name: 'supplierProfile',
    label: 'Supplier Profile',
    ref: React.createRef(),
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      isValid,
      setFieldValue,
      setFieldTouched,
    } = this.props;

    const sectionHeaderRefs = [this.sectionCompany, this.sectionCompanyDetails];

    if (values.type === 'supplier') {
      sectionHeaderRefs.push(this.sectionSupplierProfile);
    }

    return (
      <form onSubmit={handleSubmit} className={'padding-bottom-180'}>
        <ErrorTop isSubmitting={isSubmitting} isValid={isValid} errors={errors} />
        <StickyMenu items={sectionHeaderRefs} />
        <label className="section-header" ref={this.sectionCompany.ref}>
          {this.sectionCompany.label}
        </label>
        <FormSectionCompany
          values={values}
          fieldsNamePrefix=""
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
        />
        <label className="section-header" ref={this.sectionCompanyDetails.ref}>
          {this.sectionCompanyDetails.label}
        </label>
        <FormSectionCompanyDetails
          values={values}
          fieldsNamePrefix=""
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
        />
        {this.props.type === 'supplier' && (
          <label className="section-header" ref={this.sectionSupplierProfile.ref}>
            {this.sectionSupplierProfile.label}
          </label>
        )}
        {this.props.type === 'supplier' && (
          <FormSectionSupplierProfile
            values={values.supplier}
            fieldsNamePrefix="supplier."
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errors={errors.supplier}
            touched={touched.supplier}
          />
        )}
        <div className={'actions-bottom'}>
          {errors.common && <label className="error common">{errors.common}</label>}
          <SubmitButtonStyled className="btn blue-button" disabled={isSubmitting} type="submit">
            Save & Continue
          </SubmitButtonStyled>
        </div>
      </form>
    );
  }
}

const CompanyCreateNew = withFormik({
  mapPropsToValues: (props) => {
    let values = {
      name: '',
      type: props.type,
      logoFile: null,
      address: '',
      address2: '',
      zipCode: '',
      country: '',
      telephone: '',
      website: '',
      about: '',
      staff: '',
      revenue: '',
      established: '',
    };
    if (props.type === 'supplier') {
      values.supplier = {
        products: '',
        markets: [],
        photoFiles: [],
        customers: '',
        machineList: '',
        businessTerms: '',
        rdStaff: '',
        qcStaff: '',
        salesStaff: '',
        operationsStaff: '',
        otherStaff: '',
      };
    }
    return values;
  },
  validationSchema: (props) => {
    let schema = Yup.object().shape({
      ...FormSectionCompanyValidationScheme,
      ...FormSectionCompanyDetailsValidationScheme,
    });
    if (props.type === 'supplier') {
      schema.supplier = Yup.object().shape({
        ...FormSectionSupplierProfileValidationScheme,
      });
    }
    return schema;
  },
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

CompanyCreateNew.propTypes = {
  type: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyCreateNew;
