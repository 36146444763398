import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '../../common/Modal';
import IconClose from '../../icon/IconClose';
import './style.css';
import IconCirclePlus from '../../icon/IconCirclePlus';
import { Can } from '../../../util/session';

const CompanyItemStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CompanyCheckboxStyled = styled.input`
  margin-right: 36px;
`;

const CreateNewCompanyButtonStyled = styled.button`
  float: right;
  width: 209px;
`;

const ModalHeaderStyled = styled.div`
  margin-bottom: 28px;
`;

class ModalCompanySwitch extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onCreateNewCompanyClick: PropTypes.func.isRequired,
    onSwitchCompany: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
    activeCompanyId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      checkedCompanyId: props.activeCompanyId,
    };
  }

  onChangeCompany = (companyId) => {
    this.setState({ checkedCompanyId: companyId });
  };

  onSwitchCompanyClick = () => {
    this.props.onSwitchCompany(this.state.checkedCompanyId);
    this.props.onClose();
  };

  render() {
    const companiesList = this.props.companies.map((company) => {
      const isActive = company._id === this.state.checkedCompanyId;

      return (
        <div className={'row'} key={company._id}>
          <div className={'section-separator'} />
          <CompanyItemStyled
            className={'form-group col-sm-12'}
            onClick={() => this.onChangeCompany(company._id)}
          >
            <CompanyCheckboxStyled type="checkbox" checked={isActive} onChange={() => {}} />
            <div className={'letter-circle-container'}>{company.name.toUpperCase()[0]}</div>
            <span>
              <div>{company.name}</div>
              <div className={'description-uppercase-small'}>{company.type}</div>
            </span>
          </CompanyItemStyled>
        </div>
      );
    });

    return (
      <Modal>
        <Popup
          className="modal-company-switch"
          open={true}
          onClose={this.props.onClose}
          modal
          contentStyle={{ width: '797px', padding: '50px' }}
        >
          <div>
            <div className={'icon-close-container'} onClick={this.props.onClose}>
              <IconClose width={18} height={18} />
            </div>
            <ModalHeaderStyled>
              <label className="form-header">Switch company</label>
              <Can I="create" a="Company">
                <CreateNewCompanyButtonStyled
                  onClick={this.props.onCreateNewCompanyClick}
                  className="btn white-button"
                >
                  <div className={'icon'}>
                    <IconCirclePlus width={19} height={19} color={'var(--common-text-color)'} />
                  </div>
                  <span className={'text'}>Create New Company</span>
                </CreateNewCompanyButtonStyled>
              </Can>
            </ModalHeaderStyled>
            {companiesList}
            <div className={'row'}>
              <div className={'section-separator'} />
            </div>
            <div className="row">
              <div className="col-sm-12 buttons-bottom">
                <button
                  className="btn blue-button"
                  onClick={this.onSwitchCompanyClick}
                  type="button"
                  disabled={this.state.checkedCompanyId === this.props.activeCompanyId}
                >
                  Switch Company
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalCompanySwitch;
