import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Search from '../../../component/common/Search';
import SuppliersTable from '../../../component/supplier/SuppliersTable';
import Pagination from '../../../component/common/Pagination';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import IconCirclePlus from '../../../component/icon/IconCirclePlus';
import ModalInviteCompanies from '../../../component/company/ModalInviteCompanies';
import { modalClose, modalOpen } from '../../../actions';

export class SuppliersListContainer extends React.Component {
  static propTypes = {
    suppliers: PropTypes.array,
    suppliersCount: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    onSwitchFavorite: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
    onSwitchPage: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    modalInviteCompaniesOpened: false,
  };

  modalInviteCompaniesOpen = () => {
    this.props.modalOpen();
    this.setState({ modalInviteCompaniesOpened: true });
  };

  modalInviteCompaniesClose = () => {
    this.props.modalClose();
    this.setState({ modalInviteCompaniesOpened: false });
  };

  render() {
    const {
      suppliers,
      suppliersCount,
      limit,
      companyId,
      projectId,
      history,
      onSwitchFavorite,
    } = this.props;
    return !suppliers ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className="suppliers-table-container">
        <label className="page-header">Suppliers</label>
        <Search search={this.props.search} setSearch={this.props.setSearch} />
        <button className="btn white-button btn-top-right" onClick={this.modalInviteCompaniesOpen}>
          <div className={'icon'}>
            <IconCirclePlus width={19} height={19} color={'var(--common-text-color)'} />
          </div>
          <span className={'text'}>Invite Suppliers</span>
        </button>
        {this.state.modalInviteCompaniesOpened && (
          <ModalInviteCompanies companyType="client" onClose={this.modalInviteCompaniesClose} />
        )}
        <SuppliersTable
          suppliers={suppliers}
          companyId={companyId}
          projectId={projectId}
          history={history}
          onSwitchFavorite={onSwitchFavorite}
        />
        {suppliersCount > limit && (
          <Pagination
            pages={this.props.pages}
            page={this.props.page}
            onSwitchPage={this.props.onSwitchPage}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  modalOpen: () => dispatch(modalOpen()),
  modalClose: () => dispatch(modalClose()),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(SuppliersListContainer)
);
