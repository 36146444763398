import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import FormSectionCompany, {
  FormSectionCompanyValidationScheme,
} from '../../form/FormSectionCompany';
import FormSectionCompanyDetails, {
  FormSectionCompanyDetailsValidationScheme,
} from '../../form/FormSectionCompanyDetails';
import ErrorTop from '../../form/ErrorTop';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import FormSectionSupplierProfile, {
  FormSectionSupplierProfileValidationScheme,
} from '../../form/FormSectionSupplierProfile';

export class Form extends React.Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      isValid,
      setFieldValue,
      setFieldTouched,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="company-edit-form">
        <ErrorTop isSubmitting={isSubmitting} isValid={isValid} errors={errors} />
        <label className="form-header">Company Information</label>
        <FormSectionCompany
          values={values.company}
          fieldsNamePrefix="company."
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors.company}
          touched={touched.company}
        />
        <div className="section-separator with-vertical-margin-65" />
        <label className="form-header">Company Details</label>
        <FormSectionCompanyDetails
          values={values.company}
          fieldsNamePrefix="company."
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors.company}
          touched={touched.company}
        />
        <div className="section-separator with-vertical-margin-65" />
        <label className="form-header">Supplier Profile</label>
        <FormSectionSupplierProfile
          values={values.supplier}
          fieldsNamePrefix="supplier."
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors.supplier}
          touched={touched.supplier}
        />
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className="col-sm-12 actions-bottom">
          <button
            className="btn white-button"
            disabled={isSubmitting}
            type="button"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          <button className="btn blue-button" disabled={isSubmitting} type="submit">
            Save
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    ...FormSectionCompanyValidationScheme,
    ...FormSectionCompanyDetailsValidationScheme,
  }),
  supplier: Yup.object().shape({
    ...FormSectionSupplierProfileValidationScheme,
  }),
});

const CompanyProfileSupplierEdit = withFormik({
  mapPropsToValues: (props) => {
    let photoFiles = [];
    let photoPreviews = [];
    if (props.company.supplier.photos) {
      photoFiles = props.company.supplier.photos.map((name) => ({ name, uploaded: true }));
      photoPreviews = props.company.supplier.photosUrl;
    }
    return {
      company: {
        ...props.company,
        logoFile: null,
      },
      supplier: { ...props.company.supplier, photoFiles, photoPreviews },
    };
  },
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

CompanyProfileSupplierEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  company: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
};

export default CompanyProfileSupplierEdit;
