import { PRODUCT_CREATE_NEW_SUCCESS } from './constants';
import { EventTypes } from 'redux-segment';

export function productCreateNewSuccess(product) {
  return {
    type: PRODUCT_CREATE_NEW_SUCCESS,
    product,
    meta: {
      analytics: EventTypes.track,
    },
  };
}
