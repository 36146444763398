import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';

class QuoteFormSectionSignup extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { readOnly, errors, touched, t } = this.props;

    return (
      <fieldset>
        <div className={'row'}>
          <div className="col-sm-5">
            <div
              className={classNames('form-group', {
                'with-error': touched.fullName && errors.fullName,
              })}
            >
              <label>{_startCase(t('common.full name'))}</label>
              <Field name="fullName" className="form-control" type="text" disabled={readOnly} />
              {touched.fullName && errors.fullName && (
                <label className="error">{t(`account.validation.${errors.fullName}`)}</label>
              )}
            </div>
          </div>
          <div className="col-sm-5">
            <div
              className={classNames('form-group', {
                'with-error': touched.companyName && errors.companyName,
              })}
            >
              <label>{_startCase(t('common.company name'))}</label>
              <Field name="companyName" className="form-control" type="text" disabled={readOnly} />
              {touched.companyName && errors.companyName && (
                <label className="error">{t(`account.validation.${errors.companyName}`)}</label>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <div
              className={classNames('form-group', {
                'with-error': touched.email && errors.email,
              })}
            >
              <label>{_startCase(t('common.email address'))}</label>
              <Field name="email" className="form-control" type="text" disabled={readOnly} />
              {touched.email && errors.email && (
                <label className="error">{t(`account.validation.${errors.email}`)}</label>
              )}
            </div>
          </div>
          <div className="col-sm-5">
            <div
              className={classNames('form-group', {
                'with-error': touched.password && errors.password,
              })}
            >
              <label>{_startCase(t('common.password'))}</label>
              <Field name="password" className="form-control" type="password" disabled={readOnly} />
              {touched.password && errors.password && (
                <label className="error">{t(`account.validation.${errors.password}`)}</label>
              )}
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default QuoteFormSectionSignup;
