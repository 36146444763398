import React from 'react';
import PropTypes from 'prop-types';

const IconFactory = (props) => {
  const viewBox = `0 0 38 38`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M29.148 1c-1.033 0-1.936.802-1.98 1.867h-.023v.068l-1.35 13.973a.714.714 0 0 0-.315-.068h-.72v-4.32a.718.718 0 0 0-1.035-.653L13.24 16.75v-3.51a.718.718 0 0 0-1.058-.63l-10.8 5.76A.721.721 0 0 0 1 19v17.28c0 .397.323.72.72.72h34.56a.72.72 0 0 0 .72-.765L34.975 2.98v-.045C34.868 1.875 34.047 1 32.972 1h-3.825zm0 1.44h3.825c.213 0 .506.273.562.63l1.98 32.49H2.44V19.427l9.36-4.995v3.128c0 .397.323.72.72.72h.72a.714.714 0 0 0 .315-.068l9.765-4.567v3.915c0 .397.323.72.72.72h.72v9.36c-.02.397.29.734.686.754.397.02.734-.29.754-.686v-.068l2.363-24.637a.633.633 0 0 0 .022-.068c0-.335.177-.495.563-.495zm-23.108 18v3.6H8.2v-3.6H6.04zm6.48 0v3.6h2.16v-3.6h-2.16zm6.48 0v3.6h2.16v-3.6H19zM6.04 26.92v3.6H8.2v-3.6H6.04zm6.48 0v3.6h2.16v-3.6h-2.16zm6.48 0v3.6h2.16v-3.6H19z"
      />
    </svg>
  );
};

IconFactory.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconFactory;
