import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';

import i18n from './service/i18n';
import configureStore from './store/configureStore';
import SignContainer from './container/sign/SignContainer';
import DashboardContainer from './container/dashboard';
import SignUpCompleteContainer from './container/sign/SignUpCompleteContainer';
import './App.css';
import './buttons.css';
import PrivateRoute from './component/common/PrivateRoute';
import ErrorBoundary from './component/common/ErrorBoundary';
import { appIsTabActive, appSetRedirectProductId } from './actions';
import { isAuthorized } from './util/session';
import SignQuoteContainer from './container/sign/SignQuoteContainer';

const history = createBrowserHistory();

const store = configureStore({}, history);

export class App extends Component {
  onVisibilityChange = () => {
    store.dispatch(appIsTabActive(document.visibilityState !== 'hidden'));
  };

  componentDidMount() {
    document.addEventListener('visibilitychange', this.onVisibilityChange, false);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }

  render() {
    return (
      <div className="importist-app">
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return isAuthorized() ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />;
            }}
          />
          <Route exact path="/p1/:productId" component={SignQuoteContainer} />
          <Route exact path="/p2/:productId" component={SignQuoteContainer} />
          <Route exact path="/p3/:productId" component={SignQuoteContainer} />
          <Route exact path="/p4/:productId" component={SignQuoteContainer} />
          <Route exact path="/p5/:productId" component={SignQuoteContainer} />
          <Route
            exact
            path="/p/:productId"
            render={(props) => {
              store.dispatch(appSetRedirectProductId(props.match.params.productId));
              return <Redirect to="/signin" />;
            }}
          />
          <Route exact path="/signup-complete" component={SignUpCompleteContainer} />
          <Route path="/sign*" component={SignContainer} />
          <PrivateRoute path="/dashboard*" component={DashboardContainer} />
        </Switch>
      </div>
    );
  }
}

class AppContainer extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </ConnectedRouter>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default AppContainer;
