import React from 'react';
import PropTypes from 'prop-types';

const ViewProjectInfo = (props) => {
  const { name, description, onEditClick } = props;
  return (
    <div>
      <label className="section-header">
        Project Information
        <span
          id={'project-settings-edit-link'}
          className={'action-link-text'}
          onClick={onEditClick}
        >
          Edit
        </span>
      </label>
      <div className="row">
        <div className="form-group col-sm-8">
          <label>Project Name</label>
          <div className="form-value">{name}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-8">
          <label>Project Description</label>
          <div className="form-value">{description}</div>
        </div>
      </div>
    </div>
  );
};

ViewProjectInfo.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default ViewProjectInfo;
