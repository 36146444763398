import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { AbilityContext, defineAbilityFor } from '../../util/session';

import pusher from '../../service/pusher';
import AccountContainer from './AccountContainer';
import ProjectContainer from '../project';
import CreateNewCompanyContainer from '../company/CreateNewCompanyContainer';
import QuoteContainer from '../quote';
import './style.css';
import './left-menu.css';
import './chat.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';
import {
  authenticate,
  changeUserActiveCompanyIdAndRedirectDefault,
  getProjectsByCompanyId,
  dashboardUserInvitedAddToCompany,
  dashboardOnRouteChange,
  dashboardWsChannelCompanySetSuccess,
  dashboardIncProjectsNewMessagesCountSuccess,
  dashboardIncCompanyNewMessagesCount,
} from './actions';
import { modalClose, modalOpen } from '../../actions';
import SpinnerLoading from '../../component/common/SpinnerLoading';
import { Can } from '../../util/session';
import { getProjectPath, pathLeftMenuHiddenRegExps } from '../../util/path';
import ModalCompanySwitch from '../../component/dashboard/ModalCompanySwitch';
import ModalNewCompanyType from '../../component/dashboard/ModalNewCompanyType';
import ModalUserInvited from '../../component/dashboard/ModalUserInvited';
import { signupSetInvitedCompanySuccess, signupSetInvitedTokenSuccess } from '../sign/actions';
import BlackPageContainer from './BlackPageContainer';
import CompanyContainer from '../company';
import { getCompanyChannelName } from '../../util/wsChannel';
import LeftMenuContainer from './LeftMenuContainer';
import { activeProjectSelector } from './selectors';

export class DashboardContainer extends Component {
  static defaultProps = {
    basePath: '/dashboard/',
  };

  state = {
    isCompanySwitcherVisible: false,
    isModalNewCompanyTypeVisible: false,
  };

  static propTypes = {
    user: PropTypes.object.isRequired,
    userInvitedToken: PropTypes.string,
    company: PropTypes.object,
    activeChatId: PropTypes.string,
    activeCompanyId: PropTypes.string,
    projects: PropTypes.array.isRequired,
    activeProjectId: PropTypes.string,
    modalOpened: PropTypes.bool.isRequired,
    appLoading: PropTypes.bool.isRequired,
    loadUser: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  wsCompanyChannelOnNewChatMessage = (wsNewChatMessageEvent) => {
    if (
      this.props.user._id !== wsNewChatMessageEvent.data.lastMessageUserId &&
      (this.props.activeChatId !== wsNewChatMessageEvent.data.chatId || !this.props.isTabActive)
    ) {
      if (
        Array.isArray(this.props.projects) &&
        this.props.projects.findIndex(
          (project) => project._id === wsNewChatMessageEvent.data.projectId
        ) >= 0
      ) {
        this.props.onProjectNewMessagesCountIncrement(wsNewChatMessageEvent.data.projectId, 1);
      }
      this.props.onCompanyNewMessagesCountIncrement(1);
    }
  };

  wsSubscribeCompanyChannel = () => {
    const companyChannel = pusher.subscribe(getCompanyChannelName(this.props.activeCompanyId));
    if (companyChannel) {
      this.props.setCompanyChannel(companyChannel);
      companyChannel.bind('newChatMessage', this.wsCompanyChannelOnNewChatMessage);
    }
  };

  wsUnsubscribeCompanyChannel = (companyId) => {
    this.props.setCompanyChannel(null);
    pusher.unsubscribe(getCompanyChannelName(companyId));
  };

  componentDidMount() {
    if (!this.props.user._id) {
      this.props.loadUser();
    } else if (this.props.activeCompanyId) {
      this.wsSubscribeCompanyChannel();
      // TODO fix possible race condition
      // TODO do not request for supplier
      this.props.getProjectsByCompanyId(this.props.activeCompanyId);
    }
    if (this.props.userInvitedToCompany) {
      this.showModalInvitedUser(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeCompanyId && this.props.activeCompanyId !== prevProps.activeCompanyId) {
      if (prevProps.activeCompanyId) {
        this.wsUnsubscribeCompanyChannel(prevProps.activeCompanyId);
      }
      this.wsSubscribeCompanyChannel();

      this.props.getProjectsByCompanyId(this.props.activeCompanyId);
    }

    if (prevProps.activeCompanyId && !this.props.activeCompanyId) {
      this.wsUnsubscribeCompanyChannel(prevProps.activeCompanyId);
    }

    if (this.props.userInvitedToCompany && !prevProps.userInvitedToCompany) {
      this.showModalInvitedUser(true);
    }
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChanged();
    }
  }

  componentWillUnmount() {
    if (this.props.activeCompanyId) {
      this.wsUnsubscribeCompanyChannel(this.props.activeCompanyId);
    }
  }

  showCompanySwitcher = (show) => {
    if (show) {
      this.props.modalOpen();
    } else {
      this.props.modalClose();
    }
    this.setState({ isCompanySwitcherVisible: show });
  };

  showModalNewCompany = (show) => {
    const state = {
      isModalNewCompanyTypeVisible: show,
    };
    if (show) {
      state.isCompanySwitcherVisible = false;
      this.props.modalOpen();
    } else {
      this.props.modalClose();
    }

    this.setState(state);
  };

  showModalInvitedUser = (show) => {
    if (show) {
      this.props.modalOpen();
    } else {
      this.props.userInvitedTokenReset();
      this.props.userInvitedCompanyReset();
      this.props.modalClose();
    }
  };

  isLeftMenuShown = () => {
    const currentPathsWithoutLeftMenu = Object.values(pathLeftMenuHiddenRegExps).filter(
      (pathRegExp) => {
        return pathRegExp.test(this.props.history.location.pathname);
      }
    );
    return currentPathsWithoutLeftMenu.length === 0;
  };

  render() {
    const showLeftMenu = this.isLeftMenuShown();
    const { activeProject } = this.props;

    const contentClass = classNames('content', {
      'content-blur': this.props.modalOpened,
      'hide-left-menu': !showLeftMenu,
    });

    return this.props.appLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <AbilityContext.Provider value={defineAbilityFor(this.props.user, this.props.company)}>
        <div className="dashboard-container">
          {this.props.appFetchingData && <SpinnerLoading />}
          {showLeftMenu && (
            <div className="left-menu">
              <LeftMenuContainer
                onOpenCompanySwitcher={() => {
                  this.showCompanySwitcher(true);
                }}
                basePath={`${this.props.basePath}company/${this.props.activeCompanyId}/`}
              />
            </div>
          )}
          {this.state.isCompanySwitcherVisible && (
            <ModalCompanySwitch
              companies={this.props.userCompanies}
              activeCompanyId={this.props.activeCompanyId}
              onClose={() => {
                this.showCompanySwitcher(false);
              }}
              onCreateNewCompanyClick={() => {
                this.showModalNewCompany(true);
              }}
              onSwitchCompany={this.props.changeUserActiveCompanyId}
            />
          )}
          {this.state.isModalNewCompanyTypeVisible && (
            <ModalNewCompanyType
              basePath={this.props.basePath}
              onClose={() => {
                this.showModalNewCompany(false);
              }}
              onSubmit={() => {}}
            />
          )}
          {this.props.userInvitedToCompany && (
            <ModalUserInvited
              company={this.props.userInvitedToCompany}
              onClose={() => {
                this.showModalInvitedUser(false);
              }}
              onSubmit={() => {
                this.props.modalClose();
                this.props.userInvitedAddToCompany(
                  this.props.userInvitedToCompany._id,
                  this.props.userInvitedToken
                );
              }}
            />
          )}
          <div className={contentClass}>
            <Switch>
              <Route
                exact
                path="/dashboard"
                render={() => {
                  const path =
                    this.props.company.type === 'client' && this.props.activeProjectId
                      ? `/dashboard${getProjectPath(
                          this.props.activeCompanyId,
                          this.props.activeProjectId
                        )}/products`
                      : `/dashboard/company/${this.props.activeCompanyId}/quotes`;
                  return <Redirect to={path} />;
                }}
              />
              <Route path="/dashboard/choose-company" render={() => <BlackPageContainer />} />
              <Route path="/dashboard/account" render={() => <AccountContainer />} />
              <Route
                path="/dashboard/company/:companyId/quote*"
                render={() => <QuoteContainer />}
              />
              <Route
                path="/dashboard/company/:companyId/project"
                render={() => (
                  <Can I="select" a="Project">
                    <ProjectContainer project={activeProject} />
                  </Can>
                )}
              />
              <Route
                exact
                path="/dashboard/company/new"
                render={() => (
                  <Can I="create" a="Company">
                    <CreateNewCompanyContainer />
                  </Can>
                )}
              />
              <Route path="/dashboard/company/:companyId" render={() => <CompanyContainer />} />
            </Switch>
          </div>
        </div>
      </AbilityContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  userCompanies: state.account.companies,
  activeCompanyId: state.dashboard.activeCompanyId,
  company: state.dashboard.company,
  projects: state.dashboard.projects,
  activeProjectId: state.dashboard.activeProjectId,
  activeProject: activeProjectSelector(state),
  modalOpened: state.app.modalOpened,
  appLoading: state.app.loading,
  appFetchingData: state.app.fetchingData,
  isTabActive: state.app.isTabActive,
  // User invited by token
  userInvitedToken: state.sign.invited.token,
  userInvitedToCompany: state.sign.invited.toCompany,
  activeChatId: state.dashboard.chats.activeChatId,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: () => dispatch(authenticate()),
  getProjectsByCompanyId: (companyId) => dispatch(getProjectsByCompanyId(companyId)),
  changeUserActiveCompanyId: (companyId) =>
    dispatch(changeUserActiveCompanyIdAndRedirectDefault(companyId)),
  onProjectNewMessagesCountIncrement: (projectId, inc) =>
    dispatch(dashboardIncProjectsNewMessagesCountSuccess(projectId, inc)),
  onCompanyNewMessagesCountIncrement: (inc) => dispatch(dashboardIncCompanyNewMessagesCount(inc)),
  onRouteChanged: () => dispatch(dashboardOnRouteChange()),
  userInvitedTokenReset: () => dispatch(signupSetInvitedTokenSuccess(null)),
  userInvitedCompanyReset: () => dispatch(signupSetInvitedCompanySuccess(null)),
  userInvitedAddToCompany: (companyId, token) =>
    dispatch(dashboardUserInvitedAddToCompany(companyId, token)),
  modalOpen: () => dispatch(modalOpen()),
  modalClose: () => dispatch(modalClose()),
  setCompanyChannel: (channel) => dispatch(dashboardWsChannelCompanySetSuccess(channel)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardContainer)
);
