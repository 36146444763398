import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import IconLogo from '../../../component/icon/IconLogo';
import './style.css';
import { appSetRedirectProductId } from '../../../actions';
import { changeUserActiveCompanyIdAndRedirectDefault } from '../../dashboard/actions';

const CompanyChooseContainerStyled = styled.div`
  position: relative;
  width: 340px;
  min-height: 400px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0b0b0b, #000000);
  border: solid 1px #202020;
  margin-left: auto;
  margin-right: auto;
  padding: 45px 30px;
`;

class CompanyChooseContainer extends Component {
  state = {
    company: null,
  };

  componentDidMount = () => {
    const { companies, activeCompanyId } = this.props;
    let isSupplierActive = false;
    let countSuppliers = 0;

    for (let i = 0; i < companies.length; i++) {
      if (companies[i].type === 'supplier') {
        countSuppliers++;
        if (companies[i]._id === activeCompanyId) {
          isSupplierActive = true;
        }
      }
    }

    if (this.props.redirectProductId) {
      const productId = this.props.redirectProductId;
      let redirectCompanyId;

      if (countSuppliers === 1 && isSupplierActive) {
        redirectCompanyId = activeCompanyId;
      }
      if (companies.length === 1 && companies[0].type === 'supplier') {
        redirectCompanyId = companies[0]._id;
      }

      if (redirectCompanyId) {
        this.props.appResetRedirectProductId();
        this.props.history.push(
          `/dashboard/company/${redirectCompanyId}/quote/request/${productId}`
        );
      }
    }
  };

  onContinue = () => {
    if (this.props.redirectProductId) {
      const productId = this.props.redirectProductId;
      if (this.state.company.type === 'supplier') {
        this.props.appResetRedirectProductId();
        this.props.changeUserActiveCompanyId(
          this.state.company._id,
          `/dashboard/company/${this.state.company._id}/quote/request/${productId}`
        );
      }
    }
  };

  render() {
    return (
      <CompanyChooseContainerStyled className="company-choose-container">
        <div className="logo-container">
          <IconLogo width={44} height={44} />
        </div>
        <div className="form-header">Choose Company</div>
        <div className="form-description">
          Which company would you like to view this product for?
        </div>
        {this.props.companies.map((company, index) => (
          <div
            className={classNames('company-item', {
              selected: this.state.company && this.state.company._id === company._id,
            })}
            key={index}
            onClick={() => {
              this.setState({ company: company });
            }}
          >
            <div className={'letter-circle-container'}>{company.name.toUpperCase()[0]}</div>
            <span>
              <div>{company.name}</div>
              <div className={'description-uppercase-small'}>{company.type}</div>
            </span>
          </div>
        ))}
        <button
          className="btn"
          type="submit"
          disabled={this.state.company === null}
          onClick={this.onContinue}
        >
          Continue
        </button>
      </CompanyChooseContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.account.companies,
  activeCompanyId: state.dashboard.activeCompanyId,
  redirectProductId: state.app.redirectProductId,
});

const mapDispatchToProps = (dispatch) => ({
  appResetRedirectProductId: () => {
    dispatch(appSetRedirectProductId(null));
  },
  changeUserActiveCompanyId: (companyId, redirectPath) => {
    dispatch(changeUserActiveCompanyIdAndRedirectDefault(companyId, redirectPath));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyChooseContainer)
);
