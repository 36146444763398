export const SIGNUP_SUBMITTING = 'importist/sign/SIGNUP_SUBMITTING';
export const SIGNUP_EMAIL_SUCCESS = 'importist/sign/SIGNUP_EMAIL_SUCCESS';
export const SIGNUP_EMAIL_RESTORE = 'importist/sign/SIGNUP_EMAIL_RESTORE';
export const SIGNUP_PASSWORD_SUCCESS = 'importist/sign/SIGNUP_PASSWORD_SUCCESS';
export const SIGNUP_COMPANY_SUCCESS = 'importist/sign/SIGNUP_COMPANY_SUCCESS';
export const SIGNUP_SET_INVITED_TOKEN_SUCCESS = 'importist/sign/SIGNUP_SET_INVITED_TOKEN_SUCCESS';
export const SIGNUP_SET_INVITED_COMPANY_SUCCESS =
  'importist/sign/SIGNUP_SET_INVITED_COMPANY_SUCCESS';
export const SIGNUP_SUCCESS = 'importist/sign/SIGNUP_SUCCESS';
export const SIGNUP_AND_SEND_QUOTE_SUCCESS = 'importist/sign/SIGNUP_AND_SEND_QUOTE_SUCCESS';
export const LOGOUT_SUCCESS = 'importist/sign/LOGOUT_SUCCESS';
export const SIGN_IN_COMPLETE_USER_SUCCESS = 'importist/sign/SIGN_IN_COMPLETE_USER_SUCCESS';
