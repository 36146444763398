import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Can } from '../../../util/session';
import CompanyProfileView from '../../../component/company/CompanyProfileView';
import CompanyProfileClientEdit from '../../../component/company/CompanyProfileClientEdit';
import CompanyProfileSupplierEdit from '../../../component/company/CompanyProfileSupplierEdit';
import { companyUpdate } from '../actions';
import '../style.css';

class CompanyProfileContainer extends React.Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
  };

  onCompanyDataSubmit = (data, setSubmitting, setErrors) => {
    const redirectPath = `/dashboard/company/${this.props.company._id}/settings/company-profile`;
    this.props.companyUpdate(data, setSubmitting, setErrors, redirectPath);
  };

  onCompanyEditCancel = () => {
    const redirectPath = `/dashboard/company/${this.props.company._id}/settings/company-profile`;
    this.props.history.push(redirectPath);
  };

  onEditLinkClick = () => {
    const redirectPath = `/dashboard/company/${
      this.props.company._id
    }/settings/company-profile/edit`;
    this.props.history.push(redirectPath);
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/dashboard/company/:companyId/settings/company-profile/edit"
          render={() => {
            return this.props.company.type === 'client' ? (
              <CompanyProfileClientEdit
                company={this.props.company}
                onSubmit={this.onCompanyDataSubmit}
                onCancel={this.onCompanyEditCancel}
              />
            ) : (
              <CompanyProfileSupplierEdit
                company={this.props.company}
                onSubmit={this.onCompanyDataSubmit}
                onCancel={this.onCompanyEditCancel}
              />
            );
          }}
        />
        <Can I="manage" a="Company">
          <Route
            exact
            path="/dashboard/company/:companyId/settings/company-profile"
            render={() => (
              <CompanyProfileView company={this.props.company} onEditClick={this.onEditLinkClick} />
            )}
          />
        </Can>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.dashboard.company,
});

const mapDispatchToProps = (dispatch) => ({
  companyUpdate: (data, setSubmitting, setErrors, redirectPath) => {
    dispatch(companyUpdate(data, setSubmitting, setErrors, redirectPath));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyProfileContainer)
);
