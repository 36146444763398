import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Search from '../../../component/common/Search';
import ClientsTable from '../../../component/client/ClientsTable';
import Pagination from '../../../component/common/Pagination';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import IconCirclePlus from '../../../component/icon/IconCirclePlus';
import ModalInviteCompanies from '../../../component/company/ModalInviteCompanies';
import { modalClose, modalOpen } from '../../../actions';

export class ClientsListContainer extends Component {
  static propTypes = {
    clients: PropTypes.array,
    clientsCount: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    companyId: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
    onSwitchPage: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    modalInviteCompaniesOpened: false,
  };

  modalInviteCompaniesOpen = () => {
    this.props.modalOpen();
    this.setState({ modalInviteCompaniesOpened: true });
  };

  modalInviteCompaniesClose = () => {
    this.props.modalClose();
    this.setState({ modalInviteCompaniesOpened: false });
  };

  render() {
    const { clients, clientsCount, limit, companyId, projectId, history } = this.props;
    return !clients ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className="clients-table-container">
        <label className="page-header">Clients</label>
        <Search search={this.props.search} setSearch={this.props.setSearch} />
        <button className="btn white-button btn-top-right" onClick={this.modalInviteCompaniesOpen}>
          <div className={'icon'}>
            <IconCirclePlus width={19} height={19} color={'var(--common-text-color)'} />
          </div>
          <span className={'text'}>Invite Clients</span>
        </button>
        {this.state.modalInviteCompaniesOpened && (
          <ModalInviteCompanies companyType="supplier" onClose={this.modalInviteCompaniesClose} />
        )}
        <ClientsTable
          clients={clients}
          companyId={companyId}
          projectId={projectId}
          history={history}
        />
        {clientsCount > limit && (
          <Pagination
            pages={this.props.pages}
            page={this.props.page}
            onSwitchPage={this.props.onSwitchPage}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  modalOpen: () => dispatch(modalOpen()),
  modalClose: () => dispatch(modalClose()),
});

export default connect(
  null,
  mapDispatchToProps
)(ClientsListContainer);
