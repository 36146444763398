import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import { AbilityContext } from '../../util/session';
import { Can } from '../../util/session';
import AddProjectFormContainer from './AddProjectFormContainer';
import ProductsContainer from '../product';
import AddProductFormContainer from '../product/AddProductFormContainer';
import EditProductFormContainer from '../product/EditProductFormContainer';
import ProductQuotesContainer from '../quote/ProductQuotesContainer';
import ProjectSettingsContainer from './ProjectSettingsContainer';
import SuppliersContainer from '../supplier';
import './style.css';
import QuoteFullFormContainer from '../quote/QuoteFullFormContainer';

export class ProjectContainer extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  render() {
    const basePath = `/dashboard/company/${this.props.activeCompanyId}/project/`;

    return (
      <div className="project-container">
        <Switch>
          <Route path={`${basePath}add`} render={() => <AddProjectFormContainer />} />
          <Route
            path={`${basePath}:projectId/products/add`}
            render={() => <AddProductFormContainer />}
          />
          <Route
            exact
            path={`${basePath}:projectId/product/:productId`}
            render={() => <EditProductFormContainer />}
          />
          <Route
            exact
            path={`${basePath}:projectId/product/:productId/duplicate`}
            render={() => <AddProductFormContainer isDuplication={true} />}
          />
          <Route
            exact
            path={`${basePath}:projectId/product/:productId/full`}
            render={() => <QuoteFullFormContainer />}
          />
          <Route
            path={`${basePath}:projectId/products`}
            render={() => <ProductsContainer basePath={basePath} />}
          />
          <Route
            path={`${basePath}:projectId/settings`}
            render={() => (
              <Can I="edit" a="Project">
                <ProjectSettingsContainer basePath={basePath} />
              </Can>
            )}
          />
          <Route
            path={`${basePath}:projectId/product/:productId/quotes`}
            render={() => (
              <Can I="view" a="ProductQuotes">
                <ProductQuotesContainer />
              </Can>
            )}
          />
          <Route
            path={`${basePath}:projectId/suppliers`}
            render={() => (
              <SuppliersContainer
                basePath={`${basePath}:projectId/suppliers`}
                companyId={this.props.activeCompanyId}
                projectId={this.props.activeProjectId}
                history={this.props.history}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  activeCompanyId: state.dashboard.activeCompanyId,
  activeProjectId: state.dashboard.activeProjectId,
});

export default withRouter(
  connect(mapStateToProps)((props) => (
    <AbilityContext.Consumer>
      {(ability) => <ProjectContainer {...props} ability={ability} />}
    </AbilityContext.Consumer>
  ))
);
