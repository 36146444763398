import React from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SpinnerLoading from '../../common/SpinnerLoading';

export const Form = ({
  isChangePasswordMode,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
  onCancel,
  user,
}) => {
  const fullNameGroupClass = classNames('form-group col-sm-3', {
    'with-error': touched.fullName && errors.fullName,
  });

  return (
    <div>
      <label className="section-header">User Information</label>
      <form style={{ minHeight: '300px' }} onSubmit={handleSubmit}>
        {!isChangePasswordMode && (
          <div>
            <div className="row">
              <div className={fullNameGroupClass}>
                <label>Full Name</label>
                <Field className="form-control" name="fullName" />
                {touched.fullName && errors.fullName && (
                  <label className="error">{errors.fullName}</label>
                )}
              </div>
              <div className="form-group col-sm-3">
                <label>Position</label>
                <Field className="form-control" name="position" />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-3">
                <label>Email</label>
                <div>{user.email}</div>
              </div>
              <div className="form-group col-sm-3">
                <label>Telephone</label>
                <Field className="form-control" name="telephone" />
              </div>
            </div>
          </div>
        )}
        {isChangePasswordMode && (
          <div className="row">
            <div
              className={classNames('form-group col-sm-3', {
                'with-error': touched.password && errors.password,
              })}
            >
              <label>New Password</label>
              <Field className="form-control" type="password" name="password" />
              {touched.password && errors.password && (
                <label className="error">{errors.password}</label>
              )}
            </div>
            <div
              className={classNames('form-group col-sm-3', {
                'with-error': touched.confirmPassword && errors.confirmPassword,
              })}
            >
              <label>Confirm New Password</label>
              <Field className="form-control" type="password" name="confirmPassword" />
              {touched.confirmPassword && errors.confirmPassword && (
                <label className="error">{errors.confirmPassword}</label>
              )}
            </div>
            <input
              style={{ display: 'none', visibility: 'hidden' }}
              id="email"
              type="email"
              name="email"
              value={user.email}
              autoComplete="username email"
            />
          </div>
        )}
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className={'actions-bottom'}>
          <button
            onClick={onCancel}
            className="btn white-button"
            disabled={isSubmitting}
            type="button"
          >
            Cancel
          </button>
          <button className="btn blue-button" disabled={isSubmitting} type="submit">
            Save
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    </div>
  );
};

const editValidationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
});

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string().test('confirm-password-test', 'Passwords do not match', function(
    value
  ) {
    const { password } = this.parent;
    return !password || value === password;
  }),
});

const AccountMyProfileEditForm = withFormik({
  mapPropsToValues: (props) => {
    if (props.isChangePasswordMode) {
      return {
        _id: props.user._id,
        isChangePasswordMode: true,
        password: '',
        confirmPassword: '',
      };
    }

    return {
      _id: props.user._id,
      fullName: props.user.fullName,
      position: props.user.position,
      telephone: props.user.telephone,
    };
  },
  validate: (values) => {
    let errors = {};

    try {
      if (values.isChangePasswordMode) {
        passwordValidationSchema.validateSync(values);
      } else {
        editValidationSchema.validateSync(values);
      }
    } catch (err) {
      errors[err.path] = err.message;
    }

    return errors;
  },
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

AccountMyProfileEditForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isChangePasswordMode: PropTypes.bool,
};

export default AccountMyProfileEditForm;
