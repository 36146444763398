import { AbilityBuilder, Ability } from '@casl/ability';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

import api from '../service/api';

export function setJwtToken(token) {
  localStorage.setItem('jwtToken', token);
}

export function getJwtToken() {
  return localStorage.getItem('jwtToken');
}

export function sessionLogout() {
  localStorage.removeItem('jwtToken');
}

export function sessionSetLastCompanyId(companyId) {
  localStorage.setItem('lastCompanyId', companyId);
}

export function sessionGetLastCompanyId() {
  return localStorage.getItem('lastCompanyId');
}

export function sessionSetLastProjectId(companyId, projectId) {
  const key = `company.${companyId}`;
  let companyData = localStorage.getItem(key);
  if (companyData) {
    companyData = JSON.parse(companyData);
    companyData.lastProjectId = projectId;
  } else {
    companyData = {
      lastProjectId: projectId,
    };
  }
  localStorage.setItem(key, JSON.stringify(companyData));
}

export function sessionGetLastProjectId(companyId) {
  const key = `company.${companyId}`;
  let companyData = localStorage.getItem(key);
  if (companyData) {
    companyData = JSON.parse(companyData);
    return companyData.lastProjectId;
  }

  return null;
}

export function isAuthorized() {
  return typeof getJwtToken() === 'string';
}

export async function isTokenActive() {
  if (!isAuthorized()) {
    return Promise.reject(new Error('Not authorized'));
  }

  return api.userGet();
}

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export function defineAbilityFor(user, company) {
  const { rules, can } = AbilityBuilder.extract();

  if (!user || !company) {
    return new Ability(rules);
  }

  switch (company.type) {
    case 'client':
      can('select', 'Project');
      can('view', 'Products');
      can('view', 'ProductQuotes');
      can('view', 'Suppliers');
      can('view', 'Messages');
      break;
    case 'supplier':
      can('request', 'ProductQuote');
      can('edit', 'ProductQuote');
      can('view', 'Quotes');
      can('view', 'Clients');
      can('view', 'Messages');
      break;
    default:
  }

  let role = 'guest';
  user.companies.forEach((userCompany) => {
    if (userCompany.companyId === company._id) {
      // eslint-disable-next-line prefer-destructuring
      role = userCompany.role;
    }
  });

  switch (role) {
    case 'owner':
      can('manage', 'User');
      can('manage', 'Company');
      can('create', 'Company');
      can('transfer', 'CompanyOwnership');
      if (company.type === 'client') {
        can('add', 'Project');
        can('edit', 'Project');
        can('add', 'Product');
      }
      break;
    case 'admin':
      can('manage', 'User');
      can('manage', 'Company');
      can('create', 'Company');
      if (company.type === 'client') {
        can('add', 'Project');
        can('edit', 'Project');
        can('add', 'Product');
      }
      break;
    case 'project-manager':
      can('add', 'Product');
      break;
    case 'team-member':
      can('add', 'Product');
      break;
    default:
  }

  return new Ability(rules);
}
