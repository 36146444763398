import React from 'react';
import PropTypes from 'prop-types';

class IconShare extends React.Component {
  static defaultProps = {
    strokeColor: '#111',
  };

  static propTypes = {
    width: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
    strokeColor: PropTypes.string,
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={this.props.strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M15.5.5l-8 8M8.5.5h7v7M12.5 9.5v5a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h5" />
        </g>
      </svg>
    );
  }
}

export default IconShare;
