import {
  DASHBOARD_CHANGE_ACTIVE_COMPANY_ID_SUCCESS,
  DASHBOARD_CHANGE_ACTIVE_PROJECT_ID_SUCCESS,
  DASHBOARD_GET_COMPANIES_SUCCESS,
  DASHBOARD_GET_PRODUCTS_SUCCESS,
  DASHBOARD_GET_PROJECTS_SUCCESS,
  DASHBOARD_ADD_PROJECT_SUCCESS,
  DASHBOARD_LEFT_MENU_SET_ACTIVE_ITEM_SUCCESS,
  DASHBOARD_WS_CHANNEL_COMPANY_SET,
  DASHBOARD_EDIT_PROJECT_SUCCESS,
  DASHBOARD_SET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_RESET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_INC_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_REPLACE_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_ACTIVE_COMPANY_SET,
  DASHBOARD_SET_COMPANY_NEW_MESSAGES_COUNT_SUCCESS,
  DASHBOARD_INC_COMPANY_NEW_MESSAGES_COUNT_SUCCESS,
} from './constants';

export function dashboardGetUserCompanies(state = [], action) {
  switch (action.type) {
    case DASHBOARD_GET_COMPANIES_SUCCESS:
      return action.companies;
    default:
      return state;
  }
}

export function dashboardChangeProjects(state = [], action) {
  switch (action.type) {
    case DASHBOARD_GET_PROJECTS_SUCCESS:
      return action.projects;
    case DASHBOARD_ADD_PROJECT_SUCCESS:
      return [action.project, ...state];
    case DASHBOARD_EDIT_PROJECT_SUCCESS:
      return state.map((project) => {
        if (project._id === action.project._id) {
          return action.project;
        }

        return project;
      });
    default:
      return state;
  }
}

export function dashboardChangeActiveProjectIdSuccess(state = null, action) {
  switch (action.type) {
    case DASHBOARD_CHANGE_ACTIVE_PROJECT_ID_SUCCESS:
      return action.projectId;
    default:
      return state;
  }
}

export function dashboardChangeActiveCompanyId(state = null, action) {
  switch (action.type) {
    case DASHBOARD_CHANGE_ACTIVE_COMPANY_ID_SUCCESS:
      return action.companyId;
    default:
      return state;
  }
}

export function dashboardActiveCompany(state = {}, action) {
  switch (action.type) {
    case DASHBOARD_ACTIVE_COMPANY_SET:
      return action.company;
    default:
      return state;
  }
}

export function dashboardGetProductsSuccess(state = [], action) {
  switch (action.type) {
    case DASHBOARD_GET_PRODUCTS_SUCCESS:
      return action.products;
    default:
      return state;
  }
}

export function dashboardLeftMenuSetActiveItem(state = null, action) {
  switch (action.type) {
    case DASHBOARD_LEFT_MENU_SET_ACTIVE_ITEM_SUCCESS:
      return action.name;
    default:
      return state;
  }
}

export function dashboardWsChannelCompanySet(state = null, action) {
  switch (action.type) {
    case DASHBOARD_WS_CHANNEL_COMPANY_SET:
      return action.channel;
    default:
      return state;
  }
}

export function dashboardCompanyNewMessagesCount(state = 0, action) {
  switch (action.type) {
    case DASHBOARD_SET_COMPANY_NEW_MESSAGES_COUNT_SUCCESS:
      return action.count;
    case DASHBOARD_INC_COMPANY_NEW_MESSAGES_COUNT_SUCCESS:
      // This is not right
      // However, if application has some bug which makes counter negative, user never sees new notifications
      const sum = state + action.inc;
      return sum > 0 ? sum : 0;
    default:
      return state;
  }
}

export function dashboardProjectsNewMessagesCount(state = {}, action) {
  switch (action.type) {
    case DASHBOARD_SET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS:
      const newState = { ...state };
      newState[action.projectId] = action.count;
      return newState;
    case DASHBOARD_INC_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS:
      const newIncState = { ...state };
      // Update messages count only if user has access to project
      if (typeof newIncState[action.projectId] !== 'undefined') {
        const sum = newIncState[action.projectId] + action.inc;
        newIncState[action.projectId] = sum > 0 ? sum : 0;
      }
      return newIncState;
    case DASHBOARD_REPLACE_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS:
      return action.projectsMessagesCount;
    case DASHBOARD_RESET_PROJECTS_NEW_MESSAGES_COUNT_SUCCESS:
      return {};
    default:
      return state;
  }
}
