import React from 'react';
import PropTypes from 'prop-types';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import moment from '../../../util/moment';
import QuoteRange from '../../../component/quote/QuoteRange';
import { getQuoteRange } from '../../../util/quote';

class ProductDetails extends React.Component {
  static propTypes = {
    product: PropTypes.object,
    quote: PropTypes.object,
    onViewFullSpec: PropTypes.func,
  };

  render() {
    const { product, quote } = this.props;

    return (
      product !== null &&
      quote !== null && (
        <div className="product-description">
          <div className="col">
            <img
              className="product-photo"
              src={this.props.product.photosUrl[0] || imgPlaceholder}
              alt={''}
            />
          </div>
          <div className="col">
            <div className="name">{this.props.product.name}</div>
            <div className="date">{moment(this.props.product.createdAt).format()}</div>
          </div>
          <div className="col">
            {product.quotesRange && product.currency ? (
              <QuoteRange quoteRange={product.quotesRange} currency={product.currency} />
            ) : (
              <QuoteRange
                quoteRange={getQuoteRange(quote.requiredQuantity)}
                currency={quote.currency}
              />
            )}
          </div>
          <div className="col">
            <button onClick={this.props.onViewFullSpec} className="btn white-button" type="button">
              View Full Spec
            </button>
          </div>
        </div>
      )
    );
  }
}

export default ProductDetails;
