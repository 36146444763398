import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.css';

class StickyMenu extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    offsetTop: PropTypes.number,
  };

  static defaultProps = {
    offsetTop: 0,
  };

  state = {
    currentName: this.props.items[0] ? this.props.items[0].name : null,
  };

  itemIdPrefix = 'sticky-menu-item-';

  containerRef = React.createRef();

  handleScroll = () => {
    let currentName = null;
    let itemMaxOffsetTop = 0;
    const offsetTopMiddleWindow = window.scrollY + Math.floor(window.innerHeight / 2);

    this.props.items.forEach((item) => {
      if (
        offsetTopMiddleWindow > item.ref.current.offsetTop &&
        itemMaxOffsetTop < item.ref.current.offsetTop
      ) {
        currentName = item.name;
        itemMaxOffsetTop = item.ref.current.offsetTop;
      }
    });

    if (currentName === null) {
      currentName = this.props.items[0] ? this.props.items[0].name : null;
    }

    this.setState({ currentName });
  };

  onClick(event) {
    let id = event.target.id;
    id = id.replace(this.itemIdPrefix, '');
    window.scroll({
      top:
        this.props.items[id].ref.current.offsetTop -
        this.containerRef.current.offsetHeight -
        this.props.offsetTop,
      behavior: 'smooth',
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    return window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const menu = this.props.items.map((item, index) => {
      const className = classNames('item', {
        active: this.state.currentName === item.name,
      });
      return (
        <a
          className={className}
          key={item.name}
          id={`${this.itemIdPrefix}${index}`}
          onClick={this.onClick.bind(this)}
        >
          {item.label}
        </a>
      );
    });

    return (
      <div style={{ top: this.props.offsetTop }} ref={this.containerRef} className="sticky-menu">
        {menu}
      </div>
    );
  }
}

export default StickyMenu;
