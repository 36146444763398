import React from 'react';
import PropTypes from 'prop-types';

const IconCompany = (props) => {
  const viewBox = `0 0 ${props.width} ${props.height}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M0 0v29.333h13.313v-.114l-2.553-2.55H2.667V2.667h20v9.507l2.666 2.67V0H0zm5.333 5.333V8h5.334V5.333H5.333zm9.334 0V8H20V5.333h-5.333zm-9.334 5.334v2.666h5.334v-2.666H5.333zm9.334 0v2.666H20v-2.666h-5.333zm7.979 5.26l-2.76 2.76h-3.907v3.907l-2.76 2.76 2.76 2.76v3.907h3.906l2.76 2.76 2.761-2.76h3.907v-3.906l2.76-2.76-2.76-2.761v-3.907h-3.907l-2.76-2.76zM5.333 16v2.667h5.334V16H5.333zm17.313 3.698l1.656 1.656h2.344v2.344l1.656 1.656-1.656 1.656V29.354h-2.344l-1.656 1.656-1.656-1.656h-2.344V27.01l-1.656-1.656 1.656-1.656v-2.344h2.344l1.656-1.656zM5.333 21.333V24h5.334v-2.667H5.333z"
      />
    </svg>
  );
};

IconCompany.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconCompany;
