import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
}) => {
  const fullNameGroupClass = classNames('form-group', {
    'with-error': touched.fullName && errors.fullName,
  });
  const emailGroupClass = classNames('form-group', {
    'with-error': touched.email && errors.email,
  });

  return (
    <form onSubmit={handleSubmit}>
      {errors.common && <label className="error common">{errors.common}</label>}
      <div className={fullNameGroupClass}>
        <label>Full Name</label>
        <input
          className="form-control"
          type="text"
          name="fullName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.fullName}
        />
        {touched.fullName && errors.fullName && <label className="error">{errors.fullName}</label>}
      </div>
      <div className={emailGroupClass}>
        <label>Email Address</label>
        <input
          className="form-control"
          type="email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
        {touched.email && errors.email && <label className="error">{errors.email}</label>}
      </div>
      <button className="btn" disabled={isSubmitting} type="submit">
        Get Started
      </button>
    </form>
  );
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string()
    .email('Email Address is not valid')
    .required('Email Address is required'),
});

const SignUpFormEmail = withFormik({
  mapPropsToValues: (props) => ({
    fullName: props.user.fullName,
    email: props.user.email,
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    const user = {
      fullName: values.fullName,
      email: values.email,
    };
    setSubmitting(true);
    // Errors set by setErrors from action if action rejected
    props.onSubmit(user, setSubmitting, setErrors);
  },
})(Form);

export default SignUpFormEmail;
