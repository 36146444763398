import React from 'react';
import styled from 'styled-components';

const MarketsContainerStyled = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const PhotoContainerStyled = styled.div`
  margin-right: 30px;
  display: inline-block;
`;

const DivMarginBottom10 = styled.div`
  margin-bottom: 10px;
`;

const CompanyProfileSupplier = (props) => {
  const { supplier } = props;

  return supplier ? (
    <div>
      <label className="section-header">Supplier Information</label>
      {supplier.products && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Products</label>
            <div className="form-value">{supplier.products}</div>
          </div>
        </DivMarginBottom10>
      )}
      {supplier.markets.length > 0 && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Markets</label>
            <MarketsContainerStyled>
              {supplier.markets.map((market) => {
                return (
                  <span key={market} className="label-block-blue">
                    {market}
                  </span>
                );
              })}
            </MarketsContainerStyled>
          </div>
        </DivMarginBottom10>
      )}
      {supplier.customers && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Customers</label>
            <div className="form-value">{supplier.customers}</div>
          </div>
        </DivMarginBottom10>
      )}
      {supplier.photosUrl.length > 0 && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Photos</label>
          </div>
          <div className="form-group col-sm-12">
            {supplier.photosUrl.map((photoUrl, index) => {
              return (
                <PhotoContainerStyled key={index}>
                  <img width={125} height={125} alt="" src={photoUrl} />
                </PhotoContainerStyled>
              );
            })}
          </div>
        </DivMarginBottom10>
      )}
      {supplier.machineList && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Machine List</label>
            <div className="form-value">{supplier.machineList}</div>
          </div>
        </DivMarginBottom10>
      )}
      {supplier.businessTerms && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Business Terms</label>
            <div className="form-value">{supplier.businessTerms}</div>
          </div>
        </DivMarginBottom10>
      )}
      <div className="row">
        {supplier.rdStaff && (
          <div className="form-group col-sm-6">
            <label>R&D Staff</label>
            <div className="form-value">{supplier.rdStaff}</div>
          </div>
        )}
        {supplier.qcStaff && (
          <div className="form-group col-sm-6">
            <label>QC Staff</label>
            <div className="form-value">{supplier.qcStaff}</div>
          </div>
        )}
      </div>
      <div className="row">
        {supplier.salesStaff && (
          <div className="form-group col-sm-6">
            <label>Sales Staff</label>
            <div className="form-value">{supplier.salesStaff}</div>
          </div>
        )}
        {supplier.operationsStaff && (
          <div className="form-group col-sm-6">
            <label>Operations Staff</label>
            <div className="form-value">{supplier.operationsStaff}</div>
          </div>
        )}
      </div>
      <div className="row">
        {supplier.otherStaff && (
          <div className="form-group col-sm-6">
            <label>Other Staff</label>
            <div className="form-value">{supplier.otherStaff}</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    ''
  );
};

export default CompanyProfileSupplier;
