import React from 'react';
import axios, { CancelToken } from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { push } from 'connected-react-router';

import { signUpAndSendQuoteA } from '../../sign/actions';
import QuoteFormSignupA from '../../../component/quote/QuoteFormSignupA';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import api from '../../../service/api';
import IconLandingLogo from '../../../component/icon/IconLandingLogo';

export class QuoteRequestSignUpFormAContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      product: {},
      cancelSourceFetchData: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    try {
      const productId = this.props.match.params.productId;
      const res = await api.quoteRequestProductGet(
        productId,
        this.state.cancelSourceFetchData.token
      );

      this.setState({
        product: res.data,
        isLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    this.state.cancelSourceFetchData.cancel();
  }

  render() {
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className="quote-form-container landing-quote-form-container">
        <IconLandingLogo width={160} height={23} />

        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : (
          <QuoteFormSignupA
            product={this.state.product}
            company={this.state.product.clientCompany}
            userId={this.props.user._id}
            userCompanyId={this.props.activeCompanyId}
            onSubmit={this.props.signUpAndSendQuoteOnSubmit}
            signupAndSendQuoteDone={this.props.signupAndSendQuoteDone}
            signUpAndSendQuoteOnFinished={this.props.signUpAndSendQuoteOnFinished}
            t={this.props.t}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  signupAndSendQuoteDone: state.sign.signupAndSendQuoteDone,
  activeCompanyId: state.dashboard.activeCompanyId,
});

const mapDispatchToProps = (dispatch) => ({
  signUpAndSendQuoteOnSubmit: (data, setSubmitting, setErrors) =>
    dispatch(signUpAndSendQuoteA(data, setSubmitting, setErrors)),
  signUpAndSendQuoteOnFinished: () => {
    dispatch(push('/signup-complete'));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNamespaces()(QuoteRequestSignUpFormAContainer))
);
