import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class FormSectionUser extends React.Component {
  static defaultProps = {
    emailDisabled: true,
  };

  static propTypes = {
    values: PropTypes.object.isRequired,
    fieldsNamePrefix: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    emailDisabled: PropTypes.bool,
  };

  render() {
    const touched = this.props.touched || {};
    const errors = this.props.errors || {};

    const fieldNameFullName = `${this.props.fieldsNamePrefix}fullName`;
    const fullNameGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.fullName && errors.fullName,
    });
    const fieldNameEmail = `${this.props.fieldsNamePrefix}email`;
    const emailGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.fullName && errors.fullName,
    });
    const fieldNamePosition = `${this.props.fieldsNamePrefix}position`;

    const fieldNameTelephone = `${this.props.fieldsNamePrefix}telephone`;

    return (
      <fieldset className="fieldset-last">
        <div className="row">
          <div className={fullNameGroupClass}>
            <label>
              Full Name<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameFullName}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameFullName, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameFullName, true);
              }}
              value={this.props.values.fullName}
            />
            {touched.fullName && errors.fullName && (
              <label className="error">{errors.fullName}</label>
            )}
          </div>
          <div className="form-group col-sm-6">
            <label>Position</label>
            <input
              className="form-control"
              type="text"
              name={fieldNamePosition}
              onChange={(event) => {
                this.props.setFieldValue(fieldNamePosition, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNamePosition, true);
              }}
              value={this.props.values.position}
            />
          </div>
        </div>
        <div className="row">
          <div className={emailGroupClass}>
            <label>
              Email<sup> *</sup>
            </label>
            <input
              className="form-control"
              type="text"
              name={fieldNameEmail}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameEmail, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameEmail, true);
              }}
              value={this.props.values.email}
              disabled={this.props.emailDisabled}
            />
            {touched.email && errors.email && <label className="error">{errors.email}</label>}
          </div>
          <div className="form-group col-sm-6">
            <label>Telephone</label>
            <input
              className="form-control"
              type="text"
              name={fieldNameTelephone}
              onChange={(event) => {
                this.props.setFieldValue(fieldNameTelephone, event.target.value);
              }}
              onBlur={() => {
                this.props.setFieldTouched(fieldNameTelephone, true);
              }}
              value={this.props.values.telephone}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}

export const FormSectionUserValidationScheme = {
  fullName: Yup.string().required('Full Name is required'),
};

export default FormSectionUser;
