import { combineReducers } from 'redux';
import {
  signupSubmitting,
  signupSuccess,
  signInCompleteUserSuccess,
  signupSetInvitedToken,
  signupSetInvitedCompany,
} from './container/sign/reducers';
import {
  APP_FETCHING_DATA,
  APP_IS_TAB_ACTIVE,
  APP_LOADING,
  APP_MODAL_CLOSE,
  APP_MODAL_OPEN,
  APP_SET_REDIRECT_PRODUCT_ID,
} from './constants';
import { getCompanyUsersSuccess } from './container/dashboard/AccountContainer/reducers';
import { signupCompleteSuccess, signupAndSendQuoteSuccess } from './container/sign/reducers';
import {
  dashboardChangeActiveCompanyId,
  dashboardChangeActiveProjectIdSuccess,
  dashboardGetProductsSuccess,
  dashboardChangeProjects,
  dashboardGetUserCompanies,
  dashboardLeftMenuSetActiveItem,
  dashboardWsChannelCompanySet,
  dashboardProjectsNewMessagesCount,
  dashboardActiveCompany,
  dashboardCompanyNewMessagesCount,
} from './container/dashboard/reducers';
import { companyCreateSelectType } from './container/company/reducers';
import { productCreateNew } from './container/product/reducers';
import { LOGOUT_SUCCESS } from './container/sign/constants';
import {
  chatCompanyChats,
  chatCompanySetLoading,
  chatCurrentMessagesSetLoading,
  chatCurrentMessagesChange,
  chatIdActive,
} from './container/chat/reducers';
import { userAfterLogout } from './util/user';

function modalStateChange(state = false, action) {
  switch (action.type) {
    case APP_MODAL_OPEN:
    case APP_MODAL_CLOSE:
      return action.openModal;
    default:
      return state;
  }
}

function appLoading(state = true, action) {
  switch (action.type) {
    case APP_LOADING:
      return action.loading;
    default:
      return state;
  }
}

function appFetchingData(state = false, action) {
  switch (action.type) {
    case APP_FETCHING_DATA:
      return action.fetchingData;
    default:
      return state;
  }
}

function appSetRedirectProductId(state = null, action) {
  switch (action.type) {
    case APP_SET_REDIRECT_PRODUCT_ID:
      return action.productId;
    default:
      return state;
  }
}

function appIsTabActive(state = true, action) {
  switch (action.type) {
    case APP_IS_TAB_ACTIVE:
      return action.is;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  sign: combineReducers({
    signupDone: signupCompleteSuccess,
    signupAndSendQuoteDone: signupAndSendQuoteSuccess,
    isSubmitting: signupSubmitting,
    user: signupSuccess,
    invited: combineReducers({
      token: signupSetInvitedToken,
      toCompany: signupSetInvitedCompany,
    }),
  }),
  account: combineReducers({
    user: signInCompleteUserSuccess,
    companies: dashboardGetUserCompanies,
  }),
  dashboard: combineReducers({
    leftMenu: combineReducers({
      activeItem: dashboardLeftMenuSetActiveItem,
    }),
    activeCompanyId: dashboardChangeActiveCompanyId,
    company: dashboardActiveCompany, // Active company should be in sync with activeCompanyId
    companyNewMessagesCount: dashboardCompanyNewMessagesCount,
    companyUsers: getCompanyUsersSuccess,
    projects: dashboardChangeProjects,
    projectsNewMessagesCount: dashboardProjectsNewMessagesCount,
    activeProjectId: dashboardChangeActiveProjectIdSuccess,
    products: dashboardGetProductsSuccess,
    lastChangedProduct: productCreateNew,
    chats: combineReducers({
      data: chatCompanyChats,
      loading: chatCompanySetLoading,
      currentMessages: combineReducers({
        data: chatCurrentMessagesChange,
        loading: chatCurrentMessagesSetLoading,
      }),
      activeChatId: chatIdActive,
    }),
    ws: combineReducers({
      companyChannel: dashboardWsChannelCompanySet,
    }),
  }),
  newCompany: combineReducers({
    type: companyCreateSelectType,
  }),
  app: combineReducers({
    modalOpened: modalStateChange,
    loading: appLoading,
    fetchingData: appFetchingData,
    redirectProductId: appSetRedirectProductId,
    isTabActive: appIsTabActive,
  }),
});

export default (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    userAfterLogout();
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};
