import React from 'react';
import Popup from 'reactjs-popup';
import Modal from '../../common/Modal/index';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalOwnerTransferConfirm from './ModalOwnerTransferConfirm';

class ModalEditUserRole extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    loggedInUserRole: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      role: props.user.role,
      modalOwnerTransferConfirmOpened: false,
    };
  }

  onChangeRole(role) {
    this.setState({ role });
  }

  modalOwnerTransferConfirmOpen = () => {
    this.setState({ modalOwnerTransferConfirmOpened: true });
  };

  onUserRoleSave = (userData) => {
    if (userData.role === 'owner') {
      this.modalOwnerTransferConfirmOpen();
    } else {
      this.props.onSave(userData);
    }
  };

  modalOwnerTransferConfirmClose = () => {
    this.setState({ modalOwnerTransferConfirmOpened: false });
  };

  render() {
    const { user } = this.props;

    return (
      <Modal>
        <Popup
          className="popup-edit-user-role"
          open={true}
          onClose={this.props.onClose}
          modal
          contentStyle={{ padding: '50px' }}
        >
          <div>
            <label className="form-header">
              Edit <span className="blue-color">{user.fullName}'s</span> Role
            </label>
            <div className="row section-header">
              <div className="form-group col-sm-3">
                <label>Team Member</label>
              </div>
              <div className="form-group col-sm-3">
                <label>Project Manager</label>
              </div>
              <div className="form-group col-sm-3">
                <label>Administrator</label>
              </div>
              <div
                style={this.props.loggedInUserRole !== 'owner' ? { opacity: 0.3 } : {}}
                className="form-group col-sm-3"
              >
                <label>Owner</label>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-3">
                <div>
                  Team members can create product specifications, access quotes, and messaging.
                </div>
              </div>
              <div className="form-group col-sm-3">
                <div>
                  Project managers have all team member permissions, but can also create and manage
                  projects.
                </div>
              </div>
              <div className="form-group col-sm-3">
                <div>
                  Administrators can edit the company profile and assign permissions. Additional
                  permissions are customizable in the field below.
                </div>
              </div>
              <div
                style={this.props.loggedInUserRole !== 'owner' ? { opacity: 0.3 } : {}}
                className="form-group col-sm-3"
              >
                <div>
                  There can only be one owner of the project - only they have the ability to delete
                  the company profile
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-3">
                <button
                  onClick={() => this.onChangeRole('team-member')}
                  type={'button'}
                  className={classNames('btn border-x2', {
                    'blue-white-button': this.state.role !== 'team-member',
                    'blue-button': this.state.role === 'team-member',
                  })}
                >
                  {this.state.role === 'team-member' ? 'CURRENT ROLE' : 'CHANGE ROLE'}
                </button>
              </div>
              <div className="form-group col-sm-3">
                <button
                  onClick={() => this.onChangeRole('project-manager')}
                  type={'button'}
                  className={classNames('btn border-x2', {
                    'blue-white-button': this.state.role !== 'project-manager',
                    'blue-button': this.state.role === 'project-manager',
                  })}
                >
                  {this.state.role === 'project-manager' ? 'CURRENT ROLE' : 'CHANGE ROLE'}
                </button>
              </div>
              <div className="form-group col-sm-3">
                <button
                  onClick={() => this.onChangeRole('admin')}
                  type={'button'}
                  className={classNames('btn border-x2', {
                    'blue-white-button': this.state.role !== 'admin',
                    'blue-button': this.state.role === 'admin',
                  })}
                >
                  {this.state.role === 'admin' ? 'CURRENT ROLE' : 'CHANGE ROLE'}
                </button>
              </div>
              <div
                style={this.props.loggedInUserRole !== 'owner' ? { opacity: 0.3 } : {}}
                className="form-group col-sm-3"
              >
                <button
                  type={'button'}
                  className={classNames('btn', {
                    'disabled-button border-x2': this.props.loggedInUserRole !== 'owner',
                    'red-white-button border-x2': this.state.role !== 'owner',
                    'blue-button border-x2': this.state.role === 'owner',
                  })}
                  onClick={() => this.onChangeRole('owner')}
                  disabled={this.props.loggedInUserRole !== 'owner'}
                >
                  TRANSFER OWNERSHIP
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <button
                  onClick={() => {
                    this.onUserRoleSave({ _id: this.props.user._id, role: this.state.role });
                  }}
                  type={'submit'}
                  className={'btn blue-button'}
                  disabled={this.state.role === user.role}
                >
                  Save
                </button>
              </div>
            </div>
            {this.state.modalOwnerTransferConfirmOpened && (
              <ModalOwnerTransferConfirm
                userPayload={{ _id: this.props.user._id, role: this.state.role }}
                onSave={this.props.onSave}
                onClose={this.modalOwnerTransferConfirmClose}
              />
            )}
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalEditUserRole;
