import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconCompany from '../../icon/IconCompany';
import IconFactory from '../../icon/IconFactory';

const Form = ({
  user,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
}) => {
  const passwordGroupClass = classNames('form-group', {
    'with-error': touched.password && errors.password,
  });
  const confirmPasswordGroupClass = classNames('form-group', {
    'with-error': touched.confirmPassword && errors.confirmPassword,
  });
  const userTypeColumnClientClass = classNames('user-type-column', {
    selected: values.signupType === 'client',
  });
  const userTypeColumnSupplierClass = classNames('user-type-column', {
    selected: values.signupType === 'supplier',
  });

  return (
    <form onSubmit={handleSubmit}>
      {errors.common && <label className="error common">{errors.common}</label>}
      <div className={passwordGroupClass}>
        <label>Password</label>
        <input
          className="form-control"
          id="password"
          type="password"
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          autoComplete="new-password"
        />
        {touched.password && errors.password && <label className="error">{errors.password}</label>}
        <input
          style={{ display: 'none', visibility: 'hidden' }}
          id="email"
          type="email"
          name="email"
          value={user.email}
          autoComplete="username email"
        />
      </div>
      <div className={confirmPasswordGroupClass}>
        <label>Confirm Password</label>
        <input
          className="form-control"
          type="password"
          name="confirmPassword"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
        />
        {touched.confirmPassword && errors.confirmPassword && (
          <label className="error">{errors.confirmPassword}</label>
        )}
      </div>
      <div className="form-group user-type">
        <div
          className={userTypeColumnClientClass}
          onClick={() => setFieldValue('signupType', 'client')}
        >
          <div className="user-type-column-icon">
            <IconCompany
              width={33}
              height={35}
              color={values.signupType === 'client' ? '#0095ff' : '#fff'}
            />
          </div>
          <label>I’m looking for suppliers</label>
          <button className="btn" id="button-client" type="button">
            Client
          </button>
        </div>
        <div
          className={userTypeColumnSupplierClass}
          onClick={() => setFieldValue('signupType', 'supplier')}
        >
          <div className="user-type-column-icon">
            <IconFactory
              width={37}
              height={36}
              color={values.signupType === 'supplier' ? '#0095ff' : '#fff'}
            />
          </div>
          <label>I’m a supplier looking for clients</label>
          <button className="btn" id="button-supplier" type="button">
            Supplier
          </button>
        </div>
      </div>
      <button className="btn" disabled={isSubmitting} type="submit">
        Create Account
      </button>
    </form>
  );
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .test('confirm-password-test', 'Passwords do not match', function(value) {
      const { password } = this.parent;
      return value === password;
    }),
  signupType: Yup.string().required('Choose Client or Supplier'),
});

const SignUpFormPassword = withFormik({
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
    signupType: '',
  }),
  validationSchema,
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    const data = {
      email: props.user.email,
      fullName: props.user.fullName,
      password: values.password,
      signupType: values.signupType,
    };
    setSubmitting(true);
    // Errors set by setErrors from action if action rejected
    props.onSubmit(data, setSubmitting, setErrors);
  },
})(Form);

SignUpFormPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
  }),
};

export default SignUpFormPassword;
