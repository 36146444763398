import React from 'react';
import PropTypes from 'prop-types';

const IconSendMessage = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="1.5">
        <path d="M15 6.878L9 1 3 6.878M18 18.633H0M9 15.694V1" />
      </g>
    </svg>
  );
};

IconSendMessage.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconSendMessage;
