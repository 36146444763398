import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';

import CompanyChooseContainer from '../../company/CompanyChooseContainer';
import './style.css';

const BlackPageContainerStyled = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 51% 2%, #080808, #000000);
  display: flex;
  align-items: center;
`;

class BlackPageContainer extends Component {
  render() {
    return (
      <BlackPageContainerStyled className="black-page-container">
        <Switch>
          <Route path="/dashboard/choose-company" render={() => <CompanyChooseContainer />} />
        </Switch>
      </BlackPageContainerStyled>
    );
  }
}

export default BlackPageContainer;
