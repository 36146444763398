import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';
import styled from 'styled-components';

import IconCirclePlus from '../../icon/IconCirclePlus';
import { Can } from '../../../util/session';
import IconBox from '../../icon/IconBox';
import IconQuote from '../../icon/IconQuote';
import IconSuppliers from '../../icon/IconSuppliers';
import IconClients from '../../icon/IconClients';
import IconMessages from '../../icon/IconMessages';
import IconSettings from '../../icon/IconSettings';
import LeftMenuProjects from '../LeftMenuProjects';

const StyledRightDiv = styled.div`
  position: absolute;
  right: 15px;
`;

const LeftMenuItem = (props) => {
  const {
    itemKey,
    isActive,
    pathSuffix,
    icon,
    label,
    basePath,
    onActiveItemChange,
    historyPush,
    counterNewMessages,
  } = props;

  const itemClassName = classNames('menu-item', {
    active: isActive,
  });

  return (
    <div
      className={itemClassName}
      onClick={() => {
        onActiveItemChange(itemKey);
        historyPush(`${basePath}${pathSuffix}`);
      }}
    >
      <span>{icon}</span>
      <span className={'text'}>{label}</span>
      {counterNewMessages > 0 && (
        <span className="counter-new-messages">
          {counterNewMessages > 99 ? '99+' : counterNewMessages}
        </span>
      )}
    </div>
  );
};

class LeftMenu extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    companyNewMessagesCount: PropTypes.number,
    projects: PropTypes.array.isRequired,
    projectsNewMessagesCount: PropTypes.object,
    activeItem: PropTypes.any,
    activeProjectId: PropTypes.any,
    onActiveItemChange: PropTypes.func.isRequired,
    onActiveProjectChange: PropTypes.func.isRequired,
    onOpenCompanySwitcher: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  render() {
    const isChatOn = process.env.REACT_APP_CHAT_DISPLAY;

    const { projects } = this.props;
    let activeProjectLabel = null;
    for (let i = 0; i < projects.length; i++) {
      if (projects[i]._id === this.props.activeProjectId) {
        activeProjectLabel = projects[i].name;
        break;
      }
    }

    let allMessages = [];

    // All Messages item
    if (isChatOn && this.props.ability.can('view', 'Messages')) {
      const itemKey = 'messages';
      const isActive = this.props.activeItem === itemKey;
      allMessages.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={itemKey}
          icon={
            <IconMessages
              width={16}
              height={16}
              color={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={this.props.company.type === 'client' ? 'All Messages' : 'Messages'}
          basePath={this.props.basePath}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
          counterNewMessages={
            this.props.company.type === 'supplier' ? this.props.companyNewMessagesCount : 0
          }
        />
      );
    }

    let mainMenuItems = [];

    // My Products item
    if (this.props.ability.can('view', 'Products')) {
      const itemKey = 'products';
      const isActive = this.props.activeItem === itemKey;

      mainMenuItems.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={itemKey}
          icon={
            <IconBox
              width={19}
              height={19}
              color={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={'My Products'}
          basePath={`${this.props.basePath}project/${this.props.activeProjectId}/`}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
        />
      );
    }

    // Suppliers item
    if (this.props.ability.can('view', 'Suppliers')) {
      const itemKey = 'suppliers';
      const isActive = this.props.activeItem === itemKey;

      mainMenuItems.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={itemKey}
          icon={
            <IconSuppliers
              width={16}
              height={15}
              color={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={'Suppliers'}
          basePath={`${this.props.basePath}project/${this.props.activeProjectId}/`}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
        />
      );
    }

    // Messages item
    if (
      isChatOn &&
      this.props.company.type === 'client' &&
      this.props.ability.can('view', 'Messages')
    ) {
      const itemKey = 'project-messages';
      const isActive = this.props.activeItem === itemKey;

      mainMenuItems.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={''}
          icon={
            <IconMessages
              width={16}
              height={16}
              color={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={'Messages'}
          basePath={`${this.props.basePath}messages/project/${this.props.activeProjectId}`}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
          counterNewMessages={this.props.projectsNewMessagesCount[this.props.activeProjectId]}
        />
      );
    }

    // Project settings
    if (this.props.ability.can('edit', 'Project')) {
      const itemKey = 'project-settings';
      const isActive = this.props.activeItem === itemKey;

      mainMenuItems.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={'settings/info'}
          icon={
            <IconSettings
              width={17}
              height={17}
              color={isActive ? 'white' : 'var(--common-text-color)'}
              fill={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={'Project Settings'}
          basePath={`${this.props.basePath}project/${this.props.activeProjectId}/`}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
        />
      );
    }

    // Supplier menu
    let supplierMenuItems = [];

    // Quotes
    if (this.props.ability.can('view', 'Quotes')) {
      const itemKey = 'quotes';
      const isActive = this.props.activeItem === itemKey;

      supplierMenuItems.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={itemKey}
          icon={
            <IconQuote
              width={14}
              height={17}
              color={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={'Quotes'}
          basePath={this.props.basePath}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
        />
      );
    }

    // Clients
    if (this.props.ability.can('view', 'Clients')) {
      const itemKey = 'clients';
      const isActive = this.props.activeItem === itemKey;

      supplierMenuItems.push(
        <LeftMenuItem
          key={itemKey}
          itemKey={itemKey}
          isActive={isActive}
          pathSuffix={itemKey}
          icon={
            <IconClients
              width={16}
              height={17}
              color={isActive ? 'white' : 'var(--common-text-color)'}
            />
          }
          label={'Clients'}
          basePath={this.props.basePath}
          onActiveItemChange={this.props.onActiveItemChange}
          historyPush={this.props.history.push}
        />
      );
    }

    let projectsCountNewMessages = 0;
    Object.values(this.props.projectsNewMessagesCount).forEach(
      (n) => (projectsCountNewMessages += n)
    );

    return (
      <div>
        <Can I="view" a="Messages">
          <div className={'menu-main-section'}>{allMessages}</div>
        </Can>
        <Can I="select" a="Project">
          <Popup
            trigger={
              <div className="menu-item projects">
                {activeProjectLabel ? activeProjectLabel : 'Projects'}
                {projectsCountNewMessages > 0 ? (
                  <div className="indicator-new-messages" />
                ) : (
                  <div className={'triangle-right'} />
                )}
              </div>
            }
            position="right top"
            on="hover"
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: '0px', border: 'none', width: '275px' }}
            arrow={false}
          >
            <div className="sub-menu">
              <LeftMenuProjects
                projects={this.props.projects}
                projectsNewMessagesCount={this.props.projectsNewMessagesCount}
                onActiveProjectChange={this.props.onActiveProjectChange}
              />
              <Can I="add" a="Project">
                <div
                  className="menu-item add-new-project"
                  onClick={() => {
                    this.props.onActiveItemChange('project/add');
                    this.props.history.push(`${this.props.basePath}project/add`);
                  }}
                >
                  <IconCirclePlus width={17} height={17} color={'white'} />
                  <label>Add New Project</label>
                </div>
              </Can>
            </div>
          </Popup>
        </Can>
        {this.props.activeProjectId && (
          <div className={'menu-main-section'}>
            <Can I="select" a="Project">
              <div className={'menu-main-section-header'}>VIEW SELECTED</div>
            </Can>
            {mainMenuItems}
          </div>
        )}
        <Can I="view" a="Quotes">
          <div className={'menu-main-section'}>{supplierMenuItems}</div>
        </Can>
        <Popup
          trigger={
            <div className="menu-item account">
              <div className={'letter-circle-container'}>
                {this.props.user.fullName.toUpperCase()[0]}
              </div>
              <span>{this.props.user.fullName}</span>
              <StyledRightDiv className={'triangle-top'} />
            </div>
          }
          position="top center"
          on="hover"
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{
            padding: '0',
            border: 'none',
            backgroundColor: '#262626',
            borderRadius: '4px',
            width: '220px',
          }}
          offsetY={5}
          arrow={false}
        >
          {this.props.company._id && (
            <div className="sub-menu account">
              <div className={'sub-menu-items-container'}>
                <div className={'menu-item'} onClick={this.props.onOpenCompanySwitcher}>
                  <div className={'letter-circle-container'}>
                    {this.props.company.name.toUpperCase()[0]}
                  </div>
                  <span>
                    <div>{this.props.company.name}</div>
                    <div className={'description-uppercase-small'}>{this.props.company.type}</div>
                  </span>
                  <StyledRightDiv className={'triangle-right'} />
                </div>
                <Can I="manage" a="Company">
                  <div
                    className={'menu-item'}
                    onClick={() => {
                      this.props.onActiveItemChange('settings/company-profile');
                      this.props.history.push(`${this.props.basePath}settings/company-profile`);
                    }}
                  >
                    Company Settings
                  </div>
                </Can>
                <div
                  className={'menu-item'}
                  onClick={() => {
                    this.props.onActiveItemChange('account');
                    this.props.history.push(`/dashboard/account`);
                  }}
                >
                  My Importist Account
                </div>
                <div
                  className={'menu-item'}
                  onClick={() => {
                    this.props.onLogout();
                    this.props.history.push(`/signin`);
                  }}
                >
                  Logout
                </div>
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  }
}

export default LeftMenu;
