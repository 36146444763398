import React from 'react';
import PropTypes from 'prop-types';

class IconEmail extends React.Component {
  static defaultProps = {
    strokeColor: '#4D4D4D',
  };

  static propTypes = {
    width: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
    strokeColor: PropTypes.string,
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 17 14"
      >
        <g fill="none" fillRule="evenodd" stroke={this.props.strokeColor}>
          <path d="M1 1l7.333 6.667L15.667 1" />
          <path strokeLinecap="square" d="M1 1h14.667v12H1z" />
        </g>
      </svg>
    );
  }
}

export default IconEmail;
