import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 44 44"
    >
      <defs>
        <linearGradient id="icon-logo-gradient" x1="100%" x2="0%" y1="42.45%" y2="92.156%">
          <stop offset="0%" stopColor="#00C7FF" />
          <stop offset="100%" stopColor="#0095FF" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="44" height="44" fill="url(#icon-logo-gradient)" rx="6" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M29 11.917c0-.506.447-.917 1-.917.553 0 1 .41 1 .917v20.166c0 .506-.447.917-1 .917-.553 0-1-.41-1-.917V11.917zM13.667 21.778L11 15.556h6.222v-2.667a.89.89 0 0 1 .89-.889h8a.89.89 0 0 1 .888.889v10.667a.89.89 0 0 1-.889.888H19.89l4.392 2.928a.879.879 0 0 1-.837.628H11l2.667-6.222z" />
        </g>
      </g>
    </svg>
  );
};

IconLogo.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};

export default IconLogo;
