import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';
import { displayMoney } from '../../../util/currency';
import { debouncedAfterChangeTargetRequiredQuantity } from '../QuoteForm/helper';

const CheckboxStyled = styled.input`
  display: inline-flex;
`;

const CheckboxLabelStyled = styled.label`
  margin-left: 7px;
`;

class QuoteFormSignupDStep1 extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onChangeFormStep: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      product,
      values,
      errors,
      setValues,
      touched,
      setFieldTouched,
      setFieldValue,
      readOnly,
      t,
    } = this.props;

    function isFieldValid(field) {
      return (!!values[field] || touched[field]) && !errors[field];
    }

    return (
      <fieldset className="form-step-content mt-0">
        <div
          className={classNames('form-group', {
            'with-error': touched.minimumQuantity && errors.minimumQuantity,
          })}
        >
          <label>{_capitalize(t('common.minimum order quantity'))}</label>
          <div className="form-value">
            <input
              className="form-control big-input"
              type="number"
              maxLength="15"
              name="minimumQuantity"
              placeholder="Volume (number of units)"
              onChange={(event) => {
                const v = event.target.value;
                const data = { ...values, minimumQuantity: v };
                Object.keys(data.requiredQuantity).forEach((i) => {
                  if (data.requiredQuantity[i].q === parseInt(v, 10)) {
                    data.requiredQuantity[i].pricePerUnit = data.pricePerUnit;
                    data.requiredQuantity[i].leadTime = data.leadTime;
                  }
                  if (data.requiredQuantity[i].q < parseInt(v, 10)) {
                    data.requiredQuantity[i].pricePerUnit = '';
                    data.requiredQuantity[i].leadTime = '';
                  }
                });
                setValues(data);
              }}
              onBlur={() => {
                setFieldTouched('minimumQuantity', true);
              }}
              value={values.minimumQuantity}
              disabled={readOnly}
            />
            {touched.minimumQuantity && errors.minimumQuantity && (
              <label className="error">{t(`quote.validation.${errors.minimumQuantity}`)}</label>
            )}
          </div>
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.pricePerUnit && errors.pricePerUnit,
          })}
        >
          <div className="form-value">
            <input
              className="form-control big-input"
              type="number"
              name="pricePerUnit"
              placeholder="Price (ex. 4.50)"
              onChange={(event) => {
                const v = event.target.value;
                const data = { ...values, pricePerUnit: v };
                Object.keys(data.requiredQuantity).forEach((i) => {
                  if (data.requiredQuantity[i].q === parseInt(data.minimumQuantity, 10)) {
                    data.requiredQuantity[i].pricePerUnit = v;
                  }
                });
                setValues(data);
              }}
              onBlur={() => {
                setFieldTouched('pricePerUnit', true);
              }}
              value={values.pricePerUnit}
              disabled={readOnly}
            />
            {touched.pricePerUnit && errors.pricePerUnit && (
              <label className="error">{t(`quote.validation.${errors.pricePerUnit}`)}</label>
            )}
          </div>
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.leadTime && errors.leadTime,
          })}
        >
          <div className="form-value">
            <input
              className="form-control big-input"
              name="leadTime"
              type="number"
              placeholder="Lead Time in Days (ex. 30)"
              onChange={(event) => {
                const v = event.target.value;
                const data = { ...values, leadTime: v };
                Object.keys(data.requiredQuantity).forEach((i) => {
                  if (data.requiredQuantity[i].q === parseInt(data.minimumQuantity, 10)) {
                    data.requiredQuantity[i].leadTime = v;
                  }
                });
                setValues(data);
              }}
              onBlur={() => {
                setFieldTouched('leadTime', true);
              }}
              value={values.leadTime}
              disabled={readOnly}
            />
            {touched.leadTime && errors.leadTime && (
              <label className="error">{t(`quote.validation.${errors.leadTime}`)}</label>
            )}
          </div>
        </div>

        {values.requiredQuantity.map((item, index) => {
          const quantity = item.q;
          const fieldTouched = (touched.requiredQuantity && touched.requiredQuantity[index]) || {};
          const fieldError = (errors.requiredQuantity && errors.requiredQuantity[index]) || {};
          const fieldDisabled =
            (values.minimumQuantity && values.minimumQuantity >= quantity) || readOnly;

          return (
            <div key={quantity}>
              <div
                className={classNames('form-group', {
                  'with-error': fieldTouched.pricePerUnit && fieldError.pricePerUnit,
                })}
              >
                <label>{quantity} Units</label>
                <div className="form-value">
                  <Field
                    className="form-control big-input"
                    type="number"
                    name={`requiredQuantity.${index}.pricePerUnit`}
                    placeholder="Price (ex. 4.50)"
                    disabled={fieldDisabled}
                  />
                  {fieldTouched.pricePerUnit && fieldError.pricePerUnit && (
                    <label className="error">
                      {t(`quote.validation.${fieldError.pricePerUnit}`)}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={classNames('form-group', {
                  'with-error': fieldTouched.leadTime && fieldError.leadTime,
                })}
              >
                <div className="form-value">
                  <Field
                    className="form-control big-input"
                    name={`requiredQuantity.${index}.leadTime`}
                    placeholder="Lead Time in Days (ex. 30)"
                    disabled={fieldDisabled}
                  />
                  {fieldTouched.leadTime && fieldError.leadTime && (
                    <label className="error">{t(`quote.validation.${fieldError.leadTime}`)}</label>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {product.targetPrice && (
          <div data-id="quote-form-target-required-quantity">
            <div
              className={classNames('form-group', {
                'with-error': touched.targetRequiredQuantity && errors.targetRequiredQuantity,
              })}
            >
              <label>
                {_startCase(t('common.target price'))}
                <span className="target-price-value highlighted" id="targetPrice">
                  {displayMoney(product.targetPrice, product.currency)}
                </span>
              </label>
              <Field
                name="targetRequiredQuantity"
                className="form-control big-input"
                placeholder="Quantity (ex. 1000)"
                type="number"
                disabled={
                  values.targetPriceCanNotReach ||
                  (values.minimumQuantity === values.targetRequiredQuantity &&
                    values.pricePerUnit === product.targetPrice) ||
                  readOnly
                }
                onChange={(event) => {
                  const { value } = event.target;
                  setFieldValue('targetRequiredQuantity', value);
                  debouncedAfterChangeTargetRequiredQuantity(this.props, value);
                }}
                onBlur={() => {
                  setFieldTouched('targetRequiredQuantity', true);
                }}
              />
              {touched.targetRequiredQuantity && errors.targetRequiredQuantity && (
                <label id="targetRequiredQuantity-error" className="error">
                  {t(`quote.validation.${errors.targetRequiredQuantity}`)}
                </label>
              )}
            </div>
            <div
              className={classNames('form-group', {
                'with-error': touched.targetLeadTime && errors.targetLeadTime,
              })}
            >
              <Field
                className="form-control big-input"
                name={`targetLeadTime`}
                type="number"
                placeholder="Lead Time in Days (ex. 30)"
                disabled={
                  values.targetPriceCanNotReach ||
                  (values.minimumQuantity === values.targetRequiredQuantity &&
                    values.pricePerUnit === product.targetPrice) ||
                  readOnly
                }
                onChange={(event) => {
                  const v = event.target.value;
                  const data = { ...values, targetLeadTime: v };
                  Object.keys(data.requiredQuantity).forEach((i) => {
                    if (data.requiredQuantity[i].q === parseInt(v, 10)) {
                      data.requiredQuantity[i].leadTime = v;
                    }
                  });
                  setValues(data);
                }}
                onBlur={() => {
                  setFieldTouched('targetLeadTime', true);
                }}
              />
              {touched.targetLeadTime && errors.targetLeadTime && (
                <label className="error">{t(`quote.validation.${errors.targetLeadTime}`)}</label>
              )}
            </div>
            <div className="form-group">
              <CheckboxStyled
                id="targetPriceCanNotReach"
                name="targetPriceCanNotReach"
                type="checkbox"
                onChange={() => {
                  setFieldValue('targetPriceCanNotReach', !values.targetPriceCanNotReach);
                }}
                onBlur={() => {
                  setFieldTouched('targetPriceCanNotReach', true);
                }}
                checked={values.targetPriceCanNotReach}
                disabled={readOnly}
              />
              <CheckboxLabelStyled htmlFor="targetPriceCanNotReach">
                {t('quote.common.Select here if you cannot reach this price')}
              </CheckboxLabelStyled>
            </div>
          </div>
        )}
        {errors.common && <label className="error common">{t(errors.common, errors.common)}</label>}
        {!this.props.readOnly && !this.props.withoutSubmitButton && (
          <button
            className="btn blue-button wide-button big-button fullwidth-button"
            disabled={
              !isFieldValid('minimumQuantity') ||
              !isFieldValid('targetLeadTime') ||
              !isFieldValid('targetRequiredQuantity') ||
              !isFieldValid('pricePerUnit') ||
              !isFieldValid('leadTime')
            }
            type="button"
            onClick={() => {
              this.props.onChangeFormStep(2);
            }}
          >
            {t('quote.common.Next')}
          </button>
        )}
      </fieldset>
    );
  }
}

export default QuoteFormSignupDStep1;
