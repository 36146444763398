import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.css';

export class FormStep extends Component {
  static propTypes = {
    stepName: PropTypes.string.isRequired,
    firstStep: PropTypes.bool.isRequired,
    lastStep: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <div
        className={classNames('form-step', {
          'passed-opened-step': !this.props.disabled,
          'first-step': this.props.firstStep,
          'last-step': this.props.lastStep,
        })}
      >
        <div
          className={classNames('form-step-tab', {
            active: this.props.active,
          })}
        >
          {this.props.stepName}
        </div>
      </div>
    );
  }
}

export default class FormStepsTabsIndicator extends Component {
  static propTypes = {
    stepsNames: PropTypes.array.isRequired,
    currentStep: PropTypes.number.isRequired,
  };

  render() {
    const { stepsNames, currentStep } = this.props;
    const steps =
      stepsNames &&
      currentStep &&
      this.props.stepsNames.map((stepName, index) => {
        return (
          <FormStep
            key={stepName}
            stepName={stepName}
            disabled={index + 1 > currentStep}
            active={index + 1 === currentStep}
            firstStep={index === 0}
            lastStep={index === stepsNames.length - 1}
          />
        );
      });

    return (
      <div className={`form-steps-tabs-indicator step-${currentStep}`}>
        <div className="form-steps">{steps}</div>
      </div>
    );
  }
}
