import React from 'react';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import api from '../../../service/api';
import CompanyProfileHeader from '../../../component/company/CompanyProfileHeader';
import CompanyProfileCommon from '../../../component/company/CompanyProfileCommon';

const ContentStyled = styled.div`
  width: 753px;
`;

export class ClientProfileContainer extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      client: {},
      cancelSource: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    try {
      const clientId = this.props.match.params.clientId;
      const res = await api.clientGet(
        this.props.companyId,
        clientId,
        this.state.cancelSource.token
      );

      this.setState({ client: res.data, isLoading: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };
  render() {
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className="client-profile">
        <CompanyProfileHeader company={this.state.client} />
        <ContentStyled>
          <CompanyProfileCommon company={this.state.client} />
        </ContentStyled>
      </div>
    );
  }
}

export default withRouter(ClientProfileContainer);
