import React from 'react';
import axios, { CancelToken } from 'axios';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import QuoteForm from '../../../component/quote/QuoteForm';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import api from '../../../service/api';
import IconClose from '../../../component/icon/IconClose';

const ContainerStyled = styled.div`
  margin-top: 105px;
  width: 740px;
  margin-left: auto;
  margin-right: auto;
`;
const HeaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 22px 0;
  line-height: 1.81;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 #f2f2f2;
`;

export class QuoteFullFormContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      cancelSourceGetProduct: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    try {
      if (this.props.company.type === 'supplier') {
        const quoteId = this.props.match.params.quoteId;
        const res = await api.quoteSupplierGet(
          this.props.activeCompanyId,
          quoteId,
          this.state.cancelSourceGetProduct.token
        );
        if (this._isMounted) {
          this.setState({
            quote: { product: res.data.product, clientCompany: {} },
            isLoading: false,
          });
        }
      } else {
        const productId = this.props.match.params.productId;
        const productRes = await api.productGet(
          this.props.activeCompanyId,
          this.props.activeProjectId,
          productId,
          this.state.cancelSourceGetProduct.token
        );
        if (this._isMounted) {
          this.setState({
            quote: { product: productRes.data, clientCompany: {} },
            isLoading: false,
          });
        }
      }
    } catch (err) {
      if (!axios.isCancel(err) && this._isMounted) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.state.cancelSourceGetProduct.cancel();
  }

  goToClientProfile = (clientId) => {
    this.props.history.push(`/dashboard/company/${this.props.activeCompanyId}/clients/${clientId}`);
  };

  render() {
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <ContainerStyled>
        <HeaderStyled>Full Specification - Read Only</HeaderStyled>
        <div className={'icon-close-container'} onClick={this.props.history.goBack}>
          <IconClose width={18} height={18} color={'black'} />
        </div>
        {this.state.error ? (
          <div>{this.state.error}</div>
        ) : (
          <QuoteForm
            readOnly={true}
            quote={this.state.quote}
            product={this.state.quote.product}
            company={this.state.quote.clientCompany}
            userId={this.props.user._id}
            userCompanyId={this.props.activeCompanyId}
            onSubmit={() => {}}
            goToClientProfile={this.goToClientProfile}
            t={this.props.t}
          />
        )}
      </ContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  company: state.dashboard.company,
  activeCompanyId: state.dashboard.activeCompanyId,
  activeProjectId: state.dashboard.activeProjectId,
});

export default withRouter(connect(mapStateToProps)(withNamespaces()(QuoteFullFormContainer)));
