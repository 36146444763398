import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import moment from '../../../util/moment';
import IconDotsHorizontal from '../../icon/IconDotsHorizontal';
import IconView from '../../icon/IconView';
import QuoteRange from '../QuoteRange';
import { getQuoteRange } from '../../../util/quote';

export class QuoteTableRow extends React.Component {
  static propTypes = {
    quote: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    popupOpened: PropTypes.bool.isRequired,
    onPopupStateChange: PropTypes.func.isRequired,
  };

  render() {
    const { quote, onClick, popupOpened, onPopupStateChange } = this.props;
    const quoteRange = getQuoteRange(quote.requiredQuantity);

    return (
      <tr
        key={quote._id}
        onClick={() => onClick(quote._id, quote.status === 'draft' ? 'edit' : 'view')}
      >
        <td>
          <img
            width={100}
            height={100}
            src={quote.product.photosUrl[0] || imgPlaceholder}
            alt={''}
          />
        </td>
        <td className={'top-baseline'}>
          <div>{quote.product.name}</div>
        </td>
        <td className={'top-baseline'}>
          <QuoteRange quoteRange={quoteRange} currency={quote.currency} />
        </td>
        <td className={'top-baseline'}>
          {quote.status === 'complete' ? moment(quote.updatedAt).format() : ''}
        </td>
        <td>
          <div
            className={classNames('important-text', { 'danger-text': quote.status === 'draft' })}
          >
            {quote.status === 'draft' ? 'Pending' : 'Quote Sent'}
          </div>
          <a onClick={() => {}}>{quote.status === 'draft' ? 'Edit' : 'View'} Quote &raquo;</a>
        </td>
        <td className={'top-baseline'}>
          <div className={'popup-container'} onClick={(e) => e.stopPropagation()}>
            <Popup
              open={popupOpened}
              onOpen={() => onPopupStateChange(true)}
              onClose={() => onPopupStateChange(false)}
              trigger={
                <div className={'btn action'}>
                  <IconDotsHorizontal
                    width={24}
                    height={6}
                    color={popupOpened ? 'var(--active-blue-color)' : '#e9e9e9'}
                  />
                </div>
              }
              arrow={false}
              position="bottom right"
              contentStyle={{ width: '209px', padding: '17px 12px' }}
            >
              <div>
                <div className={'btn'} onClick={() => onClick(quote._id, 'edit')}>
                  <div className={'icon-container'}>
                    <IconView width={14} height={16} />
                  </div>
                  Edit Quote
                </div>
              </div>
            </Popup>
          </div>
        </td>
      </tr>
    );
  }
}

export default class Quotes extends React.Component {
  static propTypes = {
    quotes: PropTypes.array.isRequired,
    onQuoteClick: PropTypes.func.isRequired,
  };

  state = {
    actionsPopupOpenQuoteId: null,
  };

  render() {
    const quotesList = this.props.quotes.map((quote) => (
      <QuoteTableRow
        key={quote._id}
        popupOpened={this.state.actionsPopupOpenQuoteId === quote._id}
        onPopupStateChange={(opened) =>
          opened
            ? this.setState({ actionsPopupOpenQuoteId: quote._id })
            : this.setState({ actionsPopupOpenQuoteId: null })
        }
        quote={quote}
        onClick={this.props.onQuoteClick}
      />
    ));

    return this.props.error ? (
      this.props.error
    ) : (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Quantity and Price</th>
            <th scope="col">Date Sent</th>
            <th scope="col">Status</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>{quotesList}</tbody>
      </table>
    );
  }
}
