import _get from 'lodash.get';

export const SOMETHING_WENT_WRONG_ERROR_TEXT = 'Something went wrong. Try again later, please.';

/**
 *
 * @param axiousRes
 */
export function errorsTransformAxiosToFormik(axiousRes) {
  const transformed = {};
  const errors = _get(axiousRes, 'response.data.errors', {});
  Object.keys(errors).forEach((name) => {
    transformed[name] = errors[name].message;
  });

  return transformed;
}

export function addCommonFormikErrorsIfEmpty(errors) {
  return Object.keys(errors).length ? errors : { common: SOMETHING_WENT_WRONG_ERROR_TEXT };
}

export function prepareFormikError(name, message) {
  const objErr = {};
  objErr[name] = message;
  return objErr;
}
