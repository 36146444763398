import React from 'react';
import PropTypes from 'prop-types';

/**
 * Autoload data on scroll with 'load' func, which has 'page' attribute to load exact page
 * It binds on scroll event listener to container by 'containerRef'
 * It relies on async 'loading' flag to stop fetching data on scroll, until it changes true -> false
 * 'lastPage' is the first loaded page, older pages are loaded on scroll top
 * It auto scrolls bottom on new item added to list if user does not scrolled up
 */
export default class InfiniteScrollReversed extends React.Component {
  static propTypes = {
    containerRef: PropTypes.object.isRequired,
    headerRef: PropTypes.object,
    contentRef: PropTypes.object.isRequired,
    load: PropTypes.func.isRequired, // Func to load more items
    loading: PropTypes.bool.isRequired, // Is data loading now
    loadedItemsCount: PropTypes.number, // total items loaded in pages
    lastPage: PropTypes.number.isRequired, // The most recent page
    listActiveId: PropTypes.string, // Id of current list if it's needed to switch them
  };

  constructor(props) {
    super(props);

    this.state = {
      lastContentHeight: 0,
      lastLoadedPage: props.lastPage,
      loadingStarted: false, // Local state needed as 'loading' value in props is async
    };
  }

  componentDidMount() {
    this.props.load(this.state.lastLoadedPage, true);
  }

  componentWillUnmount = () => {
    if (this.props.containerRef.current) {
      this.props.containerRef.current.removeEventListener('scroll', this.onScroll);
    }
  };

  componentDidUpdate = (prevProps) => {
    // Used for properly switching separate lists if it's needed (listActiveId not nessesary variable)
    if (prevProps.listActiveId !== this.props.listActiveId && this.props.listActiveId) {
      this.setState({
        lastContentHeight: 0,
        lastLoadedPage: this.props.lastPage,
        loadingStarted: true,
      });
      this.props.load(this.props.lastPage, true);
    } else {
      let containerHeight = this.props.containerRef.current
        ? this.props.containerRef.current.clientHeight
        : null;
      if (containerHeight && this.props.headerRef && this.props.headerRef.current) {
        containerHeight = containerHeight - this.props.headerRef.current.clientHeight;
      }

      const contentHeight = this.props.contentRef.current
        ? this.props.contentRef.current.clientHeight
        : null;
      const scrollTop = this.props.containerRef.current
        ? this.props.containerRef.current.scrollTop
        : null;

      if (prevProps.loading && !this.props.loading) {
        if (this.state.lastLoadedPage === this.props.lastPage) {
          // Add scroll event listener after the last page is loaded
          this.props.containerRef.current.scrollTop = contentHeight;
          this.props.containerRef.current.addEventListener('scroll', this.onScroll);
        } else {
          if (this.state.lastContentHeight <= containerHeight) {
            // Scroll bottom if content's height was less than container on the first load
            this.props.containerRef.current.scrollTop = contentHeight;
          } else {
            // Keep scroll position during new content added to the top
            this.props.containerRef.current.scrollTop =
              scrollTop + (contentHeight - this.state.lastContentHeight - containerHeight);
          }
        }
        this.setState(
          {
            lastContentHeight: contentHeight,
            loadingStarted: false,
          },
          () => {
            // Trigger onScroll if content's height less than container's height
            if (contentHeight <= containerHeight) {
              this.onScroll();
            }
          }
        );
      } else if (
        this.props.loadedItemsCount - prevProps.loadedItemsCount === 1 &&
        scrollTop > this.state.lastContentHeight - containerHeight &&
        this.state.lastContentHeight > 0
      ) {
        // Scroll to the bottom on new message added, if user does not scroll up at the moment
        this.props.containerRef.current.scrollTop = contentHeight;
        this.setState({
          lastContentHeight: contentHeight,
        });
      }
    }
  };

  onScroll = () => {
    if (!this.props.containerRef.current) {
      return false;
    }
    const containerHeight = this.props.containerRef.current.clientHeight;
    const scrollTop = this.props.containerRef.current.scrollTop;

    if (
      this.state.lastLoadedPage > 1 &&
      scrollTop < containerHeight &&
      !this.state.loadingStarted
    ) {
      const prevPage = this.state.lastLoadedPage - 1;
      this.setState({
        loadingStarted: true,
        lastLoadedPage: prevPage,
      });
      this.props.load(prevPage);
    }
  };

  render() {
    return this.props.loading && this.state.lastLoadedPage === this.props.lastPage
      ? null
      : this.props.children;
  }
}
