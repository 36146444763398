import React from 'react';
import PropTypes from 'prop-types';

const IconSuppliers = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <g fill={props.color} fillRule="nonzero">
        <path d="M10.57 2.886C9.84 1.16 7.908 0 5.76 0S1.68 1.16.95 2.886l-.366.864h10.352l-.365-.864zM11.52 8.016V4.375l-5.53 4.05L4.235 5H.64v9.375c0 .345.287.625.64.625h14.08c.353 0 .64-.28.64-.625V4.266l-4.48 3.75zM8.96 12.5H7.04v-1.25h1.92v1.25zm4.48 0h-1.92v-1.25h1.92v1.25z" />
      </g>
    </svg>
  );
};

IconSuppliers.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconSuppliers;
