import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';

class QuoteFormSignupDStep2 extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onChangeFormStep: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      values,
      errors,
      setValues,
      setErrors,
      touched,
      setFieldValue,
      setSubmitting,
      isSubmitting,
      handleSubmit,
      readOnly,
      t,
    } = this.props;

    return (
      <fieldset className="form-step-content mt-0">
        <p>
          {t('quote.common.Already have an account?')} &nbsp;
          <a href="/signin">{_capitalize(t('common.sign in'))}</a>
        </p>

        <div
          className={classNames('form-group', {
            'with-error': touched.fullName && errors.fullName,
          })}
        >
          <Field
            name="fullName"
            className="form-control big-input"
            type="text"
            disabled={readOnly}
            placeholder={_startCase(t('common.full name'))}
          />
          {touched.fullName && errors.fullName && (
            <label className="error">{t(`account.validation.${errors.fullName}`)}</label>
          )}
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.companyName && errors.companyName,
          })}
        >
          <Field
            name="companyName"
            className="form-control big-input"
            type="text"
            disabled={readOnly}
            placeholder={t(`account.validation.${errors.companyName}`)}
          />
          {touched.companyName && errors.companyName && (
            <label className="error">{t(`account.validation.${errors.companyName}`)}</label>
          )}
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.email && errors.email,
          })}
        >
          <Field
            name="email"
            className="form-control big-input"
            type="text"
            disabled={readOnly}
            placeholder={_startCase(t('common.email'))}
          />
          {touched.email && errors.email && (
            <label className="error">{t(`account.validation.${errors.email}`)}</label>
          )}
        </div>
        <div
          className={classNames('form-group', {
            'with-error': touched.password && errors.password,
          })}
        >
          <Field
            name="password"
            className="form-control big-input"
            type="password"
            disabled={readOnly}
            placeholder={_startCase(t('common.password'))}
          />
          {touched.password && errors.password && (
            <label className="error">{t(`account.validation.${errors.password}`)}</label>
          )}
        </div>

        {errors.common && <label className="error common">{t(errors.common, errors.common)}</label>}
        {!this.props.readOnly && (
          <button
            className="btn blue-button wide-button big-button fullwidth-button"
            style={{ marginTop: '20px' }}
            disabled={isSubmitting}
            type="button"
            onClick={() => {
              setValues({
                ...values,
                samplesNumber: 0,
                samplesTotalPrice: '',
                samplesLeadTime: '',
                samplesShippingCost: '',
                questions: [{ q: '', a: '' }],
                cartonSize: '',
                quantityPerCarton: '',
                cartonGrossWeight: '',
                cartonNetWeight: '',
              });
              setFieldValue('status', 'complete');
              handleSubmit(values, { props: this.props, setSubmitting, setErrors });
            }}
          >
            {t('quote.common.Send Quote and Create Account')}
          </button>
        )}
      </fieldset>
    );
  }
}

export default QuoteFormSignupDStep2;
