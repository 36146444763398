import React from 'react';
import PropTypes from 'prop-types';
import IconClose from '../../../component/icon/IconClose';

class ProductQuoteHeader extends React.Component {
  static propTypes = {
    companyType: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    activeCompanyId: PropTypes.string.isRequired,
    activeProjectId: PropTypes.string,
    companyName: PropTypes.string,
    companionCompanyId: PropTypes.string,
    heading: PropTypes.string,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  goToSupplierProfile = (supplierId) => {
    this.props.history.push(
      `/dashboard/company/${this.props.activeCompanyId}/project/${
        this.props.activeProjectId
      }/suppliers/${supplierId}`
    );
  };

  goToClientProfile = (clientId) => {
    this.props.history.push(`/dashboard/company/${this.props.activeCompanyId}/clients/${clientId}`);
  };

  render() {
    const { companyType, companyName, heading } = this.props;
    const productHeading =
      this.props.projectId && this.props.productName
        ? this.props.projects.map((project) => {
            let heading = '';
            if (project._id === this.props.projectId) {
              heading = project.name + ' / ' + this.props.productName;
            }
            return heading;
          })
        : this.props.productName;
    const isClient = companyType === 'client';

    return (
      <div className="product-quotes-header">
        <div className={'icon-close-container'} onClick={this.props.history.goBack}>
          <IconClose width={18} height={18} color={'black'} />
        </div>
        <div className="heading-label">{heading ? heading : 'Quotes'}</div>
        <h3>{productHeading}</h3>
        {companyName.length > 0 ? (
          isClient ? (
            <p>
              <span>Please review the quote by </span>
              <span
                className="heading-link"
                onClick={() => {
                  this.goToSupplierProfile(this.props.companionCompanyId);
                }}
                data-id="companion-company-name"
              >
                {this.props.companyName}
              </span>
            </p>
          ) : (
            <p>
              <span>Your quote for </span>
              <span
                className="heading-link"
                onClick={() => {
                  this.goToClientProfile(this.props.companionCompanyId);
                }}
                data-id="companion-company-name"
              >
                {this.props.companyName}
              </span>
            </p>
          )
        ) : (
          <p>Please review your quotes below.</p>
        )}
      </div>
    );
  }
}

export default ProductQuoteHeader;
