import _cloneDeep from 'lodash.clonedeep';
import s3 from '../service/s3';
import api from '../service/api';
import { bulkFilesS3SignedPut } from './s3';

export async function prepareCompanyDataAndUploadFiles(company, supplier) {
  const res = _cloneDeep(company);
  if (supplier) {
    res.supplier = _cloneDeep(supplier);
    if (res.supplier.photoFiles && res.supplier.photoFiles.length > 0) {
      res.supplier.photos = res.supplier.photoFiles
        .filter((file) => file.uploaded)
        .map((file) => file.name);

      const uploadFiles = res.supplier.photoFiles.filter((file) => !file.uploaded);
      const uploadedKeys = await bulkFilesS3SignedPut('supplierPhoto', uploadFiles);
      res.supplier.photos.push(...uploadedKeys);
      delete res.supplier.photoFiles;
    }
  }

  if (res.logoFile) {
    const params = {
      objectType: 'companyLogo',
      contentType: res.logoFile.type,
      name: res.logoFile.name,
    };
    const s3SignedPut = await api.signS3Put(params);
    await s3.presignedPut(s3SignedPut.data.url, res.logoFile, s3SignedPut.data.headers);
    res.logo = s3SignedPut.data.key;
    delete res.logoFile;
  }

  return res;
}
