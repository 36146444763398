import { CREATE_COMPANY_SELECT_TYPE } from './constants';

export function companyCreateSelectType(state = 'client', action) {
  switch (action.type) {
    case CREATE_COMPANY_SELECT_TYPE:
      return action.companyType;
    default:
      return state;
  }
}
