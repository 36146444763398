import { appFetchingData } from '../../../actions';
import { GET_COMPANY_USERS_SUCCESS } from './constants';
import { EventTypes } from 'redux-segment';

export function getCompanyUsersSuccess(users) {
  return {
    type: GET_COMPANY_USERS_SUCCESS,
    users,
    meta: {
      analytics: EventTypes.track,
    },
  };
}

export function getCompanyUsers(companyId) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      const res = await services.api.companyUsersGet(companyId);
      dispatch(getCompanyUsersSuccess(res.data));
      dispatch(appFetchingData(false));
    } catch (err) {
      dispatch(appFetchingData(false));
    }
  };
}

export function updateUserRole(data) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      await services.api.userPutRole(data);
      if (data.role === 'owner') {
        window.location.reload();
      } else {
        dispatch(getCompanyUsers(data.companyId));
      }
    } catch (err) {
      dispatch(appFetchingData(false));
    }
  };
}

export function deleteUser(userData, companyId) {
  return async (dispatch, getStore, services) => {
    dispatch(appFetchingData(true));
    try {
      await services.api.companyUserDelete(companyId, userData);
      dispatch(getCompanyUsers(companyId));
    } catch (err) {
      dispatch(appFetchingData(false));
    }
  };
}
