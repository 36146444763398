import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CompanyProfileCommon from '../CompanyProfileCommon';
import CompanyProfileSupplier from '../CompanyProfileSupplier';

const SectionSeparatorStyled = styled.div`
  margin-top: 65px;
  margin-bottom: 65px;
`;

const CompanyProfileView = (props) => {
  return (
    <div>
      <CompanyProfileCommon
        company={props.company}
        showCompanyNameAndLogo={true}
        onEditClick={props.onEditClick}
      />
      {props.company.type === 'supplier' && (
        <SectionSeparatorStyled className="section-separator" />
      )}
      {props.company.type === 'supplier' && (
        <CompanyProfileSupplier supplier={props.company.supplier} />
      )}
    </div>
  );
};

CompanyProfileView.propTypes = {
  company: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
};

export default CompanyProfileView;
