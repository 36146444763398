import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import * as Yup from 'yup';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';

import QuoteFormSignupBSignup from '../QuoteFormSignupBSignup';
import QuoteFormSignupAStep2, { Step2ValidationScheme } from '../QuoteFormSignupAStep2';

import {
  prepareQuoteValues,
  prepareAccountValues,
  validateRequiredQuantity,
  SignupValidationScheme,
} from '../QuoteForm/helper';
import FormStepsTabsIndicator from '../../../component/form/FormStepsTabsIndicator';
import '../QuoteFormSignupA/style.css';

const ProductImageStyled = styled.div`
  width: 290px;
  height: 290px;
  background: center/contain no-repeat;
`;

const ProductImageStyledPlaceholder = styled.img`
  width: 290px;
  height: 290px;
`;

const PhotoStyled = styled.div`
  width: 50px;
  height: 50px;
  margin: 30px 24px 40px 0;
  background: center/contain no-repeat;
`;

export class Form extends Component {
  static defaultProps = {
    readOnly: false,
    signupAndSendQuoteDone: false,
  };

  static propTypes = {
    product: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.string,
    userCompanyId: PropTypes.string,
    onSubmit: PropTypes.func,
    signupAndSendQuoteDone: PropTypes.bool,
    signUpAndSendQuoteOnFinished: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    goToClientProfile: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentStep: 1,
    photoIndex: 0,
    isLightboxOpen: false,
    specificationOpened: false,
  };

  onChangeFormStep = (stepNumber) => {
    this.setState({ currentStep: stepNumber });
  };

  onSwitchSpecification = () => {
    this.setState({ specificationOpened: !this.state.specificationOpened });
  };

  render() {
    const { values, touched, errors, handleSubmit, t } = this.props;
    const photosLength = this.props.product.photosUrl.length;
    const clientName = this.props.company.name;
    const productName = this.props.product.name;
    const { photoIndex } = this.state;
    const { product } = this.props;

    function isFieldValid(field) {
      return (!!values[field] || touched[field]) && !errors[field];
    }

    if (this.props.signupAndSendQuoteDone && this.state.currentStep !== 3) {
      this.setState({ currentStep: 3 });
    }

    if (errors.email === 'Email address is already registered') {
      if (this.state.currentStep !== 1) {
        this.setState({ currentStep: 1 });
      }
    }

    return (
      <form
        className={'padding-bottom-180 quote-request-form landing-quote-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <div className="landing-quote-big-heading">
            <Trans i18nKey="quote.common.headerText">
              <strong>{{ clientName }}</strong> has invited you to quote for&nbsp;
              <strong className="highlighted">{{ productName }}</strong> on the Importist Platform.
            </Trans>
          </div>
          <p style={{ margin: '0 0 5px' }}>{t('quote.common.importistShortDescription')}</p>
          <p>
            {t('quote.common.Already have an account?')} &nbsp;
            <a href="/signin">{_capitalize(t('common.sign in'))}</a>
          </p>
          <div className="landing-quote-big-heading highlighted">
            {t('quote.common.formShortDescription')}
          </div>
        </div>

        {photosLength > 0 && (
          <div className="row">
            <div className="col-lg-3">
              {product.photosUrl[0] ? (
                <ProductImageStyled style={{ backgroundImage: `url(${product.photosUrl[0]})` }} />
              ) : (
                <ProductImageStyledPlaceholder src={imgPlaceholder} alt={''} />
              )}
              {product.photosUrl.map((photoUrl, index) => {
                return (
                  <PhotoStyled
                    style={{ backgroundImage: `url(${photoUrl})` }}
                    onClick={() => {
                      this.setState({ isLightboxOpen: true, photoIndex: index });
                    }}
                    className="photo-thumbnail"
                    key={index}
                  />
                );
              })}
              {this.state.isLightboxOpen && (
                <Lightbox
                  mainSrc={product.photosUrl[photoIndex]}
                  nextSrc={product.photosUrl[(photoIndex + 1) % photosLength]}
                  prevSrc={product.photosUrl[(photoIndex + photosLength - 1) % photosLength]}
                  onCloseRequest={() => this.setState({ isLightboxOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + photosLength - 1) % photosLength,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % photosLength,
                    })
                  }
                />
              )}
            </div>
            <div className="col-lg-9 specification-col">
              <div className="heading-row properties-row">
                <div className="row">
                  <div className="col-sm-6 landing-quote-big-heading top-heading">
                    <strong>{product.name}</strong>
                  </div>
                </div>
              </div>

              <div className="properties-row">
                <label className="section-header">{_startCase(t(`common.specification`))}</label>
              </div>
              <div
                className={classNames('specification', {
                  opened: this.state.specificationOpened,
                })}
                style={{ marginBottom: '40px' }}
              >
                <div className="specification-block">
                  {this.state.specificationOpened
                    ? product.specs.map((spec, index) => (
                        <div key={index} className="properties-row">
                          <label>
                            {_startCase(
                              t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name))
                            )}
                          </label>
                          <div className="property-value">{spec.value}</div>
                        </div>
                      ))
                    : product.specs
                        .filter((spec, index) => index < 2)
                        .map((spec, index) => (
                          <div key={index} className="properties-row">
                            <label>
                              {_startCase(
                                t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name))
                              )}
                            </label>
                            <div className="property-value">{spec.value}</div>
                          </div>
                        ))}
                </div>
                {product.specs.length > 2 && (
                  <div className="specificaiton-block-bottom">
                    <span onClick={this.onSwitchSpecification} className="specification-trigger" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <FormStepsTabsIndicator
          stepsNames={[
            _startCase(t('common.register')),
            _startCase(t('common.quote_verb')),
            _startCase(t('common.send')),
          ]}
          currentStep={this.state.currentStep}
        />

        {this.state.currentStep === 1 && (
          <div style={{ textAlign: 'center' }}>
            <QuoteFormSignupBSignup {...this.props} withoutHeading={true} />
            <button
              className="btn blue-button wide-button big-button"
              disabled={
                !isFieldValid('fullName') ||
                !isFieldValid('companyName') ||
                !isFieldValid('email') ||
                !isFieldValid('password')
              }
              type="button"
              onClick={() => {
                this.onChangeFormStep(2);
              }}
              style={{ dispay: 'block', width: '300px', margin: '50px auto 20px' }}
            >
              {_startCase(t('quote.common.start quote'))}
            </button>
          </div>
        )}

        {this.state.currentStep === 2 && (
          <QuoteFormSignupAStep2
            {...this.props}
            onChangeFormStep={this.onChangeFormStep}
            withoutSpecification="true"
            withIncotermsAndValidUntil="true"
          />
        )}

        {this.state.currentStep === 3 && (
          <div className="form-step-content form-step-3-content">
            <h2>{t('quote.common.Your quote has been sent!')}</h2>
            <p>{t('quote.common.lastStepImportistNote')}</p>
            <button
              className="btn blue-button big-button wide-button"
              type="button"
              onClick={() => {
                this.props.signUpAndSendQuoteOnFinished();
              }}
            >
              {t('quote.common.Complete Your Supplier Account')}
            </button>
          </div>
        )}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  ...SignupValidationScheme,
  ...Step2ValidationScheme,
});

const QuoteFormSignupC = withFormik({
  mapPropsToValues: (props) => {
    const quoteValues = prepareQuoteValues(props);
    const accountValues = prepareAccountValues(props);

    return {
      ...quoteValues,
      ...accountValues,
    };
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantity(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }

    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

QuoteFormSignupC.defaultProps = {
  quote: {},
};

export default QuoteFormSignupC;
