import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';

import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import ErrorTop from '../../form/ErrorTop';

const ProductImageStyled = styled.div`
  width: 290px;
  height: 290px;
  background: center/contain no-repeat;
`;

const ProductImageStyledPlaceholder = styled.img`
  width: 290px;
  height: 290px;
`;

class QuoteFormSignupAStep1 extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onChangeFormStep: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { product, values, errors, touched, isValid, isSubmitting, readOnly, t } = this.props;

    function isFieldValid(field) {
      return (!!values[field] || touched[field]) && !errors[field];
    }

    const clientName = this.props.company.name;
    const productName = this.props.product.name;

    return (
      <div className="form-step-content form-step-1-content">
        <div className="row">
          <div className="col-md-8 landing-quote-big-heading">
            <Trans i18nKey="quote.common.headerText">
              <strong>{{ clientName }}</strong> has invited you to quote for&nbsp;
              <strong className="highlighted">{{ productName }}</strong> on the Importist Platform.
            </Trans>
          </div>
          <div className="col-md-4">
            <p>{t('quote.common.importistShortDescription')}</p>
            <p>
              {t('quote.common.Already have an account?')} &nbsp;
              <a href="/signin">{_capitalize(t('common.sign in'))}</a>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="landing-quote-image-and-volume-quotes">
              {product.photosUrl[0] ? (
                <ProductImageStyled style={{ backgroundImage: `url(${product.photosUrl[0]})` }} />
              ) : (
                <ProductImageStyledPlaceholder src={imgPlaceholder} alt={''} />
              )}

              <div className="landing-quote-volume-quotes">
                <div className="volume-quotes-label">{t('common.volume quotes').toUpperCase()}</div>
                {values.requiredQuantity.map((item, index) => {
                  const quantity = item.q;
                  return (
                    <div className="volume-quotes-value" key={index}>
                      {quantity} {t('common.unit', { count: quantity })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <ErrorTop
              isSubmitting={isSubmitting}
              isValid={isValid}
              errors={errors}
              commonErrorText={t('Please, fix errors below')}
            />

            <fieldset>
              <div
                className={classNames('form-group', {
                  'with-error': touched.companyName && errors.companyName,
                })}
              >
                <Field
                  name="companyName"
                  className="form-control big-input"
                  type="text"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.company name'))}
                />
                {touched.companyName && errors.companyName && (
                  <label className="error">{t(`account.validation.${errors.companyName}`)}</label>
                )}
              </div>
              <div
                className={classNames('form-group', {
                  'with-error': touched.fullName && errors.fullName,
                })}
              >
                <Field
                  name="fullName"
                  className="form-control big-input"
                  type="text"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.full name'))}
                />
                {touched.fullName && errors.fullName && (
                  <label className="error">{t(`account.validation.${errors.fullName}`)}</label>
                )}
              </div>
              <div
                className={classNames('form-group', {
                  'with-error': touched.email && errors.email,
                })}
              >
                <Field
                  name="email"
                  className="form-control big-input"
                  type="text"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.email'))}
                />
                {touched.email && errors.email && (
                  <label className="error">{t(`account.validation.${errors.email}`)}</label>
                )}
              </div>
              <div
                className={classNames('form-group', {
                  'with-error': touched.password && errors.password,
                })}
              >
                <Field
                  name="password"
                  className="form-control big-input"
                  type="password"
                  disabled={readOnly}
                  placeholder={_startCase(t('common.password'))}
                />
                {touched.password && errors.password && (
                  <label className="error">{t(`account.validation.${errors.password}`)}</label>
                )}
              </div>
            </fieldset>

            <button
              className="btn blue-button wide-button big-button fullwidth-button uppercased-button"
              disabled={
                !isFieldValid('fullName') ||
                !isFieldValid('companyName') ||
                !isFieldValid('email') ||
                !isFieldValid('password')
              }
              type="button"
              onClick={() => {
                this.props.onChangeFormStep(2);
              }}
            >
              {_startCase(t('quote.common.start quote'))}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuoteFormSignupAStep1;
