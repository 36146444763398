import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import _startCase from 'lodash.startcase';

import { modalOpen, modalClose } from '../../../actions';
import {
  getCompanyUsers,
  updateUserRole,
  deleteUser,
} from '../../../container/dashboard/AccountContainer/actions';
import './style.css';
import ModalInviteUsers from './ModalInviteUsers';
import ModalEditUserRole from './ModalEditUserRole';
import IconDotsHorizontal from '../../icon/IconDotsHorizontal';
import IconSettings from '../../icon/IconSettings';
import IconGroup from '../../icon/IconGroup';
import { userGetCompanyRole } from '../../../util/user';

class CompanyUsersAndPermissions extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    updateUserRole: PropTypes.func.isRequired,
  };

  state = {
    modalInviteUsersOpened: false,
    modalEditUserRoleOpened: false,
    actionsPopupOpenUser: null,
  };

  modalInviteUsersOpen = () => {
    this.props.modalOpen();
    this.setState({ modalInviteUsersOpened: true });
  };

  modalInviteUsersClose = () => {
    this.props.modalClose();
    this.setState({ modalInviteUsersOpened: false });
  };

  modalEditUserRoleOpen = () => {
    this.props.modalOpen();
    this.setState({ modalEditUserRoleOpened: true });
  };

  modalEditUserRoleClose = () => {
    this.props.modalClose();
    this.setState({ modalEditUserRoleOpened: false });
  };

  onUserSave = (userData) => {
    let data = { ...userData };
    data.companyId = this.props.companyId;
    this.props.updateUserRole(data);
    this.modalEditUserRoleClose();
    this.setState({ actionsPopupOpenUser: null });
  };

  onUserDelete = (userData) => {
    this.props.deleteUser(userData, this.props.companyId);
    this.modalEditUserRoleClose();
    this.setState({ actionsPopupOpenUser: null });
  };

  componentDidMount() {
    if (this.props.companyId) {
      this.props.getCompanyUsers(this.props.companyId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId && this.props.companyId !== prevProps.companyId) {
      this.props.getCompanyUsers(this.props.companyId);
    }
  }

  render() {
    const usersList = this.props.companyUsers.map((user) => {
      return (
        <tr key={user._id}>
          <td>{user.fullName}</td>
          <td>{user.email}</td>
          <td>{_startCase(user.role)}</td>
          <td>
            {user.role !== 'owner' && (
              <div className={'popup-container'}>
                <Popup
                  open={
                    this.state.actionsPopupOpenUser &&
                    this.state.actionsPopupOpenUser._id === user._id
                  }
                  onOpen={() => this.setState({ actionsPopupOpenUser: user })}
                  onClose={() => this.setState({ actionsPopupOpenUser: null })}
                  trigger={
                    <div className={'btn action'}>
                      <IconDotsHorizontal
                        width={24}
                        height={6}
                        color={
                          this.state.actionsPopupOpenUser !== null &&
                          this.state.actionsPopupOpenUser._id === user._id
                            ? 'var(--active-blue-color)'
                            : '#e9e9e9'
                        }
                      />
                    </div>
                  }
                  arrow={false}
                  position="bottom right"
                  closeOnDocumentClick
                  contentStyle={{ width: '209px', padding: '17px 23px' }}
                >
                  <div>
                    <div className={'btn'} onClick={this.modalEditUserRoleOpen}>
                      <div className={'icon-container'}>
                        <IconSettings width={17} height={17} color={'#080513'} />
                      </div>
                      Edit Permissions
                    </div>
                    <div
                      className={'btn danger'}
                      onClick={() => {
                        this.onUserDelete(user);
                      }}
                    >
                      <IconGroup
                        style={{ marginLeft: '-2px' }}
                        width={21}
                        height={17}
                        color={'#FE374F'}
                        strokeColor={'#FE374F'}
                      />
                      Delete User
                    </div>
                  </div>
                </Popup>
              </div>
            )}
          </td>
        </tr>
      );
    });

    const loggedInUserRole = userGetCompanyRole(
      this.props.user.companies,
      this.props.activeCompanyId
    );

    return (
      <div>
        <label className="section-header">Users and Permissions</label>
        <button className="btn blue-button btn-top-right" onClick={this.modalInviteUsersOpen}>
          Add User
        </button>
        {this.state.modalInviteUsersOpened && (
          <ModalInviteUsers companyId={this.props.companyId} onClose={this.modalInviteUsersClose} />
        )}
        {this.state.modalEditUserRoleOpened && (
          <ModalEditUserRole
            loggedInUserRole={loggedInUserRole}
            user={this.state.actionsPopupOpenUser}
            onSave={this.onUserSave}
            onClose={this.modalEditUserRoleClose}
          />
        )}
        <table className="table">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">ROLE</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>{usersList}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modalOpened: state.app.modalOpened,
  user: state.account.user,
  activeCompanyId: state.dashboard.activeCompanyId,
  companyUsers: state.dashboard.companyUsers,
});

const mapDispatchToProps = (dispatch) => ({
  modalOpen: () => dispatch(modalOpen()),
  modalClose: () => dispatch(modalClose()),
  getCompanyUsers: (companyId) => dispatch(getCompanyUsers(companyId)),
  updateUserRole: (data) => dispatch(updateUserRole(data)),
  deleteUser: (userData, companyId) => dispatch(deleteUser(userData, companyId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUsersAndPermissions);
