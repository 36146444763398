import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from '../../../util/moment';
import { Field } from 'formik';
import classNames from 'classnames';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';
import * as Yup from 'yup';

import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import ErrorTop from '../../form/ErrorTop';
import IconCalendar from '../../icon/IconCalendar';
import { displayMoney } from '../../../util/currency';
import { incotermsList } from '../../../util/incoterms';
import {
  QuantitiesValidationScheme,
  debouncedAfterChangeTargetRequiredQuantity,
} from '../QuoteForm/helper';

const ProductImageStyled = styled.div`
  width: 290px;
  height: 290px;
  background: center/contain no-repeat;
`;

const ProductImageStyledPlaceholder = styled.img`
  width: 290px;
  height: 290px;
`;

const PhotoStyled = styled.div`
  width: 50px;
  height: 50px;
  margin: 30px 24px 40px 0;
  background: center/contain no-repeat;
`;

const CheckboxStyled = styled.input`
  display: inline-flex;
`;

const CheckboxLabelStyled = styled.label`
  margin-left: 7px;
`;

const IconCalendarContainerStyled = styled.div`
  position: absolute;
  z-index: 2500;
  top: 6px;
  left: 10px;
`;

const ContainerInputCalendarStyled = styled.div`
  position: relative;
`;

class QuoteFormSignupAStep2 extends React.Component {
  static defaultProps = {
    readOnly: false,
  };

  static propTypes = {
    readOnly: PropTypes.bool,
    product: PropTypes.object.isRequired,
    onChangeFormStep: PropTypes.func,
    withoutSpecification: PropTypes.bool,
    withIncotermsAndValidUntil: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    photoIndex: 0,
    isLightboxOpen: false,
    specificationOpened: false,
  };

  onSwitchSpecification = () => {
    this.setState({ specificationOpened: !this.state.specificationOpened });
  };

  componentWillUnmount() {
    debouncedAfterChangeTargetRequiredQuantity.cancel();
  }

  render() {
    const { photoIndex } = this.state;
    const photosLength = this.props.product.photosUrl.length;
    const { withoutSpecification, withIncotermsAndValidUntil } = this.props;

    const {
      readOnly,
      product,
      values,
      errors,
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
      setFieldTouched,
      setValues,
      setErrors,
      setSubmitting,
      handleSubmit,
      t,
    } = this.props;

    return (
      <div className="form-step-content form-step-2-content">
        <ErrorTop
          isSubmitting={isSubmitting}
          isValid={isValid}
          errors={errors}
          commonErrorText={t('Please, fix errors below')}
        />

        <fieldset>
          {!withoutSpecification && (
            <div className="row">
              {photosLength > 0 && (
                <div className="col-lg-3">
                  {product.photosUrl[0] ? (
                    <ProductImageStyled
                      style={{ backgroundImage: `url(${product.photosUrl[0]})` }}
                    />
                  ) : (
                    <ProductImageStyledPlaceholder src={imgPlaceholder} alt={''} />
                  )}
                  {product.photosUrl.map((photoUrl, index) => {
                    return (
                      <PhotoStyled
                        style={{ backgroundImage: `url(${photoUrl})` }}
                        onClick={() => {
                          this.setState({ isLightboxOpen: true, photoIndex: index });
                        }}
                        className="photo-thumbnail"
                        key={index}
                      />
                    );
                  })}
                  {this.state.isLightboxOpen && (
                    <Lightbox
                      mainSrc={product.photosUrl[photoIndex]}
                      nextSrc={product.photosUrl[(photoIndex + 1) % photosLength]}
                      prevSrc={product.photosUrl[(photoIndex + photosLength - 1) % photosLength]}
                      onCloseRequest={() => this.setState({ isLightboxOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + photosLength - 1) % photosLength,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % photosLength,
                        })
                      }
                    />
                  )}
                </div>
              )}
              <div className="col-lg-9 specification-col">
                <div className="heading-row properties-row">
                  <div className="row">
                    <div className="col-sm-6 landing-quote-big-heading top-heading">
                      <strong>{product.name}</strong>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="highlighted">{_startCase(t('common.incoterms'))}</label>
                        <div className="form-value">
                          <Field
                            className="form-control narrow"
                            component="select"
                            name="termsPreference"
                            disabled={readOnly}
                          >
                            {Object.values(incotermsList)
                              .filter((incoterm) => incoterm.code !== 'standard')
                              .map((incoterm, index) => {
                                return (
                                  <option value={incoterm.code} key={index}>
                                    {incoterm.label}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div
                        className={classNames('form-group with-datepicker', {
                          'with-error': touched.validUntil && errors.validUntil,
                        })}
                      >
                        <label className="highlighted">
                          {_startCase(
                            t('common.quote', 'Quote') +
                              ' ' +
                              t('common.valid', 'Valid') +
                              ' ' +
                              t('common.until', 'Until')
                          )}
                        </label>
                        <ContainerInputCalendarStyled>
                          <IconCalendarContainerStyled>
                            <IconCalendar width={18} height={18} color="#4d4d4d" />
                          </IconCalendarContainerStyled>
                          <DatePicker
                            name="validUntil"
                            className={'form-control'}
                            onChange={(date) => {
                              setFieldValue('validUntil', date.toISOString());
                            }}
                            onBlur={() => {
                              setFieldTouched('validUntil', true);
                            }}
                            selected={values.validUntil.length ? moment(values.validUntil) : null}
                            dateFormat="MM/DD/YYYY"
                            placeholderText="mm/dd/yyyy"
                            disabled={readOnly}
                            minDate={moment()}
                          />
                        </ContainerInputCalendarStyled>
                        {touched.validUntil && errors.validUntil && (
                          <label className="error">
                            {t(`quote.validation.${errors.validUntil}`)}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="properties-row">
                  <label className="section-header">{_startCase(t(`common.specification`))}</label>
                </div>
                <div
                  className={classNames('specification', {
                    opened: this.state.specificationOpened,
                  })}
                >
                  <div className="specification-block">
                    {this.state.specificationOpened
                      ? product.specs.map((spec, index) => (
                          <div key={index} className="properties-row">
                            <label>
                              {_startCase(
                                t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name))
                              )}
                            </label>
                            <div className="property-value">{spec.value}</div>
                          </div>
                        ))
                      : product.specs
                          .filter((spec, index) => index < 2)
                          .map((spec, index) => (
                            <div key={index} className="properties-row">
                              <label>
                                {_startCase(
                                  t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name))
                                )}
                              </label>
                              <div className="property-value">{spec.value}</div>
                            </div>
                          ))}
                  </div>
                  {product.specs.length > 2 && (
                    <div className="specificaiton-block-bottom">
                      <span
                        onClick={this.onSwitchSpecification}
                        className="specification-trigger"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {withIncotermsAndValidUntil && (
            <div className="properties-row quantities-row">
              <div className="row">
                <div className="col-md-4 form-group">
                  <div>
                    <label>{_startCase(t('common.incoterms'))}</label>
                    <div className="form-value">
                      <Field
                        className="form-control narrow"
                        component="select"
                        name="termsPreference"
                        disabled={readOnly}
                      >
                        {Object.values(incotermsList)
                          .filter((incoterm) => incoterm.code !== 'standard')
                          .map((incoterm, index) => {
                            return (
                              <option value={incoterm.code} key={index}>
                                {incoterm.label}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 form-group">
                  <div
                    className={classNames('with-datepicker', {
                      'with-error': touched.validUntil && errors.validUntil,
                    })}
                  >
                    <label>
                      {_startCase(
                        t('common.quote', 'Quote') +
                          ' ' +
                          t('common.valid', 'Valid') +
                          ' ' +
                          t('common.until', 'Until')
                      )}
                    </label>
                    <ContainerInputCalendarStyled>
                      <IconCalendarContainerStyled>
                        <IconCalendar width={18} height={18} color="#4d4d4d" />
                      </IconCalendarContainerStyled>
                      <DatePicker
                        name="validUntil"
                        className={'form-control'}
                        onChange={(date) => {
                          setFieldValue('validUntil', date.toISOString());
                        }}
                        onBlur={() => {
                          setFieldTouched('validUntil', true);
                        }}
                        selected={values.validUntil.length ? moment(values.validUntil) : null}
                        dateFormat="MM/DD/YYYY"
                        placeholderText="mm/dd/yyyy"
                        disabled={readOnly}
                        minDate={moment()}
                      />
                    </ContainerInputCalendarStyled>
                    {touched.validUntil && errors.validUntil && (
                      <label className="error">{t(`quote.validation.${errors.validUntil}`)}</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="properties-row">
            <label className="section-header underlined">
              {_startCase(t(`common.quantities`))}
            </label>
            <div className="quote-currency">
              {_capitalize(t('common.quote in'))}{' '}
              <span className="highlighted">{product.currency}</span>
            </div>
          </div>

          <div className="properties-row quantities-row">
            <div className="row">
              <div
                className={classNames('form-group col-md-4', {
                  'with-error': touched.minimumQuantity && errors.minimumQuantity,
                })}
              >
                <label>{_capitalize(t('common.quantity'))}</label>
                <div className="form-value">
                  <input
                    className="form-control narrow"
                    type="number"
                    maxLength="15"
                    name="minimumQuantity"
                    placeholder="MOQ"
                    onChange={(event) => {
                      const v = event.target.value;
                      const data = { ...values, minimumQuantity: v };
                      Object.keys(data.requiredQuantity).forEach((i) => {
                        if (data.requiredQuantity[i].q === parseInt(v, 10)) {
                          data.requiredQuantity[i].pricePerUnit = data.pricePerUnit;
                          data.requiredQuantity[i].leadTime = data.leadTime;
                        }
                        if (data.requiredQuantity[i].q < parseInt(v, 10)) {
                          data.requiredQuantity[i].pricePerUnit = '';
                          data.requiredQuantity[i].leadTime = '';
                        }
                      });
                      setValues(data);
                    }}
                    onBlur={() => {
                      setFieldTouched('minimumQuantity', true);
                    }}
                    value={values.minimumQuantity}
                    disabled={readOnly}
                  />
                  {touched.minimumQuantity && errors.minimumQuantity && (
                    <label className="error">
                      {t(`quote.validation.${errors.minimumQuantity}`)}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={classNames('form-group col-md-4', {
                  'with-error': touched.pricePerUnit && errors.pricePerUnit,
                })}
              >
                <label>{_startCase(t('common.price per unit'))}</label>
                <div className="form-value">
                  <input
                    className="form-control narrow"
                    type="number"
                    name="pricePerUnit"
                    placeholder="ex. $4.56"
                    onChange={(event) => {
                      const v = event.target.value;
                      const data = { ...values, pricePerUnit: v };
                      Object.keys(data.requiredQuantity).forEach((i) => {
                        if (data.requiredQuantity[i].q === parseInt(data.minimumQuantity, 10)) {
                          data.requiredQuantity[i].pricePerUnit = v;
                        }
                      });
                      setValues(data);
                    }}
                    onBlur={() => {
                      setFieldTouched('pricePerUnit', true);
                    }}
                    value={values.pricePerUnit}
                    disabled={readOnly}
                  />
                  {touched.pricePerUnit && errors.pricePerUnit && (
                    <label className="error">{t(`quote.validation.${errors.pricePerUnit}`)}</label>
                  )}
                </div>
              </div>
              <div
                className={classNames('form-group col-md-4', {
                  'with-error': touched.leadTime && errors.leadTime,
                })}
              >
                <label>{_startCase(t('quote.common.leadTimeLabel'))}</label>
                <div className="form-value">
                  <input
                    className="form-control narrow"
                    name="leadTime"
                    type="number"
                    placeholder="ex. 30"
                    onChange={(event) => {
                      const v = event.target.value;
                      const data = { ...values, leadTime: v };
                      Object.keys(data.requiredQuantity).forEach((i) => {
                        if (data.requiredQuantity[i].q === parseInt(data.minimumQuantity, 10)) {
                          data.requiredQuantity[i].leadTime = v;
                        }
                      });
                      setValues(data);
                    }}
                    onBlur={() => {
                      setFieldTouched('leadTime', true);
                    }}
                    value={values.leadTime}
                    disabled={readOnly}
                  />
                  {touched.leadTime && errors.leadTime && (
                    <label className="error">{t(`quote.validation.${errors.leadTime}`)}</label>
                  )}
                </div>
              </div>
            </div>
          </div>

          {values.requiredQuantity.map((item, index) => {
            const quantity = item.q;
            const fieldTouched =
              (touched.requiredQuantity && touched.requiredQuantity[index]) || {};
            const fieldError = (errors.requiredQuantity && errors.requiredQuantity[index]) || {};
            const fieldDisabled =
              (values.minimumQuantity && values.minimumQuantity >= quantity) || readOnly;

            return (
              <div className="properties-row quantities-row" key={index}>
                <div className="row">
                  <div className="form-group col-md-4">
                    <div className="form-value vertically-centered required-quantity">
                      {quantity}
                    </div>
                  </div>
                  <div
                    className={classNames('form-group col-md-4', {
                      'with-error': fieldTouched.pricePerUnit && fieldError.pricePerUnit,
                    })}
                  >
                    <div className="form-value">
                      <Field
                        className="form-control narrow"
                        type="number"
                        name={`requiredQuantity.${index}.pricePerUnit`}
                        placeholder={_startCase(t('common.price')) + ' (ex. 4.50)'}
                        disabled={fieldDisabled}
                      />
                      {fieldTouched.pricePerUnit && fieldError.pricePerUnit && (
                        <label className="error">
                          {t(`quote.validation.${fieldError.pricePerUnit}`)}
                        </label>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames('form-group col-md-4', {
                      'with-error': fieldTouched.leadTime && fieldError.leadTime,
                    })}
                  >
                    <div className="form-value">
                      <Field
                        className="form-control narrow"
                        name={`requiredQuantity.${index}.leadTime`}
                        placeholder={_startCase(t('quote.common.Lead Time in Days')) + ' (ex. 30)'}
                        disabled={fieldDisabled}
                      />
                      {fieldTouched.leadTime && fieldError.leadTime && (
                        <label className="error">
                          {t(`quote.validation.${fieldError.leadTime}`)}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {product.targetPrice && (
            <div data-id="quote-form-target-required-quantity">
              <div className="properties-row quantities-row">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <div
                      className={classNames('form-group', {
                        'with-error':
                          touched.targetRequiredQuantity && errors.targetRequiredQuantity,
                      })}
                    >
                      <label>{_startCase(t('common.target price'))}</label>
                      <Field
                        name="targetRequiredQuantity"
                        className="form-control"
                        placeholder={_startCase(t('common.quantity')) + ' (ex. 1000)'}
                        type="number"
                        disabled={
                          values.targetPriceCanNotReach ||
                          (values.minimumQuantity === values.targetRequiredQuantity &&
                            values.pricePerUnit === product.targetPrice) ||
                          readOnly
                        }
                        onChange={(event) => {
                          const { value } = event.target;
                          setFieldValue('targetRequiredQuantity', value);
                          debouncedAfterChangeTargetRequiredQuantity(this.props, value);
                        }}
                        onBlur={() => {
                          setFieldTouched('targetRequiredQuantity', true);
                        }}
                      />
                      {touched.targetRequiredQuantity && errors.targetRequiredQuantity && (
                        <label id="targetRequiredQuantity-error" className="error">
                          {t(`quote.validation.${errors.targetRequiredQuantity}`)}
                        </label>
                      )}
                    </div>
                    <div className="form-group">
                      <CheckboxStyled
                        id="targetPriceCanNotReach"
                        name="targetPriceCanNotReach"
                        type="checkbox"
                        onChange={() => {
                          setFieldValue('targetPriceCanNotReach', !values.targetPriceCanNotReach);
                        }}
                        onBlur={() => {
                          setFieldTouched('targetPriceCanNotReach', true);
                        }}
                        checked={values.targetPriceCanNotReach}
                        disabled={readOnly}
                      />
                      <CheckboxLabelStyled htmlFor="targetPriceCanNotReach">
                        {t('quote.common.Select here if you cannot reach this price')}
                      </CheckboxLabelStyled>
                    </div>
                  </div>
                  <div className="col-md-4 form-group">
                    <div className="">
                      <label>&nbsp;</label>
                      <div className="target-price-value" id="targetPrice">
                        {displayMoney(product.targetPrice, product.currency)}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 form-group">
                    <div
                      className={classNames('form-group', {
                        'with-error': touched.targetLeadTime && errors.targetLeadTime,
                      })}
                    >
                      <label>&nbsp;</label>
                      <Field
                        className="form-control narrow"
                        name={`targetLeadTime`}
                        type="number"
                        placeholder={_startCase(t('quote.common.Lead Time in Days')) + ' (ex. 30)'}
                        disabled={
                          values.targetPriceCanNotReach ||
                          (values.minimumQuantity === values.targetRequiredQuantity &&
                            values.pricePerUnit === product.targetPrice) ||
                          readOnly
                        }
                        onChange={(event) => {
                          const v = event.target.value;
                          const data = { ...values, targetLeadTime: v };
                          Object.keys(data.requiredQuantity).forEach((i) => {
                            if (data.requiredQuantity[i].q === parseInt(v, 10)) {
                              data.requiredQuantity[i].leadTime = v;
                            }
                          });
                          setValues(data);
                        }}
                        onBlur={() => {
                          setFieldTouched('targetLeadTime', true);
                        }}
                      />
                      {touched.targetLeadTime && errors.targetLeadTime && (
                        <label className="error">
                          {t(`quote.validation.${errors.targetLeadTime}`)}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </fieldset>

        {errors.common && <label className="error common">{t(errors.common, errors.common)}</label>}
        {!this.props.readOnly && !this.props.withoutSubmitButton && (
          <div className="actions-bottom actions-bottom-centered">
            <button
              className="btn blue-button wide-button big-button"
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                setValues({
                  ...values,
                  samplesNumber: 0,
                  samplesTotalPrice: '',
                  samplesLeadTime: '',
                  samplesShippingCost: '',
                  questions: [{ q: '', a: '' }],
                  cartonSize: '',
                  quantityPerCarton: '',
                  cartonGrossWeight: '',
                  cartonNetWeight: '',
                });
                setFieldValue('status', 'complete');
                handleSubmit(values, { props: this.props, setSubmitting, setErrors });
              }}
            >
              {t('quote.common.Send Quote and Create Account')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export const Step2ValidationScheme = {
  validUntil: Yup.string().required('Quote Valid Until is required'),
  ...QuantitiesValidationScheme,
};

export default QuoteFormSignupAStep2;
