import React from 'react';
import PropTypes from 'prop-types';

const IconLocation = (props) => {
  const viewBox = `0 0 12 16`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.5 6c0 4.5-5.5 9.5-5.5 9.5S.5 10.5.5 6a5.5 5.5 0 0 1 11 0z" />
        <circle cx="6" cy="6" r="2.5" />
      </g>
    </svg>
  );
};

IconLocation.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconLocation;
