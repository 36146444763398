import React from 'react';
import PropTypes from 'prop-types';
import _startCase from 'lodash.startcase';

class TableUsers extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    initialUserIds: PropTypes.array,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialUserIds: [],
  };

  render() {
    const { users, values, setFieldValue, initialUserIds } = this.props;
    let changeableUsersLength = 0;
    users.forEach((user) => {
      if (user.role !== 'owner' && user.role !== 'admin') {
        changeableUsersLength++;
      }
    });

    return (
      <table className="table black-table actions-first-column project-users-table">
        <thead>
          <tr>
            <th scope="col">
              <input
                type={'checkbox'}
                checked={values.users.length === changeableUsersLength}
                onChange={() => {
                  const areAllSelected = values.users.length === changeableUsersLength;
                  setFieldValue(
                    'users',
                    users
                      .filter(
                        (user) => !areAllSelected && user.role !== 'owner' && user.role !== 'admin'
                      )
                      .map((user) => user._id)
                  );
                }}
                onBlur={() => {}}
              />
            </th>
            <th scope="col">NAME</th>
            <th scope="col">ROLE</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr
                key={user._id}
                onClick={() => {
                  if (user.role === 'owner' || user.role === 'admin') {
                    return;
                  }

                  if (values.users.indexOf(user._id) >= 0) {
                    let selectedUsers = values.users.slice();
                    selectedUsers.splice(values.users.indexOf(user._id), 1);
                    setFieldValue('users', selectedUsers);
                  } else {
                    setFieldValue('users', [...values.users, user._id]);
                  }
                }}
              >
                <td>
                  <input
                    type={'checkbox'}
                    checked={
                      values.users.indexOf(user._id) >= 0 ||
                      user.role === 'owner' ||
                      user.role === 'admin'
                    }
                    onChange={() => {}}
                    disabled={user.role === 'owner' || user.role === 'admin'}
                    className={
                      !values.users.includes(user._id) && initialUserIds.includes(user._id)
                        ? 'removed-item'
                        : ''
                    }
                  />
                </td>
                <td>{user.fullName}</td>
                <td>{_startCase(user.role)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default TableUsers;
