import React from 'react';
import PropTypes from 'prop-types';

class IconClose extends React.Component {
  static defaultProps = {
    color: '#4d4d4d',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 191.414 191.414"
        enableBackground="new 0 0 191.414 191.414"
      >
        <g>
          <path
            fill={this.props.color}
            d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z"
          />
        </g>
      </svg>
    );
  }
}

IconClose.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string,
};

export default IconClose;
