import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Modal from '../../common/Modal/index';

class ModalCongrats extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal>
        <Popup
          overlayStyle={{ pointerEvents: 'none' }}
          contentStyle={{ width: '340px', padding: '0px !important' }}
          className={'popup-signup-just-done'}
          open={true}
          onClose={this.props.onClose}
          modal
        >
          <div className="sign-container">
            <div className="content">
              <div className="logo" />
              <label className="form-header">Congratulations!</label>
              <label className="form-header">Your account has been created.</label>
              <div className="row">
                <div className="form-group col-sm-12">
                  <button
                    id="signup-complete-modal-congrats-finish-btn"
                    onClick={this.props.onClick}
                    className="btn"
                    type="submit"
                  >
                    Finished
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalCongrats;
