import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import Modal from '../../common/Modal/index';

const CompanyTextStyled = styled.label`
  padding: 0 40px;
  font-size: 16px;
  text-align: center;
  color: #0095ff;
  margin-bottom: 12px;
`;

const BoldButtonStyled = styled.button`
  font-weight: bold;
`;

class ModalCongrats extends React.Component {
  static propTypes = {
    company: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal>
        <Popup
          contentStyle={{ width: '340px', padding: '0px !important' }}
          className={'popup-signup-just-done'}
          open={true}
          onClose={this.props.onClose}
          modal
        >
          <div className="sign-container">
            <div className="content">
              <div className="logo" />
              <label className="form-header">You’ve been invited to join a new team.</label>
              <CompanyTextStyled>
                Would you like to join the {this.props.company.name} Importist Company?
              </CompanyTextStyled>
              <div className="row">
                <div className="form-group col-sm-12">
                  <button onClick={this.props.onSubmit} className="btn" type="submit">
                    Accept
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-12">
                  <BoldButtonStyled
                    onClick={this.props.onClose}
                    className="btn danger-button"
                    type="button"
                  >
                    Decline
                  </BoldButtonStyled>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalCongrats;
