import React from 'react';
import PropTypes from 'prop-types';

const IconDotsHorizontal = (props) => {
  const viewBox = `0 0 ${props.width} ${props.height}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <g fill={props.color} fillRule="evenodd">
        <circle cx="3" cy="3" r="3" />
        <circle cx="12" cy="3" r="3" />
        <circle cx="21" cy="3" r="3" />
      </g>
    </svg>
  );
};

IconDotsHorizontal.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconDotsHorizontal;
