import React from 'react';
import PropTypes from 'prop-types';

const IconGroup = (props) => {
  const viewBox = `0 0 ${props.width} ${props.height}`;
  return (
    <div style={props.style} className={'icon-container'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox={viewBox}
      >
        <path
          fill="#FE374F"
          fillRule="nonzero"
          stroke="#FE374F"
          strokeWidth=".2"
          d="M2.24 1C1.56 1 1 1.484 1 2.071V14.93C1 15.516 1.56 16 2.24 16h16.52c.68 0 1.24-.484 1.24-1.071V3.5c0-.588-.56-1.071-1.24-1.071H7.61c.035 0-.013 0-.114-.1-.101-.101-.225-.268-.355-.447-.13-.18-.266-.37-.437-.538C6.53 1.176 6.295 1 5.957 1H2.239zm0 .714h3.717c-.026 0 .025.003.127.103.102.1.227.266.356.444.129.178.263.369.43.536.168.167.4.346.739.346H18.76c.232 0 .413.156.413.357v.781a1.382 1.382 0 0 0-.413-.067H2.239c-.145 0-.283.027-.413.067v-2.21c0-.2.18-.357.413-.357zm0 3.215h16.52c.234 0 .414.155.414.357v9.643c0 .2-.18.357-.413.357H2.239c-.233 0-.413-.156-.413-.357V5.286c0-.202.18-.357.413-.357z"
        />
      </svg>
    </div>
  );
};

IconGroup.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  strokeColor: PropTypes.string.isRequired,
};

export default IconGroup;
