import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import QuoteFormSignupBSignup from '../QuoteFormSignupBSignup';
import QuoteFormSignupAStep2, { Step2ValidationScheme } from '../QuoteFormSignupAStep2';

import {
  prepareQuoteValues,
  prepareAccountValues,
  validateRequiredQuantity,
  SignupValidationScheme,
} from '../QuoteForm/helper';
import '../QuoteFormSignupA/style.css';

export class Form extends Component {
  static defaultProps = {
    readOnly: false,
    signupAndSendQuoteDone: false,
  };

  static propTypes = {
    product: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.string,
    userCompanyId: PropTypes.string,
    onSubmit: PropTypes.func,
    signupAndSendQuoteDone: PropTypes.bool,
    readOnly: PropTypes.bool,
    goToClientProfile: PropTypes.func,
    t: PropTypes.func,
  };

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form
        className={'padding-bottom-180 quote-request-form landing-quote-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {!this.props.signupAndSendQuoteDone && (
          <div>
            <QuoteFormSignupBSignup {...this.props} />
            <QuoteFormSignupAStep2 {...this.props} />
          </div>
        )}

        {this.props.signupAndSendQuoteDone && (
          <div className="form-step-content form-step-3-content">
            <h2>{t('quote.common.Your quote has been sent!')}</h2>
            <p>{t('quote.common.lastStepImportistNote')}</p>
            <button
              className="btn blue-button big-button wide-button"
              type="button"
              onClick={() => {
                this.props.signUpAndSendQuoteOnFinished();
              }}
            >
              {t('quote.common.Complete Your Supplier Account')}
            </button>
          </div>
        )}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  ...SignupValidationScheme,
  ...Step2ValidationScheme,
});

const QuoteFormSignupB = withFormik({
  mapPropsToValues: (props) => {
    const quoteValues = prepareQuoteValues(props);
    const accountValues = prepareAccountValues(props);

    return {
      ...quoteValues,
      ...accountValues,
    };
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantity(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }

    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

QuoteFormSignupB.defaultProps = {
  quote: {},
};

export default QuoteFormSignupB;
