import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from '../../../util/moment';
import styled from 'styled-components';

import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import ChatMessageQuoteTable from '../../../component/chat/ChatMessageQuoteTable';
import ChatMessageFile from '../../../component/chat/ChatMessageFile';

const CompanyPhotoStyled = styled.div`
  width: 46px;
  height: 46px;
  background: center/contain no-repeat;
  margin-right: 20px;
`;

const CompanyPhotoStyledPlaceholder = styled.img`
  width: 46px;
  height: 46px;
  margin-right: 20px;
`;

class ChatMessageItem extends React.Component {
  static propTypes = {
    goToQuote: PropTypes.func.isRequired,
    chatProduct: PropTypes.object.isRequired,
    company: PropTypes.shape({
      name: PropTypes.string,
      logoUrl: PropTypes.string,
    }),
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      quote: PropTypes.object,
      message: PropTypes.string,
      file: PropTypes.object,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  };

  render() {
    const { item, chatProduct, company } = this.props;
    return (
      <div>
        {item.newDate && (
          <div className="heading-mark">
            <span className="mark-text">Today</span>
          </div>
        )}
        {item.newMessagesStart && (
          <div className="heading-mark new-messages">
            <span className="mark-text">New Messages</span>
          </div>
        )}
        <div
          className={classNames('message', {
            'quote-sent-message': item.quoteMessage,
          })}
        >
          <div className="chat-heading">
            {company.logoUrl ? (
              <CompanyPhotoStyled style={{ backgroundImage: `url(${company.logoUrl})` }} />
            ) : (
              <CompanyPhotoStyledPlaceholder src={imgPlaceholder} alt={''} />
            )}
            <div className="heading-with-names">
              <div className="company-name">{company.name}</div>
              <div className="contact-name">{item.userName}</div>
            </div>
            <div className="time-stamp">{moment(item.createdAt).format()}</div>
          </div>
          <div className="chat-message">{item.message}</div>
          {item.quote && chatProduct && (
            <ChatMessageQuoteTable
              quote={item.quote}
              product={chatProduct}
              goToQuote={this.props.goToQuote}
            />
          )}
          {item.file && item.file.key && <ChatMessageFile file={item.file} />}
        </div>
      </div>
    );
  }
}

export default ChatMessageItem;
