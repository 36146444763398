import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import * as Yup from 'yup';
import _startCase from 'lodash.startcase';
import _capitalize from 'lodash.capitalize';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import QuoteFormSignupDStep1 from '../QuoteFormSignupDStep1';
import QuoteFormSignupDStep2 from '../QuoteFormSignupDStep2';
import QuoteFormSignupDStep3 from '../QuoteFormSignupDStep3';
import { Step2ValidationScheme } from '../QuoteFormSignupAStep2';
import {
  prepareQuoteValues,
  prepareAccountValues,
  validateRequiredQuantity,
  SignupValidationScheme,
} from '../QuoteForm/helper';
import FormStepsIndicator from '../../../component/form/FormStepsIndicator';
import ErrorTop from '../../form/ErrorTop';

import '../QuoteFormSignupA/style.css';

const ProductImageStyled = styled.div`
  width: 500px;
  height: 500px;
  background: center/contain no-repeat;
`;

const ProductImageStyledPlaceholder = styled.img`
  width: 290px;
  height: 290px;
`;

const PhotoStyled = styled.div`
  width: 75px;
  height: 75px;
  margin: 30px;
  background: top center/contain no-repeat;
`;

export class Form extends Component {
  static defaultProps = {
    readOnly: false,
    signupAndSendQuoteDone: false,
  };

  static propTypes = {
    product: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.string,
    userCompanyId: PropTypes.string,
    onSubmit: PropTypes.func,
    signupAndSendQuoteDone: PropTypes.bool,
    signUpAndSendQuoteOnFinished: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    goToClientProfile: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentStep: 1,
    photoIndex: 0,
    isLightboxOpen: false,
    specificationOpened: false,
  };

  onChangeFormStep = (stepNumber) => {
    this.setState({ currentStep: stepNumber });
  };

  onSwitchSpecification = () => {
    this.setState({ specificationOpened: !this.state.specificationOpened });
  };

  render() {
    const { isValid, product, errors, isSubmitting, handleSubmit, t } = this.props;
    const clientName = this.props.company.name;
    const productName = this.props.product.name;

    if (this.props.signupAndSendQuoteDone && this.state.currentStep !== 4) {
      this.setState({ currentStep: 4 });
    }

    if (errors.email === 'Email address is already registered') {
      if (this.state.currentStep !== 3) {
        this.setState({ currentStep: 3 });
      }
    }

    return !this.props.signupAndSendQuoteDone ? (
      <form
        className={'padding-bottom-180 quote-request-form landing-quote-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <div className="landing-quote-big-heading">
            <Trans i18nKey="quote.common.headerText">
              <strong>{{ clientName }}</strong> has invited you to quote for&nbsp;
              <strong className="highlighted">{{ productName }}</strong> on the Importist Platform.
            </Trans>
          </div>
          <p style={{ margin: '0 0 5px' }}>{t('quote.common.importistShortDescription')}</p>
          <p>
            {t('quote.common.Already have an account?')} &nbsp;
            <a href="/signin">{_capitalize(t('common.sign in'))}</a>
          </p>
          <div className="landing-quote-big-heading highlighted">
            {t('quote.common.formShortDescription')}
          </div>
        </div>

        <div className="row heading-row">
          <div className="col-md-6 images-specification">
            {product.photosUrl[0] ? (
              <ProductImageStyled
                style={{ backgroundImage: `url(${product.photosUrl[0]})` }}
                className="big-product-image"
              />
            ) : (
              <ProductImageStyledPlaceholder src={imgPlaceholder} alt={''} />
            )}
            {product.photosUrl.map((photoUrl, index) => {
              return (
                <PhotoStyled
                  style={{ backgroundImage: `url(${photoUrl})` }}
                  onClick={() => {
                    this.setState({ isLightboxOpen: true, photoIndex: index });
                  }}
                  className="photo-thumbnail"
                  key={index}
                />
              );
            })}
            <div className="properties-row">
              <label className="section-header">{_startCase(t(`common.specification`))}</label>
            </div>
            <div
              className={classNames('specification', {
                opened: this.state.specificationOpened,
              })}
              style={{ marginBottom: '40px' }}
            >
              <div className="specification-block">
                {this.state.specificationOpened
                  ? product.specs.map((spec, index) => (
                      <div key={index} className="properties-row">
                        <label>
                          {_startCase(
                            t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name))
                          )}
                        </label>
                        <div className="property-value">{spec.value}</div>
                      </div>
                    ))
                  : product.specs
                      .filter((spec, index) => index < 2)
                      .map((spec, index) => (
                        <div key={index} className="properties-row">
                          <label>
                            {_startCase(
                              t(`common.${spec.name.toLowerCase()}`, _startCase(spec.name))
                            )}
                          </label>
                          <div className="property-value">{spec.value}</div>
                        </div>
                      ))}
              </div>
              {product.specs.length > 2 && (
                <div className="specificaiton-block-bottom">
                  <span onClick={this.onSwitchSpecification} className="specification-trigger" />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <FormStepsIndicator
              stepsNames={[
                _startCase(t('common.volumes')),
                _startCase(t('common.terms')),
                _startCase(t('common.signup')),
                _startCase(t('common.send')),
              ]}
              currentStep={this.state.currentStep}
            />

            <ErrorTop
              isSubmitting={isSubmitting}
              isValid={isValid}
              errors={errors}
              commonErrorText={t('Please, fix errors below')}
            />

            {this.state.currentStep === 1 && (
              <QuoteFormSignupDStep1 {...this.props} onChangeFormStep={this.onChangeFormStep} />
            )}

            {this.state.currentStep === 2 && (
              <QuoteFormSignupDStep2 {...this.props} onChangeFormStep={this.onChangeFormStep} />
            )}

            {this.state.currentStep === 3 &&
              (!this.props.readOnly && (
                <QuoteFormSignupDStep3 {...this.props} onChangeFormStep={this.onChangeFormStep} />
              ))}
          </div>
        </div>
      </form>
    ) : (
      <form
        className={'padding-bottom-180 quote-request-form landing-quote-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="form-step-content form-step-3-content">
          <h2>{t('quote.common.Your quote has been sent!')}</h2>
          <p>{t('quote.common.lastStepImportistNote')}</p>
          <button
            className="btn blue-button big-button wide-button"
            type="button"
            onClick={() => {
              this.props.signUpAndSendQuoteOnFinished();
            }}
          >
            {t('quote.common.Complete Your Supplier Account')}
          </button>
        </div>
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  ...SignupValidationScheme,
  ...Step2ValidationScheme,
});

const QuoteFormSignupD = withFormik({
  mapPropsToValues: (props) => {
    const quoteValues = prepareQuoteValues(props);
    const accountValues = prepareAccountValues(props);

    return {
      ...quoteValues,
      ...accountValues,
    };
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantity(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }

    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

QuoteFormSignupD.defaultProps = {
  quote: {},
};

export default QuoteFormSignupD;
