import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios, { CancelToken } from 'axios';
import api from '../../../service/api';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import _cloneDeep from 'lodash.clonedeep';
import ProductQuoteHeader from '../../../component/product/ProductQuoteHeader';
import ProductDetails from '../../../component/product/ProductDetails';
import ProductQuotesTable from '../../../component/product/ProductQuotesTable';
import ClientQuoteContainer from '../ClientQuoteContainer';
import IconClose from '../../../component/icon/IconClose';
import './style.css';

export class ProductQuotesContainer extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      product: null,
      productQuotes: [],
      productName: '',
      projects: [],
      quote: {},
      quoteId: '',
      companyName: '',
      createdByCompanyId: '',
      getCancelToken: CancelToken.source(),
    };
  }

  componentDidMount = async () => {
    const companyId = this.props.companyId;
    const projectId = this.props.projectId;
    const productId = this.props.match.params.productId;
    try {
      const [productRes, quotesRes] = await Promise.all([
        api.productGet(companyId, projectId, productId, this.state.getCancelToken.token),
        api.productQuotesGet(productId, this.state.getCancelToken.token),
      ]);
      this.setState({
        product: productRes.data,
        productQuotes: quotesRes.data,
        productName: productRes.data.name,
        projects: this.props.projects,
        isLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.isExact !== this.props.match.isExact && this.props.match.isExact) {
      this.setState({ companyName: '' });
    }
  }

  onSwitchFavorite = async (quoteId, quoteQuantity, setVal) => {
    try {
      await api.quoteFavoritePut(quoteId, {
        quantity: quoteQuantity,
        favorite: setVal,
      });
      const productQuotes = _cloneDeep(this.state.productQuotes);
      productQuotes.map((quote) => {
        quote.requiredQuantity.map((quantity) => {
          if (quote._id === quoteId && quoteQuantity === quantity.q) {
            quantity.favorite = setVal;
          }
          return false;
        });
        return false;
      });
      this.setState({
        productQuotes: productQuotes,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
      this.setState({
        productQuotes: {},
      });
    }
  };

  getQuote = async (quoteId) => {
    const productId = this.props.match.params.productId;
    try {
      const quoteRes = await api.quoteGet(productId, quoteId, this.state.getCancelToken.token);
      this.setState({
        quote: quoteRes.data,
        companyName: quoteRes.data.createdByCompany.name,
        createdByCompanyId: quoteRes.data.createdByCompany._id,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  componentWillUnmount() {
    this.state.getCancelToken.cancel();
  }

  render() {
    const basePath = `/dashboard/company/${this.props.companyId}/project/${
      this.props.projectId
    }/product/${this.props.match.params.productId}`;
    const layoutContainer = classNames({
      'product-quotes-container': this.props.history.location.pathname === `${basePath}/quotes`,
      'product-quote-container': this.props.history.location.pathname.includes(
        `${basePath}/quotes/`
      ),
    });

    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className={layoutContainer}>
        <div className={'icon-close-container'} onClick={this.props.history.goBack}>
          <IconClose width={18} height={18} color={'black'} />
        </div>
        <ProductQuoteHeader
          companyType={this.props.companyType}
          projects={this.state.projects}
          productName={this.state.productName}
          projectId={this.props.projectId}
          companyName={this.state.companyName}
          companionCompanyId={this.state.createdByCompanyId}
          activeCompanyId={this.props.companyId}
          activeProjectId={this.props.projectId}
          history={this.props.history}
        />
        <ProductDetails
          product={this.state.product}
          onViewFullSpec={() => {
            this.props.history.push(`${basePath}/full`);
          }}
        />
        <Switch>
          <Route
            exact
            path={`${basePath}/quotes`}
            render={() => (
              <ProductQuotesTable
                productQuotes={this.state.productQuotes}
                onSwitchFavorite={this.onSwitchFavorite}
                history={this.props.history}
                companyId={this.props.companyId}
                projectId={this.props.projectId}
                productId={this.props.match.params.productId}
                getQuote={this.getQuote}
              />
            )}
          />
          <Route
            path={`${basePath}/quotes/:quoteId`}
            history={this.props.history}
            render={() => <ClientQuoteContainer productId={this.props.match.params.productId} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.dashboard.activeProjectId,
  companyId: state.dashboard.activeCompanyId,
  projects: state.dashboard.projects,
  companyType: state.dashboard.company.type,
});

export default withRouter(connect(mapStateToProps)(ProductQuotesContainer));
