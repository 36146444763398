import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import * as navigation from './navMenu';
import QuoteFormSectionHeader, { HeaderValidationScheme } from '../QuoteFormSectionHeader';
import StickyMenu from '../../common/StickyMenu';
import QuoteFormSectionSpecifications from '../QuoteFormSectionSpecifications';
import QuoteFormSectionQuantities from '../QuoteFormSectionQuantities';
import QuoteFormSectionSamples, { SamplesValidationScheme } from '../QuoteFormSectionSamples';
import QuoteFormSectionQuestions, { QuestionsValidationScheme } from '../QuoteFormSectionQuestions';
import ErrorTop from '../../form/ErrorTop';
import {
  prepareQuoteValues,
  validateRequiredQuantity,
  QuantitiesValidationScheme,
  ShippingValidationScheme,
} from './helper';

export class Form extends Component {
  static defaultProps = {
    navigation: navigation,
    readOnly: false,
  };

  static propTypes = {
    product: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.string,
    userCompanyId: PropTypes.string,
    onSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    goToClientProfile: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      values,
      errors,
      setFieldValue,
      setErrors,
      setSubmitting,
      isValid,
      isSubmitting,
      handleSubmit,
      t,
    } = this.props;

    const sectionHeaderRefs = [navigation.sectionSpecifications, navigation.sectionQuantities];
    if (values.samplesNumber > 0) {
      sectionHeaderRefs.push(navigation.sectionSamples);
    }
    if (values.questions.length > 0) {
      sectionHeaderRefs.push(navigation.sectionExtraQuestions);
    }

    return (
      <form
        className={'padding-bottom-180 quote-request-form'}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <QuoteFormSectionHeader {...this.props} />
        <ErrorTop
          isSubmitting={isSubmitting}
          isValid={isValid}
          errors={errors}
          commonErrorText={t('Please, fix errors below')}
        />
        <StickyMenu items={sectionHeaderRefs} />
        <label className="section-header" ref={this.props.navigation.sectionSpecifications.ref}>
          {this.props.navigation.sectionSpecifications.label}
        </label>
        <QuoteFormSectionSpecifications product={this.props.product} t={t} />
        <label className="section-header" ref={this.props.navigation.sectionQuantities.ref}>
          {this.props.navigation.sectionQuantities.label}
        </label>
        <QuoteFormSectionQuantities {...this.props} />
        {values.samplesNumber > 0 && (
          <label className="section-header" ref={this.props.navigation.sectionSamples.ref}>
            {this.props.navigation.sectionSamples.label}
          </label>
        )}
        {values.samplesNumber > 0 && <QuoteFormSectionSamples {...this.props} />}

        {values.questions.length > 0 && (
          <label className="section-header" ref={this.props.navigation.sectionExtraQuestions.ref}>
            {this.props.navigation.sectionExtraQuestions.label}
          </label>
        )}
        {values.questions.length > 0 && <QuoteFormSectionQuestions {...this.props} />}

        {errors.common && <label className="error common">{t(errors.common, errors.common)}</label>}
        {!this.props.readOnly && (
          <div className={'actions-bottom'}>
            <button
              className="btn white-button"
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                setFieldValue('status', 'draft');
                handleSubmit(values, { props: this.props, setSubmitting, setErrors });
              }}
            >
              Save Draft
            </button>
            <button
              className="btn blue-button"
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                setFieldValue('status', 'complete');
                handleSubmit(values, { props: this.props, setSubmitting, setErrors });
              }}
            >
              Send Quote
            </button>
          </div>
        )}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  ...HeaderValidationScheme,
  ...QuantitiesValidationScheme,
  ...ShippingValidationScheme,
  ...SamplesValidationScheme,
  ...QuestionsValidationScheme,
});

const QuoteForm = withFormik({
  mapPropsToValues: (props) => {
    const quoteValues = prepareQuoteValues(props);

    return quoteValues;
  },
  validate: (values) => {
    const errors = {};
    const requiredQuantity = validateRequiredQuantity(values);
    if (requiredQuantity) {
      errors.requiredQuantity = requiredQuantity;
    }

    return errors;
  },
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

QuoteForm.defaultProps = {
  quote: {},
};

export default QuoteForm;
