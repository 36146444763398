import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from '../../../util/moment';
import { displayMoney } from '../../../util/currency';

export default class ChatMessageQuoteTable extends Component {
  static propTypes = {
    goToQuote: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    quote: PropTypes.shape({
      _id: PropTypes.string,
      productId: PropTypes.string,
      projectId: PropTypes.string,
      productCompanyId: PropTypes.string,
      createdByCompanyId: PropTypes.string,
      createdBy: PropTypes.string,
      status: PropTypes.string,
      validUntil: PropTypes.string,
      currency: PropTypes.string,
      requiredQuantity: PropTypes.array,
      termsPreference: PropTypes.string,
      cartonSize: PropTypes.string,
      quantityPerCarton: PropTypes.number,
      cartonGrossWeight: PropTypes.number,
      cartonNetWeight: PropTypes.number,
      samplesTotalPrice: PropTypes.number,
      samplesLeadTime: PropTypes.string,
      samplesShippingCost: PropTypes.number,
      questions: PropTypes.array,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  };

  render() {
    const { product, quote } = this.props;
    let displayTargetQuantityMarkNote = false;

    return (
      product &&
      quote && (
        <div>
          <table className="message-quote-table">
            <thead>
              <tr>
                <th valign="top">
                  <div className="product-name">{product.name}</div>
                  <div className="quoted-date">Quoted:</div>
                  <div className="quoted-date">{moment(product.createdAt).format()}</div>
                </th>
                <th valign="top">
                  {this.props.product.targetPrice > 0 && (
                    <div className="target-price">Target Price</div>
                  )}
                  {this.props.product.targetPrice > 0 && (
                    <div className="target-price">
                      {displayMoney(this.props.product.targetPrice, this.props.product.currency)}
                    </div>
                  )}
                </th>
                <th valign="top">
                  <div
                    className="btn white-button"
                    onClick={() => this.props.goToQuote(this.props.quote._id)}
                  >
                    View Quote
                  </div>
                </th>
              </tr>
            </thead>

            <thead>
              <tr>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Lead Time (Days)</th>
              </tr>
            </thead>
            <tbody>
              {quote.requiredQuantity &&
                quote.requiredQuantity.map((quantity, index) => {
                  if (quantity.target) {
                    displayTargetQuantityMarkNote = true;
                  }

                  return (
                    <tr key={index}>
                      <td className={'top-baseline'}>
                        {quantity.q}
                        {quantity.q === 1 ? <span> unit</span> : <span> units</span>}
                        {index === 0 && <sup className="important-mark">*</sup>}
                        {quantity.target && <sup className="target-mark">*</sup>}
                      </td>
                      <td className={'top-baseline'}>
                        {displayMoney(quantity.pricePerUnit, quote.currency)}
                      </td>
                      <td className={'top-baseline'}>{quantity.leadTime}</td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan="3" className="quantity-mark">
                  <span className="important-mark-note">
                    <span className="important-mark">* </span>
                    minimum order quantity
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="quantity-mark target-price-quantity-mark">
                  {displayTargetQuantityMarkNote && (
                    <div>
                      <span className="important-mark-note">
                        <span className="target-mark">* </span>
                        order quantity required for requested target price
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );
  }
}
