import React from 'react';
import PropTypes from 'prop-types';

class IconDuplicate extends React.Component {
  static defaultProps = {
    strokeColor: '#111',
  };

  static propTypes = {
    width: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
    strokeColor: PropTypes.string,
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={this.props.strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M7.5 5.5h8v10h-8z" />
          <path d="M4.5 13.5h-4V.5h11v2" />
        </g>
      </svg>
    );
  }
}

export default IconDuplicate;
