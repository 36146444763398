import React from 'react';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';
import Modal from '../../common/Modal/index';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import api from '../../../service/api';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../../util/error';

class ModalInviteUsers extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Modal>
        <Popup
          className="popup-invite-users"
          open={true}
          onClose={this.props.onClose}
          modal
          contentStyle={{ padding: '50px' }}
        >
          <div>
            <label className="form-header">
              Invite your team to use <span className="blue-color">Importist</span>!
            </label>
            <div className="row">
              <div className="form-group col-sm-6">
                <label>Add team members via email</label>
                <div className="description">
                  You can add multiple email addresses. Please separate email addresses with a comma
                  (,).
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                emails: [],
              }}
              validationSchema={Yup.object().shape({
                emails: Yup.array()
                  .required('Type at least one email address')
                  .of(Yup.string().email('One or more email address is not valid')),
              })}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                api
                  .userEmailInvites(this.props.companyId, values.emails)
                  .then(() => {
                    setSubmitting(false);
                    this.props.onClose();
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    const errors = errorsTransformAxiosToFormik(err);
                    setErrors(addCommonFormikErrorsIfEmpty(errors));
                  });
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <textarea
                        name="emails"
                        value={values.emails}
                        onChange={(event) => {
                          setFieldValue('emails', event.target.value.split(/[\s,]+/));
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.emails && errors.emails && (
                        <label className="error">{errors.emails}</label>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <button className="btn blue-button" type="submit" disabled={isSubmitting}>
                        Invite
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalInviteUsers;
