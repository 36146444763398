import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Raven from 'raven-js';
import styled from 'styled-components';

import {
  chatCompanyUpdateUserReadCount,
  chatCurrentMessagesNewSuccess,
  chatUpdateReadCount,
  fetchChatMessages,
  sendChatMessage,
} from '../actions';
import moment from '../../../util/moment';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import IconSendMessage from '../../../component/icon/IconSendMessage';
import ChatMessageItem from '../../../component/chat/ChatMessageItem';
import ContentEditable from 'react-contenteditable';
import pusher from '../../../service/pusher';
import { getChatChannelName } from '../../../util/wsChannel';
import InfiniteScrollReversed from '../../../component/common/InfiniteScrollReversed';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { attachmentS3SignedPut } from '../../../util/s3';

const ProductPhotoStyled = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

export class ChatMessagesContainer extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    activeCompanyId: PropTypes.string.isRequired,
    activeCompany: PropTypes.object.isRequired,
    activeProjectId: PropTypes.string,
    messages: PropTypes.shape({
      data: PropTypes.array.isRequired,
      loading: PropTypes.bool.isRequired,
    }),
    chat: PropTypes.object.isRequired,
    isTabActive: PropTypes.bool,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    currentMessage: '',
    isFilesUploading: false,
  };

  chatChannel = null;

  chatMessagesContainerRef = React.createRef();
  chatMessagesHeaderRef = React.createRef();
  chatMessagesRef = React.createRef();

  wsChatChannelOnNewChatMessageFull = (res) => {
    // Update read count only if it isn't current user's message
    if (this.props.isTabActive && res.data.userId !== this.props.user._id) {
      this.updateChatReadCount(this.props.chat);
    }
    this.props.currentChatMessagesNew([res.data]);
  };

  wsSubscribeChatChannel = () => {
    this.chatChannel = pusher.subscribe(getChatChannelName(this.props.chat._id));
    if (this.chatChannel) {
      this.chatChannel.bind('newChatMessageFull', this.wsChatChannelOnNewChatMessageFull);
    }
  };

  wsUnsubscribeChatChannel = (chatId) => {
    pusher.unsubscribe(getChatChannelName(chatId));
  };

  componentDidMount() {
    this.updateChatReadCount(this.props.chat);
    // TODO fix possible race condition because of latency of channel subscription process
    this.wsSubscribeChatChannel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chat._id !== this.props.chat._id) {
      this.wsUnsubscribeChatChannel(prevProps.chat._id);
      this.wsSubscribeChatChannel();
      this.updateChatReadCount(this.props.chat);
    } else if (!prevProps.isTabActive && this.props.isTabActive) {
      this.updateChatReadCount(this.props.chat);
    }
  }

  componentWillUnmount() {
    this.updateChatReadCount(this.props.chat);
    this.wsUnsubscribeChatChannel(this.props.chat._id);
  }

  goToQuote = (quoteId) => {
    if (this.props.activeCompany.type === 'client') {
      this.props.history.push(
        `/dashboard/company/${this.props.activeCompanyId}/project/${
          this.props.activeProjectId
        }/product/${this.props.chat.product._id}/quotes/${quoteId}`
      );
    } else {
      this.props.history.push(`/dashboard/company/${this.props.activeCompanyId}/quotes/${quoteId}`);
    }
  };

  onFetchChatMessages = (page, isFirstPage = false) => {
    this.props.fetchChatMessages(this.props.activeCompanyId, this.props.chat._id, {
      isFirstPage: isFirstPage,
      page: page,
    });
  };

  updateChatReadCount = (chat) => {
    this.props.updateChatReadCount(chat);
  };

  onMessageInputUpdate = (e) => {
    this.setState({ currentMessage: e.target.value });
  };

  onMessageInputKeyPress = (evt) => {
    let e = evt.nativeEvent;
    if (e.code === 'Enter') {
      e.preventDefault();
      if (e.ctrlKey || e.shiftKey) {
        document.execCommand('insertText', false, '\n');
      } else {
        this.sendMessage();
      }
    }
  };

  onAttachmentChange = async (e) => {
    e.preventDefault();
    this.setState({ isFilesUploading: true });
    const { files } = e.target;
    const companyId = this.props.activeCompanyId;
    try {
      const uploadingPromises = [];
      for (let i = 0; i < files.length; i++) {
        uploadingPromises.push(attachmentS3SignedPut(files[i]));
      }

      const uploadedFiles = await Promise.all(uploadingPromises);
      uploadedFiles.forEach((file) => {
        this.props.sendChatMessage(this.props.chat._id, {
          file,
          companyId,
        });
      });
    } catch (err) {
      Raven.captureException(err);
    }

    this.setState({ isFilesUploading: false });
  };

  sendMessage() {
    const data = {
      message: this.state.currentMessage,
      companyId: this.props.activeCompanyId,
    };

    this.props.sendChatMessage(this.props.chat._id, data);
    this.setState({ currentMessage: '' });
  }

  render() {
    const productPhoto = '';
    const { chat, activeCompanyId } = this.props;
    const companionCompanyName =
      activeCompanyId === chat.clientCompanyId
        ? chat.clientCompany.name
        : chat.supplierCompany.name;
    const messages =
      this.props.messages.data &&
      this.props.messages.data.map((item) => (
        <ChatMessageItem
          key={item._id}
          item={item}
          chatProduct={chat.product}
          goToQuote={this.goToQuote}
          company={
            item.companyId === chat.clientCompanyId ? chat.clientCompany : chat.supplierCompany
          }
        />
      ));

    if (this.props.messages.loading) {
      messages.unshift(
        <SpinnerLoading
          key={'messages-loading-spinner'}
          embed={true}
          containerStyle={{ height: '50px' }}
        />
      );
    }

    return (
      chat.product !== null &&
      this.props.messages.data && (
        <InfiniteScrollReversed
          containerRef={this.chatMessagesContainerRef}
          headerRef={this.chatMessagesHeaderRef}
          contentRef={this.chatMessagesRef}
          load={this.onFetchChatMessages}
          loading={this.props.messages.loading}
          loadedItemsCount={this.props.messages.data.length}
          lastPage={chat.totalPages}
          listActiveId={chat._id}
        >
          <div ref={this.chatMessagesContainerRef} className="messages-feed">
            <div ref={this.chatMessagesHeaderRef} className="messages-heading">
              {chat.product.photosUrl && chat.product.photosUrl.length > 0 ? (
                <ProductPhotoStyled src={chat.product.photosUrl[0] || imgPlaceholder} alt={''} />
              ) : (
                <ProductPhotoStyled src={productPhoto || imgPlaceholder} alt={''} />
              )}
              <div className="messages-heading-details">
                <h2>{chat.product.name}</h2>
                <h3>{companionCompanyName}</h3>
                <div className="date">{moment(chat.product.createdAt).format()}</div>
                <span
                  onClick={() => {
                    this.goToQuote(this.props.chat.quoteId);
                  }}
                  className="btn white-button"
                >
                  View Quote
                </span>
              </div>
            </div>

            <div ref={this.chatMessagesRef} className="messages-items messages-list-items">
              {messages}
            </div>

            <form className="send-message-form">
              <div className="message-attach-files-container">
                <button type="button" className="btn message-attach-files-button">
                  {this.state.isFilesUploading ? (
                    <SpinnerLoading
                      embed={true}
                      containerStyle={{
                        position: 'absolute',
                        top: '29px',
                      }}
                    />
                  ) : (
                    <IconSendMessage width={18} height={20} />
                  )}
                </button>
                <input
                  className="hidden-attach-files-input"
                  onChange={this.onAttachmentChange}
                  type="file"
                  accept={'*'}
                  multiple={true}
                  disabled={this.state.isFilesUploading}
                />
              </div>
              <ContentEditable
                html={this.state.currentMessage}
                onChange={this.onMessageInputUpdate}
                onKeyPress={this.onMessageInputKeyPress}
                className="message-text-input"
              />
            </form>
          </div>
        </InfiniteScrollReversed>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  activeCompanyId: state.dashboard.activeCompanyId,
  activeProjectId: state.dashboard.activeProjectId,
  activeCompany: state.dashboard.company,
  messages: state.dashboard.chats.currentMessages,
  isTabActive: state.app.isTabActive,
});

const mapDispatchToProps = (dispatch) => ({
  fetchChatMessages: (companyId, chatId, params) =>
    dispatch(fetchChatMessages(companyId, chatId, params)),
  currentChatMessagesNew: (messages) => dispatch(chatCurrentMessagesNewSuccess(messages)),
  sendChatMessage: (chatId, data) => dispatch(sendChatMessage(chatId, data)),
  updateChatReadCount: (chat) => {
    if (chat.totalMessages > chat.chatUser.readCount) {
      // Simulate updated chat user data, so global counters are not updated twice or more
      // And store real read count, which may be reverted later
      const newChatUser = { ...chat.chatUser };
      newChatUser.realReadCount = newChatUser.readCount;
      newChatUser.readCount = chat.totalMessages;
      dispatch(chatCompanyUpdateUserReadCount(newChatUser));
    }
    dispatch(chatUpdateReadCount(chat));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessagesContainer);
