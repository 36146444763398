import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import SupplierProfileContainer from './SupplierProfileContainer';
import SuppliersListContainer from './SuppliersListContainer';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../util/error';
import _cloneDeep from 'lodash.clonedeep';
import api from '../../service/api';

class SuppliersContainer extends Component {
  static propTypes = {
    basePath: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      suppliersCount: 0,
      search: '',
      pages: 1,
      page: 1,
      limit: 20,
      getCancelToken: CancelToken.source(),
    };
  }

  setSearch = async (search) => {
    await this.setState({ search: search });
    await this.getSuppliers(this.state.search, this.state.page, this.state.limit);
  };

  getSuppliers = async (search, page, limit) => {
    const companyId = this.props.companyId;
    const projectId = this.props.projectId;
    const params = {
      search: search,
      page: page,
      limit: limit,
    };
    try {
      const suppliersRes = await api.suppliersGet(
        companyId,
        projectId,
        this.state.getCancelToken.token,
        params
      );
      this.setState({
        suppliers: suppliersRes.data.items,
        suppliersCount: suppliersRes.data.totalCount,
        pages: Math.floor(suppliersRes.data.totalCount / this.state.limit),
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  onSwitchFavorite = async (companyId, setVal) => {
    try {
      await api.companyfavoritePut(this.props.companyId, {
        companyId: companyId,
        favorite: setVal,
      });
      const suppliers = _cloneDeep(this.state.suppliers);
      suppliers.map((supplier) => {
        if (supplier._id === companyId) {
          supplier.favorite = setVal;
        }
        return false;
      });
      this.setState({
        suppliers: suppliers,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  onSwitchPage = (page) => {
    this.setState({ page: page });
    this.getSuppliers(this.state.search, page, this.state.limit);
  };

  componentDidMount() {
    this.getSuppliers(this.state.search, this.state.page, this.state.limit);
  }

  render() {
    return (
      <div className="suppliers-container">
        <Switch>
          <Route
            path={`${this.props.basePath}/:supplierId`}
            render={() => <SupplierProfileContainer companyId={this.props.companyId} />}
          />
          <Route
            path={`${this.props.basePath}`}
            render={() => (
              <SuppliersListContainer
                suppliers={this.state.suppliers}
                suppliersCount={this.state.suppliersCount}
                pages={this.state.pages}
                page={this.state.page}
                limit={this.state.limit}
                companyId={this.props.companyId}
                projectId={this.props.projectId}
                history={this.props.history}
                onSwitchFavorite={this.onSwitchFavorite}
                search={this.state.search}
                setSearch={this.setSearch}
                onSwitchPage={this.onSwitchPage}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(SuppliersContainer);
