import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../util/error';
import api from '../../service/api';
import ClientProfileContainer from './ClientProfileContainer';
import ClientsListContainer from './ClientsListContainer';

class ClientsContainer extends Component {
  static propTypes = {
    basePath: PropTypes.string,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      clientsCount: 0,
      search: '',
      pages: 1,
      page: 1,
      limit: 20,
      getCancelToken: CancelToken.source(),
    };
  }

  setSearch = async (search) => {
    await this.setState({ search: search });
    await this.getClients(this.state.search, this.state.page, this.state.limit);
  };

  getClients = async (search, page, limit) => {
    const companyId = this.props.match.params.companyId;
    const params = {
      search: search,
      page: page,
      limit: limit,
    };
    try {
      const clientsRes = await api.clientsGet(companyId, this.state.getCancelToken.token, params);
      this.setState({
        clients: clientsRes.data.items,
        clientsCount: clientsRes.data.totalCount,
        pages: Math.floor(clientsRes.data.totalCount / this.state.limit),
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  onSwitchPage = (page) => {
    this.setState({ page: page });
    this.getClients(this.state.search, page, this.state.limit);
  };

  componentDidMount() {
    this.getClients(this.state.search, this.state.page, this.state.limit);
  }

  componentWillUnmount() {
    this.state.getCancelToken.cancel();
  }

  render() {
    const companyId = this.props.match.params.companyId;
    return (
      <div className="clients-container">
        <Switch>
          <Route
            path={`${this.props.basePath}/:clientId`}
            render={() => <ClientProfileContainer companyId={companyId} />}
          />
          <Route
            path={`${this.props.basePath}`}
            render={() => (
              <ClientsListContainer
                clients={this.state.clients}
                clientsCount={this.state.clientsCount}
                pages={this.state.pages}
                page={this.state.page}
                limit={this.state.limit}
                companyId={this.props.match.params.companyId}
                history={this.props.history}
                search={this.state.search}
                setSearch={this.setSearch}
                onSwitchPage={this.onSwitchPage}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(ClientsContainer);
