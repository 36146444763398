import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import IconLngEn from '../../icon/lng/IconLngEn';
import IconLngZh from '../../icon/lng/IconLngZh';

const lngs = {
  en: { label: 'ENG', icon: <IconLngEn width={15} height={10} /> },
  zh: { label: '中文', icon: <IconLngZh width={15} height={10} /> },
};

class LanguageSelector extends React.PureComponent {
  static propTypes = {
    lng: PropTypes.string,
    onLngChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    lng: 'en',
  };

  state = {
    expanded: false,
    lng: null,
  };

  static getDerivedStateFromProps(props, state) {
    return state.lng === null ? { lng: props.lng } : null;
  }

  onItemClick = (lng) => {
    if (this.state.expanded) {
      this.props.onLngChange(lng);
      this.setState({ expanded: !this.state.expanded, lng });
    } else {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  render() {
    let items = [];
    if (!this.state.expanded) {
      let currentLng = lngs[this.state.lng];
      if (typeof currentLng === 'undefined' && typeof this.state.lng === 'string') {
        // Try to get common locale
        const commonLocale = this.state.lng.substr(0, 2);
        if (typeof lngs[commonLocale] !== 'undefined') {
          currentLng = lngs[commonLocale];
        }
      }
      if (!currentLng) {
        currentLng = lngs.en;
      }
      items.push(currentLng);
    } else {
      items = lngs;
    }

    return (
      <div className="language-selector">
        {Object.keys(items).map((lng, index) => (
          <div
            key={lng}
            className="language-item"
            onClick={() => {
              this.onItemClick(lng);
            }}
          >
            <div className="icon-lng-container">{items[lng].icon}</div>
            {items[lng].label}
            {index === 0 && <span className="caret" />}
          </div>
        ))}
      </div>
    );
  }
}

export default LanguageSelector;
