import React from 'react';
import PropTypes from 'prop-types';
import _isUndefined from 'lodash.isundefined';
import _cloneDeep from 'lodash.clonedeep';
import UploadButton from '../UploadButton';
import IconMuted from '../../icon/IconMuted';
import ImageFixedSizeContain from '../ImageFixedSizeContain';

class ImageGroupUpload extends React.Component {
  static defaultProps = {
    maxFiles: 5,
    maxFileSize: 512, // Kb
    files: [],
    previews: [],
  };

  static propTypes = {
    id: PropTypes.string,
    files: PropTypes.array,
    previews: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onInnerEvents: PropTypes.func,
  };

  inputRef = React.createRef();

  static EVENT_INNER_TYPE_MAX_FILES = 'maxFiles';
  static EVENT_INNER_TYPE_MAX_FILE_SIZE = 'maxFileSize';

  constructor(props) {
    super(props);

    this.state = {
      files: props.files,
      previews: props.previews,
    };
  }

  /**
   *
   * @param data
   * @param data.type
   * @param data.fileName
   * @returns {{type: string, fileName: string, defaultMessage: string, maxFiles: number, maxFileSize: number}}
   */
  prepareInnerEvent = (data) => {
    let defaultMessage;

    switch (data.type) {
      case ImageGroupUpload.EVENT_INNER_TYPE_MAX_FILES:
        defaultMessage = `Cannot add "${data.fileName}", the maximum of ${
          this.props.maxFiles
        } images has been reached`;
        break;
      case ImageGroupUpload.EVENT_INNER_TYPE_MAX_FILE_SIZE:
        defaultMessage = `Cannot add "${
          data.fileName
        }", the image is over the maximum file size of ${this.props.maxFileSize}kb`;
        break;
      default:
        defaultMessage = '';
    }

    return {
      ...data,
      defaultMessage,
      maxFiles: this.props.maxFiles,
      maxFileSize: this.props.maxFileSize,
    };
  };

  onChange = (inputFiles) => {
    if (inputFiles && inputFiles.length > 0) {
      let countProcessedFiles = 0;
      const innerEvents = [];
      let previews = _cloneDeep(this.state.previews);
      let files = _cloneDeep(this.state.files);
      let filesLength = files.length;
      for (let i = 0; i < this.props.maxFiles - filesLength; i++) {
        if (_isUndefined(inputFiles[i])) {
          break;
        }

        if (inputFiles[i].size > this.props.maxFileSize * 1024) {
          innerEvents.push(
            this.prepareInnerEvent({
              type: ImageGroupUpload.EVENT_INNER_TYPE_MAX_FILE_SIZE,
              fileName: inputFiles[i].name,
            })
          );
          filesLength--;
          countProcessedFiles = countProcessedFiles + 1;
          continue;
        }

        const alreadyAdded = files.filter((obj) => {
          return obj.name === inputFiles[i].name;
        });

        if (alreadyAdded.length === 0) {
          files.push(inputFiles[i]);
          previews.push(URL.createObjectURL(inputFiles[i]));
        } else {
          filesLength--;
        }

        countProcessedFiles = countProcessedFiles + 1;
      }

      if (countProcessedFiles < inputFiles.length) {
        for (let i = countProcessedFiles; i < inputFiles.length; i++) {
          innerEvents.push(
            this.prepareInnerEvent({
              type: ImageGroupUpload.EVENT_INNER_TYPE_MAX_FILES,
              fileName: inputFiles[i].name,
            })
          );
        }
      }

      if (typeof this.props.onInnerEvents === 'function') {
        this.props.onInnerEvents(innerEvents);
      }

      this.setState({
        files,
        previews,
      });

      this.props.onChange(files);
    }
  };

  onRemove = (index) => {
    let files = _cloneDeep(this.state.files);
    let previews = _cloneDeep(this.state.previews);
    files.splice(index, 1);
    previews.splice(index, 1);

    this.inputRef.current.value = null;
    this.setState({
      files,
      previews,
    });
    this.props.onChange(files);
  };

  render() {
    return (
      <div className="row">
        <div className="col-sm-3">
          <UploadButton
            id={this.props.id}
            onChange={this.onChange}
            inputRef={this.inputRef}
            accept="image/*"
            multiple={true}
            buttonLabel={'Upload Photos'}
          />
        </div>
        <div className="col-sm-3">
          <div>
            <sup>Max file size: {this.props.maxFileSize}Kb</sup>
          </div>
          <div>
            <sup>Max files: {this.props.maxFiles}</sup>
          </div>
        </div>
        <div className="photos-container col-sm-12">
          {this.state.previews.length > 0 &&
            this.state.previews.map((photoUrl, index) => {
              return (
                <div className="image-preview-container" key={index}>
                  <ImageFixedSizeContain
                    className="img-thumbnail"
                    width="115px"
                    height="115px"
                    url={photoUrl}
                  />
                  <div className="image-preview-remove" onClick={this.onRemove.bind(this, index)}>
                    <IconMuted width={12} height={12} color="#959595" />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default ImageGroupUpload;
