export const revenueOptions = [
  { name: '$0-$1M', value: 0 },
  { name: '$1M-$5M', value: 1 },
  { name: '$5M-$20M', value: 5 },
  { name: '$20M-$100M', value: 20 },
  { name: '$100M-$1B', value: 100 },
  { name: '$1B+', value: 1000 },
];

export const staffOptions = [
  { name: '1-10', value: 1 },
  { name: '11-50', value: 11 },
  { name: '51-100', value: 51 },
  { name: '101-500', value: 101 },
  { name: '500+', value: 501 },
];

export function getLabelFromOptions(options, value) {
  const filteredOptions = options.filter((item) => item.value === value);
  if (filteredOptions.length === 0) {
    return undefined;
  }

  return filteredOptions[0].name;
}
