import React from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
  status,
}) => {
  const emailGroupClass = classNames('form-group', {
    'with-error': touched.email && errors.email,
  });

  return (
    <form onSubmit={handleSubmit}>
      {errors.common && <label className="error common">{errors.common}</label>}
      {status && <label className="success common">{status}</label>}
      <div className={emailGroupClass}>
        <label>Email Address</label>
        <Field className="form-control" type="email" name="email" />
        {touched.email && errors.email && <label className="error">{errors.email}</label>}
      </div>
      <button className="btn" disabled={isSubmitting} type="submit">
        Send Reset Link
      </button>
    </form>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email Address is not valid')
    .required('Email Address is required'),
});

const SignForgotPasswordForm = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    // Errors set by setErrors from action if action rejected
    props.onSubmit(values.email, setSubmitting, setErrors, setStatus);
  },
})(Form);

SignForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignForgotPasswordForm;
