import React from 'react';
import PropTypes from 'prop-types';

/**
 * Autoload data on scroll by 'load' func, which has 'page' attribute to load exact page
 * It binds on scroll event listener to container by 'containerRef'
 * It uses 'contentRef' to get content height
 * It relies on async 'loading' flag to stop fetching data on scroll, until it changes true -> false
 * 'itemsCount' and 'totalCount' values are used to stop loading data, when all items are loaded
 */
export default class InfiniteScroll extends React.Component {
  static propTypes = {
    containerRef: PropTypes.object.isRequired,
    contentRef: PropTypes.object.isRequired,
    load: PropTypes.func.isRequired, // Func to load more items
    loading: PropTypes.bool.isRequired, // Is data loading now
    itemsCount: PropTypes.number.isRequired, // Count of loaded items
    totalCount: PropTypes.number.isRequired, // Total items
    listActiveId: PropTypes.string, // Id of current list if it's needed to switch them
    search: PropTypes.string,
  };

  state = {
    lastScrollTop: 0,
    lastPage: 1,
    loadingStarted: false, // Local state needed as 'loading' value in props is async
  };

  componentDidMount() {
    this.props.containerRef.current.addEventListener('scroll', this.onScroll);
    this.props.load(this.state.lastPage, true);
  }

  componentWillUnmount = () => {
    this.props.containerRef.current.removeEventListener('scroll', this.onScroll);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setState({ loadingStarted: false });
    }
    // Used for properly switching separate lists if it's needed (listActiveId not nessesary variable)
    if (
      (prevProps.listActiveId !== this.props.listActiveId && this.props.listActiveId) ||
      prevProps.search !== this.props.search
    ) {
      this.setState({ lastPage: 1 });
      this.props.load(1, true);
    }
  };

  onScroll = () => {
    const itemsHeight = this.props.contentRef.current.clientHeight;
    const containerHeight = this.props.containerRef.current.clientHeight;
    const scrollTop = this.props.containerRef.current.scrollTop;

    if (
      this.props.totalCount > this.props.itemsCount &&
      scrollTop + 3 * containerHeight >= itemsHeight &&
      !this.state.loadingStarted
    ) {
      const nextPage = this.state.lastPage + 1;
      this.setState({
        lastScrollTop: this.props.containerRef.current.scrollTop,
        loadingStarted: true,
        lastPage: nextPage,
      });
      this.props.load(nextPage);
    }
  };

  render() {
    return this.props.children;
  }
}
