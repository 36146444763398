import React from 'react';
import PropTypes from 'prop-types';

export default class ImageFixedSizeContain extends React.PureComponent {
  static propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    className: PropTypes.string,
    url: PropTypes.string,
    placeholder: PropTypes.any,
  };

  render() {
    return this.props.url ? (
      <div
        className={this.props.className}
        style={{
          width: this.props.width,
          height: this.props.height,
          background: 'center/contain no-repeat',
          backgroundImage: `url(${this.props.url})`,
        }}
      />
    ) : (
      <img
        className={this.props.className}
        style={{
          width: this.props.width,
          height: this.props.height,
        }}
        src={this.props.placeholder}
        alt={''}
      />
    );
  }
}
