import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { createTracker } from 'redux-segment';

import config from '../util/config';
import allReducers from '../reducers';
import api from '../service/api';
import s3 from '../service/s3';

const tracker = createTracker(); // Create the tracker...

const ravenConfigParams = {};
if (process.env.NODE_ENV !== 'production') {
  ravenConfigParams.debug = true;
}
ravenConfigParams.release = config.version;
Raven.config(config.SENTRY_DSN, ravenConfigParams).install();

export default function configureStore(initialState, history) {
  return createStore(
    connectRouter(history)(allReducers),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        createRavenMiddleware(Raven),
        tracker,
        thunk.withExtraArgument({ api, s3 }),
        routerMiddleware(history)
      )
    )
  );
}
