import React from 'react';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';

import EditProjectUsersForm from '../../../component/project/EditProjectUsersForm';
import api from '../../../service/api';
import { SOMETHING_WENT_WRONG_ERROR_TEXT } from '../../../util/error';
import SpinnerLoading from '../../../component/common/SpinnerLoading';

export class ProjectSettingsUsersContainer extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    onProjectFormSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { users: [], getUsersCancelSource: CancelToken.source(), error: null };
  }

  componentDidMount = async () => {
    try {
      const res = await api.companyUsersGet(
        this.props.companyId,
        this.state.getUsersCancelSource.token
      );

      this.setState({ users: res.data });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT });
      }
    }
  };

  componentWillUnmount() {
    this.state.getUsersCancelSource.cancel();
  }

  render() {
    return (
      <div className={'project-settings-users-container'}>
        {this.state.users.length === 0 && this.state.error === null && (
          <SpinnerLoading centered={true} />
        )}
        {this.state.users.length > 0 && (
          <EditProjectUsersForm
            project={this.props.project}
            usersError={this.state.error}
            users={this.state.users}
            onCancel={() => {}}
            onSubmit={this.props.onProjectFormSubmit}
          />
        )}
      </div>
    );
  }
}

export default ProjectSettingsUsersContainer;
