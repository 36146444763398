import React from 'react';
import PropTypes from 'prop-types';

const onClick = (e) => {
  e.stopPropagation();
};

const ExternalLink = (props) => {
  const link = props.link;
  return (
    <a onClick={onClick} href={`http://${link}`} target="_blank" rel="noopener noreferrer">
      {link}
    </a>
  );
};

ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default ExternalLink;
