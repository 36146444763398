import React from 'react';
import PropTypes from 'prop-types';
import { displayMoney } from '../../../util/currency';

class QuoteRange extends React.Component {
  static defaultProps = {
    minProductQuantity: 0,
  };

  static propTypes = {
    quoteRange: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    minProductQuantity: PropTypes.number,
  };

  render() {
    const maxPriceText =
      this.props.quoteRange.minPrice !== this.props.quoteRange.maxPrice
        ? ` - ${displayMoney(this.props.quoteRange.maxPrice, this.props.currency)}`
        : '';

    const minUnitsText =
      this.props.quoteRange.minUnits === 0
        ? this.props.minProductQuantity
        : this.props.quoteRange.minUnits;

    const maxUnitsText =
      this.props.quoteRange.minUnits !== this.props.quoteRange.maxUnits
        ? ` - ${this.props.quoteRange.maxUnits}`
        : '';

    return (
      <div>
        <div className="units">
          {minUnitsText}
          {maxUnitsText} Units
        </div>
        <div className="price-range">
          <span />
          {displayMoney(this.props.quoteRange.minPrice, this.props.currency)}
          {maxPriceText}
        </div>
      </div>
    );
  }
}

export default QuoteRange;
