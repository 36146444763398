import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';

const QuoteFormSectionQuestions = (props) => {
  const { values, errors, touched, handleChange, handleBlur, readOnly, t } = props;

  return (
    <fieldset>
      <div className="section-header-description">{t('quote.common.questionsText')}</div>
      {values.questions.map((item, index) => {
        const fieldTouched = touched.questions && touched.questions[index];
        const fieldError = errors.questions && errors.questions[index] && errors.questions[index].a;

        return (
          <div className="row" key={index}>
            <div
              className={classNames('form-group col-sm-12', {
                'with-error': fieldTouched && fieldError,
              })}
            >
              <label>{item.q}</label>
              <textarea
                className="form-control"
                name={`questions.${index}.a`}
                value={item.a}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={readOnly}
              />
              {fieldTouched && fieldError && (
                <label className="error">
                  {t(`quote.validation.${errors.questions[index].a}`)}
                </label>
              )}
            </div>
          </div>
        );
      })}
    </fieldset>
  );
};

export const QuestionsValidationScheme = {
  questions: Yup.array().of(
    Yup.object().shape({
      a: Yup.string().required('Answer is required'),
    })
  ),
};

export default QuoteFormSectionQuestions;
