import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import FormSectionUser, { FormSectionUserValidationScheme } from '../../form/FormSectionUser';
import StickyMenu from '../../common/StickyMenu';
import SpinnerLoading from '../../../component/common/SpinnerLoading';

class Form extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  sectionUserInformation = {
    name: 'userInformation',
    label: 'User Information',
    ref: React.createRef(),
  };

  sectionHeaderRefs = [this.sectionUserInformation];

  componentDidMount = () => {
    if (this.props.location.search.indexOf('?token=') === -1) {
      this.props.history.push('/signin');
    } else {
      const token = this.props.location.search.replace('?token=', '');
      this.props.setValues({
        ...this.props.values,
        token,
      });
    }
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
    } = this.props;

    const passwordGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.password && errors.password,
    });
    const confirmPasswordGroupClass = classNames('form-group col-sm-6', {
      'with-error': touched.confirmPassword && errors.confirmPassword,
    });

    return (
      <form className="form-sign-up-complete-invited" onSubmit={handleSubmit}>
        <StickyMenu items={this.sectionHeaderRefs} />
        <label className="section-header" ref={this.sectionUserInformation.ref}>
          {this.sectionUserInformation.label}
        </label>
        <FormSectionUser
          values={values}
          fieldsNamePrefix=""
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          emailDisabled={false}
        />
        <div className="row fieldset-last">
          <div className={passwordGroupClass}>
            <label>
              Password<sup> *</sup>
            </label>
            <Field className="form-control" type="password" name="password" />
            {touched.password && errors.password && (
              <label className="error">{errors.password}</label>
            )}
          </div>
          <div className={confirmPasswordGroupClass}>
            <label>
              Confirm Password<sup> *</sup>
            </label>
            <Field className="form-control" type="password" name="confirmPassword" />
            {touched.confirmPassword && errors.confirmPassword && (
              <label className="error">{errors.confirmPassword}</label>
            )}
          </div>
        </div>
        {errors.common && <label className="error common">{errors.common}</label>}
        <div className="col-sm-12 buttons-bottom">
          <button className="btn blue-button" disabled={isSubmitting} type="submit">
            Save & Continue
          </button>
        </div>
        {isSubmitting && <SpinnerLoading centered={true} />}
      </form>
    );
  }
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .test('confirm-password-test', 'Passwords do not match', function(value) {
      const { password } = this.parent;
      return value === password;
    }),
  ...FormSectionUserValidationScheme,
});

const SignUpCompleteFormInvited = withFormik({
  mapPropsToValues: () => ({
    fullName: '',
    position: '',
    email: '',
    telephone: '',
    password: '',
    confirmPassword: '',
  }),
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props.onSubmit(values, setSubmitting, setErrors);
  },
})(Form);

SignUpCompleteFormInvited.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withRouter(SignUpCompleteFormInvited);
