import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { AbilityContext } from '../../../util/session';

import TopMenu from '../../../component/dashboard/TopMenu/index';
import CompanyUsersAndPermissions from '../../../component/company/CompanyUsersAndPermissions/index';
import { Can } from '../../../util/session';
import CompanyProfileContainer from '../CompanyProfileContainer';

class CompanySettingsContainer extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    basePath: '/dashboard/company/',
  };

  constructor(props) {
    super(props);

    this.state = {
      basePath: `/dashboard/company/${props.companyId}/settings/`,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.onCompanyIdChange(this.props.companyId);
    }
  }

  onCompanyIdChange(companyId) {
    this.setState({ basePath: `/dashboard/company/${companyId}/settings/` });
  }

  render() {
    let topMenuItems = [
      {
        name: 'company-profile',
        nameAlias: 'company-profile/edit',
        label: 'Company Profile',
      },
    ];

    if (this.props.ability.can('manage', 'User')) {
      topMenuItems.push({
        name: 'users-and-permissions',
        label: 'Users & Permissions',
      });
    }

    return (
      <div className="company-container">
        <label className="page-header">Company Settings</label>
        <TopMenu items={topMenuItems} basePath={this.state.basePath} />
        <Switch>
          <Route
            path="/dashboard/company/:companyId/settings/company-profile"
            render={() => <CompanyProfileContainer />}
          />
          <Can I="manage" a="User">
            <Route
              path="/dashboard/company/:companyId/settings/users-and-permissions"
              render={() => (
                <CompanyUsersAndPermissions
                  companyId={this.props.companyId}
                  user={this.props.user}
                />
              )}
            />
          </Can>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
  companyId: state.dashboard.activeCompanyId,
});

export default withRouter(
  connect(mapStateToProps)((props) => (
    <AbilityContext.Consumer>
      {(ability) => <CompanySettingsContainer {...props} ability={ability} />}
    </AbilityContext.Consumer>
  ))
);
