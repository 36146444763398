import React from 'react';
import Popup from 'reactjs-popup';
import Modal from '../../common/Modal/index';
import PropTypes from 'prop-types';

class ModalOwnerTransferConfirm extends React.Component {
  static propTypes = {
    userPayload: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal>
        <Popup
          className="popup-user-owner-transfer-confirm"
          open={true}
          onClose={this.props.onClose}
          modal
          contentStyle={{ padding: '40px', width: '450px' }}
        >
          <div className={'popup-user-owner-transfer-confirm-content'}>
            <label className="form-header danger-text">WARNING</label>
            <div className="row">
              <div className="col-sm-12">
                <p>Are you sure you wish to Transfer Ownership of this Importist Account?</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 important-text">
                <p>This action cannot be undone.</p>
              </div>
            </div>
            <div className="row">
              <div className="form-group half-margin-bottom col-sm-12">
                <button
                  onClick={() => {
                    this.props.onSave(this.props.userPayload);
                  }}
                  type={'button'}
                  className={'btn red-white-button border-x2'}
                >
                  TRANSFER OWNERSHIP
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button
                  onClick={() => {
                    this.props.onClose();
                  }}
                  type={'button'}
                  className={'btn blue-white-button border-x2'}
                >
                  TAKE ME BACK
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </Modal>
    );
  }
}

export default ModalOwnerTransferConfirm;
