import { GET_COMPANY_USERS_SUCCESS } from './constants';

export function getCompanyUsersSuccess(state = [], action) {
  switch (action.type) {
    case GET_COMPANY_USERS_SUCCESS:
      return action.users;
    default:
      return state;
  }
}
