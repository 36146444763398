export const CHAT_COMPANY_FETCH_CHATS_SUCCESS =
  'importist/messages/CHAT_COMPANY_FETCH_CHATS_SUCCESS';
export const CHAT_COMPANY_ADD_CHATS_SUCCESS = 'importist/messages/CHAT_COMPANY_ADD_CHATS_SUCCESS';
export const CHAT_COMPANY_REPLACE_OR_ADD_CHAT_SUCCESS =
  'importist/messages/CHAT_COMPANY_REPLACE_OR_ADD_CHAT_SUCCESS';
export const CHAT_COMPANY_UPDATE_USER_READ_COUNT_SUCCESS =
  'importist/messages/CHAT_COMPANY_UPDATE_USER_READ_COUNT_SUCCESS';
export const CHAT_COMPANY_REVERT_USER_READ_COUNT =
  'importist/messages/CHAT_COMPANY_REVERT_USER_READ_COUNT';
export const CHAT_COMPANY_SET_LOADING = 'importist/messages/CHAT_COMPANY_SET_LOADING';
export const CHAT_CURRENT_MESSAGES_ADD_SUCCESS =
  'importist/messages/CHAT_CURRENT_MESSAGES_ADD_SUCCESS';
export const CHAT_CURRENT_MESSAGES_FETCH_SUCCESS =
  'importist/messages/CHAT_CURRENT_MESSAGES_FETCH_SUCCESS';
export const CHAT_CURRENT_MESSAGES_NEW_SUCCESS =
  'importist/messages/CHAT_CURRENT_MESSAGES_NEW_SUCCESS';
export const CHAT_CURRENT_MESSAGES_SET_LOADING =
  'importist/messages/CHAT_CURRENT_MESSAGES_SET_LOADING';
export const CHAT_CURRENT_MESSAGES_SEND_SUCCESS =
  'importist/messages/CHAT_CURRENT_MESSAGES_SEND_SUCCESS';
export const CHAT_ACTIVE_ID_SET = 'importist/messages/CHAT_ACTIVE_ID_SET';
