import React from 'react';
import styled from 'styled-components';
import { getLabelFromOptions, revenueOptions, staffOptions } from '../../../util/selectOptions';
import ExternalLink from '../../common/ExternalLink';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import ImageFixedSizeContain from '../../common/ImageFixedSizeContain';

const SectionSeparatorStyled = styled.div`
  margin-top: 65px;
  margin-bottom: 65px;
`;

const DivMarginBottom10 = styled.div`
  margin-bottom: 10px;
`;

const CompanyProfileCommon = (props) => {
  const { company, showCompanyNameAndLogo, onEditClick } = props;

  return company ? (
    <div>
      <label className="section-header">Company Information</label>
      {onEditClick && (
        <span className={'action-link-text'} onClick={onEditClick}>
          Edit
        </span>
      )}
      {showCompanyNameAndLogo && (
        <div className="row">
          <div className="form-group col-sm-6">
            <label>Company Name</label>
            <div className="form-value">{company.name}</div>
          </div>
        </div>
      )}
      {showCompanyNameAndLogo && (
        <div className="row">
          <div className="form-group col-sm-6">
            <ImageFixedSizeContain
              width={'125px'}
              height={'125px'}
              placeholder={imgPlaceholder}
              url={company.logoUrl}
            />
          </div>
        </div>
      )}
      <div className="row">
        {company.address && (
          <div className="form-group col-sm-6">
            <label>Address Line 1</label>
            <div className="form-value">{company.address}</div>
          </div>
        )}
        {company.address2 && (
          <div className="form-group col-sm-6">
            <label>Address Line 2</label>
            <div className="form-value">{company.address2}</div>
          </div>
        )}
      </div>
      <div className="row">
        {company.zipCode && (
          <div className="form-group col-sm-6">
            <label>Zip / Postal Code</label>
            <div className="form-value">{company.zipCode}</div>
          </div>
        )}
        {company.country && (
          <div className="form-group col-sm-6">
            <label>Country</label>
            <div className="form-value">{company.country}</div>
          </div>
        )}
      </div>
      <div className="row">
        {company.telephone && (
          <div className="form-group col-sm-6">
            <label>Telephone</label>
            <div className="form-value">{company.telephone}</div>
          </div>
        )}
        {company.website && (
          <div className="form-group col-sm-6">
            <label>Website</label>
            <div className="form-value">
              {company.website && <ExternalLink link={company.website} />}
            </div>
          </div>
        )}
      </div>
      <SectionSeparatorStyled className="section-separator" />
      <label className="section-header">Company Details</label>
      {company.staff && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>About</label>
            <div className="form-value">{company.about}</div>
          </div>
        </DivMarginBottom10>
      )}
      {company.staff && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-6">
            <label>Staff</label>
            <div className="form-value">{getLabelFromOptions(staffOptions, company.staff)}</div>
          </div>
          <div className="form-group col-sm-6">
            <label>Revenue</label>
            <div className="form-value">{getLabelFromOptions(revenueOptions, company.revenue)}</div>
          </div>
        </DivMarginBottom10>
      )}
      {company.established && (
        <DivMarginBottom10 className="row">
          <div className="form-group col-sm-12">
            <label>Established</label>
            <div className="form-value">{company.established}</div>
          </div>
        </DivMarginBottom10>
      )}
    </div>
  ) : (
    ''
  );
};

export default CompanyProfileCommon;
