import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import AddProductForm from '../../../component/product/AddProductForm/index';
import IconBox from '../../../component/icon/IconBox';
import { appFetchingData } from '../../../actions';
import IconClose from '../../../component/icon/IconClose';
import api from '../../../service/api';
import {
  addCommonFormikErrorsIfEmpty,
  errorsTransformAxiosToFormik,
  SOMETHING_WENT_WRONG_ERROR_TEXT,
} from '../../../util/error';
import _cloneDeep from 'lodash.clonedeep';
import { bulkFilesS3SignedPut } from '../../../util/s3';
import { productCreateNewSuccess } from '../actions';
import SpinnerLoading from '../../../component/common/SpinnerLoading';

class EditProductFormContainer extends React.Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      product: {},
      cancelSourceGetProduct: CancelToken.source(),
      error: null,
    };
  }

  componentDidMount = async () => {
    try {
      const productId = this.props.match.params.productId;
      const res = await api.productGet(
        this.props.companyId,
        this.props.projectId,
        productId,
        this.state.cancelSourceGetProduct.token
      );

      this.setState({ product: res.data, isLoading: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ error: SOMETHING_WENT_WRONG_ERROR_TEXT, isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    this.state.cancelSourceGetProduct.cancel();
  }

  onSaveProduct = async (values, setSubmitting, setErrors) => {
    try {
      let payload = _cloneDeep(values);

      if (payload.photoFiles && payload.photoFiles.length > 0) {
        payload.photos = payload.photoFiles
          .filter((file) => file.uploaded)
          .map((file) => file.name);

        const uploadFiles = payload.photoFiles.filter((file) => !file.uploaded);
        const uploadedKeys = await bulkFilesS3SignedPut('productPhoto', uploadFiles);
        payload.photos.push(...uploadedKeys);
      }
      delete payload.photoFiles;

      const res = await api.productPut(this.props.companyId, this.props.projectId, payload);
      if (res.data.status === 'complete') {
        this.props.productCreateNewSuccess(res.data);
      }
      this.props.history.goBack();
    } catch (err) {
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
      setSubmitting(false);
    }
  };

  render() {
    return this.state.isLoading ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className={'add-product-from-container'}>
        <div className={'icon-close-container'} onClick={this.props.history.goBack}>
          <IconClose width={18} height={18} color={'black'} />
        </div>
        <div className="form-header">
          <span className="form-header-icon">
            <IconBox width={36} height={36} color={'#0095FF'} />
          </span>
          Edit Product
        </div>
        <div className="form-header-description">
          This is where you edit a product, which you seek a quotation against. <br /> Please have
          your details ready and save your product below.
        </div>
        <AddProductForm
          product={this.state.product}
          user={this.props.user}
          company={this.props.company}
          onCancel={this.props.history.goBack}
          onSubmit={this.onSaveProduct}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.dashboard.activeCompanyId,
  projectId: state.dashboard.activeProjectId,
  user: state.account.user,
  company: state.dashboard.company,
});

const mapDispatchToProps = (dispatch) => ({
  appFetchingData: (is) => dispatch(appFetchingData(is)),
  productCreateNewSuccess: (product) => dispatch(productCreateNewSuccess(product)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProductFormContainer)
);
