import moment from './moment';

export function yearsRange(start = 1900, end = new Date().getFullYear()) {
  return Array(end - start)
    .fill(1)
    .map((x, y) => start + x + y);
}

export function getShortDatetimeDistance(dateStr) {
  const date = moment(dateStr);
  const now = moment();
  const duration = moment.duration(now.diff(date));

  const durationMinutes = Math.floor(duration.asMinutes());
  if (durationMinutes <= 59) {
    return `${durationMinutes}m`;
  }

  if (now.isSame(date, 'day')) {
    return moment.utc(duration.as('milliseconds')).format('h:mmA');
  }

  return date.format('DD/MM/YY');
}
