import _findIndex from 'lodash.findindex';
import _cloneDeep from 'lodash.clonedeep';
import _isNumber from 'lodash.isnumber';
import * as Yup from 'yup';
import { quantityValidationRule } from '../../../util/yup';
import _debounce from 'lodash.debounce';

export function prepareQuantitiesValues(props) {
  let requiredQuantities = prepareRequiredQuantityFromQuoteOrProduct(props.quote, props.product);
  const targetQuantity = getTargetQuantityFromRequiredQuantities(
    requiredQuantities,
    props.product.targetPrice
  );
  // Remove target from array
  requiredQuantities = requiredQuantities.filter((qntObj) => qntObj.target !== true);

  const minimumQuantityItem = getMOQFromSortedRequiredQuantities(requiredQuantities);
  // Remove MOQ if necessary
  if (minimumQuantityItem.q > 0) {
    requiredQuantities = moqCheckAndRemoveIfQuantityNotInProduct(
      minimumQuantityItem.q,
      requiredQuantities,
      props.product.requiredQuantity
    );
  }

  return {
    targetRequiredQuantity: targetQuantity.q,
    targetPricePerUnit: targetQuantity.pricePerUnit,
    targetLeadTime: targetQuantity.leadTime,
    minimumQuantity: minimumQuantityItem.q,
    pricePerUnit: minimumQuantityItem.pricePerUnit,
    leadTime: minimumQuantityItem.leadTime,
    requiredQuantity: requiredQuantities,
  };
}

export function prepareQuoteValues(props) {
  const quantityValues = prepareQuantitiesValues(props);

  let questions;
  if (props.quote.questions) {
    questions = props.quote.questions;
  } else {
    questions = props.product.questions.map((q) => {
      return { q, a: '' };
    });
  }

  return {
    ...quantityValues,
    _id: props.quote._id || null,
    productId: props.product._id,
    createdBy: props.userId,
    createdByCompanyId: props.userCompanyId,
    status: props.quote.status || 'draft',
    validUntil: props.quote.validUntil || '',
    currency: props.quote.currency || props.product.currency,
    targetPriceCanNotReach: props.quote.targetPriceCanNotReach || false,
    termsPreference: props.quote.termsPreference || props.product.incoterms,
    cartonSize: props.quote.cartonSize || '',
    quantityPerCarton: props.quote.quantityPerCarton || '',
    cartonGrossWeight: _isNumber(props.quote.cartonGrossWeight)
      ? props.quote.cartonGrossWeight
      : '',
    cartonNetWeight: _isNumber(props.quote.cartonNetWeight) ? props.quote.cartonNetWeight : '',
    samplesNumber: _isNumber(props.product.numberSamples) ? props.product.numberSamples : 0,
    samplesTotalPrice: _isNumber(props.quote.samplesTotalPrice)
      ? props.quote.samplesTotalPrice
      : '',
    samplesLeadTime: props.quote.samplesLeadTime || '',
    samplesShippingCost: _isNumber(props.quote.samplesShippingCost)
      ? props.quote.samplesShippingCost
      : '',
    questions,
  };
}

export function prepareAccountValues(props) {
  return {
    fullName: props.fullName || '',
    email: props.email || '',
    companyName: props.companyName || '',
    password: props.password || '',
  };
}

export function prepareRequiredQuantityFromQuoteOrProduct(quote, product) {
  let requiredQuantity = product.requiredQuantity.map((q) => {
    return { q, pricePerUnit: '', leadTime: '' };
  });

  if (quote.requiredQuantity) {
    quote.requiredQuantity.forEach((quantityItem) => {
      const existsIndex = _findIndex(requiredQuantity, ['q', quantityItem.q]);
      if (existsIndex < 0) {
        requiredQuantity.push(quantityItem);
      } else {
        requiredQuantity[existsIndex] = quantityItem;
      }
    });
  }

  requiredQuantity.sort((item1, item2) => item1.q - item2.q);

  return requiredQuantity;
}

export function getTargetQuantityFromRequiredQuantities(quantities, productTargetPrice) {
  for (let i = 0; i < quantities.length; i++) {
    if (quantities[i].target === true) {
      return quantities[i];
    }
  }

  return {
    q: '',
    pricePerUnit: productTargetPrice || '',
    leadTime: '',
  };
}

export function getMOQFromSortedRequiredQuantities(requiredQuantity) {
  for (let i = 0; i < requiredQuantity.length; i++) {
    if (requiredQuantity[i].pricePerUnit > 0) {
      return requiredQuantity[i];
    }
  }

  return { q: '', pricePerUnit: '', leadTime: '' };
}

export function moqCheckAndRemoveIfQuantityNotInProduct(
  moq,
  requiredQuantity,
  productRequiredQuantity
) {
  let modifiedRequiredQuantity = _cloneDeep(requiredQuantity);
  if (productRequiredQuantity.indexOf(moq) < 0) {
    modifiedRequiredQuantity = modifiedRequiredQuantity.filter((item) => item.q !== moq);
  }

  return modifiedRequiredQuantity;
}

export function validateRequiredQuantity(values) {
  let errors = [];
  let isErrorRequiredQuantity = false;
  if (values.minimumQuantity > 0 || !values.minimumQuantity) {
    values.requiredQuantity.forEach((obj, index) => {
      if (obj.q > values.minimumQuantity || !values.minimumQuantity) {
        errors[index] = {};
        if (!obj.pricePerUnit) {
          errors[index].pricePerUnit = `Price Per Unit is required`;
          isErrorRequiredQuantity = true;
        } else if (values.targetPriceCanNotReach && obj.pricePerUnit <= values.targetPricePerUnit) {
          errors[index].pricePerUnit =
            validationError.ERROR_PRICE_MUST_BE_MORE_TARGET_PRICE_CANNOT_REACH;
        }
        if (obj.leadTime < 0) {
          errors[index].leadTime = `Only positive value allowed`;
          isErrorRequiredQuantity = true;
        }
        if (!obj.leadTime) {
          errors[index].leadTime = `Lead Time is required`;
          isErrorRequiredQuantity = true;
        }
      }
    });
  }

  return !isErrorRequiredQuantity ? null : errors;
}

export const validationError = {
  ERROR_PRICE_MUST_BE_MORE_TARGET_PRICE_CANNOT_REACH: 'Must be more than Target Price',
};

export function prepareSaveQuantities(quoteFormData) {
  const payload = _cloneDeep(quoteFormData);
  if (payload.minimumQuantity > 0) {
    payload.requiredQuantity = payload.requiredQuantity.filter(
      (quantityObj) => quantityObj.q > payload.minimumQuantity
    );
  }

  if (quoteFormData.targetPricePerUnit > 0 && !quoteFormData.targetPriceCanNotReach) {
    const targetQuantity = {
      q: payload.targetRequiredQuantity,
      pricePerUnit: payload.targetPricePerUnit,
      leadTime: payload.targetLeadTime,
      target: true,
    };

    payload.requiredQuantity.push(targetQuantity);
    payload.requiredQuantity.sort((i1, i2) => i1.q - i2.q);
  }

  delete payload.targetRequiredQuantity;
  delete payload.targetPricePerUnit;
  delete payload.targetLeadTime;

  if (payload.minimumQuantity > 0) {
    payload.requiredQuantity.unshift({
      q: payload.minimumQuantity,
      pricePerUnit: payload.pricePerUnit,
      leadTime: payload.leadTime,
    });
  }

  delete payload.minimumQuantity;
  delete payload.pricePerUnit;
  delete payload.leadTime;

  return payload;
}

export function prepareSaveSamples(quoteFormData) {
  const payload = _cloneDeep(quoteFormData);

  if (!(payload.samplesTotalPrice > 0)) {
    delete payload.samplesTotalPrice;
    delete payload.samplesLeadTime;
    delete payload.samplesShippingCost;
  }

  delete payload.samplesNumber;

  return payload;
}

export function afterChangeTargetRequiredQuantity(values, targetRequiredQuantity, product) {
  let data = { ..._cloneDeep(values), targetRequiredQuantity: targetRequiredQuantity };
  Object.keys(data.requiredQuantity).forEach((i) => {
    if (data.requiredQuantity[i].q === parseInt(targetRequiredQuantity, 10)) {
      if (!data.requiredQuantity[i].pricePerUnit) {
        data.requiredQuantity[i].pricePerUnit = product.targetPrice;
      }
      if (!data.requiredQuantity[i].leadTime) {
        data.requiredQuantity[i].leadTime = data.targetLeadTime;
      }
    }
    if (
      data.minimumQuantity === targetRequiredQuantity &&
      data.pricePerUnit === product.targetPrice &&
      !data.targetLeadTime
    )
      data.targetLeadTime = data.leadTime;
  });
  return data;
}

export const debouncedAfterChangeTargetRequiredQuantity = _debounce(
  (props, value) => {
    const { product, values, setValues } = props;
    const data = afterChangeTargetRequiredQuantity(values, value, product);
    setValues(data);
  },
  400,
  { trailing: true }
);

export const SignupValidationScheme = {
  fullName: Yup.string().required('Full Name is required'),
  companyName: Yup.string().required('Company Name is required'),
  email: Yup.string().required('Email Address is required'),
  password: Yup.string().required('Password is required'),
};

export const QuantitiesValidationScheme = {
  targetPricePerUnit: Yup.number().notRequired(),
  targetPriceCanNotReach: Yup.bool().notRequired(),
  targetRequiredQuantity: Yup.mixed().when(['targetPricePerUnit', 'targetPriceCanNotReach'], {
    is: (targetPricePerUnit, targetPriceCanNotReach) =>
      targetPricePerUnit > 0 && !targetPriceCanNotReach,
    then: quantityValidationRule
      .required('Target Required Quantity is required')
      .test(
        'target-required-quantity-more-moq',
        'Target Required Quantity can not be less than Minimum Quantity',
        function(targetRequiredQuantity) {
          const { minimumQuantity } = this.parent;
          const moq = parseInt(minimumQuantity, 10);
          const trq = parseInt(targetRequiredQuantity, 10);
          if (moq > 0) {
            return trq >= moq;
          } else {
            return true;
          }
        }
      ),
    otherwise: Yup.mixed().notRequired(),
  }),
  targetLeadTime: Yup.number()
    .integer()
    .positive('Only positive numbers allowed')
    .when(['targetPricePerUnit', 'targetPriceCanNotReach'], {
      is: (targetPricePerUnit, targetPriceCanNotReach) =>
        targetPricePerUnit > 0 && !targetPriceCanNotReach,
      then: Yup.number().required('Lead Time is required'),
      otherwise: Yup.number().notRequired(),
    }),
  minimumQuantity: Yup.number()
    .integer()
    .positive()
    .required('Minimum Quantity is required'),
  pricePerUnit: Yup.number()
    .required('Price Per Unit is required')
    .when('targetPriceCanNotReach', {
      is: true,
      then: Yup.number().test(
        'price-per-unit-leq-target-price-cannot-reach',
        validationError.ERROR_PRICE_MUST_BE_MORE_TARGET_PRICE_CANNOT_REACH,
        function(pricePerUnit) {
          const { targetPricePerUnit } = this.parent;
          return pricePerUnit > targetPricePerUnit;
        }
      ),
    })
    .when(['targetRequiredQuantity', 'minimumQuantity'], {
      is: (targetRequiredQuantity, minimumQuantity) =>
        targetRequiredQuantity > 0 &&
        minimumQuantity > 0 &&
        targetRequiredQuantity === minimumQuantity,
      then: Yup.number().test(
        'price-per-unit-matches-target-price',
        'Price per unit must match Target price',
        function(pricePerUnit) {
          const { targetPricePerUnit } = this.parent;
          return pricePerUnit === targetPricePerUnit;
        }
      ),
    }),
  leadTime: Yup.number()
    .integer()
    .positive('Only positive numbers allowed')
    .required('Lead Time is required'),
};

export const ShippingValidationScheme = {
  cartonSize: Yup.string().required('Carton Size is required'),
  quantityPerCarton: quantityValidationRule.required('Quantity Per Carton is required'),
  cartonGrossWeight: Yup.number().required('Carton Gross Weight is required'),
  cartonNetWeight: Yup.number().required('Carton Net Weight is required'),
};
