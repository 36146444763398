import React from 'react';
import PropTypes from 'prop-types';
import moment from '../../../util/moment';
import { displayMoney } from '../../../util/currency';
import Popup from 'reactjs-popup';
import IconDotsHorizontal from '../../icon/IconDotsHorizontal';
import IconView from '../../icon/IconView';
import IconStar from '../../icon/IconStar';

class QuoteTableRow extends React.Component {
  static propTypes = {
    quote: PropTypes.object.isRequired,
    quantity: PropTypes.object.isRequired,
    quoteKey: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    getQuote: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    actionsPopupOpenQuote: null,
  };

  goToQuote = (qouteId) => {
    this.props.getQuote(qouteId);
    this.props.history.push(
      '/dashboard/company/' +
        this.props.companyId +
        '/project/' +
        this.props.projectId +
        '/product/' +
        this.props.productId +
        '/quotes/' +
        qouteId
    );
  };

  onSwitchFavoriteClick = function(e, id, quantity, value) {
    e.stopPropagation();
    this.props.onSwitchFavorite(id, quantity, value);
  };

  render() {
    const { quote, quantity, quantityIndex, quoteKey } = this.props;
    const quoteFavorite = quantity.favorite;

    return (
      <tr
        key={quoteKey}
        onClick={() => {
          this.goToQuote(quote._id, quote.createdByCompany._id);
        }}
      >
        <td className={'top-baseline'}>
          <span
            className="add-to-favorites"
            onClick={(e) => {
              this.onSwitchFavoriteClick(e, quote._id, quantity.q, !quoteFavorite);
            }}
          >
            <IconStar width={17} height={16} color={quoteFavorite ? '#0095FF' : 'transparent'} />
          </span>
        </td>
        <td className={'top-baseline'}>{quote.createdByCompany.name}</td>
        <td className={'top-baseline'}>{quote.createdByCompany.country}</td>
        <td className={'top-baseline'}>{quantity.q} units</td>
        <td className={'top-baseline'}>{displayMoney(quantity.pricePerUnit, quote.currency)}</td>
        <td className={'top-baseline'}>{quantity.leadTime}</td>
        <td className={'top-baseline'}>{moment(quote.validUntil).format('L')}</td>
        <td
          className={'top-baseline'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Popup
            onClick={(e) => {
              this.onOpenPopup(e);
            }}
            open={
              this.state.actionsPopupOpenQuote &&
              this.state.actionsPopupOpenQuote._id + quantityIndex === quoteKey
            }
            onOpen={() => this.setState({ actionsPopupOpenQuote: quote })}
            onClose={() => this.setState({ actionsPopupOpenQuote: null })}
            arrow={false}
            trigger={
              <div
                className={'btn action'}
                onClick={(e) => {
                  this.onOpenPopup(e);
                }}
              >
                <IconDotsHorizontal
                  width={24}
                  height={6}
                  color={
                    this.state.actionsPopupOpenQuote !== null &&
                    this.state.actionsPopupOpenQuote._id + quantityIndex === quoteKey
                      ? 'var(--active-blue-color)'
                      : '#e9e9e9'
                  }
                />
              </div>
            }
            position="bottom right"
            contentStyle={{ width: '209px', padding: '17px 10px', border: 'none' }}
          >
            <div
              className={'btn'}
              onClick={() => {
                this.goToQuote(quote._id);
                this.setState({ actionsPopupOpenQuote: null });
              }}
            >
              <div className={'icon-container'}>
                <IconView width={14} height={16} />
              </div>
              View Quote
            </div>
          </Popup>
        </td>
      </tr>
    );
  }
}

class ProductQuotesTable extends React.Component {
  static propTypes = {
    productQuotes: PropTypes.array.isRequired,
    onSwitchFavorite: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    getQuote: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  render() {
    const productQuotes =
      this.props.productQuotes &&
      this.props.productQuotes.map((quote) => {
        return quote.requiredQuantity.map((quantity, quantityIndex) => (
          <QuoteTableRow
            key={quote._id + quantityIndex}
            onSwitchFavorite={this.props.onSwitchFavorite}
            quote={quote}
            quantity={quantity}
            quoteKey={quote._id + quantityIndex}
            quantityIndex={quantityIndex}
            history={this.props.history}
            companyId={this.props.companyId}
            projectId={this.props.projectId}
            productId={this.props.productId}
            getQuote={this.props.getQuote}
          />
        ));
      });

    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Favorite</th>
              <th scope="col">Supplier</th>
              <th scope="col">Location</th>
              <th scope="col">Quantity</th>
              <th scope="col">Price</th>
              <th scope="col">Lead Time (Days)</th>
              <th scope="col">Valid</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>{productQuotes}</tbody>
        </table>
      </div>
    );
  }
}

export default ProductQuotesTable;
