import { push } from 'connected-react-router';
import {
  dashboardAddProjectSuccess,
  dashboardChangeActiveProjectIdSuccess,
  dashboardLeftMenuSetActiveItemSuccess,
  dashboardSetProjectsNewMessagesCountSuccess,
} from '../dashboard/actions';
import { sessionSetLastProjectId } from '../../util/session';

/**
 * Write new active project ID to session and state.
 * Redirect to My Products page
 *
 * @param companyId
 * @param project
 * @returns {Function}
 */
export function projectCreatedSuccess(companyId, project) {
  return (dispatch) => {
    dispatch(dashboardAddProjectSuccess(project));
    dispatch(dashboardSetProjectsNewMessagesCountSuccess(project._id, 0));
    sessionSetLastProjectId(companyId, project._id);
    dispatch(dashboardChangeActiveProjectIdSuccess(project._id));
    dispatch(dashboardLeftMenuSetActiveItemSuccess('products'));
    dispatch(push(`/dashboard/company/${companyId}/project/${project._id}/products`));
  };
}
