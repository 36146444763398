export default {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  WS_AUTH_ENDPOINT: `${process.env.REACT_APP_API_ENDPOINT}${
    process.env.REACT_APP_WS_AUTH_ENDPOINT
  }`,
  PUSHER_APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  VERSION: 'development',
};
