import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from '../../../util/moment';
import Popup from 'reactjs-popup';
import imgPlaceholder from '../../../container/dashboard/img/image-placeholder.png';
import styled from 'styled-components';
import IconDotsHorizontal from '../../icon/IconDotsHorizontal';
import IconView from '../../icon/IconView';
import IconEmail from '../../icon/IconEmail';
import IconStar from '../../icon/IconStar';

const SupplierPhotoStyled = styled.div`
  width: 100px;
  height: 100px;
  background: center/contain no-repeat;
`;

const SupplierPhotoStyledPlaceholder = styled.img`
  width: 100px;
  height: 100px;
`;

export class SuppliersTableRow extends Component {
  static propTypes = {
    supplier: PropTypes.object,
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    onSwitchFavorite: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    actionsPopupOpenSupplier: null,
  };

  onSwitchFavoriteClick = (e, supplierId, setVal) => {
    e.stopPropagation();
    this.props.onSwitchFavorite(supplierId, setVal);
  };

  goToSupplierProfile = (supplierId) => {
    this.props.history.push(
      `/dashboard/company/${this.props.companyId}/project/${
        this.props.projectId
      }/suppliers/${supplierId}`
    );
  };

  render() {
    const isChatOn = process.env.REACT_APP_CHAT_DISPLAY;
    const supplier = this.props.supplier;
    const supplierFavorite = this.props.supplier.favorite;

    return (
      <tr
        key={supplier._id}
        onClick={() => {
          this.goToSupplierProfile(supplier._id);
        }}
      >
        <td>
          {supplier.logoUrl ? (
            <SupplierPhotoStyled style={{ backgroundImage: `url(${supplier.logoUrl})` }} />
          ) : (
            <SupplierPhotoStyledPlaceholder src={imgPlaceholder} alt={''} />
          )}
        </td>
        <td>{supplier.name}</td>
        <td>{supplier.country}</td>
        <td>{supplier.quotesCount + ' Quotes Sent'}</td>
        <td>{moment(supplier.createdAt).format('L')}</td>
        <td>
          <span
            className="add-to-favorites"
            onClick={(e) => {
              this.onSwitchFavoriteClick(e, supplier._id, !supplierFavorite);
            }}
          >
            <IconStar width={17} height={16} color={supplierFavorite ? '#0095FF' : 'transparent'} />
          </span>
        </td>
        <td
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Popup
            onClick={(e) => {
              this.onOpenPopup(e);
            }}
            open={
              this.state.actionsPopupOpenSupplier &&
              this.state.actionsPopupOpenSupplier._id === supplier._id
            }
            onOpen={() => this.setState({ actionsPopupOpenSupplier: supplier })}
            onClose={() => this.setState({ actionsPopupOpenSupplier: null })}
            arrow={false}
            trigger={
              <div
                className={'btn action'}
                onClick={(e) => {
                  this.onOpenPopup(e);
                }}
              >
                <IconDotsHorizontal
                  width={24}
                  height={6}
                  color={
                    this.state.actionsPopupOpenSupplier !== null &&
                    this.state.actionsPopupOpenSupplier._id === supplier._id
                      ? 'var(--active-blue-color)'
                      : '#e9e9e9'
                  }
                />
              </div>
            }
            position="bottom right"
            contentStyle={{
              textAlign: 'left',
              width: '209px',
              padding: '17px 10px',
              border: 'none',
            }}
          >
            <div>
              <div
                className={'btn'}
                onClick={() => {
                  this.goToSupplierProfile(supplier._id);
                  this.setState({ actionsPopupOpenSupplier: null });
                }}
              >
                <div className={'icon-container'}>
                  <IconView width={14} height={16} />
                </div>
                View Supplier
              </div>
              {isChatOn && (
                <div
                  className={'btn'}
                  onClick={() => {
                    this.goToSupplierProfile(supplier._id);
                    this.setState({ actionsPopupOpenSupplier: null });
                  }}
                >
                  <div className={'icon-container'}>
                    <IconEmail width={20} height={16} />
                  </div>
                  Message Supplier
                </div>
              )}
            </div>
          </Popup>
        </td>
      </tr>
    );
  }
}

export default class SuppliersTable extends Component {
  static propTypes = {
    suppliers: PropTypes.array.isRequired,
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    onSwitchFavorite: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  render() {
    const suppliers =
      this.props.suppliers &&
      this.props.suppliers.map((supplier) => {
        return (
          <SuppliersTableRow
            key={supplier._id}
            supplier={supplier}
            companyId={this.props.companyId}
            projectId={this.props.projectId}
            history={this.props.history}
            onSwitchFavorite={this.props.onSwitchFavorite}
          />
        );
      });

    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Logo</th>
              <th scope="col">Client</th>
              <th scope="col">Location</th>
              <th scope="col">Quotes Sent</th>
              <th scope="col">Last Contact</th>
              <th scope="col">Favorite</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>{suppliers}</tbody>
        </table>
      </div>
    );
  }
}
