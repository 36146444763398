import React from 'react';
import PropTypes from 'prop-types';

const IconMuted = (props) => {
  const viewBox = `0 0 12 12`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={viewBox}
    >
      <path
        fill={props.color}
        fillRule="nonzero"
        opacity={props.opacity}
        d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm2.35 7.65c.2.2.2.5 0 .7-.1.1-.2.15-.35.15-.15 0-.25-.05-.35-.15L6 6.7 4.35 8.35c-.1.1-.2.15-.35.15-.15 0-.25-.05-.35-.15-.2-.2-.2-.5 0-.7L5.3 6 3.65 4.35c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0L6 5.3l1.65-1.65c.2-.2.5-.2.7 0 .2.2.2.5 0 .7L6.7 6l1.65 1.65z"
      />
    </svg>
  );
};

IconMuted.propsDefault = {
  opacity: 1,
};

IconMuted.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.any,
};

export default IconMuted;
