import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

class TopMenu extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    basePath: PropTypes.string.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  render() {
    const currentPath = this.props.history.location.pathname;
    const menu = this.props.items.map((item) => {
      const className = classNames('item', {
        active:
          currentPath === `${this.props.basePath}${item.name}` ||
          (item.nameAlias && currentPath === `${this.props.basePath}${item.nameAlias}`),
      });
      return (
        <a
          className={className}
          key={item.name}
          onClick={() => this.props.history.push(`${this.props.basePath}${item.name}`)}
        >
          {item.label}
        </a>
      );
    });

    return <div className="dashboard-top-menu">{menu}</div>;
  }
}

export default withRouter(TopMenu);
