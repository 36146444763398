import {
  APP_FETCHING_DATA,
  APP_IS_TAB_ACTIVE,
  APP_LOADING,
  APP_MODAL_CLOSE,
  APP_MODAL_OPEN,
  APP_SET_REDIRECT_PRODUCT_ID,
} from './constants';

export function modalOpen() {
  return {
    type: APP_MODAL_OPEN,
    openModal: true,
  };
}

export function modalClose() {
  return {
    type: APP_MODAL_CLOSE,
    openModal: false,
  };
}

export function appLoading(is) {
  return {
    type: APP_LOADING,
    loading: is,
  };
}

export function appFetchingData(is) {
  return {
    type: APP_FETCHING_DATA,
    fetchingData: is,
  };
}

export function appSetRedirectProductId(productId) {
  return {
    type: APP_SET_REDIRECT_PRODUCT_ID,
    productId,
  };
}

export function appIsTabActive(is) {
  return {
    type: APP_IS_TAB_ACTIVE,
    is,
  };
}
