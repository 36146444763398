import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import SignUpCompleteFormClient from '../../../component/sign/SignUpCompleteFormClient';
import SignUpCompleteFormSupplier from '../../../component/sign/SignUpCompleteFormSupplier';
import SignUpCompleteFormInvited from '../../../component/sign/SignUpCompleteFormInvited';
import { signUpComplete, signUpCompleteInvited } from '../actions';
import ModalCongrats from '../../../component/sign/ModalCongrats';
import { push } from 'connected-react-router';
import { modalClose } from '../../../actions';
import { isAuthorized } from '../../../util/session';

export class SignUpCompleteFormContainer extends Component {
  render() {
    let form;
    switch (this.props.user.signupType) {
      case 'client':
        form = (
          <SignUpCompleteFormClient
            user={this.props.user}
            onSubmit={this.props.signUpCompleteOnSubmit}
          />
        );
        break;
      case 'supplier':
        form = (
          <SignUpCompleteFormSupplier
            user={this.props.user}
            onSubmit={this.props.signUpCompleteOnSubmit}
          />
        );
        break;
      default:
        form = <SignUpCompleteFormInvited onSubmit={this.props.signUpCompleteInvitedOnSubmit} />;
        break;
    }
    return (
      <div>
        {this.props.signupDone && (
          <ModalCongrats
            onClick={() => {
              let redirectPath = '/dashboard';
              if (this.props.appRedirectProductId) {
                redirectPath = '/dashboard/choose-company';
              } else if (this.props.user.signupType === 'client') {
                redirectPath = `/dashboard/company/${this.props.activeCompanyId}/project/add`;
              }
              this.props.signUpCongratsFinished(redirectPath);
            }}
          />
        )}
        {isAuthorized() && !this.props.user._id ? <Redirect to="/dashboard" /> : form}
      </div>
    );
  }
}

SignUpCompleteFormContainer.propTypes = {
  signUpCompleteOnSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    signupType: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  user: state.sign.user,
  signupDone: state.sign.signupDone,
  appRedirectProductId: state.app.redirectProductId,
  activeCompanyId: state.dashboard.activeCompanyId,
});

const mapDispatchToProps = (dispatch) => ({
  signUpCompleteOnSubmit: (data, setSubmitting, setErrors) =>
    dispatch(signUpComplete(data, setSubmitting, setErrors)),
  signUpCompleteInvitedOnSubmit: (data, setSubmitting, setErrors) =>
    dispatch(signUpCompleteInvited(data, setSubmitting, setErrors)),
  signUpCongratsFinished: (redirectPath) => {
    dispatch(modalClose());
    dispatch(push(redirectPath));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUpCompleteFormContainer)
);
