import React from 'react';
import styled from 'styled-components';
import CompanyProfileCommon from '../../company/CompanyProfileCommon';
import CompanyProfileSupplier from '../../company/CompanyProfileSupplier';

const ContentStyled = styled.div`
  width: 753px;
`;

const SectionSeparatorStyled = styled.div`
  margin-top: 65px;
  margin-bottom: 65px;
`;

const SupplierProfile = (props) => {
  const { supplier } = props;
  return (
    <ContentStyled>
      <CompanyProfileCommon company={supplier} />
      {supplier && supplier.supplier && <SectionSeparatorStyled className="section-separator" />}
      <CompanyProfileSupplier supplier={supplier.supplier} />
    </ContentStyled>
  );
};

export default SupplierProfile;
