import React from 'react';
import PropTypes from 'prop-types';

const IconMessages = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <g fill={props.color} fillRule="nonzero">
        <path d="M13.333 4.174v5.565c0 .384-.298.696-.666.696H7.76l-3.094 2.782h4.666l4 2.783v-2.783h2c.368 0 .667-.311.667-.695V4.87c0-.384-.299-.696-.667-.696h-2z" />
        <path d="M11.333 0H.667C.299 0 0 .312 0 .696v7.652c0 .384.299.695.667.695h2v3.805l4.666-3.805h4c.368 0 .667-.311.667-.695V.696C12 .312 11.701 0 11.333 0z" />
      </g>
    </svg>
  );
};

IconMessages.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
};

export default IconMessages;
