import React from 'react';
import PropTypes from 'prop-types';
import { displayMoney } from '../../../util/currency';
import styled from 'styled-components';
import IconLocation from '../../icon/IconLocation';
import SpinnerLoading from '../../../component/common/SpinnerLoading';
import { incotermsList } from '../../../util/incoterms';
import './style.css';

const QuoteCreatorNameStyled = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
  color: black;
`;

const IconLocationContainerStyled = styled.div`
  margin-right: 5px;
  display: inline;
`;

class ProductQuote extends React.Component {
  static propTypes = {
    goToMessages: PropTypes.func.isRequired,
    companyType: PropTypes.string.isRequired,
    quote: PropTypes.object.isRequired,
    companyName: PropTypes.string,
    companyCountry: PropTypes.string,
    deliveryAddress: PropTypes.object,
    onClickBack: PropTypes.func,
  };

  render() {
    const isChatOn = process.env.REACT_APP_CHAT_DISPLAY;
    const { companyType, quote, companyName, companyCountry, deliveryAddress } = this.props;
    const questionsAnswers = quote.questions
      ? quote.questions.map((question) => {
          return (
            <div key={question.q}>
              <div className="section-question">{question.q}</div>
              <div className="section-answer">{question.a}</div>
            </div>
          );
        })
      : [];
    const messageButton =
      companyType === 'supplier' ? (
        <button className="btn blue-button" type="button" onClick={this.props.goToMessages}>
          Message Client
        </button>
      ) : (
        <button className="btn blue-button" type="button" onClick={this.props.goToMessages}>
          Message Supplier
        </button>
      );

    let displayTargetQuantityMarkNote = false;

    return !quote.requiredQuantity ? (
      <SpinnerLoading centered={true} />
    ) : (
      <div className={'product-quote'}>
        <div className="quote-creator-details">
          <div className="name-address">
            <QuoteCreatorNameStyled>{companyName}</QuoteCreatorNameStyled>
            <div>
              <IconLocationContainerStyled>
                <IconLocation width={11} height={15} color={'#111111'} />
              </IconLocationContainerStyled>
              {companyCountry}
            </div>
          </div>
          {isChatOn && messageButton}
        </div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Quantity</th>
              <th scope="col">Price</th>
              <th scope="col">Lead Time (Days)</th>
            </tr>
          </thead>
          <tbody>
            {quote.requiredQuantity &&
              quote.requiredQuantity.map((quantity, index) => {
                if (quantity.target) {
                  displayTargetQuantityMarkNote = true;
                }

                return (
                  <tr key={index}>
                    <td className={'top-baseline'}>
                      {quantity.q}
                      {quantity.q === 1 ? <span> unit</span> : <span> units</span>}
                      {index === 0 && <sup className="important-mark">*</sup>}
                      {quantity.target && <sup className="target-mark">*</sup>}
                    </td>
                    <td className={'top-baseline'}>
                      {displayMoney(quantity.pricePerUnit, quote.currency)}
                    </td>
                    <td className={'top-baseline'}>{quantity.leadTime}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div>
          <span className="important-mark-note">
            <span className="important-mark">* </span>
            minimum order quantity
          </span>
        </div>
        {displayTargetQuantityMarkNote && (
          <div>
            <span className="important-mark-note">
              <span className="target-mark">* </span>
              order quantity required for requested target price
            </span>
          </div>
        )}

        <div className="quote-details">
          <div className="section-header">Incoterms</div>
          <div className="section-header-description">Please review our Incoterms</div>
          <div className="quote-details-value">{incotermsList[quote.termsPreference].label}</div>
          {quote.samplesTotalPrice > 0 && (
            <div data-id="product-quote-details-samples-block">
              <div className="section-header">Samples</div>
              <div className="section-header-description">
                Please see our terms and cost for samples
              </div>
              <div className="quote-details-value">
                {quote.targetRequiredQuantity}
                <span> Samples Requested</span>
              </div>
              <br />

              {deliveryAddress && (
                <div className="shipped-to">
                  <strong>Shipped to:</strong>
                  <div>{deliveryAddress.address}</div>
                  <div>{deliveryAddress.country}</div>
                </div>
              )}
              <br />

              <span className="quote-details-value">
                {displayMoney(quote.samplesTotalPrice, quote.currency)}
              </span>
              <span> plus </span>
              <span className="quote-details-value">
                {displayMoney(quote.samplesShippingCost, quote.currency)}
                <span> Shipping </span>
              </span>
              <br />
              <br />

              <p className="gray-note">
                <span>Estimated lead time: </span>
                <span className="quote-details-value">{quote.samplesLeadTime}</span>
              </p>
              <p className="gray-note">This is refundable upon first order</p>
            </div>
          )}

          <div className="section-header">Additional Questions</div>
          {questionsAnswers}

          <div className="section-header">Shipping Details</div>
          <div className="row">
            <div className="col">Carton Size</div>
            <div className="col">{quote.cartonSize}</div>
          </div>
          <div className="row">
            <div className="col">Quantity per Carton</div>
            <div className="col">{quote.quantityPerCarton}</div>
          </div>
          <div className="row">
            <div className="col">Carton Gross Weight (Kg)</div>
            <div className="col">{quote.cartonGrossWeight}</div>
          </div>
          <div className="row">
            <div className="col">Carton Net Weight (Kg)</div>
            <div className="col">{quote.cartonNetWeight}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductQuote;
