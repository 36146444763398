import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SignForgotPasswordForm from '../../../component/sign/SignForgotPasswordForm';
import api from '../../../service/api';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../../util/error';

class SignForgotPasswordFormContainer extends Component {
  onSubmit = async (email, setSubmitting, setErrors, setStatus) => {
    try {
      await api.userEmailResetPasswordLink(email);
      setStatus('Reset Password email successfully sent');
    } catch (err) {
      setSubmitting(false);
      const errors = errorsTransformAxiosToFormik(err);
      setErrors(addCommonFormikErrorsIfEmpty(errors));
    }
  };

  render() {
    return (
      <div className="sign-forgot-password-form-container form-black-container">
        <div className="form-header">Forgot Password?</div>
        <SignForgotPasswordForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default withRouter(SignForgotPasswordFormContainer);
