export function getQuoteRange(requiredQuantity) {
  const quoteRange = {
    minPrice: 0,
    maxPrice: 0,
    minUnits: 0,
    maxUnits: 0,
  };

  requiredQuantity.forEach((quantityData) => {
    if (quantityData.q < quoteRange.minUnits || quoteRange.minUnits === 0) {
      quoteRange.minUnits = quantityData.q;
    }
    if (quantityData.q > quoteRange.maxUnits) {
      quoteRange.maxUnits = quantityData.q;
    }
    if (quantityData.pricePerUnit < quoteRange.minPrice || quoteRange.minPrice === 0) {
      quoteRange.minPrice = quantityData.pricePerUnit;
    }
    if (quantityData.pricePerUnit > quoteRange.maxPrice) {
      quoteRange.maxPrice = quantityData.pricePerUnit;
    }
  });

  return quoteRange;
}
