import React from 'react';

export default (props) => {
  return (
    <div className="chat-message attachment">
      <a href={props.file.url} target={'blank'}>
        {props.file.origName}
      </a>
    </div>
  );
};
