import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Can } from '../../util/session';
import QuotesContainer from './QuotesContainer';
import QuoteRequestFormContainer from './QuoteRequestFormContainer';
import QuoteEditFormContainer from './QuoteEditFormContainer';
import './style.css';

class QuoteContainer extends Component {
  render() {
    const basePath = `/dashboard/company/:companyId/quote/`;

    return (
      <div>
        <Switch>
          <Route
            path={`/dashboard/company/:companyId/quotes`}
            render={() => (
              <Can I="view" a="Quotes">
                <QuotesContainer />
              </Can>
            )}
          />
          <Route
            path={`${basePath}request/:productId`}
            render={() => (
              <Can I="request" a="ProductQuote">
                <QuoteRequestFormContainer />
              </Can>
            )}
          />
          <Route
            path={`${basePath}:quoteId`}
            render={() => (
              <Can I="edit" a="ProductQuote">
                <QuoteEditFormContainer />
              </Can>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(QuoteContainer);
