import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import axios, { CancelToken } from 'axios';

import TopMenu from '../../../component/dashboard/TopMenu';
import AccountMyProfile from '../../../component/account/AccountMyProfile';
import AccountMyProfileEditForm from '../../../component/account/AccountMyProfileEditForm';
import api from '../../../service/api';
import { addCommonFormikErrorsIfEmpty, errorsTransformAxiosToFormik } from '../../../util/error';
import { signInCompleteUserSuccess } from '../../sign/actions';

class AccountContainer extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object.isRequired,
      push: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    basePath: '/dashboard/account/',
  };

  cancelSourceUserPut = CancelToken.source();

  onMyProfileEditClick = () => {
    this.props.history.push(`${this.props.basePath}my-profile/edit`);
  };

  onMyProfileChangePasswordClick = () => {
    this.props.history.push(`${this.props.basePath}my-profile/change-password`);
  };

  onMyProfileEditCancel = () => {
    this.props.history.push(`${this.props.basePath}my-profile`);
  };

  onMyProfileFormSubmit = async (values, setSubmitting, setErrors) => {
    try {
      const res = await api.userPut(values, this.cancelSourceUserPut.token);
      if (!values.isChangePasswordMode) {
        this.props.onUserUpdated(res.data);
      }
      this.props.history.push(`${this.props.basePath}my-profile`);
    } catch (err) {
      if (!axios.isCancel(err)) {
        const errors = errorsTransformAxiosToFormik(err);
        setErrors(addCommonFormikErrorsIfEmpty(errors));
        setSubmitting(false);
      }
    }
  };

  componentWillUnmount() {
    this.cancelSourceUserPut.cancel();
  }

  render() {
    let topMenuItems = [
      {
        name: 'my-profile',
        label: 'My Profile',
        nameAlias: 'my-profile/edit',
      },
    ];

    return (
      <div className="account-container">
        <label className="page-header">My Account</label>
        <TopMenu items={topMenuItems} basePath={this.props.basePath} />
        <Switch>
          <Route
            exact
            path="/dashboard/account"
            render={() => <Redirect to="/dashboard/account/my-profile" />}
          />
          <Route
            exact
            path="/dashboard/account/my-profile"
            render={() => (
              <AccountMyProfile
                {...this.props.user}
                onEditClick={this.onMyProfileEditClick}
                onClickChangePassword={this.onMyProfileChangePasswordClick}
              />
            )}
          />
          <Route
            exact
            path="/dashboard/account/my-profile/edit"
            render={() => (
              <AccountMyProfileEditForm
                user={this.props.user}
                onSubmit={this.onMyProfileFormSubmit}
                onCancel={this.onMyProfileEditCancel}
              />
            )}
          />
          <Route
            exact
            path="/dashboard/account/my-profile/change-password"
            render={() => (
              <AccountMyProfileEditForm
                user={this.props.user}
                onSubmit={this.onMyProfileFormSubmit}
                onCancel={this.onMyProfileEditCancel}
                isChangePasswordMode={true}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) => ({
  onUserUpdated: (user) => dispatch(signInCompleteUserSuccess(user)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountContainer)
);
