import React from 'react';
import PropTypes from 'prop-types';

const IconSearch = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <path
        fill={props.fill}
        fillRule="nonzero"
        d="M19.634 17.866l-4.957-4.957a8.068 8.068 0 0 0 1.573-4.784C16.25 3.645 12.605 0 8.125 0S0 3.645 0 8.125s3.645 8.125 8.125 8.125a8.07 8.07 0 0 0 4.784-1.573l4.957 4.957 1.768-1.768zM1.25 8.125A6.883 6.883 0 0 1 8.125 1.25 6.883 6.883 0 0 1 15 8.125 6.883 6.883 0 0 1 8.125 15 6.883 6.883 0 0 1 1.25 8.125z"
      />
    </svg>
  );
};

IconSearch.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  fill: PropTypes.string,
};

IconSearch.defaultProps = {
  fill: '#111',
};

export default IconSearch;
